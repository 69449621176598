<template>
    <BreadCrumb PageTitle="Liste des modèles de boîte" />
    <ListeModeleBoite />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeModeleBoite from "../../components/ModeleBoite/ListeModeleBoite.vue";

  
    export default defineComponent({
      name: "ListeModeleBoitePage",
      components: {
        BreadCrumb,
        ListeModeleBoite,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des abonnements MPOSTBOX" />
    <ListeMpostBox />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeMpostBox from "../../components/MpostBox/ListeMpostBox.vue";
  
  
  export default defineComponent({
    name: "ListeMpostBoxPage",
    components: {
      BreadCrumb,
      ListeMpostBox,
    },
  });
  </script>
<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
      >
        <div class="d-sm-flex align-items-center">
        </div>
        <div class="d-flex align-items-center">
          <form class="search-box position-relative me-15">
            <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Rechercher une demande de transfert"/>
            <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0">
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
          <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  ANCIENNE AGENCE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  NOUVELLE AGENCE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  ADRESSE POSTALE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  Abonné
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  BOITE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  REFERENCE ABONNEMENT
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  DATE TRANSFERT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(demande, index) in demandeTransferts" :key="index">
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ demande.ancienneAgence.nom }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.nouvelleAgence.nom}}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.abonnement.boite ? demande?.abonnement?.boite?.adressePostale?.adresse_postale : demande?.abonnement?.adresseVirtuelle }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.abonnement?.abonne?.nomComplet}}
                </td>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ demande.abonnement.boite?.refBoite }}
                </td>
               
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.abonnement.refAbo}}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ format_date(demande.createdAt)  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
          <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
    <AddTransfertModal
    @getAllTransferts="getAllTransferts"
    :id="idTransfert"
    @openmodal="showModalEdite"
  />
  </template>
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import Swal from "sweetalert2";
  import ApiService from "@/services/ApiService";
  import { Abonnements, Abonnement } from "@/models/Abonnement";
  import { Transferts } from "@/models/Transferts";
  import { format_date,suppression, showModal, error, } from "@/utils/utils";
  import AddTransfertModal from "./AddTransfertModal.vue";
  import PaginationComponent from "@/components/Utilities/Pagination.vue";
  
  export default defineComponent({
    name: "ListeTransfert",
    components: {
        AddTransfertModal,
        PaginationComponent
    },
    setup: () => {
      onMounted(() => {
        getAllTransferts();
      });
  
      const demandeTransferts = ref<Array<Transferts>>([]);
      const abonnement = ref<Abonnement>();
      const idTransfert = ref(0);
      const searchTerm = ref('');
      const page = ref(1);
      const totalPages = ref(0);
      const limit = ref(10);
      const totalElements = ref(0);
      const recherche = ref("");
      
  
      function getAllTransferts(page = 1, limi = 10, searchTerm = '') {
        const element = {
                recherche:recherche.value,
                page:page,
                totalPages:totalPages.value,
                limit:limit.value,
                totalElements:totalElements.value,
                mot:searchTerm
            };
        return ApiService.post("/transferts/getAllTransferts",element)
          .then(({ data }) => {
            demandeTransferts.value = data.data.data
            totalPages.value = data.data.totalPages;
            limit.value = data.data.limit;
            totalElements.value = data.data.totalElements;
            return data.data;
          })
          .catch(({ response }) => {          
            error(response.data.message)     
        });
      }
    
      function moddifier(id) {
        idTransfert.value = id;
      }

      function showModalEdite(model){
        showModal(model);
        idTransfert.value=0;
      }
  
      const deleteAbonne = (id: number) => {
        ApiService.delete(`/demandes/${id}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: 'success',
            title: 'General Title',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Rssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
  
        for(let i = 0; i < demandeTransferts.value.length; i++) {
          if (demandeTransferts.value[i].id === id) {
            demandeTransferts.value.splice(i,1);
          }
        }
      };
      const handlePaginate = ({ page_, limit_ }) => {
          try {
            page.value = page_;
            getAllTransferts(page_, limit_);
          } catch (error) {
            //
            
          }
        };
      

      return {AddTransfertModal,handlePaginate,showModalEdite,searchTerm,totalElements,limit,totalPages,page, demandeTransferts,idTransfert,format_date,suppression,getAllTransferts,deleteAbonne,moddifier, };
    },
    methods:{
      
    }
  });
  </script>
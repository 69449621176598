<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-abonnes" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>NOM & PRENOMS ou DENOMINATION</td>
                  <td>{{ abonne?.nomComplet }}</td>
                </tr>
                <tr>
                  <td>Pays de résidence</td>
                  <td>{{ abonne?.nationalite }}</td>
                </tr>
                <tr>
                  <td>Téléphone</td>
                  <td>{{ abonne?.telephone }}</td>
                </tr>
                <tr v-if="abonne?.dateNaissance">
                  <td v-if="abonne?.categorieAbonne?.id === 1">Date de naissance</td>
                  <td v-else>Date de création</td>
                  <td>{{ format_Date(abonne.dateNaissance) }}</td>
                </tr>
                <tr v-if="abonne?.email">
                  <td>Email</td>
                  <td>{{ abonne?.email }}</td>
                </tr>
                <tr v-if="abonne?.categorieAbonne">
                  <td v-if="abonne?.categorieAbonne?.id === 1">Sexe</td>
                  <td v-if="abonne?.categorieAbonne?.id === 1">{{ abonne?.sexe }}</td>
                </tr>
                <tr v-if="abonne?.profession">
                  <td>Profession</td>
                  <td>{{ abonne?.profession }}</td>
                </tr>
                <tr v-if="abonne?.adresseResidence">
                  <td>Adresse de résidence</td>
                  <td>{{ abonne?.adresseResidence }}</td>
                </tr>
                <tr v-if="abonne?.villeResidence">
                  <td>Ville de résidence</td>
                  <td>{{ abonne?.villeResidence }}</td>
                </tr>
                <tr>
                  <td>Catégorie de l'abonné</td>
                  <td>{{ abonne?.categorieAbonne?.libelle }}</td>
                </tr>
                <tr>
                  <td>Secteur d'activité</td>
                  <td>{{ abonne?.activite?.libelle }}</td>
                </tr>
                <tr>
                  <td>Image</td>
                  <td> 
                     <img :src="getUrlApiForFiles('abonnes',abonne?.urlImage)" alt="Image" width="500" height="500">

                    <!-- <a href="javascript:;" class="btn btn-default bg-primary text-white" @click="showImage(abonne.id)">
                      <i class="flaticon-eye position-relative top-1"></i>
                      Voir la ressource
                    </a>  -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>

  <!-- MODAL -->
    <div
    class="modal fade createNewModal"
    id="Image"
    tabindex="-1"
    ref="ImageRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body pt-0 ps-0 pe-0 pb-0">
          <div>
            <img :src="image_" alt="" />
          </div>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
  <!-- ENDMODAL -->

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnes } from "@/models/Abonne";
import { error, format_Date, showModal, getUrlApiForFiles } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewAbonne",
  setup: () => {
    const route = useRoute();
    const abonne = ref<Abonnes | null>(null);
    const imageBaseUrl = process.env.VUE_APP_SERVER_ADDRESS+'/uploads/abonnes/' || 'http://localhost:3001/uploads/abonnes/';
    const image_ = ref("");
    const imageName = ref("");
    const ImageRef = ref<null | HTMLElement>(null);

    function getAbonne(id: string) {
      return ApiService.get("/abonnes", id)
        .then(({ data }) => {
          abonne.value = data.data; 
          imageName.value = abonne.value?.urlImage || "";
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    function showImage(id: number) {

      image_.value = imageBaseUrl+'/'+abonne.value?.urlImage || "";
      if (abonne.value) {
        showModal(ImageRef.value);
      }
    }

    onMounted(() => {
      const id = route.params.id as string;
      getAbonne(id);
    });

    return { 
      abonne,
      getAbonne,
      format_Date,
      showImage,
      image_,
      ImageRef,
      getUrlApiForFiles
     };
  },
});
</script>

<template>
    <canvas ref="chartCanvas"></canvas>
</template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { Chart, registerables,ChartData, ChartOptions } from 'chart.js';
  
  export default defineComponent({
    name: 'ChartJsChart',
    props: {
      chartData: {
        type: Object as () => ChartData,
        required: true
      },
      chartOptions: {
        type: Object as () => ChartOptions,
        default: () => ({})
      }
    },
    setup(props) {
      const chartCanvas = ref<HTMLCanvasElement | null>(null);
      let chartInstance: Chart | null = null;
  
      onMounted(() => {
        if (chartCanvas.value) {
          const ctx = chartCanvas.value.getContext('2d');
          if (ctx) {
            chartInstance = new Chart(ctx, {
              type: 'polarArea',
              data: props.chartData,
              options: props.chartOptions,
            });
          }
        }
      });
  
      return {
        chartCanvas
      };
    }
  });
  </script>

<template>
    <BreadCrumb PageTitle="Faire un réabonnement" />
    <AddPaiement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddPaiement from "../../components/Paiement/AddPaiement.vue";
  
    export default defineComponent({
      name: "AddPaiementPage",
      components: {
        BreadCrumb,
        AddPaiement,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des types de demandes" />
    <ListeTypeDemande />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeTypeDemande from "../../components/TypeDemande/ListeTypeDemande.vue";

  
    export default defineComponent({
      name: "ListeTypeDemandePage",
      components: {
        BreadCrumb,
        ListeTypeDemande,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des campagnes" />
    <ListeCampagne/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCampagne from "../../components/Campagne/ListeCampagne.vue";

    export default defineComponent({
      name: "ListeCampagnePage",
      components: {
        BreadCrumb,
        ListeCampagne,
      },
    });
  </script>
import { Modal } from "bootstrap";
import moment from "moment";
import ApiService from "@/services/ApiService";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { defineComponent, onMounted, ref, watch } from "vue";
//import { format } from 'date-fns/esm';
import { fr } from 'date-fns/locale';
import tinymce from 'tinymce';
import JwtService from "@/services/JwtService";

const initTinyMCE = (target, config) => {
  return tinymce.init({
    target,
    ...config,
  });
};

const decouperEtJoindre = (chaine: string, separateur: string, nombreCaratere:number): string =>{
  const blocs: string[] = [];
  for (let i = 0; i < chaine.length; i += nombreCaratere) {
      blocs.push(chaine.substring(i, i + nombreCaratere));
  }
  return blocs.join(separateur);
}

const  getDatePlusXDays = (x: number)=>{
  const currentDate = new Date();
  const futureDate = new Date();
  
  futureDate.setDate(currentDate.getDate() + x);
  // Obtenez les composants de la date
  const year = futureDate.getFullYear();
  const month = (futureDate.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0
  const day = futureDate.getDate().toString().padStart(2, '0');

  // Formattez la date comme "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

const destroyTinyMCE = (editor) => {
  if (editor) {
    editor.destroy();
  }
};


const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  
  const myModal = Modal.getInstance(modalEl);
  myModal?.hide();
};

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal?.show()
};

const getUrlApiForFiles = (dossier:string|null, nomFichier:string|null)=>{
  if(dossier){
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/${dossier}/${nomFichier}`;
  }else{
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/Erreur404.pdf`;
  }
}


const  success = (message) => {
  Swal.fire({
    title: 'Succès',
    text: message,
    icon: "success",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false,
  });
}

const error = (message) => {
  Swal.fire({
    title: 'Erreur',
    text: message,
    icon: "error",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false
  });
}

const  format_date = (value)=>{
  if (value) {
    return format(new Date(value), 'dd-MM-yyyy HH:mm', { locale: fr });
    //return moment(String(value)).format('DD-MM-YYYY hh:mm')
  }
}
const format_Date = (date):string|undefined => {
  if (date) {
  return format(new Date(date), 'dd-MM-yyyy', { locale: fr });
}
}

const separateur = (montant)=>{ 
  if(montant){
    return montant.toLocaleString('fr-FR');
  }
}

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};


const getAssetPath = (path: string): string => {
    return '' + path;
};

function getAllCategorieAbonnes(route:string,element:any) {
  return ApiService.get(`/${route}`)
  .then(({ data }) => {
    element.value = data.data
  })
  .catch(({ response }) => {
    //
  });
} 

import Chart from 'chart.js/auto';

interface ChartData {
    labels: string[];
    data: number[];
    backgroundColors: string[];
}

const createDonutChart = (chartData: ChartData, canvasId: string): void => {
    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: chartData.labels,
            datasets: [{
                data: chartData.data,
                backgroundColor: chartData.backgroundColors
            }]
        },
        options: {
            cutout: 70, // Pourcentage  de découpe du centre //cutoutPercentage
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        color: '#333',
                        font: {
                          size:14
                        }
                    }
                }
            }
        }
    });
}


const suppression = (id:number,element:any, route:string, entite:string) => {
  Swal.fire({
      text: "Vous êtes sur le point de supprimer " + entite +". Etes-vous sûr ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: `Annuler`,
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
          ApiService.delete(`/${route}/${id}`)
          .then(({ data }) => {
              Swal.fire({
                title: 'Succès',
                text: data.message,
                icon: "success",
                toast: true,
                timer: 5000,
                position: 'top-right',
                showConfirmButton: false,
              });
              for(let i = 0; i < element.length; i++) {
                if (element[i].id === id) {
                  element.splice(i, 1);
                }
              }
        }).catch(({ response }) => {
            Swal.fire({
              text: response.data.message, //'Oups il y a un problème',//
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Réssayer à nouveau!",
              heightAuto: false,
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
        });
      } else if (result.isDenied) {
          Swal.fire("La suppression n'est pas passée", "", "info");
      }
      });
};



async function telechargerFichier(nomFichier, donnees) {
  const blob = new Blob([donnees], { type: 'application/pdf' });

  const url = window.URL.createObjectURL(blob);

  try {
    const link = document.createElement('a');
    link.href = url;
    link.download = nomFichier;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Une erreur s\'est produite lors du téléchargement du fichier :', error);
  } finally {
    window.URL.revokeObjectURL(url);
  }
}
const telechargerFichier2 = async(fin:string, nomFichier:string) => {
  const ele = "rien";
  await ApiService.get(`/telecharger?nomFichier=${nomFichier}&fin=${fin}&ele=${ele}`)
  .then(response => {
    telechargerFichier(nomFichier, response.data )
  })
  .catch(error => {
    console.error('Une erreur s\'est produite lors du téléchargement du fichier :', error);
  });
}

const openAndPrint = (url: string) => {
  const newWindow = window.open(url, '_blank');

  if (newWindow) {
      newWindow.onload = () => {
          newWindow.print();
      };
  } else {
      console.error('Impossible d\'ouvrir la fenêtre');
  }
};

const generateRandomColor =(): string =>{
  // Générer trois valeurs hexadécimales aléatoires pour les composantes rouge, verte et bleue
  const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  // Combiner les valeurs pour former le code couleur hexadécimal
  const color = `#${red}${green}${blue}`;
  
  return color;
}

const randomColor =() =>{
  const r = Math.floor(Math.random() * 256); // Rouge
  const g = Math.floor(Math.random() * 256); // Vert
  const b = Math.floor(Math.random() * 256); // Bleu
  const a = Math.random() * 0.8 + 0.1; // Opacité entre 0.1 et 0.9
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}


function randomColorName() {
  const colorNames = [
    'rouge', 'vert', 'bleu', 'jaune', 'orange', 'violet', 'rose', 'blanc', 'noir', 'gris',
    'cyan', 'magenta', 'indigo', 'argent', 'or', 'bronze', 'aubergine', 'lavande', 'marine', 'turquoise',
    'menthe', 'saphir', 'rubis', 'émeraude', 'améthyste', 'topaze', 'ivoire', 'ébène', 'caramel', 'ambre',
    'chocolat', 'sable', 'neige', 'brume', 'fumée', 'orage', 'cerise', 'abricot', 'banane', 'cacao',
    'citron', 'framboise', 'myrtille', 'kiwi', 'melon', 'mangue', 'ananas', 'pomme', 'pêche', 'prune',
    'raisin', 'tomate', 'cerise', 'canneberge', 'cerise', 'cassis', 'aubergine', 'olive', 'moutarde', 'rouille',
    'ocre', 'citron vert', 'ambre', 'beige', 'ocre', 'blanc cassé', 'ivoire', 'lavande', 'pervenche', 'rose bonbon',
    'coquelicot', 'magenta', 'aigue-marine', 'cyan', 'saphir', 'céruléen', 'émeraude', 'malachite', 'vert olive', 'or',
    'cuivre', 'rouge vif', 'cramoisi', 'fuchsia', 'turquoise', 'cobalt', 'bleu électrique', 'lavande', 'bleu nuit', 'bleu royal',
    'argent', 'gris perle', 'noir de jais', 'brun', 'chocolat', 'terre de sienne', 'ardoise', 'anthracite', 'charbon', 'gris foncé'
  ];

  const randomIndex = Math.floor(Math.random() * colorNames.length);
  return colorNames[randomIndex];
}



export { 
  getDatePlusXDays,randomColorName, randomColor,generateRandomColor,openAndPrint,createDonutChart,getUrlApiForFiles,decouperEtJoindre,telechargerFichier2, removeModalBackdrop,suppression,separateur, hideModal, getAssetPath,format_Date, showModal, format_date, success, error,initTinyMCE,destroyTinyMCE,
};


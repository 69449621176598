<template>
    <BreadCrumb PageTitle="Vérification de la demande de cession" />
    <AcceptationCession />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AcceptationCession from "../../components/Cession/AcceptationCession.vue";
  
    export default defineComponent({
      name: "AcceptationCessionPage",
      components: {
        BreadCrumb,
        AcceptationCession,
      },
    });
  </script>
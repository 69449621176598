<template>
    <BreadCrumb PageTitle="Ajouter une clé-type de notification" />
    <AddCleTypeNotificationModal />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddCleTypeNotificationModal from "../../components/CleTypeNotification/AddCleTypeNotificationModal.vue";
  
    export default defineComponent({
      name: "AddCleTypeNotificationPage",
      components: {
        BreadCrumb,
        AddCleTypeNotificationModal,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Statistique M-post Box" />
    <div class="row">
      <div class="col-lg-6">
        
  
        <PolarAreaChart v-if="etatDemandeLibelle.length > 0" 
          :colors="colorsDemandeLibelle"  
          :labels="etatDemandeLibelle" 
          :serie="etatDemandeDonne" 
          nom="Nombre de demandes par type de demande " /> 
  
      </div>
      <div class="col-lg-6">
        
        
        <PerformanceChart  v-if="etatDemandeStatusDonne.length > 0" 
          :colors="colors"  
          :labels="etatDemandeStatusLibelle" 
          :project="etatDemandeStatusDonne" 
          nom="Nombre de demandes par statut "/>
        
      </div>
      <!-- <div class="col-lg-12">
        <div
          class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
          <WeeklySales />
        </div>
      </div> -->
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent ,onMounted, ref} from "vue";
  // import BarChart from "./BarChart.vue";
  // import PieChart from "./PieChart.vue"; 
  // import DonutChart from "./DonutChart.vue";
  import PerformanceChart from "./PerformanceChart.vue";
  import PolarAreaChart from "./PolarAreaChart.vue";
  import ApiService from "../../../services/ApiService";
  
  export default defineComponent({
    name: "DemandeChart",
    components: {
      // BarChart,
      // PieChart,
      // DonutChart,
      PerformanceChart,
      PolarAreaChart,
    },
  
    setup: () => {
        const  colorsDemandeLibelle = ["#0a59a4", "#f3c148", "#d4623a","#c80101" ,"#198754", "#1FB1E6","#007aff"];
        const  colors = ["#0a59a4", "#f3c148", "#d4623a","#c80101" ];
        const project = [60, 80, 100];
        const labels = ["Lesson Discuss", "Taking Class", "Work In Progress"];
        const categories = [2019,2020, 2021, 2022, 2023, 2024];
        const color =["#0a59a4", "#c80101" ];
        const numberChart = ref<Array<{name: string,data: number[]}>>([]);
  
        const numberChartCa = ref<Array<{name: string,data: number[]}>>([]);
        
        const etatDonne = ref<number[]>([]);
        const etatLibelle = ref<string[]>([]);
        const etatDemandeLibelle = ref<number[]>([]);
        const etatDemandeDonne = ref<string[]>([]);
  
        const etatDemandeStatusLibelle = ref<string[]>([]);
        const etatDemandeStatusDonne = ref<number[]>([]);
  
        const etatLibelleCat = ref<string[]>([]);
        const etatDonneCat = ref<number[]>([]);
  
        const etatLibelleAnne = ref<string[]>([]);
        const etatDonnePaye = ref<number[]>([]);
        const etatDonneImpaye = ref<number[]>([]);
        const etatDonneArriere = ref<number[]>([]);
  
        const etatLibelleAnneCa = ref<string[]>([]);
        const etatDonneChiffre = ref<number[]>([]);
        
  
    const fetchAbonnementByStatus = async () => {
        try {
          etatLibelle.value = [];
          etatDonne.value = [];
          const response = await ApiService.get('/status/abonnements/0');
          const etatDonneee = response.data.data.data;
            for (let i = 0; i < etatDonneee.raw.length; i++) {
              etatLibelle.value.push(etatDonneee.raw[i]["a_etat"] == null ? "NEANT" : etatDonneee.raw[i]["a_etat"]);
              etatDonne.value.push(parseInt(etatDonneee.raw[i]["nombreAbonnment"]));
            }
  
            console.log(etatLibelle.value,'777799999999999777',etatDonne.value);
  
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
  
      const fetchAbonnementEvolution = async () => {
        try {
          etatLibelleAnne.value = [];
          etatDonnePaye.value = [];
          etatDonneImpaye.value = [];
          etatDonneArriere.value = [];
  
          const response = await ApiService.get('/stat/evolution/abonnement/annee/0');
          const etatDonneee = response.data.data.data;
          for (let i = 0; i < etatDonneee.raw.length; i++) {
            etatDonnePaye.value.push(parseInt(etatDonneee.raw[i]["totalPaye"]));
            etatDonneImpaye.value.push(parseInt(etatDonneee.raw[i]["totalIMPAYES"]));
            etatDonneArriere.value.push(parseInt(etatDonneee.raw[i]["totalARRERES"]));
            etatLibelleAnne.value.push((etatDonneee.raw[i]["annee"]).toString());
          }
  
          numberChart.value = [
            {
              name: "Payés",
              data: etatDonnePaye.value,
            },
            {
              name: "Impayés",
              data: etatDonneImpaye.value,
            },
            {
              name: "Arriere",
              data: etatDonneArriere.value,
            },
          ]
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
      const fetchChifresAfaire = async () => {
        try {
          etatLibelleAnneCa.value = [];
          etatDonneChiffre.value = [];
          const response = await ApiService.get('/stat/chiffre/affaire/par/an/0');
          
          const etatDonneee = response.data.data;
          for (let i = 0; i < etatDonneee.length; i++) {
            etatDonneChiffre.value.push(parseInt(etatDonneee[i]["ca"]));
            etatLibelleAnneCa.value.push((etatDonneee[i]["annee"]).toString());
          }
          numberChartCa.value = [
            {
              name: "Chiffre d'affaire",
              data: etatDonneChiffre.value,
            },
          ];
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
  
      const fetchAbonnementByCategorie = async () => {
        try {
          etatLibelleCat.value = [];
          etatDonneCat.value = [];
          const response = await ApiService.get('etats/abonnements/par/categorie/0');
          const etatDonnee = response.data.data.data;
          console.log(etatDonnee,'cataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', response);
          for (let i = 0; i < etatDonnee.raw.length; i++) {
            etatLibelleCat.value.push(etatDonnee.raw[i]["cate_code"] == null ? "NEANT" : etatDonnee.raw[i]["cate_code"]);
            etatDonneCat.value.push(parseInt((etatDonnee.raw[i]["nombreAbonnment"]).toString()));
          }
        
          console.log(etatLibelleCat,'cataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', etatDonneCat);
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
      const fetchDemandeByType = async () => {
        try {
          etatDemandeLibelle.value = [];
          etatDemandeDonne.value = [];
          const response = await ApiService.get('/etats/demande/par/0');
          const etatDonnee = response.data.data;
            for (let i = 0; i < etatDonnee.length; i++) {
              etatDemandeLibelle.value.push(etatDonnee[i]["type"] == null ? "NEANT" : etatDonnee[i]["type"]);
              etatDemandeDonne.value.push(etatDonnee[i]["nombreDemande"]);
            }
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
      const fetchDemandeByStatus = async () => {
        try {
          etatDemandeStatusLibelle.value = [];
          etatDemandeStatusDonne.value = [];
          const response = await ApiService.get('/stat/abonnements/demande/par/status/0');
          const etatDonnee = response.data.data;
          console.log(etatDonnee,'zzzzzzzzzzzzzzzzzzzzzzzzzzz');
            for (let i = 0; i < etatDonnee.length; i++) {
              etatDemandeStatusLibelle.value.push(etatDonnee[i]["status"] == null ? "NEANT" : etatDonnee[i]["status"]);
              etatDemandeStatusDonne.value.push(parseInt(etatDonnee[i]["nombreDemande"]));
            }

            console.log(etatDonnee,'zzzzzzzzzzzzzzzzzzzzzzzzzzz',etatDemandeStatusDonne);
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
        }
      };
  
      onMounted( async () => {
       // await fetchAbonnementByStatus();
        await fetchDemandeByType();
        //await fetchAbonnementByCategorie();
        //await fetchAbonnementEvolution();
        //await fetchChifresAfaire();
        await fetchDemandeByStatus();
      });
  
        return {
          colors,
          etatDonne,
          etatLibelle,
          etatDonneCat,
          etatLibelleCat,
          etatDemandeLibelle,
          etatDemandeDonne,
          colorsDemandeLibelle,
          color,
          project,
          labels,
          categories,
          numberChart,
          etatLibelleAnne,
          numberChartCa,
          etatLibelleAnneCa,
          etatDemandeStatusLibelle,
          etatDemandeStatusDonne,
        }
    }
    });
  </script>
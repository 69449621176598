<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="paiementServiceForm" @submit="payer" :validation-schema="paiementServiceSchema">
        <div class="row">
          <!-- <div class="col-md-4"> -->
          <!-- <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement <span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'abonnement"
                  />
                </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
          </div> -->
          <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement<span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :filter-results="false" :min-chars="2"
                  :resolve-on-load="false" :delay="0" :searchable="true" :options-limit="300" :options="async (query) => {
                    //  return await fetchAbonnements(query)
                    const results = await fetchAbonnements(query);
                    if (results && results.length > 0) {
                      return results;
                    } else if (query.length >= 3) {
                      return [{ value: '', label: 'Aucun enregistrement trouvé' }];
                    } else {
                      return [];
                    }
                  }" noOptionsText="Tapez au moins trois caractères" placeholder="Rechercher un abonnement" />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Date <span class="text-danger">*</span>
            </label>
            <Field name="datePaiement" type="date" 
            class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
              <ErrorMessage name="datePaiement" class="text-danger"/>
          </div>
        </div> -->
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Service <span class="text-danger">*</span>
              </label>
              <Field name="service" v-slot="{ field }">
                <Multiselect :options="serviceOptions" :searchable="true" v-model="field.value" v-bind="field"
                  @change="serviceChange($event)" placeholder="Sélectionner le service" />
              </Field>
              <ErrorMessage name="service" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mode de paiement <span class="text-danger">*</span>
              </label>
              <Field name="modePaiement" v-model="mode" v-slot="{ field }">
                <Multiselect :options="modePaiementOptions" :searchable="true" v-model="field.value" v-bind="field"
                  placeholder="Sélectionner le mode de paiement" />
              </Field>
              <ErrorMessage name="modePaiement" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant payé <span class="text-danger">*</span>
              </label>
              <Field name="montantPaye" type="text" readonly="true" v-model="montant"
                class="form-control shadow-none fs-md-15 text-black" placholder="Entrer le montant payé" />
              <ErrorMessage name="montantPaye" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                Ajouter un paiement service
              </button>
              <router-link to="/liste-paiement_services" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, toRef, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import { error, hideModal, success, getUrlApiForFiles, openAndPrint } from '@/utils/utils';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { KKIAPAY } from '@/models/KKIAPAY';

export default defineComponent({
  name: "AddPaiementService",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const paiementServiceSchema = Yup.object().shape({
      //datePaiement: Yup.date().required('La date est obligatoire'),
      montantPaye: Yup.number().typeError('Entrer un nombre').required('Le paiement est obligatoire'),
      abonnement: Yup.number().typeError('Entrer un nombre').required('L\'abonnement est obligatoire'),
      service: Yup.string().required('Le service est obligatoire'),
      modePaiement: Yup.number().typeError('Entrer un nombre').required('Le mode de paiement est obligatoire'),
      // typePaiement: Yup.string().required('Le typ de paiement est obligatoire'),
    });

    const paiementServiceForm = ref(null);
    const abonnements = ref(null);
    const abonnementOptions = ref([]);
    const modePaiements = ref(null);
    const modePaiementOptions = ref([]);
    const services = ref(null);
    const serviceOptions = ref([]);
    const montant = ref(0);
    const router = useRouter();
    const mode = ref(1);


    function openNewWindow(data: any) {
      console.log("VOIIILA   ===> ", data)
      openAndPrint(getUrlApiForFiles("Recu", data.refPaiement + ".pdf"));
    }

    const addPaiementService = async (values: any, { resetForm }) => {
      values.service = values.service.split('-')[0];
      if (values.modePaiement == 1) {
        values.refPaiement = 0;
        values.montantRestant = 0;
        values.montantRecu = 0;
      }

      ApiService.post("/paiements", values)
        .then(async ({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "ListePaiementServicePage" });
            const result = await Swal.fire({
              html: `<div>Cliquer sur le bouton Aperçu ci-dessous pour avoir un aperçu de votre document</div>`,
              icon: "success",
              buttonsStyling: true,
              confirmButtonText: "Aperçu",
              showCancelButton: false,
              cancelButtonText: `Fermer`,
              allowOutsideClick: false,
              allowEscapeKey: false,
              heightAuto: false,
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-warning",
              },
            });

            if (result.isConfirmed) {
              openNewWindow(data.data);
            }
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };



    const fetchAbonnements = async (key) => {
      try {
        const response = await axios.get('/abonnements/recherche/' + key);
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id,
          // label: abonnement.abonne.nomComplet+' [ '+abonnement.boite.refBoite+' ]',
          label: `${abonnement.refAbo} - ${abonnement.abonne.nomComplet} - [${abonnement.adresseVirtuelle ? abonnement.adresseVirtuelle : abonnement.boite?.adressePostale?.adresse_postale}]`,
        }));
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }

    const fetchModePaiements = async () => {
      try {
        const response = await axios.get('/modepaiements');
        const modePaiementsData = response.data.data.data;
        modePaiementOptions.value = modePaiementsData.map((modePaiement) => ({
          value: modePaiement.id,
          label: modePaiement.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const serviceChange = async (e) => {
      if (e) {
        montant.value = e.split('-')[1];
      }
    }

    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        const servicesData = response.data.data;
        serviceOptions.value = servicesData.map((service) => ({
          value: service.id + '-' + service.cout,
          label: service.libelle,
        }));
      } catch (error) {
        //
      }
    };

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addPaiementService(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      if (values.modePaiement == 1) {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montantPaye + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            values["check"] = check;
            addPaiementService(values, { resetForm });
          }
          // 
          //   
        } catch (error) {
          //
        }
        resetForm();
      }
      if (values.modePaiement == 2) {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montantPaye + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            KKIAPAY.kkia(values.montantPaye);
            await new Promise<void>((resolve) => {
              addKkiapayListener('failed', (event) => {
                failHandler(event);
                resolve();
              });

              addKkiapayListener('success', (event) => {
                successHandler(event, values, { resetForm });
                resolve();
              });
              const checkRef = toRef(check);

              let resolved = false;

              // Résoudre la promesse après avoir traité le résultat du paiement
              const intervalId = setInterval(() => {
                if (!resolved && checkRef.value.typeError !== "RAS") {
                  clearInterval(intervalId);
                  resolved = true;
                  resolve(); // Ajoutez ici un argument (par exemple, undefined)
                }
              }, 500); // Vérifier toutes les 500 millisecondes
            });
          }

        } catch (error) {
          // Gérer les erreurs ici
        }
      }
    };

    onMounted(() => {
      fetchModePaiements();
      fetchServices();
    });

    return {
      paiementServiceSchema,
      addPaiementService,
      fetchAbonnements,
      paiementServiceForm,
      abonnements,
      modePaiements,
      abonnementOptions,
      modePaiementOptions,
      services,
      serviceOptions,
      serviceChange,
      montant,
      mode,
      openNewWindow,
      payer
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
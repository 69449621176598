<template>
  <div class="row">

    <div class="col-lg-6 col-xxxl-6 col-md-6">
      <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box ">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
           Nombre d'abonnements
          </span>
          <h4 class="fw-black mb-12 lh-1">{{somAbon}}</h4>
         <!-- <span class="fw-bold text-success text-badge d-inline-block">
            {{""}}
          <i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"> </i>
          </span>-->
          <ul class="list ps-0 mb-0 list-unstyled mt-15">
            <li v-for="(abo, index) in abonnemntsParStatus"  :key="index" class="text-muted position-relative fw-medium">
              Abonnements {{ abo.etat }}s = <span class="text-black fw-bold">{{abo.nombreAbonnment}}</span>
            </li>
          </ul>
          <div id="earningChart" class="chart">
            <apexchart
              type="donut"
              height="100"
              :options="earningChart"
              :series="demandes"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
     <div class="col-lg-6 col-xxxl-6 col-md-6">
      <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box ">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
            Nombre de boîtes postales
          </span>
          <h4 class="fw-black mb-12 lh-1">{{ somBoit }}</h4>
         <!--  <span class="fw-bold text-success text-badge d-inline-block">
            {{ ""}}
           <i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"> </i>
          </span>-->
          <ul class="list ps-0 mb-0 list-unstyled mt-15">
            <li v-for="(boite, index) in boitesParStatus" :key="index" class="text-muted position-relative fw-medium">
              Boite {{boite.etat}} = <span class="text-black fw-bold">{{ boite.nombre }}</span>
            </li>
            
          </ul>
          <div id="earningChart" class="chart">
            <!--<apexchart
              type="donut"
              height="100"
              :options="earningChart"
              :series="abonnement"
            ></apexchart>-->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xxxl-12 col-md-12">
      <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box ">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
            Nombre de demandes par type de demandes
          </span>
          <h4 class="fw-black mb-12 lh-1">{{totalDem}}</h4>
          <!-- <span class="fw-bold text-success text-badge d-inline-block">
            <i class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"> </i>
          </span> -->
          <div class="row"  >
              <div class="col-lg-6 col-xxxl-6 col-md-6" v-for="(dem, index) in demandeType"  :key="index">
                <ul class="list ps-0 mb-0 list-unstyled mt-5">
                  <li class="text-muted position-relative fw-medium">
                    {{"Demande de "+ dem.type }} = <span class="text-black fw-bold">{{ dem.nombreDemande }}</span>
                  </li>
                  <!--<li class="text-muted position-relative fw-medium">
                    Demande de résiliation = <span class="text-black fw-bold">11 500</span>
                  </li>
                  <li class="text-muted position-relative fw-medium">
                    Demande d'abonnements = <span class="text-black fw-bold">11 000</span>
                  </li>-->
                </ul>
              </div>
             <!-- <div class="col-lg-6 col-xxxl-6 col-md-6">
                <ul class="list ps-0 mb-0 list-unstyled mt-30">
                   
                  <li class="text-muted position-relative fw-medium">
                    Demande de Cession = <span class="text-black fw-bold">9 500</span>
                  </li>
                  <li class="text-muted position-relative fw-medium">
                    Demande de Attestation = <span class="text-black fw-bold">11 500</span>
                  </li>
                </ul>
              </div>-->
          </div>
          <div id="earningChart" class="chart">
            <apexchart
              type="donut"
              height="100"
              :options="earningChart"
              :series="donnedemandeTypes"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-lg-6 col-xxxl-6 col-md-12">
    </div>
    <div class="col-xl-12 col-lg-6 col-xxxl-6 col-md-12">
    </div>
  </div>

  
</template>

<script lang="ts">
import { defineComponent,onMounted,ref } from "vue";
  import axios from 'axios';

export default defineComponent({
  name: "ExpectedEarnings",
  data: function () {
    const donnedemandeTypes = ref<Array<number>>([]);
    const totalDem = ref(0);
    //const abonementActif = ref(0);
    onMounted(async() => {
    await getNombreBoiteParStatus();
     await getAbonInfo();
      await getAbonpayeImpaye();
      await getDemandeParType();
      demandeType.value.forEach((element: any) => {
        totalDem.value += parseInt(element.nombreDemande);
        ajouterNouvellesValeurs(element.nombreDemande);
      });
    });
    function ajouterNouvellesValeurs(valeur:number) {
      donnedemandeTypes.value.push(valeur);
    }
    const totalPayee = ref(0);
    const totalImpaye = ref(0);
    const demandeType = ref([]);

    const getDemandeParType = async () =>{
      try{
        const response = await axios.get(`/etats/abonnements/demande/par/type`);
        demandeType.value = response.data.data;
        return demandeType.value ;
      }catch (error) {
        //
      }
    }
    const chiffreAff = ref(0)
    const  getChiffreAffaire = async () =>{
      try{
        const response = await axios.get(`/etats/chiffre/af`,{});
        demandeType.value = response.data.data;
        return demandeType.value ;
      }catch (error) {
        //
      }
    }
    const abonnemntsParStatus = ref([]);
    const boitesParStatus = ref([]);
    const somAbon = ref(0);
    const somBoit = ref(0);

    const getNombreBoiteParStatus = async () =>{
      try{
        const response = await axios.get(`/etats/point/boites`);
        const valeur = response.data.data;
        boitesParStatus.value = response.data.data;
        valeur.forEach((element: any) => {
          somBoit.value += parseInt(element.nombre);
      });
        return boitesParStatus.value;
      }catch (error) {
        //
      }
    }

    const getAbonInfo = async () =>{
      try{
        const response = await axios.get(`/etats/point/abonnements`);
        const valeur = response.data.data;
        abonnemntsParStatus.value = response.data.data;
        valeur.forEach((element: any) => {
        somAbon.value += parseInt(element.nombreAbonnment);
      });
        return abonnemntsParStatus.value;
      }catch (error) {
        //
      }
    }
    const getAbonpayeImpaye = async () =>{
      try{
        const response = await axios.get(`/etats/point/redevances`);
        return response.data;
      }catch (error) {
        //
      }
    }
    return {
     // abonementActif,
      demandeType,
      totalDem,
      chiffreAff,
      abonnemntsParStatus,
      boitesParStatus,
      getDemandeParType,
      getAbonInfo,
      somAbon,
      somBoit,
      getAbonpayeImpaye,
      donnedemandeTypes,
      demandes: donnedemandeTypes.value,
      abonnement: [60, 30, 20],
      abonnet: [60, 30, 20],

      earningChart: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#6FD3F7","#0a59a4","#f3c148",],
        legend: {
          show: false,
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#ffffff",
          },
          markers: {
            offsetX: -2,
            offsetY: 1,
          },
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      },
    };
  },
});
</script>
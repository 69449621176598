<template>
    <BreadCrumb PageTitle="Demandes de transferts effectuées" />
    <ListeTransfert />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeTransfert from "../../components/Transfert/ListeTransfert.vue";
  
    export default defineComponent({
      name: "AddTransfertPage",
      components: {
        BreadCrumb,
        ListeTransfert,
      },
    });
  </script>
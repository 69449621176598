<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un bureau"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                UTILISATEUR ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOM D'UTILISATEUR
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TABLE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                OPERATION
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ADRESSE MAC
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ADRESSE IP
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(jou , index) in journals" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ jou.id}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ jou.userId }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ jou.userName }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ jou.entityName}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ jou.action}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(jou.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ jou.addressMac}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ jou.addressIP}}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >

              <a v-if="checkPermission('AddBureau')"
                class="default-btn position-relative transition border-0 fw-medium text-white pt-5 pb-5 ps-15 pe-15 rounded-1 bg-primary fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#ModalDetail"
                @click="getJournalOperation( jou.id )"
              >
                <i class="flaticon-eye lh-1 me-8 position-relative top-1" ></i>
                  Détails
              </a>

              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />

      </div>
    </div>
  </div>



  <div
    class="modal fade createNewModal"
    id="ModalDetail"
    tabindex="-1"
    aria-hidden="true"
    ref="detailModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">Détail de l'opération {{ (journal) ? journal.id : ''}} du {{ (journal) ? format_date(journal.createdAt) : '' }} </h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-5">



          <div class="card mb-25 border-0 rounded-0 bg-white to-do-list-box">
            <div class="card-body p-5 letter-spacing">
              <ul class="to-do-list ps-0 list-unstyled mb-0">
                <li class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between">
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>ID UTILISATEUR</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.userId : '' }}
                  </div>
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>NOM UTILISATEUR</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.userName : '' }}
                  </div>
                </li>
                <li class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between">
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>ID TABLE</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.entityId : '' }}
                  </div>
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>NOM TABLE</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.entityName : '' }}
                  </div>
                </li>
                <li class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between">
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>ADRESSE IP</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.addressIP : '' }}
                  </div>
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>ADRESSE MAC</b></span>
                  </div>
                  <div class="col-md-3">
                    {{ (journal) ? journal.addressMac : '' }}
                  </div>
                </li>
                <li class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between">
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>OPERATION EFFECTUÉE</b></span>
                  </div>
                  <div class="col-md-9">
                    {{ (journal) ? journal.action : '' }}
                  </div>
                </li>
                <li class="to-do-list-item ps-8 pe-5 d-flex align-items-center justify-content-between">
                  <div class="col-md-3">
                    <span class="fw-medium text-black-emphasis"><b>CONTENU DE L'OPERATION</b></span>
                  </div>
                  <div class="col-md-9">
                      <!-- <div v-for="(value, key) in journal.contenu" :key="key">
                        {{ key }}: {{ value }}
                      </div> -->
                      <div v-if="journal && journal.contenu && journal.contenu.length > 0">
                        <!-- {{ JSON.stringify(journal.contenu, null, 2) }} -->
                        <div v-for="(value, key) in JSON.parse(journal.contenu)" :key="key">
                          <template v-if="!['createdAt', 'updatedAt', 'deletedAt', 'userCreation'].includes(key)">
                            {{ key }}: {{ value }}
                          </template>
                        </div>
                      </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>









       
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { JournalConnexion } from "@/models/JournalConnexion";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression, error } from "../../utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeBureau",
    components: {
      PaginationComponent
  },
  setup: () => {
    const journals = ref<Array<JournalConnexion>>([]);
    const selectedItem = ref(0);
    const journal = ref<JournalConnexion>();

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);
    const detailModalRef = ref(null);


    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllJournalOperation(page_, limit_);
      } catch (error) {
        //
        
      }
    };
    function rechercher(){
      getAllJournalOperation(page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE
    
    const recharger = () => {
      getAllJournalOperation();
    };

    const show_Modal = (el) => {
      // open detailModalRef modal
    };

    function getJournalOperation(id: number, page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/journalOperations?page=${page}&limit=${limi}&mot=${searchTerm}&id=${id}&`)
      .then(( {data} ) => {
        journal.value = (data.data.data[0] as JournalConnexion);
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    function getAllJournalOperation(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/journalOperations?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(( {data} ) => {
        const donnees = data.data.data;
        journals.value = donnees;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

      const checkPermission = (name) => {
    return privileges.value.includes(name);
  }

    onMounted(() => {
      getAllJournalOperation();
    });

    return { 
      checkPermission,
      getJournalOperation,
      journals,
      journal,
      format_date,
      recharger,
      show_Modal,
      selectedItem ,
      suppression,
      page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher,
      detailModalRef
    };
  },
  
});
</script>
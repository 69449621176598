<template>
    <BreadCrumb PageTitle="Vérifier une attestation" />
    <AddAttestation />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddAttestation from "../../components/Attestation/AddAttestation.vue";
  
    export default defineComponent({
      name: "AddAttestationPage",
      components: {
        BreadCrumb,
        AddAttestation,
      },
    });
  </script>
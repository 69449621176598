<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="colisForm" @submit="editColis" :validation-schema="colisSchema" :initial-values="colisForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Référence <span class="text-danger">*</span>
                </label>
                <Field name="refColis" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="refColis" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Casier <span class="text-danger">*</span>
                </label>
                <Field  name="casier"  v-slot="{ field }">
                  <Multiselect
                    :options="casierOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                  />
                </Field>  
                <ErrorMessage name="casier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Abonnement <span class="text-danger">*</span>
                </label>
                <Field  name="abonnement"  v-slot="{ field }">
                  <Multiselect
                    :options="abonnementOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                  />
                </Field>  
                <ErrorMessage name="etagere" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Informations supplémentaires
                </label>
                <Field name="infoSupp" as="textarea"  cols="30"
                  rows="12" placeholder="Entrer les infos supplémentaires" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                class="form-control shadow-none rounded-0 text-black"
                  v-model="field.value"
                ></textarea>
              </Field>
                <ErrorMessage name="infoSupp" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier un colis
            </button>
            <router-link to="/liste-colis" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRoute, useRouter } from 'vue-router';
import ApiService from '@/services/ApiService';
import { Colis} from '@/models/Colis';
import * as Yup from 'yup';
import axios from 'axios';
import Multiselect from '@vueform/multiselect'


export default defineComponent({
    name: "EditColis",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const colisSchema = Yup.object().shape({
      refColis: Yup.string().required('La référence est obligatoire'),
      infoSupp: Yup.string().notRequired(),
      abonnement: Yup.string().required('L\'abonnement est obligatoire'),
      casier: Yup.string().required('Le casier est obligatoire'),
    });

    const colisForm = ref<Colis>();
    const abonnementOptions = ref([]);
    const router = useRouter();
    const route = useRoute();
    const casierOptions = ref([]);

    function getColis(id:number) {
      ApiService.get("/colis/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            colisForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    }

    const editColis = async (values, {resetForm}) => {
      const data = colisForm.value;
      ApiService.put("/colis/" + data?.id, data)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            resetForm();
            router.push({ name: "ListeColisPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchCasiers = async () => {
      ApiService.get("/casiers")
      .then(({ data }) => {
        const donnees = data.data.data;
        casierOptions.value = donnees.map((casier) => {
          return {
            label: `${casier.code} - ${casier.etagere.code}`,
            value: casier.id,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchAbonnements = async () => {
      ApiService.get("/abonnements")
      .then(({ data }) => {
        const donnees = data.data.data;
        abonnementOptions.value = donnees.map((abonnement) => {
          return {
            value: abonnement.id,
          label: `${abonnement.refAbo} - [${abonnement.abonne?.nomComplet}]`,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      fetchAbonnements();
      fetchCasiers();
      if(route.params.id) {
        getColis(parseInt(route.params.id as string));
      }
    });

    return { colisSchema, editColis, colisForm, abonnementOptions, casierOptions};
  },
});
</script>
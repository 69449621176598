<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white d-lg-flex align-items-center p-15 p-sm-20 p-md-25">
            <div class="d-sm-flex align-items-center" style="width:100%;">
                <Form style="width:100%;" class="search-box position-relative me-15">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group mb-15 mt-30 mb-sm-20 mb-md-25">
                                <Field name="recherche" type="text"  v-model="recherche"
                                class="form-control shadow-none text-black rounded-0 border-0" placeholder="Recherche"/>
                                <ErrorMessage name="recherche" class="text-danger"/>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Modèle de Boîte
                                </label>
                                <Field name="modeleBoite" v-model="model" v-slot="{ field }">
                                    <Multiselect 
                                    :options="modelesBoitesOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    placeholder="Sélectionner le modèle de la boîte"
                                    no-results-text="Aucun modele de boite"
                                    />
                                </Field>
                                <ErrorMessage name="modeleBoite" class="text-danger"/>
                            </div>
                        </div>

                        <div v-if="!etatAgence" class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Agence/Relais
                                </label>
                                <Field name="bureau" v-model="bureau" v-slot="{ field }">
                                    <Multiselect 
                                    :options="bureausBoitesOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    placeholder="Sélectionner une agence/relais"
                                    no-options-text="Aucune agence selectionnée"
                                    no-results-text="Aucune agence selectionnée"
                                    />
                                </Field>
                                <ErrorMessage name="bureau" class="text-danger"/>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Etat boite
                                </label>
                                <Field name="option" v-model="etat" v-slot="{ field }">
                                    <Multiselect 
                                    :options="etatsOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    placeholder="Sélectionner un état"
                                    no-results-text="Aucun état trouvé"
                                    />
                                </Field>
                                <ErrorMessage name="option" class="text-danger"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-10 col-3">
                            <div  class="form-group mb-15 mt-50 mb-sm-20 mb-md-25" >
                                <!-- <button
                                    class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                                    type="button"
                                    >
                                    Exporter
                                    <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
                                </button> -->
                            </div>
                        </div>

                    </div>
                </Form>
            </div>
          <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
        </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  REFERENCE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >MODELE</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >AGENCE</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >TYPE DE BOITE</th>
                <!--<th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  COULEUR
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >DIMENSION</th>-->
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Etat</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(boit , index) in boitess" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{boit.refBoite}}
              </td>
             
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ boit.modele ? boit.modele.libelle : "AUCUN" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ boit.bureau ? boit.bureau.nom : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ boit.estOfficiel }}
               
              </td>
              <!--<td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ boit.couleur }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ boit.dimension }}
              </td>-->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="boit.etat === 'Louée'" class="badge text-outline-primary">{{ boit.etat }}</span>
                <span v-else class="badge text-outline-danger">{{ boit.etat }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
    import { defineComponent, onMounted,watch, ref} from "vue";
    import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
    import Swal from "sweetalert2";
    import axios from 'axios';
    import ApiService from "@/services/ApiService";
    import { suppression,error } from "@/utils/utils";
    import PaginationComponent from '@/components/Utilities/Pagination.vue';
    import Multiselect from '@vueform/multiselect';
    import JwtService from "@/services/JwtService";
  
export default({
  name: "EtatBoite",
  components: {
    Form,
    Field,
    ErrorMessage,
    PaginationComponent,
    Multiselect
    },
    setup(){
        const modelesBoitesOptions =ref();
        const bureausBoitesOptions =ref();
        const boitess = ref([]);
        const model = ref();
        const recherche = ref("");
        const etat = ref("");
        const bureau = ref();
        const etatsOptions =[
            { value: '', label: 'Selectionnez un état'},
            {
                label: "Louée",
                value:"Louée"
            }, 
            { value: 'Disponible', label: 'Disponible'}
        ];
        const searchTerm = ref('');
        const page = ref(1);
        const totalPages = ref(0);
        const limit = ref(10);
        const totalElements = ref(0);
        const action = async(page = 1, limi = 10, searchTerm = '')=>{
            const element = {
                model:model.value,
                recherche:recherche.value,
                etat:etat.value,
                bureau:bureau.value,
                page:page,
                totalPages:totalPages.value,
                limit:limit.value,
                totalElements:totalElements.value,
                mot:searchTerm
            }
            return await ApiService.post("/etats/boites",element)
                .then((response) => {
                if (response.status === 200) {
                    boitess.value = response.data.data.data;
                    totalPages.value = response.data.data.totalPages;
                    limit.value = response.data.data.limit;
                    totalElements.value = response.data.data.totalElements;
                }
                })
                .catch((errorr) => {
                    error(errorr.response.data.message);
                });
            
        }
        const fetchModeles = async () => {
            try {
                
            const response = await axios.get('/modele_boites');
            const modelesData = response.data.data;
            modelesBoitesOptions.value = modelesData.map((boite) => ({
                value: boite.id,
                label: boite.code+" [ "+ boite.libelle+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const handlePaginate = ({ page_, limit_ }) => {
          try {
            page.value = page_;
            action(page_, limit_);
          } catch (error) {
            //
            
          }
        };
        const fetchBureaus = async () => {
            try {
            const response = await axios.get('/bureaus');
            const bureausData = response.data.data.data;
            bureausBoitesOptions.value = bureausData.map((bureau) => ({
                value: bureau.id,
                label: bureau.code+" [ "+ bureau.nom+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const etatAgence = ref(true);
        if(JwtService.getUserCheckIfAllAgence() == "true"){
          etatAgence.value = false;
        }
        
    onMounted(() => {
        fetchModeles();
        fetchBureaus();
        action(1, 10);
    });
    watch(model, (newValue, oldValue) => {
        model.value= newValue;
        action(1, 10);
    });
    watch(bureau, (newValue, oldValue) => {
       bureau.value = newValue;
       action(1, 10);
    });

    watch(etat, (newValue, oldValue) => {
        etat.value = newValue;
        action(1, 10);
    });
    watch(recherche, (newValue, oldValue) => {
        recherche.value = newValue;
        action(1, 10);
    });
     
  
      return { suppression,bureau,etatAgence, page,totalPages,limit, totalElements,handlePaginate, fetchModeles,boitess,bureausBoitesOptions,action,recherche,etat,model,etatsOptions,modelesBoitesOptions };
    },

  });
  </script>
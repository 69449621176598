<template>
    <BreadCrumb PageTitle="Envoyer une notification de redevance" />
    <AddNotificationRedevance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddNotificationRedevance from "../../components/Notification/AddNotificationRedevance.vue";
  
    export default defineComponent({
      name: "AddNotificationRedevancePage",
      components: {
        BreadCrumb,
        AddNotificationRedevance,
      },
    });
  </script>

<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link  v-if="checkPermission('AddRemboursement')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-remboursement"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Faire un remboursement
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une demande"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                REFERENCE
              </th>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TYPE DE REMBOURSEMENT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNEMENT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DESCRIPTION
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Coût
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rem , index) in remboursements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ rem.refRemboursement }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ rem.abonnement ? "Droit de caution" : 'Demande'  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ rem.abonnement ? rem.abonnement.refAbo + " - ["+rem.abonnement.abonne.nomComplet+"]" : rem.demande.abonnement.refAbo + " - ["+rem.demande.abonnement.abonne.nomComplet+"]"  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ rem.description  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(rem.dateRemboursement) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(rem.montantRembourse)  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li >
                        <a  class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                        </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, onBeforeUnmount, onMounted } from "vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import Multiselect from "@vueform/multiselect";
  import "@vueup/vue-quill/dist/vue-quill.snow.css";
  import ImageUploader from "quill-image-uploader";
  import * as Yup from "yup";
  import ApiService from "../../services/ApiService";
  import axios from "axios";
  import Swal from "sweetalert2";
  import PaginationComponent from "@/components/Utilities/Pagination.vue";
  import JwtService from "@/services/JwtService";
  import { hideModal, showModal, success,suppression,separateur, format_date,format_Date, error,getDatePlusXDays,decouperEtJoindre,telechargerFichier2,getUrlApiForFiles,openAndPrint } from '@/utils/utils';

  
  export default defineComponent({
    name: "ListeRemboursement",
    components: {
      PaginationComponent
    },
    setup: () => {
      const privileges = ref<Array<string>>(JwtService.getPrivilege());

          // BEGIN PAGINATE
      const searchTerm = ref('');
      const page = ref(1);
      const totalPages = ref(0);
      const limit = ref(10);
      const totalElements = ref(0);
      const remboursements = ref()

      const checkPermission = (name) => {
        return privileges.value.includes(name);
      }

      const  getAllRemboursement = (page = 1, limi = 10, searchTerm = '') => {
      return ApiService.get(`/remboursements?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        const donnees = data.data.data;
        remboursements.value = donnees;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }
  
      onMounted(() => {
        getAllRemboursement();
      });
  
      return {
        searchTerm,page,totalPages,limit,totalElements,checkPermission,remboursements,format_Date,separateur
      };
    },
  });
  </script>
  
  <style src="@vueform/multiselect/themes/default.css"></style>
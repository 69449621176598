<template>
    <BreadCrumb PageTitle="Liste des types de notification" />
    <ListeTypeNotification/>
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeTypeNotification from "../../components/TypeNotification/ListeTypeNotification.vue";

  
    export default defineComponent({
      name: "ListeTypeNotificationPage",
      components: {
        BreadCrumb,
        ListeTypeNotification,
      },
    });
  </script>
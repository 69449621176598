<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link  v-if="checkPermission('AddBoitePostale')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-boite"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Générer des boîtes postales
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une boîte"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                REFERENCE DE LA BOITE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MODELE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                BUREAU
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ETAT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DIMENSION
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                COULEUR
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TYPE DE BOITE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(bp , index) in boitePostales" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ bp.refBoite }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ bp.modele ? bp.modele.libelle : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" >
                {{ bp.bureau ? bp.bureau.nom : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium " :class="(bp.etat == 'Louée')? 'text-primary':'text-danger'">
                {{ bp.etat }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ bp.dimension }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{bp.couleur}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ bp.estOfficiel }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
  <AddBoitePostale :item="selectedItem"   />
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AddBoitePostale from "./AddBoitePostale.vue";
import { BoitePostale } from "@/models/BoitePostale";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression,error } from "../../utils/utils";
import { useRouter } from 'vue-router';
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeBoitePostale",
    components: {
      PaginationComponent
    },
 setup(){
    
    const router = useRouter();
    const boitePostales = ref<Array<BoitePostale>>([]);
    const selectedItem = ref(0);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllBoitePostale(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllBoitePostale(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    const editBoitePostal = (id: number) => {
      selectedItem.value = id;
      router.push({ name: 'EditBoitePostalePage',params: { id: selectedItem.value } });
    };

    function getAllBoitePostale(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/boitepostales?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        boitePostales.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      getAllBoitePostale();
    });

    
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    return { 
      boitePostales,
      checkPermission,
      format_date,
      editBoitePostal,
      selectedItem,
      suppression,
      page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
      searchTerm
    };
  },
});
</script>
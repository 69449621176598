<template>
    <BreadCrumb PageTitle="Liste des adresses postales" />
    <ListeAdressePostale />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeAdressePostale from "../../components/AdressePostale/ListeAdressePostale.vue";

  
    export default defineComponent({
      name: "ListeAdressePostalePage",
      components: {
        BreadCrumb,
        ListeAdressePostale,
      },
    });
  </script>
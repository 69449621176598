
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="notificationCampagneForm" @submit="addNotificationRedevance" :validation-schema="notificationRedevanceSchema">
      <div class="row">
        <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
              Campagne <span class="text-danger">*</span>
              </label>
              <Field name="campagne" v-slot="{ field }">
                <Multiselect 
                    :options="campagneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    :multiple="true"
                    placeholder="Sélectionner la campagne"
                />
              </Field>
              <ErrorMessage name="campagne" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Envoyez les notifications
            </button>
            <router-link to="/notification-campagne" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success } from '@/utils/utils';
import { Notification } from '@/models/Notification';
import { Campagne } from '@/models/Campagne';

export default defineComponent({
  name: "AddNotificationRedevance",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const notificationRedevanceSchema = Yup.object().shape({
    campagne: Yup.number().required('La selection est obligatoire'),
    // abonne: Yup.number().typeError('Entrer un nombre').required('L\'abonne est obligatoire'),
  });

  const router = useRouter();
  const notificationCampagneForm = ref< null | Campagne>(null);
  const abonnesOptions = ref([]);
  const campagneOptions = ref([]);

    const formattedDate = (param) => {
        return new Date(param).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    } 

    const fectCampagneEnCour = async () => {
     ApiService.get('/campagnes/available')
        .then(({ data }) => {
          const donnee = data.data.data;
            campagneOptions.value = donnee.map((item: any) => {
              return {
                label: ` [ ${formattedDate(item.dateDebut)} - ${formattedDate(item.dateFin)}  ]  ${item.libelle} `,
                value: item.id,
              };
            });
        })
        .catch((response) => {
          error(response.data.message);
        });
    };

    const addNotificationRedevance = async (value : any , { resetForm }) => {
        const id = value.campagne;
        ApiService.get("/envoie/notifications/campagne/"+id)
        .then(({ data }) => {
            const donnees = data.data;
            success(data.message);
            resetForm();
            router.push({ name: "ListeNotificationPage" });
        })
        .catch(({ response }) => {
            error(response.data.message);
        });

    }

    onMounted(() => {
      fectCampagneEnCour();
    });
  
  return { 
    notificationRedevanceSchema,
    notificationCampagneForm,
    abonnesOptions,
    campagneOptions,
    addNotificationRedevance
  };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
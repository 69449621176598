<template>
    <BreadCrumb PageTitle="Listes des réabonnements" />
    <ListeReabonnement />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeReabonnement from "../../components/Reabonnement/ListeReabonnement.vue";
  
  
  export default defineComponent({
    name: "ListeAbonnePage",
    components: {
      BreadCrumb,
      ListeReabonnement,
    },
  });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des paiements services" />
    <ListePaiementService />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListePaiementService from "../../components/PaiementService/ListePaiementService.vue";
  
  
  export default defineComponent({
    name: "ListePaiementServicePage",
    components: {
      BreadCrumb,
      ListePaiementService,
    },
  });
  </script>
<template>
    <BreadCrumb PageTitle="Ajouter une campagne" />
    <AddCampagne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddCampagne from "../../components/Campagne/AddCampagne.vue";
  
    export default defineComponent({
      name: "AddCampagnePage",
      components: {
        BreadCrumb,
        AddCampagne,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="colisForm" @submit="deliverColis" :validation-schema="colisSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Nom et prénom <span class="text-danger">*</span>
                </label>
                <Field name="nomComplet" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre nom et prénom"/>
                <ErrorMessage name="nomComplet" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Téléphone <span class="text-danger">*</span>
              </label>
              <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Entrer le numéro de téléphone"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
              <ErrorMessage name="telephone" class="text-danger"/>
              <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de la pièce d'identité<span class="text-danger">*</span>
                </label>
                <Field name="typePiece" v-model="typePiece"  v-slot="{ field }">
                  <Multiselect 
                    :options="options" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type de la pièce"
                  />
                </Field>
                <ErrorMessage name="typePiece" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Numéro de la pièce <span class="text-danger">*</span>
                </label>
                <Field name="numPiece" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le numéro de la pièce"/>
                <ErrorMessage name="numPiece" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-6" v-if="showCipField">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Numéro de la CIP
                </label>
                <Field name="cip" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le numéro de la cip"/>
                  <ErrorMessage name="cip" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="showCarteBiometriqueField">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Numéro de la carte biométrique
                </label>
                <Field name="carteBiometrique" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le numéro de la carte biométrique"/>
                  <ErrorMessage name="carteBiometrique" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="showPasseportField">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Numéro du passeport
                </label>
                <Field name="passeport" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le numéro du passeport"/>
                  <ErrorMessage name="passeport" class="text-danger"/>
              </div>
            </div> -->
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Colis <span class="text-danger">*</span>
                </label>
                <Field  name="colis"  v-slot="{ field }">
                  <Multiselect
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Rechercher un colis"
                    :filter-results="false"
                    :min-chars="3"
                    :resolve-on-load="false"
                    :delay="0"
                    :searchable="true"
                    :options-limit="300"
                    :options="async (query) => {
                      return await fetchColis(query)
                    }"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="colis" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" :disabled="!validPhone"
            >
                Livrer le colis
            </button>
            <router-link to="/liste-colis" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Colis } from '@/models/Colis';
import * as Yup from 'yup';
import axios from 'axios';
import Multiselect from '@vueform/multiselect'
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { countries } from '../Abonne/countries';


export default defineComponent({
    name: "DeliverColis",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  setup: () => {
    const colisSchema = Yup.object().shape({
      nomComplet: Yup.string().required('Le nom et le prénom sont obligatoires'),
      typePiece: Yup.string().required('Le type de la pièce est obligatoire'),
      colis: Yup.string().required('Le colis est obligatoire'),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
      numPiece: Yup.number().typeError('Les lettres ne sont pas autorisées').required('Le numéro de la pièce est obligatoire'),
      // cip: Yup.string().when([], (value, schema) => {
      //   return showCipField.value
      //     ? schema.required("Le cip est obligatoire")
      //     : schema
      // }),
      // passeport: Yup.string().when([], (value, schema) => {
      //   return showPasseportField.value
      //     ? schema.required("Le passeport est obligatoire")
      //     : schema
      // }),
      // carteBiometrique: Yup.string().when([], (value, schema) => {
      //   return showCarteBiometriqueField.value
      //     ? schema.required("La carte biométrique est obligatoire")
      //     : schema
      // }),
    });

    const colisForm = ref<Colis & { colisId: string }>;
    const router = useRouter();
    const colisOptions = ref([]);
    const nooptions="La liste est vide";
    const noResultText="Aucun enregistrement trouvé";
    const coliselect = ref();
    const countriesRef = ref(countries)
    const  codepay= ref();
    const  nationalnumlber= ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);
    const numberPhone=ref();
    

    const  options =  [
    { value: 'CIP', label: 'CIP' }, 
    { value: 'Passeport', label: 'Passeport'},
    { value: 'Carte Biométrique', label: 'Carte Biométrique'}
    ];

    // const showCipField = ref(true);
    // const showPasseportField = ref(false);
    // const showCarteBiometriqueField = ref(false);
    const typePiece = ref('CIP');

    // const handleObjetInput = () => {
    //   showCipField.value = false;
    //   showPasseportField.value = false;
    //   showCarteBiometriqueField.value = false;

    //   switch (typePiece.value) {
    //     case 'CIP':
    //       showCipField.value = true;
    //       showPasseportField.value = false;
    //       showCarteBiometriqueField.value = false;
    //       break;
    //     case 'Carte Biométrique':
    //       showCarteBiometriqueField.value = true;
    //       showCipField.value = false;
    //       showPasseportField.value = false;
    //       break;
    //     case 'Passeport':
    //       showPasseportField.value = true;
    //       showCipField.value = false;
    //       showCarteBiometriqueField.value = false;
    //       break;
    //   }
    // };

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        numberPhone.value = phoneObject.nationalNumber;

      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
        numberPhone.value="";
      }
    }

    const fetchColis = async (key) => {
      try {
        const response = await axios.get('/colis/recherche/'+key);
        const colisData = response.data.data;
        colisOptions.value = colisData.map((colis) => ({
          value: colis.id,
          label: `${colis.refColis}`,
        }));
        return colisOptions.value;
      } catch (error) {
        //
      }
    }

    // const deliverColis = async (values, {resetForm}) => {
    //   const colisId = values.colis ? values.colis.value : null;
    //   // ApiService.post('colis/livraison/' + colisId , values)
    //   //   .then(({ data }) => {
    //   //     if (data.code == 201) {
    //   //       success(data.message);
    //   //       resetForm();
    //   //       router.push({ name: "ListeColisPage" });
    //   //     }
    //   //   }).catch(({ response }) => {
    //   //     error(response.data.message);
    //   //   });
    // };

    onMounted(async () => {
      //await fetchColis();
    });

      const deliverColis = async (values, { resetForm }) => {
      const selectedColis = values.colis;
      const colisId = selectedColis.value;
      ApiService.put(`colis/livraison/${selectedColis}`, values)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            resetForm();
            router.push({ name: "ListeColisPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };




    return { colisSchema, deliverColis, colisForm,coliselect, colisOptions,
      nooptions, noResultText,
      countries: countriesRef, 
      telephone,
      validPhone,
      validate,
      onInput,
      typePiece,
      options,
      fetchColis,
      // handleObjetInput,
      // showCipField,
      // showPasseportField,
      // showCarteBiometriqueField,
    };
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Liste des réabonnements" />
    <ListePaiement />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListePaiement from "../../components/Paiement/ListePaiement.vue";
  
  
  export default defineComponent({
    name: "ListePaiementPage",
    components: {
      BreadCrumb,
      ListePaiement,
    },
  });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center" style="width:100%;">
        <Form style="width:100%;" class="search-box position-relative me-15">
          <div class="row">
            <div class="col-4">
              <div class="form-group mb-15 mt-30 mb-sm-20 mb-md-25">
                <Field name="recherche" type="text" v-model="recherche"
                  class="form-control shadow-none text-black rounded-0 border-0" placeholder="Rechercher" />
                <ErrorMessage name="recherche" class="text-danger" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date Début <span class="text-danger">*</span>
                </label>
                <Field name="debut" type="date" v-model="debut" style="background-color: #fff;"
                  class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date" />
                <ErrorMessage name="debut" class="text-danger" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date Fin <span class="text-danger">*</span>
                </label>
                <Field name="fin" v-model="fin" type="date" style="background-color: #fff;"
                  class="form-control shadow-none rounded-0" placeholder="Choisisser la date" />
                <ErrorMessage name="fin" class="text-danger" />
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Mode de paiement <span class="text-danger">*</span>
                </label>
                <Field name="modeleBoite" v-model="model" v-slot="{ field }">
                  <Multiselect :options="modeOptionsPaiements" :searchable="true" no-results-text="Aucun mode trouvé"
                    v-model="field.value" v-bind="field" placeholder="Sélectionner le mode de paiement" />
                </Field>
                <ErrorMessage name="modeleBoite" class="text-danger" />
              </div>
            </div>

            <div v-if="!etatAgence" class="col-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Agence <span class="text-danger">*</span>
                </label>
                <Field name="bureau" v-model="bureau" v-slot="{ field }">
                  <Multiselect :options="bureausBoitesOptions" :searchable="true" v-model="field.value"
                    no-results-text="Aucune agence trouvée" v-bind="field" placeholder="Sélectionner une agence" />
                </Field>
                <ErrorMessage name="bureau" class="text-danger" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de paiement<span class="text-danger">*</span>
                </label>
                <Field name="option" v-model="etat" v-slot="{ field }">
                  <Multiselect :options="etatsOptions" :searchable="true" v-model="field.value" v-bind="field"
                    no-results-text="Aucun type trouvé" placeholder="Sélectionner un type de paiement" />
                </Field>
                <ErrorMessage name="option" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="offset-9 col-3">
              <div class="form-group">
                <div class="row list justify-content-center">
                  <div class="col-lg-12 col-sm-12 text-end">
                    <div class="p-15 bg-f2f1f9 text-end">
                      <span class="d-block fw-medium text-black-emphasis fs-13 text-uppercase">
                        {{ "Montant total" }}
                      </span>
                      <h4 class="mb-0 fw-black text-primary text-end">
                        {{ (montantTotal ? separateur(montantTotal) : 0 )+ " F CFA" }}
                      </h4>
                    </div>
                  </div>
                </div>
                <!-- <button
                                    class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                                    type="button"
                                    >
                                    Exporter
                                    <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
                                </button> -->
              </div>

            </div>

          </div>
        </Form>
      </div>
      <!-- 
            <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> 
        -->
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                REFERENCE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Abonnement
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Abonné
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                MODE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">MONTANT</th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">OBJET</th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">DATE PAIEMENT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">AGENCE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(paie, index) in paiements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.refPaiement }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.abonnement?.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.abonnement?.abonne?.nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.modePaiement ? paie.modePaiement.libelle : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(paie.montantPaye) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.service ? paie.service.libelle : "Redevance" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(paie.datePaiement) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ paie.abonnement.estMPostBox == false ? paie.abonnement.boite ? paie.abonnement.boite?.bureau?.nom :
                  "" : paie.abonnement.bureau?.nom}}
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit"
          @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, ref } from "vue";
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import Swal from "sweetalert2";
import axios from 'axios';
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import ApiService from "@/services/ApiService";
import { suppression, error, format_date, separateur } from "@/utils/utils";
import Multiselect from '@vueform/multiselect';
import JwtService from "@/services/JwtService";

export default ({
  name: "EtatBoite",
  components: {
    Form,
    Field,
    PaginationComponent,
    ErrorMessage,
    Multiselect
  },
  setup() {
    const modeOptionsPaiements = ref();
    const bureausBoitesOptions = ref();
    const paiements = ref([]);
    const model = ref();
    const recherche = ref("");
    const etat = ref("");
    const bureau = ref();

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const debut = ref(formatDate(new Date()));
    const fin = ref(formatDate(new Date()));


    const etatsOptions = [
      { value: null, label: 'Selectionnez un type' },
      {
        label: "Réabonnement",
        value: "Redevance"
      },
      { value: 'Service', label: 'Service' }
    ];
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);
    const montantTotal = ref(0);
    const action = async (page = 1, limi = 10, searchTerm = '') => {
      const element = {
        mode: model.value,
        recherche: recherche.value,
        etat: etat.value,
        debut: debut.value,
        fin: fin.value,
        bureau: bureau.value,
        page: page,
        totalPages: totalPages.value,
        limit: limit.value,
        totalElements: totalElements.value,
        mot: searchTerm
      }
      return await ApiService.post("/etats/paiements", element)
        .then((response) => {
          if (response.status === 200) {
            montantTotal.value = 0;
            paiements.value = response.data.data.data;
            totalPages.value = response.data.data.totalPages;
            limit.value = response.data.data.limit;
            totalElements.value = response.data.data.totalElements;
            if(response.data.data.point && response.data.data.point.montantPaye){
              montantTotal.value = response.data.data.point.montantPaye;
            }
            //montantTotal.value = response.data.data.point.montantPaye;
            console.log("Les paiements  ", paiements.value);
          }
        })
        .catch((errorr) => {
          error(errorr.response.data.message);
        });

    }
    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        action(page_, limit_);
      } catch (error) {
        //

      }
    };
    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modepaiements');
        const modelesData = response.data.data.data;
        modeOptionsPaiements.value = modelesData.map((boite) => ({
          value: boite.id,
          label: boite.code + " [ " + boite.libelle + " ]",
        }));
      } catch (error) {
        //
      }
    };
    const fetchBureaus = async () => {
      try {
        const response = await axios.get('/bureaus');
        const bureausData = response.data.data.data;
        bureausBoitesOptions.value = bureausData.map((bureau) => ({
          value: bureau.id,
          label: bureau.code + " [ " + bureau.nom + " ]",
        }));
      } catch (error) {
        //
      }
    };
    onMounted(() => {
      fetchModeles();
      fetchBureaus();
      action(1, 10);
    });
    const etatAgence = ref(true);
    if (JwtService.getUserCheckIfAllAgence() == "true") {
      etatAgence.value = false;
    }
    watch(model, (newValue, oldValue) => {
      model.value = newValue;
      action(1, 10);
    });
    watch(bureau, (newValue, oldValue) => {
      bureau.value = newValue;
      action(1, 10);
    });
    watch(fin, (newValue, oldValue) => {
      fin.value = newValue;
      action(1, 10);
    });

    watch(debut, (newValue, oldValue) => {
      debut.value = newValue;
      action(1, 10);
    });
    watch(etat, (newValue, oldValue) => {
      etat.value = newValue;
      action(1, 10);
    });
    watch(recherche, (newValue, oldValue) => {
      recherche.value = newValue;
      action(1, 10);
    });
    return { suppression, format_date, etatAgence, paiements, bureau, debut, fin, page, totalPages, limit, totalElements, handlePaginate, fetchModeles, bureausBoitesOptions, action, separateur, recherche, etat, model, etatsOptions, modeOptionsPaiements, montantTotal };
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Statitiques globales" />
    <div class="row">
      <div class="col-lg-12">
        <StatistiqueGlobal/>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import StatistiqueGlobal from "@/components/Etats/StatistiqueGlobal.vue";
 
  
  export default defineComponent({
    name: "StatistiqueGlobalPage",
    components: {
      BreadCrumb,
      StatistiqueGlobal
     
    },
  });
  </script>
<template>
  <div
    class="modal fade createNewModal"
    id="AddModePaiementModal"
    tabindex="-1"
    ref="addModePaiementModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="modePaiementForm" @submit="addModePaiement" :validation-schema="modePaiementSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libellé <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
            <!-- <button type="button" @click="resetForm()">Reset</button> -->
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
        
      </div>
    </div>
  </div>
  <div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, toRef, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { hideModal, showModal, success, error } from '@/utils/utils';
import { ModePaiement } from '@/models/ModePaiement';

export default {
  name: "AddModePaiementModal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    item: {
      type: Number,
      required: true
    },
  },
  setup(props, { emit }){
    const modePaiementSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      code: Yup.string().required('Le code est obligatoire').max(5, "Vous ne devez pas dépasser cinq caractères"),
    });
    
    let modePaiementForm = ref<ModePaiement | null>(null);
    const router = useRouter();
    const addModePaiementModalRef = ref<null | HTMLElement>(null);
    const modepaiementnew = ref<ModePaiement | null>(null);
    const isUPDATE = ref(false);
    const title = ref('Ajouter un mode de paiement');
    const btntext = ref('Ajouter');

    watch(() => props.item , (newValue) => {
      if(newValue){
        getModePaiement(newValue);
        isUPDATE.value = true;
      }
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le mode de paiement";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un mode de paiement";
         btntext.value = "Ajouter";
      }
    }

    const getModePaiement = async (id: number) => {
      return ApiService.get("/modepaiements/"+id)
      .then(({ data }) => {
        for (const key in data.data) {
          modePaiementForm.value?.setFieldValue(key, data.data[key]);
        }
        emit('openmodal', addModePaiementModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addModePaiement = async (values: any ,modePaiementForm) => {
      
      values = values as ModePaiement;
      if(isUPDATE.value){
        ApiService.put("/modepaiements/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              modePaiementForm.resetForm();
              hideModal(addModePaiementModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/modepaiements",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              modePaiementForm.resetForm();
              hideModal(addModePaiementModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
      
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
        formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return {title,btntext, modePaiementSchema, addModePaiement, modePaiementForm, addModePaiementModalRef
    ,resetValue, };
  },
};
</script>
<template>
  <BreadCrumb PageTitle="Etats des paiements par abonnement" />
  <EtatAbonnementPaiement />
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatAbonnementPaiement from "../../components/Etats/EtatAbonnementPaiement.vue";

  
    export default defineComponent({
      name: "EtatAbonnementPaiementPage",
      components: {
        BreadCrumb,
        EtatAbonnementPaiement,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="mpostBoxForm" @submit="payer" :validation-schema="mpostBoxSchema">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune<span class="text-danger">*</span>
              </label>
              <Field name="commune" v-model="communeselect" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :disabled="true"
                  :searchable="true" placeholder="Selectionner une commune"
                  noOptionsText="Aucun enregistrement trouvé" />
              </Field>
              <ErrorMessage name="commune" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau <span class="text-danger">*</span>
              </label>
              <Field name="bureau" v-model="bureauselect" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options=bureauxOptions :disabled="true"
                  placeholder="selectionner un bureau" noOptionsText="selectionner d'abord une commune" />
              </Field>
              <ErrorMessage name="bureau" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Numéro de l'abonné <span class="text-danger">*</span>
              </label>
              <Field name="numero" v-model="telephone" v-slot="{ field }">
                <vue-tel-input placeholder="Entrer le numéro de téléphone" v-model="field.value" v-bind="field"
                  defaultCountry="BJ" mode="international" @validate="validate"
                  class="shadow-none fs-md-15 text-black py-2">
                </vue-tel-input>
              </Field>
              <ErrorMessage name="numero" class="text-danger" />
              <div v-if="!validPhone" class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse virtuelle <span class="text-danger">*</span>
              </label>
              <Field readonly="true" v-model="adressePostales" name="adresseVirtuelle" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse virtuelle" />
              <ErrorMessage name="adresseVirtuelle" class="text-danger" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonné <span class="text-danger">*</span>
              </label>
              <Field name="abonne" v-model="abonn" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" placeholder="Rechercher un abonné"
                  :filter-results="true" :options=abonnesOptions :no-options-text=noOptionsTextAbb />
              </Field>
              <ErrorMessage name="abonne" class="text-danger" />
            </div>
          </div>
          <!--<div class="col-md-4" >
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Quartier 
                </label>
                <Field 
                  name="quartier"
                  v-model="quartier"
                  type="text"
                  class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="quartier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Description
                </label>
                <Field 
                  name="description"
                  v-model="description"
                  type="text"
                  class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="description" class="text-danger"/>
              </div>
            </div> -->
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Frais d'abonnement
              </label>
              <Field name="loyer" v-model="montant" :disabled="true" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer les frais d'abonnements" />
              <ErrorMessage name="loyer" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone">
                Valider le transfert
              </button>
              <router-link to="/liste-mpostboxs" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler le transfert</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
  <AddAbonneModal @getabonne="getAbonne" />
</template>
<script lang="ts">
import Swal from "sweetalert2";
import { defineComponent, reactive, toRef, watch, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { useRouter } from 'vue-router';
import { useRoute } from "vue-router";
import { error, success } from '@/utils/utils';
import { VueTelInput } from 'vue3-tel-input';
import AddAbonneModal from "../../MpostBox/AddAbonneModal.vue";
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import { KKIAPAY } from "@/models/KKIAPAY";
// import { MpostBox } from '@/models/MpostBox';


export default defineComponent({
  name: "AddTransfertMpostBox",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    AddAbonneModal,
    VueTelInput
  },

  setup: () => {
    const mpostBoxSchema = Yup.object().shape({
      abonne: Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
      demande: Yup.number().typeError('Veuillez selectionner une demande'),
      bureau: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      commune: Yup.number().typeError('Veuillez entrer un nombre').required('La commune est obligatoire'),
      numero: Yup.string().typeError('').required(`Le numéro est obligatoire`),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant est obligatoire`),
      adresseVirtuelle: Yup.string().required('L\'adresse virtuelle est obligatoire'),
      quartier: Yup.string().typeError('Veuillez entrer du texte').notRequired(),
      description: Yup.string().typeError('Veuillez entrer du texte').notRequired(),
    });
    interface Option {
      label: string
      value: string | number
    }

    // Assurez-vous que abonnesOptions.value est initialisé avec un tableau vide ou un tableau avec le type Option
    //let abonnesOptions: { value: Option[] } = { value: [] };

    const router = useRouter();
    // const mpostBoxForm = ref<MpostBox>();
    const abonnesOptions = ref<Array<Option>>([]);
    const bureauxOptions = ref<Array<Option>>([]);
    const communesOptions = ref([]);
    const bureauselect = ref();
    const bureau = ref(null);
    const codepay = ref();
    const abonn = ref();
    const codeVirtuel = ref();
    const nationalnumlber = ref();
    const telephone = ref();

    const communeselect = ref();
    const adressePostales = ref();
    const validPhone = ref<boolean>(false);
    const montant = ref(0);
    const selectedAbonne = ref(false);
    const burea = ref();
    const nooptions = "La liste est vide";
    const noResultText = "Aucun enregistrement trouvé";
    const noOptionsTextAdd = ref("Tapez au moins deux caractères");
    const noOptionsTextAbb = ref("Tapez au moins trois caractères");
    const route = useRoute();
    const deman = ref();
    const quartier = ref("");
    const description = ref("");

    const getDemande = async (id: string) => {
      try {
        const response = await axios.get("/demandes/" + id);
        lademande.value = response.data.data;

        if (lademande.value.abonnement.adresseVirtuelle == null) {
          console.log("ICICI C'est sans")
          communeselect.value = lademande.value.nouvelleAgence.commune.id;
          const selectedBureauId = lademande.value.nouvelleAgence.id;
          telephone.value = lademande.value.abonnement.abonne.telephone;
          nationalnumlber.value = lademande.value.abonnement.abonne.numeroNational;
          quartier.value = lademande.value.quartier;
          description.value = lademande.value.description;
          genererAdresse();
          abonnement.value = lademande.value.abonnement.id;
          const element: Option = {
            label: lademande.value.abonnement.abonne.nomComplet,
            value: lademande.value.abonnement.abonne.id,
          };

          abonnesOptions.value.push(element);

          bureauxOptions.value.push({
            label: lademande.value.nouvelleAgence.nom,
            value: lademande.value.nouvelleAgence.id,
          })
          bureauselect.value = selectedBureauId;
          abonn.value = lademande.value.abonnement.abonne.id;
          await fetchmonatant();
        }

        if (lademande.value.abonnement.adresseVirtuelle != null) {
          bureau.value = lademande.value.abonnement.bureau.id;
          const numero = lademande.value.abonnement.adresseVirtuelle ? lademande.value.abonnement.numero.replace(/\s/g, '') : lademande.value.abonnement.abonne.telephone.replace(/\s/g, '');
          //
          communeselect.value = lademande.value.nouvelleAgence.commune.id;
          const selectedBureauId = lademande.value.nouvelleAgence.id;
          telephone.value = numero;
          nationalnumlber.value = lademande.value.abonnement.abonne.numeroNational;
          quartier.value = lademande.value.quartier;
          description.value = lademande.value.description;
          codeVirtuel.value = lademande.value.abonnement.adresseVirtuelle;
          adressePostales.value = lademande.value.abonnement.adresseVirtuelle;
          abonnement.value = lademande.value.abonnement.id;
          const element: Option = {
            label: lademande.value.abonnement.abonne.nomComplet,
            value: lademande.value.abonnement.abonne.id,
          };

          abonnesOptions.value.push(element);

          bureauxOptions.value.push({
            label: lademande.value.nouvelleAgence.nom,
            value: lademande.value.nouvelleAgence.id,
          })
          bureauselect.value = selectedBureauId;
          abonn.value = lademande.value.abonnement.abonne.id;
          await fetchmonatant();
        }
        return response.data.data.abonnement.adresseVirtuelle;
      } catch (error) {
        //
      }
    }

    function communeChange(value, bur) {
      if (value) {
        bureauxOptions.value = [];
        ApiService.get('/bureaux/commune/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              bureauxOptions.value = donnee.map((bureau: any) => {
                return {
                  label: bureau.nom,
                  value: bureau.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      }
    }
    const lademande = ref();
    const abonnement = ref();
    const demande = ref();

    onMounted(async () => {
      demande.value = route.params.demande as string;
      await fetchCommune();

      if (demande.value) {

        adressePostales.value = await getDemande(demande.value);


      }
    });

    watch(bureauselect, (newValue, oldValue) => {
      bureauselect.value = newValue;
    });

    const demande222 = route.params.demande as string;
    const toggleAbonne = () => {
      selectedAbonne.value = !selectedAbonne.value;
    };

    const abonnesOps = ref<Array<{ value: any; label: string }>>([]);

    const selectedAbonneId = ref(null);

    const getAbonne = (values: { id: any; nomComplet: string; telephone: string }) => {
      const data = {
        value: values.id,
        label: values.nomComplet + ' [ ' + values.telephone + ' ]',
      };
      abonnesOps.value = [...abonnesOps.value, data];
      selectedAbonneId.value = values.id;
      return abonnesOps.value;
    };

    const addMpostBox = async (values, { resetForm }) => {
      ApiService.post("/mpostbox/transfert", values)
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "ListeMpostBoxPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addMpostBox(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      values["abonnement"] = abonnement.value;
      values["demande"] = parseInt(demande.value);
      const result = await Swal.fire({
        text: "Choississez un moyen de paiement pour le paiement de la somme de " + values.loyer + " F CFA",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "ELECTRONIQUE",
        cancelButtonText: `CASH`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
      let dismissString: string | undefined;
      dismissString = String(result.dismiss);

      if (result.dismiss !== undefined && dismissString == "cancel" && result.isDismissed) {
        values["mode"] = 1;
        values["check"] = check;
        addMpostBox(values, { resetForm });
      }
      if (result.isConfirmed) {
        values["mode"] = 2;
        KKIAPAY.kkia(values.loyer);

        //Attendre que le paiement soit effectué ou échoué
        await new Promise<void>((resolve) => {
          addKkiapayListener('failed', (event) => {
            failHandler(event);  // Passe values et resetForm ici
            resolve();                                  // Résolution de la promesse
          });

          addKkiapayListener('success', (event) => {
            successHandler(event, values, { resetForm }); // Passe values et resetForm ici
            resolve();                                   // Résolution de la promesse
          });

          const checkRef = toRef(check);

          let resolved = false;

          // Résoudre la promesse après avoir traité le résultat du paiement
          const intervalId = setInterval(() => {
            if (!resolved && checkRef.value.typeError !== "RAS") {
              clearInterval(intervalId);
              resolved = true;
              resolve(); // Ajoutez ici un argument (par exemple, undefined)
            }
          }, 500); // Vérifier toutes les 500 millisecondes
        });
        
        // if ((check.typeError).toString() === "SUCCESS") {
        //   await 
        // } else {
        //   check.typeError = "RAS";
        //   check.idTransaction = 0;
        // }
      }

    }

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        genererAdresse();
      } else {
        telephone.value = "";
        codeVirtuel.value = "";
        nationalnumlber.value = "";
        adressePostales.value = "";
      }
    }

    const genererAdresse = async () => {
      if (bureau.value == null) {
        if (bureauselect.value && telephone.value && nationalnumlber.value) {
          ApiService.get('/bureaus/' + bureauselect.value)
            .then(({ data }) => {
              const bureau = data.data
              codeVirtuel.value = bureau.codeVirtuelle
              adressePostales.value = codeVirtuel.value + nationalnumlber.value;
            })
            .catch((response) => {
              //error(response.data.message);
            });
        }
      } else {
        if (telephone.value && nationalnumlber.value) {
          ApiService.get('/bureaus/' + bureau.value)
            .then(({ data }) => {
              const bureau = data.data
              codeVirtuel.value = bureau.codeVirtuelle
              adressePostales.value = codeVirtuel.value + nationalnumlber.value;
            })
            .catch((response) => {
              //error(response.data.message);
            });
        }
      }


    }





    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch((response) => {
          //error(response.data.message);
        });
    }

    const fetchmonatant = async () => {
      try {
        const response = await axios.get('/parametres/key/AMPOSTBOX');
        (response.data.data, 'response', response.data.valeur)
        console.log("Valeur ===> ", response.data.data.valeur);
        montant.value = parseFloat(response.data.data.valeur);
      } catch (error) {
        //
      }
    };

    return {
      bureauselect,
      mpostBoxSchema,
      addMpostBox,
      communeChange,
      genererAdresse,
      adressePostales,
      telephone,
      validPhone,
      validate,
      onInput,
      payer,
      communesOptions,
      bureauxOptions,
      abonnesOptions,
      selectedAbonne,
      toggleAbonne,
      selectedAbonneId,
      nooptions,
      noOptionsTextAdd,
      noOptionsTextAbb,
      abonnesOps,
      communeselect,
      getAbonne,
      abonn,
      burea,
      description,
      quartier,
      codeVirtuel,
      montant,
    };
  },
});
</script>

<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link v-if="checkPermission('AddAbonnement')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-abonnement"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un abonnement
        </router-link>
      </div>
      <div class="d-sm-flex align-items-center">
        <div v-for="(etat, index) in listeEtats" :key="index" class="form-check me-4">
          <input class="form-check-input shadow-none" type="radio" v-model="selectedEtat" :value="index" name="flexRadioDefault" :id="'option' + index">
          <label class="form-check-label fw-medium" :for="'flexRadioDefault' + index">
            {{ etat }}    
          </label>
        </div>

      </div>
      <!-- <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un abonnement"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div> -->
      
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <!-- ################################################################### -->
      <!-- ################################################################### -->
      <div class="col-md-12">
      <div class="row">
        <!-- <form class="row" @submit.prevent="rechercher"> -->
          
          <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
               Mot clé
              </label>
              <Field 
                style="max-height: 42px;"
                v-model="searchTerm"
                @keyup="rechercher" 
                name="mot" 
                type="text" 
                aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" 
                placeholder="Recherche par mot clé"/>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modele de boîte <span class="text-danger">*</span>
              </label>
              <Field v-model="_modeleBoite"   name="modeleBoite" v-slot="{ field }">
                <Multiselect
                  :options="modeleOptions" 
                  :searchable="true"
                  v-bind="field"
                  placeholder="Sélectionner un modele"
                />
              </Field>
              <ErrorMessage name="modeleBoite" class="text-danger"/>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Catégorie Abonné <span class="text-danger">*</span>
              </label>
              <Field v-model="_categorieAbonne" name="categorieAbonne" v-slot="{ field }">
                <Multiselect
                  :options="categorieAbonneOptions" 
                  :searchable="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner le type de boîte"
                />
              </Field>
              <ErrorMessage name="categorieAbonne" class="text-danger"/>
            </div>
          </div>

          <div class="col" v-if="!etatAgence">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau <span class="text-danger">*</span>
              </label>
              <Field v-model="_bureau" name="bureau" v-slot="{ field }">
                <Multiselect
                  :options="bureauOptions" 
                  :searchable="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner un bureau"
                />
              </Field>
              <ErrorMessage name="bureau" class="text-danger"/>
            </div>
          </div>

        <!-- </form> -->
      </div>
      </div>
      <hr>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                REFERENCE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ABONNE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Numéro DE BOITE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ADRESSE POSTALE 
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                TARIF
              </th>
              <!--<th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                MONTANT TOTAL
              </th>-->
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ETAT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                DATE ABONNEMENT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                MODELE DE BOITE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                CATEGORIE ABONNE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                BUREAU
              </th>
              <th scope="col"  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(abonne, index) in abonnements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ abonne.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.abonne? abonne.abonne.nomComplet : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ abonne.boite?.refBoite }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.boite?.adressePostale?.adresse_postale }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ separateur(abonne.loyer)}}
              </td>
              <!--<td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(abonne.montant) }}
              </td>-->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="abonne.etat == 'Résilié'" class="badge text-outline-danger">{{ abonne.etat }}</span>
                <span v-else class="badge text-outline-primary">{{ abonne.etat }}</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(abonne.createdAt)  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.boite.modele?.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ (abonne.abonne.categorieAbonne?.libelle.length > 25) ? abonne.abonne.categorieAbonne?.libelle.substring(0, 25) + '...' : abonne.abonne.categorieAbonne?.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.bureau?.nom }}
              </td>
              <td
              class="shadow-none lh-1 fw-medium text-end pe-0 sm"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions 
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                      <li v-if="checkPermission('ViewAbonnement')">
                          <router-link :to="{ name: 'ViewAbonnementPage', params: { id: abonne.id } }" class="dropdown-item d-flex align-items-center">
                              <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                          </router-link>
                      </li>
                      <li v-if="abonne.etat == 'En attente' && checkPermission('ValidAbonnement')">
                          <router-link :to="{ name: 'ViewAbonnementPage', params: { id: abonne.id } }" class="dropdown-item d-flex align-items-center">
                              <i class="flaticon-check lh-1 me-8 position-relative top-1"></i>Valider l'abonnement
                          </router-link>
                      </li>
                      <!-- <li v-if="abonne.etat == 'En attente' && checkPermission('ValidAbonnement')">
                          <a
                            class="dropdown-item d-flex align-items-center"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#ValideAbonnementModal"
                            @click="openValidAbonnementModal(abonne)"
                          >
                          <i class="flaticon-check lh-1 me-8 position-relative top-1"></i>
                            Valider l'abonnement
                          </a>
                        </li> -->
                      <!-- <li>
                        <a href="" @click="resiliation(abonne.id)" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-trash lh-1 me-8 position-relative top-1"></i>Résilier d'office
                        </a>
                      </li> -->
                  <!--<li>
                          <a @click="suppression(abonne.id, abonnements, 'abonnements', 'abonnement')" class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                              <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>Supprimer
                          </a>
                      </li>-->
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
        <!-- <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">11</span> out of
          <span class="fw-bold">134</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link active" href="#">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav> -->
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />

      </div>
    </div>
  </div>


  <!-- MODAL -->
    <div
    class="modal fade createNewModal"
    id="Resiliation"
    tabindex="-1"
    ref="ResiliationRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black"> Résiliation d'office</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="resiliationForm" @submit="resilier" >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Motif <span class="text-danger">*</span>
                </label>
                <Field name="motif" type="textarea" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Veuillez renseigner le motif"/>
                <ErrorMessage name="motif" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              Resilier
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
  <!-- ENDMODAL -->
  <!-- <AddBoitePostale /> -->

  <div
    class="modal fade createNewModal"
    id="ValideAbonnementModal"
    tabindex="-1"
    ref="addValidAbonnementModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="width:100%; max-width:450px;">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">
        </h5>
        </div>

        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <div class="text-center">
          <h5>Vous êtes sur le point de valider l'abonnement: </h5>
          <h5>{{ selectedAbonnement?.refAbo }} de {{ selectedAbonnement?.abonne?.nomComplet }}</h5>
          <br>
            <button
            class="btn btn-primary transition border-0 lh-1 fw-medium mr-4"
            type="submit"
            @click="valideAbonnement()"
            >
                Validez
            </button>
            <router-link to="#" 
            data-bs-dismiss="modal"
                class=" ms-3 btn btn-danger transition border-0 lh-1 fw-medium">
                <i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from "@/services/ApiService";
import { useRouter } from 'vue-router';
import { Abonnements } from "@/models/Abonnement";
import { format_date,suppression,format_Date, hideModal, showModal,separateur, error,success } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";
import Multiselect from '@vueform/multiselect';
import axios from "axios";

export default defineComponent({
    name: "ListeAbonne",
    components: {
      Form,
      Field,
      Multiselect,
      PaginationComponent,
  },
  setup: () => {
    

    const abonnements = ref<Array<Abonnements>>([]);
    const abonnement = ref<Abonnements>();
    const ResiliationRef = ref<null | HTMLElement>(null);
    const modeleOptions = ref([]);
    const bureauOptions = ref([]);
    const categorieAbonneOptions = ref([]);

    // je veux avoir la liste d'etat des abonnement
    const listeEtats: { [key: string]: string } = {
      '': 'Tout',
      'Actif': 'Actif',
      'Inactif': 'Inactif',
      'Résilié': 'Résilié',
      'Transféré': 'Transféré',
      'En attente': 'En attente'
    };
    const selectedEtat = ref('');

    // BEGIN PAGINATE
    const _modeleBoite = ref('');
    const _categorieAbonne = ref('');
    const _bureau = ref('');
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);
    const router = useRouter();

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllAbonnements(page_, limit_);
      } catch (error) {
        //
      }
    };
    const etatAgence = ref(true);
    if(JwtService.getUserCheckIfAllAgence() == "true"){
      etatAgence.value = false;
    }

    watch(_modeleBoite, () => {
      rechercher();
    });
    watch(_bureau, () => {
      rechercher();
    });
    watch(_categorieAbonne, () => {
      rechercher();
    });
    watch(selectedEtat, () => {
      if(selectedEtat.value == 'Tout')
        selectedEtat.value='';
      rechercher();
    });

    function rechercher(){
      getAllAbonnements(page.value, limit.value, searchTerm.value, _bureau.value, _categorieAbonne.value, _modeleBoite.value, selectedEtat.value );
    }

    // END PAGINATE

    const resiliation = (id: number) => {
     //show modal
      showModal(ResiliationRef.value!);
    }

    const resilier = () => {
      // ApiService.put(`abonnements/${abonnement.value?.id}/resiliation`, {motif: abonnement.value?.motif})
      // .then(({ data }) => {
      //     getAllAbonnements();
      //     hideModal(ResiliationRef.value!);
      //   })
      //   .catch(({ response }) => {
      //     error(response.data.msg)
      // });
    }

    const fetchBureaux = async () => {
      ApiService.get("/bureaus")
      .then(({ data }) => {
        const donnees = data.data.data;
        bureauOptions.value = donnees.map((bureau) => {
          return {
            value: bureau.id,
            label: bureau.nom,
          }
        });
      })
      .catch(({ response }) => {
        // error(response.data.message)
      });
    }

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        // error(error.data.message);
      }
    };

    const fetchModeles = async () => {
      ApiService.get("/modele_boites")
      .then(({ data }) => {
        const donnees = data.data;
        modeleOptions.value = donnees.map((modele) => {
          return {
            value: modele.id,
            label: modele.libelle,
          }
        });
      })
      .catch(({ response }) => {
        // error(response.data.message)
      });
    }

    function getAllAbonnements(page = 1, limi = 10, searchTerm = '', _bureau = '', _categorieAbonne = '' , _modeleBoite = '' , selectedEtat = '') {
      return ApiService.get(`/abonnements?page=${page}&limit=${limi}&mot=${searchTerm}&bureau=${_bureau}&categorieAbonne=${_categorieAbonne}&modeleBoite=${_modeleBoite}&etat=${selectedEtat}&`)
        .then(({ data }) => {
          abonnements.value = data.data.data
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
          // error(response.data.message);
        });
    }

    onMounted(() => {
      getAllAbonnements();
      fetchBureaux();
      fetchModeles();
      fetchCategorieAbonnes();
    });

    const addValidAbonnementModalRef = ref<null | HTMLElement>(null);

    const valideAbonnement = () => {
    const dataToSend = {
      id : selectedAbonnement?.value?.id.toString()      };
      console.log('data', dataToSend);
      ApiService.put("/valide/abonnements/"+ selectedAbonnement?.value?.id.toString(), dataToSend)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            hideModal(addValidAbonnementModalRef.value);
            getAllAbonnements();
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const selectedAbonnement = ref<Abonnements | undefined>(undefined);

    const openValidAbonnementModal = (abonne: Abonnements) => {
      selectedAbonnement.value = { ...abonne };
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }
    
    return { 
      abonnements,
      checkPermission,
      modeleOptions,
      bureauOptions,
      categorieAbonneOptions,
      format_date,
      suppression,
      getAllAbonnements, 
      resiliation,
      resilier,
      page, 
      etatAgence,
      totalPages,
      limit,
      format_Date,
      totalElements,
      handlePaginate,
      separateur,
      searchTerm,
      _bureau,
      _categorieAbonne,
      _modeleBoite,
      rechercher,
      Multiselect,
      valideAbonnement,
      selectedAbonnement,
      abonnement,
      openValidAbonnementModal,
      addValidAbonnementModalRef,
      listeEtats,
      selectedEtat
    };
  },
});
</script>


<template>
    <BreadCrumb PageTitle="Liste du journal operation" />
    <ListeJournalOperation/>
</template>
  
<script lang="ts">
    import { defineComponent } from "vue";

    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeJournalOperation from "../../components/Journal/ListeJournalOperation.vue";


    export default defineComponent({
        name: "ListeJournalOperationPage",
        components: {
        BreadCrumb,
        ListeJournalOperation,
        },
    });
</script>
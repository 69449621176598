<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white d-lg-flex align-items-center p-15 p-sm-20 p-md-25">
            <div class="d-sm-flex align-items-center" style="width:100%;">
                <Form style="width:100%;" class="search-box position-relative me-15">
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-15 mt-30 mb-sm-20 mb-md-25">
                                <Field name="recherche" type="text"  v-model="recherche"
                                class="form-control shadow-none text-black rounded-0 border-0" placeholder="Rechercher"/>
                                <ErrorMessage name="recherche" class="text-danger"/>
                            </div>
                        </div>
                        <div v-if="!etatAgence" class="col-4">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Agence <span class="text-danger">*</span>
                                </label>
                                <Field name="bureau" v-model="bureau" v-slot="{ field }">
                                    <Multiselect 
                                    :options="bureausBoitesOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    placeholder="Sélectionner une agence"
                                    no-results-text="Aucune agence trouvée"
                                    />
                                </Field>
                                <ErrorMessage name="bureau" class="text-danger"/>
                            </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group mb-15 mb-sm-20 mb-md-25">
                            <label class="d-block text-black fw-semibold mb-10">
                            Abonné <span class="text-danger">*</span>
                            </label>
                            <Field name="abonne" v-model="abonne" v-slot="{ field }">
                              <Multiselect
                                v-model="field.value"
                                v-bind="field"
                                placeholder="Rechercher un abonné"
                                :filter-results="false"
                                :min-chars="3"
                                :resolve-on-load="false"
                                :delay="0"
                                :searchable="true"
                                :options-limit="300"
                                no-results-text="Aucun abonné trouvé"
                                :options="async (query) => {
                                return await fetchAbonnes(query)
                                }"
                                noResultText= "Aucun enregistrement trouvé"
                                noOptionsText="Tapez au moins trois caractères"
                              />
                            </Field>
                            <ErrorMessage name="abonne" class="text-danger"/>
                          </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Etat d'abonnement <span class="text-danger">*</span>
                                </label>
                                <Field name="option" v-model="etat" v-slot="{ field }">
                                    <Multiselect 
                                    :options="etatsOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    placeholder="Sélectionner un état"
                                    no-results-text="Aucun état trouvé"
                                    />
                                </Field>
                                <ErrorMessage name="option" class="text-danger"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-10 col-3">
                            <div  class="form-group mb-15 mt-50 mb-sm-20 mb-md-25" >
                            </div>
                           
                        </div>

                    </div>
                </Form>
            </div>
          <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
        </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  REFERENCE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  DATE ABONNEMENT
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Abonné</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Téléphone</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Activité</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Loyer</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Dernière paie</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Payés</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Impayés</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Agence</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Statut</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(abon , index) in abonnements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{abon.a_refAbo}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date( abon.a_createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.abon_nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.abon_telephone }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.act_libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.a_loyer ? separateur(abon.a_loyer) : 0  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(abon.r_updatedAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.paiements ? separateur(abon.paiements) : 0 }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur((abon.totalDu - abon.paiements) < 0 ? - (abon.totalDu - abon.paiements) : (abon.totalDu - abon.paiements) )  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abon.bu_nom ? abon.bu_nom : ""  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="abon.a_etat == 'En attente'" class="badge text-outline-info">En attente</span>
                <span v-if="abon.a_etat == 'Actif'" class="badge text-outline-success">Actif</span>
                <span v-if="abon.a_etat == 'Résilié'" class="badge text-outline-danger">Résilié</span>
                <span v-if="abon.a_etat == 'Inactif'" class="badge text-outline-danger">Inactif</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
          <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
    import { defineComponent, onMounted,watch, ref} from "vue";
    import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
    import Swal from "sweetalert2";
    import axios from 'axios';
    import ApiService from "@/services/ApiService";
    import { suppression,error,format_date,separateur } from "@/utils/utils";
    import Multiselect from '@vueform/multiselect';
    import JwtService from "@/services/JwtService";
    import PaginationComponent from '@/components/Utilities/Pagination.vue';
  
export default({
  name: "EtatMPostBox",
  components: {
    Form,
    Field,
    ErrorMessage,
    PaginationComponent,
    Multiselect
    },
    
    // {{ abon.abonnement.createdAt | formatDateYear }}
    setup(){
        const modelesBoitesOptions =ref();
        const abonnements = ref();
        const abonnesOptions = ref([]);
        const bureausBoitesOptions =ref();
        const model = ref(); 
        const abonne = ref(); 
        const recherche = ref("");
        const etat = ref("");
        const bureau = ref();
        const fetchModeles = async () => {
            try {
            const response = await axios.get('/modele_boites');
            const modelesData = response.data.data;
            modelesBoitesOptions.value = modelesData.map((boite) => ({
                value: boite.id,
                label: boite.code+" [ "+ boite.libelle+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const searchTerm = ref('');
        const page = ref(1);
        const totalPages = ref(0);
        const limit = ref(10);
        const totalElements = ref(0);
        const action = async (page = 1, limi = 10, searchTerm = '')=>{
            const element = {
                abonne:abonne.value,
                model:model.value,
                recherche:recherche.value,
                etat:etat.value,
                bureau:bureau.value,
                page:page,
                totalPages:totalPages.value,
                limit:limit.value,
                totalElements:totalElements.value,
                mot:searchTerm
            }

            return await ApiService.post("/etats/mpostbox",element)
                .then((response) => {
                  if (response.status === 200){
                      abonnements.value = response.data.data.data;
                      totalPages.value = response.data.data.totalPages;
                      limit.value = response.data.data.limit;
                      totalElements.value = response.data.data.totalElements;
                  }
                })
                .catch((errorr) => {
                    error(errorr.response.data.message);
                });
        }
        const etatsOptions =[
            { value: null, label: 'Selectionnez un état'},
            {
                label: "Résilié",
                value:'Résilié'
            }, 
            { value: 'Actif', label: 'Actif'},
            { value: "En attente", label: 'En attente'}
        ];
        const fetchAbonnes = async (key) => {
              try {
                const response = await axios.get('/abonnes/recherche/'+key);
                const abonnesData = response.data.data;
                abonnesOptions.value = abonnesData.map((abonne) => ({
                  value: abonne.id,
                  label: abonne.nomComplet+' [ '+abonne.telephone+' ]',
                }));
                return abonnesOptions.value;
              } catch (error) {
                //
              }
            };
        const fetchBureaus = async () => {
            try {
                
            const response = await axios.get('/bureaus');
            const bureausData = response.data.data.data;
            bureausBoitesOptions.value = bureausData.map((bureau) => ({
                value: bureau.id,
                label: bureau.code+" [ "+ bureau.nom+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const handlePaginate = ({ page_, limit_ }) => {
          try {
            page.value = page_;
            action(page_, limit_);
          } catch (error) {
            //
            
          }
        };
    onMounted(() => {
        fetchModeles();
        fetchBureaus();
        action(1, 10);
    });

    watch(model, (newValue, oldValue) => {
        model.value= newValue;
        action(1, 10);
    });
    watch(bureau, (newValue, oldValue) => {
       bureau.value = newValue;
       action(1, 10);
    });
    watch(abonne, (newValue, oldValue) => {
        abonne.value = newValue;
        action(1, 10);
    });
    watch(etat, (newValue, oldValue) => {
        etat.value = newValue;
        action(1, 10);
    });
    watch(recherche, (newValue, oldValue) => {
        recherche.value = newValue;
        action(1, 10);
    });

    const etatAgence = ref(true);
    if(JwtService.getUserCheckIfAllAgence() == "true"){
      etatAgence.value = false;
    }
  
      return {etatAgence, suppression,page,totalPages,limit, totalElements,handlePaginate,abonnements,abonne,abonnesOptions,fetchAbonnes,separateur, etatsOptions,modelesBoitesOptions,bureausBoitesOptions,model, recherche,etat,format_date, bureau };
    },

  });
  </script>
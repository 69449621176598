<template>
    <BreadCrumb PageTitle="Modifier un colis" />
    <EditColis />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditColis from "../../components/Colis/EditColis.vue";
  
    export default defineComponent({
      name: "EditColisPage",
      components: {
        BreadCrumb,
        EditColis,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Envoyer une notification" />
    <AddNotification />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddNotification from "../../components/Notification/AddNotification.vue";
  
    export default defineComponent({
      name: "AddNotificationPage",
      components: {
        BreadCrumb,
        AddNotification,
      },
    });
  </script>
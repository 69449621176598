<template>
    <BreadCrumb PageTitle="Liste des abonnés" />
    <ListeAbonne />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeAbonne from "../../components/Abonne/ListeAbonne.vue";
  
  
  export default defineComponent({
    name: "ListeAbonnePage",
    components: {
      BreadCrumb,
      ListeAbonne,
    },
  });
  </script>
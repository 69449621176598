<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form
        ref="attestationForm"
        @submit="addRemboursement"
        :validation-schema="selectedSchema"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Date <span class="text-danger">*</span>
              </label>
              <Field
                name="dateRemboursement"
                type="date"
                v-model="debut"
                style="background-color: #fff"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Choisisser la date"
              />
              <ErrorMessage name="dateRemboursement" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
              Type de remboursement <span class="text-danger">*</span>
              </label>
              <Field  v-model="defautOp" name="typeRemboursement" v-slot="{ field }">
                <Multiselect
                  :options="options" 
                  :searchable="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner le type de boîte"
                />
              </Field>
              <ErrorMessage name="typeRemboursement" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4" v-show="fieldHide1">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement<span class="text-danger">*</span>
              </label>
              <Field
                name="abonnement"
                v-model="abonnementSelected"
                v-slot="{ field }"
              >
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonnement"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  :options="
                    async (query) => {
                      return await fetchAbonnements(query);
                    }
                  "
                  noResultText="Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-show="fieldHide2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Demande<span class="text-danger">*</span>
              </label>
              <Field
                name="demande"
                v-model="demandeSelected"
                v-slot="{ field }"
              >
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher une demande"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  :options="
                    async (query) => {
                      return await fetchDemandes(query);
                    }
                  "
                  noResultText="Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                />
              </Field>
              <ErrorMessage name="demande" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-show="fieldHide4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant Remboursé
              </label>
              <Field v-model="montanttotalval" readonly="true" name="montantRembourse" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Montant total l'abonnement"/>
                <ErrorMessage name="montantRembourse" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-8" v-show="fieldHide3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Description <span class="text-danger">*</span>
              </label>
              <Field name="description" cols="30"
                rows="5" as="textarea" placeholder="Ecrire votre description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                  v-model="field.value"
                  class="form-control shadow-none rounded-0 text-black"
                ></textarea>
            </Field>
                <ErrorMessage name="description" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center">
              <button v-show="fieldHide5"
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Rembourser
              </button>
              <router-link
                to="/liste-remboursement"
                class="btn btn-danger transition border-0 lh-1 fw-medium"
                ><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative"></span>Annuler
              </router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onBeforeUnmount, onMounted,watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import { useRouter } from "vue-router";
import router from '@/router';
import * as Yup from "yup";
import {
  format_date,
  suppression,
  format_Date,
  hideModal,
  showModal,
  separateur,
  error,
  success,
} from "@/utils/utils";
import ApiService from "../../services/ApiService";
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "AddRemboursement",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },

  setup: () => {

    const today = new Date();

    const formatDate = (date: Date): string => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        const debut = ref(formatDate(new Date()));

    const remboursementResilisationSchema = Yup.object().shape({
      dateRemboursement: Yup.date().max(`${today}`).required("La date est obligatoire"),
      abonnement: Yup.number().required("L'abonnement est obligatoire"),
      montantRembourse:Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      typeRemboursement:Yup.string().required("Le type est obligatoire"),
    });

    const remboursementDemandeSchema = Yup.object().shape({
      demande:Yup.string().required("La demande est obligatoire"),
      dateRemboursement: Yup.date().max(`${today}`).required("La date est obligatoire"),
      montantRembourse:Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      typeRemboursement:Yup.string().required("Le type est obligatoire"),
    });
    const defaultSchema = Yup.object().shape({
      demande:Yup.string().required("La demande est obligatoire"),
      abonnement: Yup.string().required("L'abonnement est obligatoire"),
      dateRemboursement: Yup.date().max(`${today}`).required("La date est obligatoire"),
      montantRembourse:Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      typeRemboursement:Yup.string().required("Le type est obligatoire"),
    });

    const  options =  [
    { value: "1", label: 'Faites un choix' }, 
    { value: 'Abonnement', label: 'Abonnement' }, 
    { value: 'Demande', label: 'Demande'}
 ];

    const defautOp = ref("1");
    const montanttotalval = ref();
    const selectedSchema = ref();
    const editorRef = ref();
    const abonnementOptions = ref([]);
    const abonnementsDatas = ref<any[]>([]);
    const demandesOptions = ref([]);
    const demandesDatas = ref<any[]>([]);
    const nooptions = "Aucun enregistrement trouvé";
    const noResultText = "Aucun enregistrement trouvé";
    const noOptionsTextAbb = ref("Tapez au moins trois caractères");
    const isReadonly = ref(true);
    const fieldHide1 = ref(false);
    const abonnementSelected = ref("");
    const demandeSelected = ref("");
    const fieldHide2 = ref(false);
    const fieldHide3 = ref(false);
    const fieldHide4 = ref(false);
    const fieldHide5 = ref(false);
    const fieldHide6 = ref(false);

    //JE RECUPERE LES ABONNEMENTS SUIVANT LE MOT CLE SAISI PAR L'UTILISATEUR
    const fetchAbonnements = async (key) => {
      try {
        const response = await axios.get(`/abonnements/recherche/resilie/${key}`);

        //JE STOCK LES DEMANDES RETROUVER PAR MA REQUETE DANS CETTE VARIABLE 
        const abonnementsData = response.data.data;
        //fin de stockage

        //ICI JE REINITIALISE LA VALEUR DE LA VARIABLE POUR QU'ELLE NE CHARGE QUE LES NOUVELLES DONNEES
        abonnementsDatas.value = [];
        // fin réinitialisation


        //JE RENSEIGNE LE TABLEAU DES OPTIONS POUR CHARGER LE SELECT DE MES ABONNEMENTS
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id,
          label: `${abonnement.abonne.nomComplet} - [${
            abonnement.adresseVirtuelle
              ? abonnement.adresseVirtuelle
              : abonnement.boite?.adressePostale?.adresse_postale
          }] - ${abonnement?.bureau?.nom}`,
        }));
        
        if (abonnementOptions.value.length == 0) {
          noOptionsTextAbb.value = noResultText;
        } else {
          noOptionsTextAbb.value = "Tapez au moins trois caractères";
        }
        //JE STOCK LES ABONNEMENTS RETROUVER PAR MA REQUETE DANS CETTE VARIABLE 
        // POUR FAIRE DES RECHERCHE D'OBJET SUR LE CONTENU DE CE TABLEAU
        abonnementsDatas.value = abonnementsData;
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    };

    //JE RECUPERE LES DEMANDES SUIVANT LE MOT CLE SAISI PAR L'UTILISATEUR
    const fetchDemandes = async (key) => {
      try {
        const response = await axios.get(`/demandes/recherche2/${key}`);
        //JE STOCK LES DEMANDES RETROUVER PAR MA REQUETE DANS CETTE VARIABLE 
        const demandesData = response.data.data;

        //ICI JE REINITIALISE LA VALEUR DE LA VARIABLE POUR QU'ELLE NE CHARGE QUE LES NOUVELLES DONNEES
        demandesDatas.value = [];
        // fin réinitialisation

        //JE RENSEIGNE LE TABLEAU DES OPTIONS POUR CHARGER LE SELECT DE MES DEMANDES
        demandesOptions.value = demandesData.map((demande) => ({
          value: demande.id, // ICI L'IDENTIFIANT
          label: `${demande.refDemande} 
          - [${demande.typeDemande.libelle}] - 
          - ${demande.abonnement.abonne.nomComplet}`, // ICI LE LIBELLE QUI SERA LU PAR L'UTILISATEUR
        }));
         //JE STOCK LES DEMANDES RETROUVER PAR MA REQUETE DANS CETTE VARIABLE 
         // POUR FAIRE DES RECHERCHE D'OBJET SUR LE CONTENU DE CE TABLEAU
        demandesDatas.value = demandesData;
        return demandesOptions.value; // UN RETOUR QUE JE N'UTILISE FINALEMENT PAS
      } catch (error) {
        //
      }
    };
    const typeRemboursemantChange = async (value) => {

      if(value == null || value == undefined || value == ""){
        return;
      }
      switch (value) {
        case "Abonnement":
          // abonnement
          selectedSchema.value = remboursementResilisationSchema;
          fieldHide1.value = true;
          fieldHide2.value = false;
          fieldHide3.value = true;
          fieldHide4.value = true;
          fieldHide5.value = true;
          break;

        case "Demande":
          // Demande
          selectedSchema.value = remboursementDemandeSchema;
          fieldHide1.value = false;
          fieldHide2.value = true;
          fieldHide3.value = true;
          fieldHide4.value = true;
          fieldHide5.value = true; 
          break;

        case "1":
          // Demande
          console.log("C'est bien dedans")
          selectedSchema.value = defaultSchema;
          fieldHide1.value = false;
          fieldHide2.value = false;
          fieldHide3.value = false;
          fieldHide4.value = false;
          fieldHide5.value = false;
          break;

        default:
          break;
      }

    }

    const addRemboursement = async (values, { resetForm }) => {
      console.log("Salutation voici ce que j'ai dans le ventre", values);
      ApiService.post('remboursements', values)
        .then(async(response) => {
          //
          if (response.status === 201) {
            success(response.data.message)
            resetForm();
            router.push({ name: 'ListeRemboursementPage'});
          }
        }).catch((errorr) => {
            error(errorr.response.data.message);
        });
    };

    onMounted(() => {
      //
    });

    watch(defautOp, async (newValue, oldValue) => {
      if(newValue != oldValue && newValue){
        abonnementOptions.value = [];
        demandesOptions.value = [];
        console.log(newValue)
        typeRemboursemantChange(newValue);
      }
    });

    watch(abonnementSelected, async (newValue, oldValue) => {
      if(newValue != oldValue && newValue){
       const abonnement =  abonnementsDatas.value.find(objet => objet.id === parseInt(newValue));
       console.log("L'abonnement est le suivant : ",abonnement);
       const montant  = abonnement.caution ? abonnement.caution : 0;
       montanttotalval.value = parseFloat(montant);
      }
    });

    watch(demandeSelected, async (newValue, oldValue) => {
      if(newValue != oldValue && newValue){
       const demande =  demandesDatas.value.find(objet => objet.id === parseInt(newValue));
       console.log("La demande est la suivante : ",demande);
       const montant  = (demande.paiement ? demande.paiement.montantPaye : demande.typeDemande.service.cout);
       montanttotalval.value = parseFloat(montant);
      }
    });



    return {
      isReadonly,
      fieldHide1,
      fetchAbonnements,
      addRemboursement,
      abonnementOptions,
      editorRef,
      noOptionsTextAbb,
      remboursementDemandeSchema,
      remboursementResilisationSchema,
      selectedSchema,
      nooptions,
      noResultText,
      abonnementSelected,
      demandeSelected,
      options,
      defautOp,
      fetchDemandes,
      montanttotalval,
      fieldHide2,
      fieldHide3,
      fieldHide4,
      fieldHide5,
      debut,
      fieldHide6
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link  v-if="checkPermission('AddResiliation')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-resiliation"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Résilier un abonnement
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une résiliation"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                REFERENCE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ABONNE
              </th>
              <!-- <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                BOITE
              </th> -->
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Statut
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ADRESSE POSTALE 
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                TARIF
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                MONTANT TOTAL
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                DATE ABONNEMENT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                DATE Résiliation
              </th>
              <!-- <th scope="col"  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resiliation, index) in resiliations" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ resiliation.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ resiliation.abonne? resiliation.abonne.nomComplet : "" }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-primary">
                {{ resiliation.boite?.refBoite }}
              </td> -->
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success">
                  {{ resiliation.etat }}
                </span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ resiliation.adresseVirtuelle ? resiliation.adresseVirtuelle:resiliation.boite.adressePostale?.adresse_postale }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ separateur(resiliation.loyer) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(resiliation.montant) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(resiliation.createdAt)  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(resiliation.dateResiliation)  }}
              </td>
              <!-- <td
              class="shadow-none lh-1 fw-medium text-end pe-0 sm"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions 
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                      <li>
                          <router-link :to="{ name: 'ViewAbonnementPage', params: { id: abonne.id } }" class="dropdown-item d-flex align-items-center">
                              <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                          </router-link>
                      </li>
                      <li>
                        <a href="" @click="resiliation(abonne.id)" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-trash lh-1 me-8 position-relative top-1"></i>Résilier d'office
                        </a>
                      </li>
                  <li>
                          <a @click="suppression(resiliation.id, resiliations, 'resiliations', 'resiliation')" class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                              <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>Supprimer
                          </a>
                      </li>
                  </ul>
              </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />

      </div>
    </div>
  </div>


</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../services/ApiService";
import { Abonnements } from "../../models/Abonnement";
import { format_date,suppression,format_Date, hideModal, showModal, separateur, error } from "../../utils/utils";
import PaginationComponent from '../../components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeResiliation",
    components: {
      PaginationComponent
  },
  setup: () => {
    onMounted(() => {
      getAllResiliations();
    });

    const resiliations = ref<Array<Abonnements>>([]);
    //const abonnement = ref<Abonnements>();
    //const ResiliationRef = ref<null | HTMLElement>(null);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllResiliations(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher(){
      getAllResiliations(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllResiliations(page = 1, limi = 10, searchTerm = '') {
      
      return ApiService.get(`/resiliation/abonnements?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          console.log(data,'datadatadatadatadatadata');
          resiliations.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          // return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }
    
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

const checkPermission = (name) => {
  return privileges.value.includes(name);
}

    return { resiliations,
      checkPermission,
    format_date,
    suppression,
    getAllResiliations, 
    page, 
    totalPages,
    limit,
    format_Date,
    totalElements,
    handlePaginate,
    searchTerm,
    separateur,
    rechercher,

    };
  },
});
</script>


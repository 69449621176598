<template>
    <BreadCrumb PageTitle="Résilier un abonnement" />
    <AddResiliation />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddResiliation from "../../components/Resiliations/AddResiliation.vue";
  
    export default defineComponent({
      name: "AddResiliationPage",
      components: {
        BreadCrumb,
        AddResiliation,
      },
    });
  </script>
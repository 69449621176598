<template>
  <BreadCrumb PageTitle="Statistique des garanties" />
  <div class="row">
    <div class="col-lg-6">
      <PieChart v-if="etatLibelleCat.length > 0 && etatDonneCat.length > 0" 
        :colors="colors"  
        :labels="etatLibelleCat" 
        :project="etatDonneCat" 
        nom="Total des garanties par catégorie d'abonnés"/>
    </div>
    <div class="col-lg-6">
      <PolarAreaChart v-if="etatCautionDonne.length > 0" 
        :colors="colorsDemandeLibelle"  
        :labels="etatCautionLibelle" 
        :serie="etatCautionDonne" 
        nom="Total des garanties par modèle de boîtes" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent ,onMounted, ref} from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
//import WeeklySales from "../../components/Charts/Charts/WeeklySales.vue";
//import BarChart from "../../components/Charts/Charts/BarChart.vue";
import PieChart from "../../components/Charts/Charts/PieChart.vue";
// import DonutChart from "../../components/Charts/Charts/DonutChart.vue";
// import PerformanceChart from "../../components/Charts/Charts/PerformanceChart.vue";
import PolarAreaChart from "../../components/Charts/Charts/PolarAreaChart.vue";
import ApiService from "@/services/ApiService";

export default defineComponent({
  name: "StatistiqueMpostPage",
  components: {
    BreadCrumb,
    //BarChart,
    PieChart,
    //DonutChart,
    //PerformanceChart,
    PolarAreaChart,
  },

  setup: () => {
      const  colorsDemandeLibelle = ["#0a59a4", "#f3c148", "#d4623a","#c80101" ,"#198754", "#1FB1E6","#007aff"];
      const  colors = ["#0a59a4", "#f3c148", "#d4623a","#c80101" ];
      const project = [60, 80, 100];
      const labels = ["Lesson Discuss", "Taking Class", "Work In Progress"];
      const categories = [2019,2020, 2021, 2022, 2023, 2024];
      const color =["#0a59a4", "#c80101" ];
      const numberChart = ref<Array<{name: string,data: number[]}>>([]);

      const numberChartCa = ref<Array<{name: string,data: number[]}>>([]);
      
      const etatDonne = ref<number[]>([]);
      const etatLibelle = ref<string[]>([]);
      const etatCautionLibelle = ref<string[]>([]);
      const etatCautionDonne = ref<number[]>([]);


      const etatLibelleCat = ref<string[]>([]);
      const etatDonneCat = ref<number[]>([]);

      const etatLibelleAnne = ref<string[]>([]);

      const etatLibelleAnneCa = ref<string[]>([]);

    const fetchAbonnementByCategorie = async () => {
      try {
        etatLibelleCat.value = [];
        etatDonneCat.value = [];
        const response = await ApiService.get('etats/cautions/par/categorie');
        const etatDonnee = response.data.data.data;
        for (let i = 0; i < etatDonnee.raw.length; i++) {
          etatLibelleCat.value.push(etatDonnee.raw[i]["cate_code"] == null ? "NEANT" : etatDonnee.raw[i]["cate_code"]);
          etatDonneCat.value.push(parseInt((etatDonnee.raw[i]["cautions"]).toString()));
        }
        console.log(etatDonneCat,'etatDonneCatetatDonneCatetatDonneCat');

      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    const fetchCautionsByModel = async () => {
      try {
        etatCautionLibelle.value = [];
        etatCautionDonne.value = [];
        const response = await ApiService.get('etats/cautions/par/modele');
        const etatDonneee = response.data.data.data;
        console.log(etatDonneee,"ffffetatDonneeetatDonneeffffffffffffffffffffffff",etatDonneee.length);
          for (let i = 0; i < etatDonneee.raw.length; i++) {
            console.log(etatDonneee.raw[i]["modele_libelle"]);
            console.log(etatDonneee.raw[i]["cautions"]);
            etatCautionLibelle.value.push(etatDonneee.raw[i]["modele_libelle"] == null ? "NEANT" : etatDonneee.raw[i]["modele_libelle"]);
            etatCautionDonne.value.push(etatDonneee.raw[i]["cautions"] == null ? 0:parseInt(etatDonneee.raw[i]["cautions"]));
          }
          
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

   
      onMounted( async () => {
        await fetchCautionsByModel();
        await fetchAbonnementByCategorie();
        
      });

      return {
        colors,
        etatDonne,
        etatLibelle,
        etatDonneCat,
        etatLibelleCat,
        etatCautionLibelle,
        etatCautionDonne,
        colorsDemandeLibelle,
        color,
        project,
        labels,
        categories,
        numberChart,
        etatLibelleAnne,
        numberChartCa,
        etatLibelleAnneCa
      }
  }
  });
</script>
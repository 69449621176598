<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
      >
        <div class="d-sm-flex align-items-center">
          <!-- <router-link 
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            to="/ajouter-transfert"
          >
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
            Ajouter un transfert
          </router-link> -->
          <!-- <button
            class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
            type="button"
          >
            Exporter
            <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
          </button> -->
        </div>
        <div class="d-flex align-items-center">
          <form class="search-box position-relative me-15">
            <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Rechercher une demande"/>
            <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0">
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
          <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table  
          
          class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  NOUVELLE REF
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                 NOUVEL ABONNE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  ANCIENNE REF
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  ANCIEN ABONNE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  BOITE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  AGENCE
                </th>
                
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  ADRESSE POSTALE
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                  DATE CESSION
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(demande, index) in demandeCessions" :key="index">
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ demande.refAbo }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.abonne?.nomComplet }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ demande.abonnement?.refAbo }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.abonnement?.abonne.nomComplet }}
                </td>
                
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.boite?.refBoite }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.boite?.bureau.nom }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ demande.boite ? demande.boite.adressePostale.adresse_postale : demande.adresseVirtuelle
 }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ format_date(demande.createdAt)  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
          <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import Swal from "sweetalert2";
  import ApiService from "../../services/ApiService";
  import { Abonnements ,Abonnement } from "../../models/Abonnement";
  import {Demande} from "../../models/Demande"
  import { format_date,suppression, showModal, error } from "../../utils/utils";
  import 'datatables.net-select';
  import { useRouter } from 'vue-router';
  import JwtService from "@/services/JwtService";
  import PaginationComponent from "@/components/Utilities/Pagination.vue";
  
  export default defineComponent({
      name: "ListeCession",
      components: {
        // DataTable
        // AddCessionModal
        PaginationComponent
    },
    setup: () => {
      onMounted(() => {
        getAllCessions();
      });
      //DataTable.use(DataTablesCore);
      let counter = 0;
      let dt;
      const data = ref([]);
      const table = ref();
      const demandeCessions = ref<Array<Abonnements>>([]);
      const abonnement = ref<Abonnement>();
      const idCession = ref(0);
      const router = useRouter();
      const searchTerm = ref('');
      const page = ref(1);
      const totalPages = ref(0);
      const limit = ref(10);
      const totalElements = ref(0);
      const recherche = ref("");
      

     //Fin fonction dataTable
  
      function getAllCessions(page = 1, limi = 10, searchTerm = '') {
        const element = {
                recherche:recherche.value,
                page:page,
                totalPages:totalPages.value,
                limit:limit.value,
                totalElements:totalElements.value,
                mot:searchTerm
            };
        return ApiService.post("/cessions/getAllCessions",element)
          .then(({ data }) => {
            console.log("deschanel",data.data.data);
            demandeCessions.value = data.data.data;
            totalPages.value = data.data.totalPages;
            limit.value = data.data.limit;
            totalElements.value = data.data.totalElements;
            console.log(demandeCessions.value);
            return data.data.data;
          })
          .catch(({ response }) => {
            error(response.data.msg)
        });
      }

      function moddifier(id) {
        idCession.value = id;
      }

      function showModalEdite(model){
        showModal(model);
        idCession.value=0;
      }
  
      const deleteAbonne = (id: number) => {
        ApiService.delete(`/demandes/${id}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: 'success',
            title: 'General Title',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Rssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
  
        for(let i = 0; i < demandeCessions.value.length; i++) {
          if (demandeCessions.value[i].id === id) {
            demandeCessions.value.splice(i, 1);
          }
        }
      };
      const handlePaginate = ({ page_, limit_ }) => {
          try {
            page.value = page_;
            getAllCessions(page_, limit_);
          } catch (error) {
            //
            
          }
        };

      const privileges = ref<Array<string>>(JwtService.getPrivilege());

      const checkPermission = (name) => {
        return privileges.value.includes(name);
      }

      return {showModalEdite, checkPermission,searchTerm,handlePaginate,totalElements,limit,totalPages,page, demandeCessions,idCession,format_date,suppression,getAllCessions,deleteAbonne,moddifier };
    },
    methods:{
      
    }
  });
  </script>
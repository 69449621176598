<template>
    <BreadCrumb PageTitle="Générer une redevance" />
    <GenererRedevance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import GenererRedevance from "../../components/Redevance/GenererRedevance.vue";
  
    export default defineComponent({
      name: "GenererRedevancePage",
      components: {
        BreadCrumb,
        GenererRedevance,
      },
    });
  </script>
<template>
  <div class="row">
      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-abonnements" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" v-if="abonnement?.estMPostBox === false">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
              
              <router-link to="/liste-mpostboxs" v-if="abonnement?.estMPostBox === true"
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr v-if="abonnement">
                  <td>Etat :
                     </td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                    <span v-if="abonnement.etat == 'Résilié'" class="badge text-outline-danger">{{ abonnement.etat }}</span>
                  </td>
                </tr>
                <tr v-if="abonnement?.refAbo">
                  <td>Référence</td>
                  <td>{{ abonnement?.refAbo }}</td>
                </tr>
                <tr v-if="abonnement?.demande?.refDemande">
                  <td>Demande</td>
                  <td>{{ abonnement?.demande.refDemande }}</td>
                </tr>
                <tr v-if="abonnement?.adresseVirtuelle">
                  <td>Adresse virtuelle</td>
                  <td>{{ abonnement?.adresseVirtuelle }}</td>
                </tr>
                <tr v-if="abonnement?.boite?.adressePostale?.adresse_postale">
                  <td>Adresse postale</td>
                  <td>{{ abonnement?.boite?.adressePostale?.adresse_postale }}</td>
                </tr>
                <tr v-if="abonnement?.loyer">
                  <td>Loyer</td>
                  <td>{{ abonnement?.loyer }}</td>
                </tr>
                <tr v-if="abonnement?.caution">
                  <td>Caution</td>
                  <td>{{ separateur(abonnement?.caution) }}</td>
                </tr>
                <tr v-if="abonnement?.fraisCle">
                  <td>Frais clé</td>
                  <td>{{ separateur(abonnement?.fraisCle) }}</td>
                </tr>
                <tr v-if="abonnement?.montant">
                  <td>Montant total</td>
                  <td>{{separateur(abonnement?.montant) }}</td>
                </tr>
                <tr v-if="abonnement?.numero">
                  <td>Numéro</td>
                  <td>{{ abonnement?.numero }}</td>
                </tr>
                <tr v-if="abonnement?.nombreCle">
                  <td>Nombre clé</td>
                  <td>{{ abonnement?.nombreCle }}</td>
                </tr>
                <tr v-if="abonnement?.typeBoite">
                  <td>Type de boîte</td>
                  <td>{{ abonnement?.typeBoite }}</td>
                </tr>
               
                <tr v-if="abonnement?.createdAt">
                  <td>Date d'abonnement</td>
                  <td>{{ format_date(abonnement?.createdAt)  }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td> <button  class="btn btn-primary transition border-0 lh-1 fw-medium fw-medium text-white pt-8 pb-8 ps-15 pe-15 pt-md-12 pb-md-12 ps-md-20 pe-md-20" v-if="abonnement?.etat == 'En attente' && checkPermission('ValidAbonnement')"  @click="valideAbonnement()">
                  <i class="flaticon-check  lh-1 me-1 position-relative top-2"></i>
                  <span class="position-relative"></span> Valider l'abonnement</button></td>
                  <td></td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Information sur l'abonné</h4>
            <table class="table">
              <tbody>
                <tr>
                  <td>Nom et prénom</td>
                  <td>{{ abonnement?.abonne?.nomComplet }}</td>
                </tr>
                <tr>
                  <td>Nationalité</td>
                  <td>{{ abonnement?.abonne?.nationalite }}</td>
                </tr>
                <tr>
                  <td>Téléphone</td>
                  <td>{{ abonnement?.abonne?.telephone }}</td>
                </tr>
                <tr>
                  <td>Date de naissance</td>
                  <td>{{ format_Date(abonnement?.abonne?.dateNaissance) }}</td>
                </tr>
                <!-- <tr>
                  <td>Date de dernière connection</td>
                  <td>{{ format_date(abonnement?.abonne?.datelastLogin) }}</td>
                </tr> -->
                <tr>
                  <td>Email</td>
                  <td>{{ abonnement?.abonne?.email }}</td>
                </tr>
                <tr>
                  <td>Sexe</td>
                  <td>{{ abonnement?.abonne?.sexe }}</td>
                </tr>
                <tr>
                  <td>Profession</td>
                  <td>{{ abonnement?.abonne?.profession }}</td>
                </tr>
                <tr>
                  <td>Adresse de résidence</td>
                  <td>{{ abonnement?.abonne?.adresseResidence }}</td>
                </tr>
                <tr>
                  <td>Ville de résidence</td>
                  <td>{{ abonnement?.abonne?.villeResidence }}</td>
                </tr>
                <tr>
                  <td>Catégorie de l'abonné</td>
                  <td>{{ abonnement?.abonne?.categorieAbonne?.libelle }}</td>
                </tr>
                <tr>
                  <td>Secteur d'activité</td>
                  <td>{{ abonnement?.abonne?.activite?.libelle }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10" v-if="!abonnement?.estMPostBox">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Information sur la boîte postale</h4>
            <table class="table">
              <tbody>
                <tr>
                  <td>Référence</td>
                  <td>{{ abonnement?.boite?.refBoite }}</td>
                </tr>
                <tr v-if="abonnement?.bureau?.nom">
                  <td>Agence</td>
                  <td>{{ abonnement?.bureau?.nom }}</td>
                </tr>
                <tr>
                  <td>Couleur</td>
                  <td>{{ abonnement?.boite?.couleur }}</td>
                </tr>
                <tr>
                  <td>Dimension</td>
                  <td>{{ abonnement?.boite?.dimension }}</td>
                </tr>
                <tr>
                  <td>Etat</td>
                  <td>{{ abonnement?.boite?.etat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10" v-if="abonnement?.quartier">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Adresse complète pour la domiciliation</h4>
            <table class="table">
              <tbody>
                <tr v-if="abonnement?.quartier?.arrondissement">
                  <td class="bld">Arrondissement</td>
                  <td>{{ abonnement?.quartier?.arrondissement.libelle }}</td>
                </tr>
                <tr v-if="abonnement?.quartier">
                  <td class="bld">Quartier</td>
                  <td>{{ abonnement?.quartier?.libelle }}</td>
                </tr>
                <tr>
                  <td class="bld">N° Carré / Maison</td>
                  <td>{{ abonnement?.adresseMaisonCarre }}</td>
                </tr>
                <tr>
                  <td class="bld">Directive</td>
                  <td>{{ abonnement?.directive }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Historique des transferts</h4>
            <table class="table">
              <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Ancienne agence
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Nouvelle agence
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Boite
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Date de transfert
              </th>
            </tr>
          </thead>
              <tbody>
                <tr v-for="(transfert, index) in abonnement?.transferts" :key="index">
                  <td>{{ transfert?.ancienneAgence?.nom }}</td>
                  <td>{{ transfert?.nouvelleAgence?.nom }}</td>
                  <td>{{ transfert?.boite?.refBoite }}</td>
                  <td>{{ format_date(transfert?.createdAt)  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnements } from "@/models/Abonnement";
import { error, format_date,separateur,format_Date, success } from "@/utils/utils";
import { useRoute, useRouter } from "vue-router";
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "ViewAbonnement",
  setup: () => {
    const route = useRoute();
    const router = useRouter();

    const abonnement = ref<Abonnements | null>(null);
    const abonnements = ref<Array<Abonnements>>([]);
    function getAbonnement(id: string) {
      return ApiService.get("/abonnements/"+id)
        .then(({ data }) => {
          abonnement.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getAbonnement(id);

    });

    const valideAbonnement = () => {
    const dataToSend = {
        id: parseInt(route.params.id as string)
    };
    
    ApiService.put("/valide/abonnements/" + parseInt(route.params.id as string), dataToSend)
        .then(({ data }) => {
            if (data.code == 200) {
                success(data.message);
                router.push({ name: "ListeAbonnementPage" });
            }
        }).catch(({ response }) => {
            error(response.data.message);
        });
};

    
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }


    return { abonnement, getAbonnement, format_date,format_Date, abonnements,
      separateur, valideAbonnement, checkPermission,router  };
  },
});
</script>

<style>
.bld {
  font-weight: bold;
}
</style>
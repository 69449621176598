<template>
  <div class="card border-0 rounded-0 bg-white performance-box">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="mb-15 d-sm-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">{{ nom }}</h5>
      </div>
      <div id="performanceChart" class="chart">
        <apexchart
          type="donut"
          height="270"
          :options="projectDistributionChart"
          :series="projects"></apexchart>
      </div>
      <ul class="list ps-0 mb-0 list-unstyled">
        <li class="d-inline-block text-primary position-relative" :style="`color:${colors[i-1]}`" v-for="i=0 in projects.length" :key="i">
          <h5 class="mb-7 fw-black lh-1" :style="`color:${colors[i-1]}`">
            {{ projects[i-1] }} <span class="text-muted fs-14 fw-semibold">demande(s)</span>
          </h5>
          <span class="d-block fs-13 fw-medium lh-1" :style="`color:${colors[i-1]}`">
            {{ labels[i-1] }}(s)
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PerformanceChart",
  props: {
    nom:{
			type:String,
			required: true,
		},
    project:{
			type: Array,
			required: true,
		},
    labels:{
			type: Array,
			required: true,
		},
    colors:{
			type: Array,
			required: true,
		},
  },

  setup:(props) =>{
      const proj = ref(props.project);
      const projects= proj.value;
      const projectDistributionChart= {
        chart: {
          type: "donut",
          height: 382,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        colors: props.colors,
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#9C9AB6",
          },
          markers: {
            offsetX: -2,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 5,
          },
        },
        labels:props.labels,
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      };
    return {
      projects,
      projectDistributionChart,
    };
  }
  // data: function () {
  //   return {
  //     performance: [35, 35, 41],
  //     performanceChart: {
  //       chart: {
  //         type: "donut",
  //         height: 270,
  //       },
  //       plotOptions: {
  //         pie: {
  //           donut: {
  //             size: "70%",
  //           },
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         width: 4,
  //       },
  //       colors: ["#06B48A", "#6560F0", "#6FD3F7"],
  //       legend: {
  //         show: false,
  //         fontWeight: 500,
  //         fontSize: "14px",
  //         position: "bottom",
  //         horizontalAlign: "center",
  //         fontFamily: "Red Hat Display, sans-serif",
  //         labels: {
  //           colors: "#9C9AB6",
  //         },
  //         markers: {
  //           offsetX: -2,
  //         },
  //         itemMargin: {
  //           horizontal: 8,
  //           vertical: 5,
  //         },
  //       },
  //       labels: ["Lesson Discuss", "Taking Class", "Work In Progress"],
  //       tooltip: {
  //         style: {
  //           fontSize: "14px",
  //           fontFamily: "Red Hat Display, sans-serif",
  //         },
  //       },
  //     },
  //   };
  // },
});
</script>

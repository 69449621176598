<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="operationForm" @submit="addOperation" :validation-schema="operationSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Casier <span class="text-danger">*</span>
                </label>
                <Field  name="casier"  v-slot="{ field }">
                  <Multiselect
                    :options="casierOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le casier"
                  />
                </Field>  
                <ErrorMessage name="casier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Colis <span class="text-danger">*</span>
                </label>
                <Field  name="colis"  v-slot="{ field }">
                  <Multiselect
                    :options="colisOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le colis"
                  />
                </Field>  
                <ErrorMessage name="colis" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Etat</label>
                <div style="display: flex;">
                  <div style="margin-right: 10px;">
                    <label>
                      <Field type="radio" name="statut"  :value="true"/>
                      Valide
                    </label>
                  </div>
                  <div>
                    <label>
                      <Field type="radio" name="statut"  :value="false"/>
                      Non valide
                    </label>
                  </div>
                </div>
                <ErrorMessage name="statut" class="text-danger"/>
              </div>
            </div> -->
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Faire une opération
            </button>
            <router-link to="/liste-operations" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Operation } from '@/models/Operation';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';

export default defineComponent({
    name: "AddOperation",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const operationSchema = Yup.object().shape({
      casier: Yup.string().required('Le casier est obligatoire'),
      colis: Yup.string().required('Le colis est obligatoire'),
      statut: Yup.boolean(),
    });

    const operationForm = ref<Operation | null>(null);
    const casiers = ref(null);
    const casierOptions = ref([]);
    const colis = ref(null);
    const colisOptions = ref([]);
    const router = useRouter();

    const addOperation = async (values, {resetForm}) => {
      ApiService.post("/operations",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeOperationPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchCasiers = async () => {
      try {
        const response = await axios.get('/casiers');
        const casiersData = response.data.data;
        casierOptions.value = casiersData.map((casier) => ({
          value: casier.id,
          label: `${casier.code} - ${casier.etagere.code}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchColis = async () => {
      try {
        const response = await axios.get('/colis');
        const colisData = response.data.data;
        colisOptions.value = colisData.map((colis) => ({
          value: colis.id,
          label: `${colis.refColis} - ${colis.infoSupp}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCasiers();
      fetchColis();
    });

    return { operationSchema, addOperation, operationForm, casiers, casierOptions, colis, colisOptions };
  },
});
</script>
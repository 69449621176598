<template>
    <div class="row">
        <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
          <div class="card mb-25 border-0 rounded-0">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <h4 class="text-black fw-bold mb-10">Informations de l'abonnement</h4>
              <table class="table">
                <tbody>
                  <tr v-if="abonnement">
                    <td><b>ABONNEMENT</b></td>
                    <td>{{ abonnement?.refAbo}}</td>
                    <td><b>DEMANDEUR</b></td>
                    <td>{{ abonnement?.abonne?.nomComplet }}</td>
                  </tr>
                  <tr v-if="abonnement?.adresseVirtuelle">
                    <td><b>Adresse virtuelle</b></td>
                    <td>{{ (abonnement?.adresseVirtuelle) ? abonnement?.adresseVirtuelle : '' }}</td>
                    <td><b>Adresse postale</b></td>
                    <td>{{ (abonnement?.boite?.adressePostale) ? abonnement?.boite?.adressePostale.adresse_postale : '' }}</td>
                  </tr>
                  <tr>
                    <td><b>Modele de boite</b></td>
                    <td>{{ (abonnement?.boite) ? abonnement?.boite?.modele?.libelle : '' }}</td>
                    <td><b>Boite postale</b></td>
                    <td>{{ abonnement?.boite?.refBoite }}</td>
                  </tr>
                  <tr>
                    <td><b>Tarif</b></td>
                    <td>{{ (abonnement?.loyer) ? abonnement?.loyer + " F CFA" : ''  }}</td>
                    <td><b>Caution</b></td>
                    <td>{{ (abonnement) ? abonnement?.caution : '' }}</td>
                  </tr>
                  <tr>
                    <td><b>Date d'abonnement</b></td>
                    <td>{{ format_date(abonnement?.dateAbonnement)  }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-if="abonnement">
                    <td><b>Etat abonnement</b></td>
                    <td>
                        <span class="badge text-bg-danger fw-medium" v-if="abonnement.estResilier">Inactif </span>
                        <span class="badge text-bg-success fw-medium" v-else>Actif</span>
                    </td>
                    <td>Arrierés</td>
                    <td>
                        <span class="badge text-bg-danger fw-medium" v-if="totaleArr.arrires > 0">{{totaleArr.arrires? (totaleArr.arrires+ " F CFA") : 0 + " F CFA"}}</span>
                        <span class="badge text-bg-success fw-medium" v-else>{{totaleArr.arrires? totaleArr.arrires : 0 + " F CFA"}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td><b> Contenu</b></td>
                    <td colspan="3">
                        {{ (demande) ? demande.contenu : '' }}
                    </td>
                  </tr>
                  <tr>
                  </tr>
                  <tr>
                    <td>
                        <button 
                            v-if="demande?.status !== 'Validée'"
                            class="btn btn-success"
                            href="javascript:void(0);"
                            @click="accept(demande)"
                        >
                        Suivant
                        </button>
                        <!-- <router-link 
                          :to="{ name: 'AddNotificationPage', params: { id:demande.id } }"
                          class="btn btn-success"
                        >
                          Accepté
                      </router-link> -->
                    </td>
                    <!--<td>
                        <button
                            v-if="demande?.status !== 'Validée'"
                            :disabled="etatRejet"
                            type="button"  
                            class="btn btn-danger" 
                            style="margin-left: 5%;"
                            data-bs-toggle="modal"
                            data-bs-target="#RejetCessionModal"
                        >
                        Rejeté
                        </button>
                    </td>-->
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <br>
                <tfoot>

                </tfoot>
              </table>
            </div>
          </div>
        </div>
        
    </div>

    <div
      class="modal fade createNewModal"
      id="RejetCessionModal"
      tabindex="-1"
      ref="addDemandeModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">Rejet d'une demande</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="rejetCessionForm" @submit="rejectResiliation" :validation-schema="rejetCessionFormSchema">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Motif <span class="text-danger">*</span>
                  </label>
                  <Field name="motif" type="textarea"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer une adresse"/>
                  <ErrorMessage name="motif" class="text-danger"/>
                </div>
              </div>
              <button 
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Confirmer le rejet
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import ApiService from "@/services/ApiService";
  import { RejetDemande } from "@/models/RejetDemande";
  import { Abonnements } from "@/models/Abonnement";
  import { Redevance2 } from "@/models/Redevance";
  import { error, hideModal, success, format_date,showModal } from "@/utils/utils";
  import { useRoute } from "vue-router";
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import { useRouter } from 'vue-router';
  import axios from "axios";
  
  export default defineComponent({
    name: "AcceptationCession",
    components: {
      Form,
      Field,
      ErrorMessage
    },
    setup: () => {
      const rejetCessionFormSchema = Yup.object().shape({
        motif: Yup.string().required('Le motif est obligatoire'),
      });
      const route = useRoute();
      const addDemandeModalRef = ref<null | HTMLElement>(null);
      const rejetCessionForm =  ref<RejetDemande | null>(null);
      const abonnement = ref<Abonnements | null>(null);
      const abonnements = ref<Array<Abonnements>>([]);
      const redevances = ref<Array<Redevance2>>([]);
  
      function getAbonnement(id: string) {
        return ApiService.get("/abonnements/"+ id)
          .then(({ data }) => {
            abonnement.value = data.data; 
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 

      // function getRedevancesAbonnement(id: string){
       
      //   return ApiService.get("/redev/non/abonnement/"+id)
      //     .then(({ data }) => {
           
      //       if(data.data.length > 0)
      //         redevances.value = data.data.data;
      //     })
      //     .catch(({ response }) => {
      //       error(response.data.message);
      //     });
      // }

      const accept =(demande:any)=>{
      demande.status = "Acceptée"
      demande.dateAcceptation = new Date();
      //router.push({ path: `/ajouter-notificationparams`});
      router.push({ name: 'AddNotifiationPage',params: { id: demande.id } });
    }

      const router = useRouter();
      const rejectResiliation = async (values,{ resetForm }) => {
      values["id"]= idDemande.value;
          ApiService.put(`/rejetDemande/demande/${values.id}`,values)
          .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              resetForm();
              hideModal(addDemandeModalRef.value);
              router.push({ path: "/liste-demandes" });
            }
          }).catch(({ response }) => {
            error(response.message);
          });
      }
  
      const etatBouton = ref(false);

      function getDemande(id: string) {
        return ApiService.get("/demandes/"+id)
          .then(({ data }) => {
             //abonnement.value = data.data; 
             //demande.value = data.data;
             return data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 
      const newLoyer =  ref(0);
      const newCaution =  ref(0);
      const getLoyer = async (categorie:number, modele:number) => {
            if(categorie &&  modele) {
                try {
                    const response = await axios.get(`/tarifAnnuels/${categorie}/${modele}`);
                    const tarifAnnuels = response.data.data;
                    newLoyer.value = tarifAnnuels.cout;
                    newCaution.value = tarifAnnuels.caution;
                } catch (error) {
                  //
                }
            }
        }
        const idDemande = ref(0);

    function showModalEdite(model){
      showModal(model);
      idDemande.value=0;
    }

      async function getMontant(value: any) {
        return ApiService.post("cession/impaye/red",value)
          .then(({ data }) => {
             return data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      }
      const demande = ref();
      const totaleArr = ref(0);
      const etatRejet = ref(false);

      onMounted( async() => {
        const id = route.params.id as string;
       
        demande.value =  await  getDemande(id);
        // getRedevancesAbonnement(demande.value.abonnement?.id);

        await getAbonnement(demande.value.abonnement?.id);
        totaleArr.value = await  getMontant({abonnement:abonnement.value?.id})
        var idModel = 0;
        if(abonnement.value?.boite?.modele){
          idModel = abonnement.value?.boite?.modele['id'];
        }

        idDemande.value = parseInt(id);
        if(idModel){
         await  getLoyer(demande.value.categorieBeneficiaire?.id,idModel);
        }

        if((demande.value?.status == "Validée")){
          etatBouton.value = true;
        }

        if( redevances.value.length > 0 ){
            etatBouton.value = true;
        }
        
        if((demande.value?.status) === 'Rejetée' || (demande.value?.status == "Acceptée") || (demande.value?.status == "Validée")){
          etatRejet.value = true;
        }else{
          etatRejet.value = false;
        }
      });
  
      return { abonnement,
        demande,
        redevances,
        rejectResiliation,
        accept,
        etatRejet,
        addDemandeModalRef,
        rejetCessionFormSchema,
        totaleArr,
        etatBouton,
        newCaution,
        idDemande,
        newLoyer,
        showModalEdite,
        getAbonnement,
        format_date,
        abonnements,
      };
    },
  });
  </script>
  
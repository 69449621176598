<template>
    <BreadCrumb PageTitle="Liste des notifications" />
    <ListeNotification />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeNotification from "../../components/Notification/ListeNotification.vue";
  
  
  export default defineComponent({
    name: "ListeNotificationPage",
    components: {
      BreadCrumb,
      ListeNotification,
    },
  });
  </script>
<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonneForm" @submit="editAbonnement" :validation-schema="abonnementSchema">
       
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import Swal from 'sweetalert2';
//import { hideModal } from '@/utils/utils';

export default defineComponent({
    name: "EditAbonnement",
    components: {
    Form,
    // Field,
    // ErrorMessage,
    // Multiselect,
  },
  data() {
  return {
    
  };
},
  setup: () => {
    const abonnementSchema = Yup.object().shape({
      // loyer: Yup.string().required('Le nom est obligatoire'),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      //   'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial'
      // ).required('Le mot de passe est obligatoire'),
      // sexe: Yup.string().required('Le sexe est obligatoire'),
      // profession: Yup.string().required('La profession est obligatoire'),
      // adresseResidence: Yup.string().required('L\'adresse de résidence est obligatoire'),
      // villeResidence: Yup.string().required('La ville de résidence est obligatoire'),
      // departementResidence: Yup.string().required('Le départemnt de résidence est obligatoire'),
      // abonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonné est obligatoire'),
      // abonne: Yup.string().required('L\'abonné est obligatoire'),
      // secteurActivite: Yup.number().typeError('Veuillez entrer un nombre').required('Le secteur d\'activité est obligatoire'),
      // motDePasseModifie: Yup.string().required('Le mot de passe modifié est obligatoire'),
    });

    const abonnementForm = ref(null);
    const categorieAbonnes = ref(null);
    const categorieAbonneOptions = ref([]);
    const secteurActivites = ref(null);
    const secteurActiviteOptions = ref([]);

    const editAbonnement = async (values, {resetForm}) => {
      ApiService.post("/abonnements/S",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            Swal.fire({
              text: data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Réssayer à nouveau!",
              heightAuto: false,
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            });
            resetForm();
          }
        }).catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
            timer:10
          });
        });
    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchSecteurActivites = async () => {
      try {
        const response = await axios.get('/secteurActivites');
        const secteurActivitesData = response.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: secteurActivite.libelle,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCategorieAbonnes();
      fetchSecteurActivites();
    });

    return { abonnementSchema, editAbonnement, abonnementForm, categorieAbonnes, categorieAbonneOptions, secteurActivites, secteurActiviteOptions };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Modifier un abonné" />
    <EditAbonne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditAbonne from "../../components/Abonne/EditAbonne.vue";
  
    export default defineComponent({
      name: "EditAbonnePage",
      components: {
        BreadCrumb,
        EditAbonne,
      },
    });
  </script>
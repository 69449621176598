<template>
    <BreadCrumb PageTitle="Ajouter une opération" />
    <AddOperation />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddOperation from "../../components/Operation/AddOperation.vue";
  
    export default defineComponent({
      name: "AddOperationPage",
      components: {
        BreadCrumb,
        AddOperation,
      },
    });
  </script>
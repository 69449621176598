import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_PolarAreaChart = _resolveComponent("PolarAreaChart")!
  const _component_DonutChart = _resolveComponent("DonutChart")!
  const _component_PerformanceChart = _resolveComponent("PerformanceChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Statistique des abonnements" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.numberChart.length > 0)
          ? (_openBlock(), _createBlock(_component_BarChart, {
              key: 0,
              colors: _ctx.colors,
              labels: _ctx.etatLibelleAnne,
              serie: _ctx.numberChart,
              nom: "Recettes, impayés, arriérés par abonnement "
            }, null, 8, ["colors", "labels", "serie"]))
          : _createCommentVNode("", true),
        (_ctx.etatLibelleCat.length > 0 && _ctx.etatDonneCat.length > 0)
          ? (_openBlock(), _createBlock(_component_PieChart, {
              key: 1,
              colors: _ctx.colors,
              labels: _ctx.etatLibelleCat,
              project: _ctx.etatDonneCat,
              nom: "Nombre d’abonnements par catégorie d'abonnés"
            }, null, 8, ["colors", "labels", "project"]))
          : _createCommentVNode("", true),
        (_ctx.numberChartCa.length > 0)
          ? (_openBlock(), _createBlock(_component_BarChart, {
              key: 2,
              colors: _ctx.colors,
              labels: _ctx.etatLibelleAnneCa,
              serie: _ctx.numberChartCa,
              nom: "Recette globale par année."
            }, null, 8, ["colors", "labels", "serie"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.etatDemandeLibelle.length > 0)
          ? (_openBlock(), _createBlock(_component_PolarAreaChart, {
              key: 0,
              colors: _ctx.colorsDemandeLibelle,
              labels: _ctx.etatDemandeLibelle,
              serie: _ctx.etatDemandeDonne,
              nom: "Nombre de demandes par type de demandes "
            }, null, 8, ["colors", "labels", "serie"]))
          : _createCommentVNode("", true),
        (_ctx.etatDonne.length > 0)
          ? (_openBlock(), _createBlock(_component_DonutChart, {
              key: 1,
              colors: _ctx.colors,
              labels: _ctx.etatLibelle,
              project: _ctx.etatDonne,
              nom: "Nombre d’abonnements par statut"
            }, null, 8, ["colors", "labels", "project"]))
          : _createCommentVNode("", true),
        (_ctx.etatDemandeStatusDonne.length > 0)
          ? (_openBlock(), _createBlock(_component_PerformanceChart, {
              key: 2,
              colors: _ctx.colors,
              labels: _ctx.etatDemandeStatusLibelle,
              project: _ctx.etatDemandeStatusDonne,
              nom: "Nombre de demandes par statut "
            }, null, 8, ["colors", "labels", "project"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
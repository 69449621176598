<template>
    <BreadCrumb PageTitle="Ajouter un rayon" />
    <AddRayon />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddRayon from "../../components/Rayon/AddRayon.vue";
  
    export default defineComponent({
      name: "AddRayonPage",
      components: {
        BreadCrumb,
        AddRayon,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des boîtes" />
    <ListeBoitePostale />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeBoitePostale from "../../components/BoitePostale/ListeBoitePostale.vue";
  
  
  export default defineComponent({
    name: "ListeBoitePostalePage",
    components: {
      BreadCrumb,
      ListeBoitePostale,
    },
  });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des colis" />
    <ListeColis/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeColis from "../../components/Colis/ListeColis.vue";

    export default defineComponent({
      name: "ListeColisPage",
      components: {
        BreadCrumb,
        ListeColis,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Vérifier une adresse" />
    <SuiviAdresse />
</template>

<script lang="ts">
    import { defineComponent } from "vue";

    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import SuiviAdresse from "../../components/SuiviAdresse/SuiviAdresse.vue";

    export default defineComponent({
        name: "AddServicePage",
        components: {
        BreadCrumb,
        SuiviAdresse,
        },
    });
</script>
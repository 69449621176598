<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="attestationForm" @submit="getAttestation" :validation-schema="attestationSchema">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Référence
              </label>
              <Field name="reference" type="text" 
              class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la référence"/>
              <ErrorMessage name="reference" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10" style="opacity: 0;">
                   _ 
              </label>
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Chercher informations
              </button>
              
            </div>
          </div>
        </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref,onBeforeUnmount, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import * as Yup from 'yup';
import { format_date,suppression,format_Date, hideModal, showModal,separateur, error,success } from "@/utils/utils";
import ApiService from '../../services/ApiService';
import axios from 'axios';
import Swal from "sweetalert2";

export default defineComponent({
    name: "AddAttestation",
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
      const attestationSchema = Yup.object().shape({
      reference: Yup.string().required('La référence est obligatoire'),
    });

    const editorRef = ref();

    const modules = {
        name: 'imageUploader',
        module: ImageUploader,
        options: {
          upload: file => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
                formData.append("image", file);
            })
          }
        }
      }

    const attestationForm = ref(null);

    const getAttestation = async (values, { resetForm }) => {
      const id = values.reference;
      ApiService.get(`/check/attestations/${id}`).then( async({ data }) => {
        if(data.code == 200){
          await Swal.fire({
            text: `L'attestation de référence n°: ${data.data.refAttestation} est au nom de ${data.data.abonnement.abonne.nomComplet}, associé à l'adresse ${data.data.abonnement.adresseVirtuelle ? data.data.abonnement.adresseVirtuelle : data.data.abonnement.boite.adressePostale.adresse_postale} valide jusqu'au ${format_Date(data.data.dateExpiration)}`,
            icon: "warning",
            buttonsStyling: true,
            heightAuto: false,
            customClass: {
            },
          });
        }
      }).catch(({ response }) => {
      error(response.data.message);
    });
    };

    onMounted(() => {
    //
    });

    return {modules,attestationSchema, getAttestation, attestationForm };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Vérification de la demande de changement de clé" />
    <AccepterChangementCle />
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AccepterChangementCle from "../../components/Demandes/AccepterChangementCle.vue";
  
    export default defineComponent({
      name: "AccepterChangementClePage",
      components: {
        BreadCrumb,
        AccepterChangementCle,
      },
    });
  </script>
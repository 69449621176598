<template>
    <BreadCrumb PageTitle="Demandes de cessions validées" />
    <ListeCession />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCession from "../../components/Cession/ListeCession.vue";
  
    export default defineComponent({
      name: "AddTransfertPage",
      components: {
        BreadCrumb,
        ListeCession,
      },
    });
  </script>
<template>
  <BreadCrumb PageTitle="Liste des réabonnements" />
  <ReabonnementsList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ReabonnementsList from "../../components/Reabonners/ReabonnementList/ReabonnementsList.vue";

export default defineComponent({
  name: "ReabonnementsListPage",
  components: {
    BreadCrumb,
    ReabonnementsList,
  },
});
</script>
<template>
    <div
      class="modal fade createNewModal"
      id="AddTransfertModal"
      tabindex="-1"
      ref="addTransfertModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="TransfertForm" @submit="addTransfert" :validation-schema="regionSchema">
            <div class="row">
                <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Modèle de Boite <span class="text-danger">*</span>
                </label>
                <Field name="modeleBoite" v-slot="{ field }">
                  <Multiselect 
                    :options="modelesBoitesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le modèle de la boîte"
                  />
                </Field>
                <ErrorMessage name="modeleBoite" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Boite <span class="text-danger">*</span>
                </label>
                <Field name="boite" v-slot="{ field }">
                  <Multiselect 
                    :options="boitesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la boîte"
                  />
                </Field>
                <ErrorMessage name="boite" class="text-danger"/>
              </div>
            </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                {{ btntext }}
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, watch, onMounted } from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import Multiselect from '@vueform/multiselect'
  import  Swal  from "sweetalert2";
  import * as Yup from 'yup';
  import ApiService from '@/services/ApiService';
  import { error, hideModal, success } from '@/utils/utils';
  import { Demande } from '@/models/Demande';
  import AddTransfertModal from "./AddTransfertModal.vue";
  import axios from 'axios';
  
  
  
  export default {
    name: "AddTransfertModal",
    components: {
        Form,
        Field,
        ErrorMessage,
        Multiselect,
    },
    props:{
    id: {
      type: Number,
      required: true,
      default:0
    },
  },
    emits: ["getAllTransferts",'openmodal'],
  
    setup: (props, { emit }) => {
  
      const loading = ref<boolean>(false);
      const regionSchema = Yup.object().shape({
        modeleBoite:Yup.number().required("Selectionner un model de boite"),
        boite: Yup.number().required('La boite est obligatoire'),
      });
  
      const idDemande = ref(props.id);
      const modelesBoitesOptions = ref([]);
      const boitesOptions = ref([]);
      const TransfertForm =  ref<Demande | null>(null);
      const addTransfertModalRef = ref<null | HTMLElement>(null);
      const title = ref('Ajouter une transfert');
      const btntext = ref('Ajouter');
      const isupdate=ref(false);

      watch(() => props.id , async (newValue) => { 
        if (newValue !=0) {
          const donnes = await getDemande(newValue);
          isupdate.value=true;
        }
        btnTitle();
      });
      const fetchBoitesByBureau = async (idBureau:number) => {
      try {
        const response = await axios.get(`boitePostales/bureau/${idBureau}`);
        const boitesData = response.data.data;

        boitesOptions.value = boitesData.map((boite) => ({
          value: boite.id,
          label: boite.refBoite,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchModeles();
    });

    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_boites');
        const modelesData = response.data.data;
        modelesBoitesOptions.value = modelesData.map((boite) => ({
          value: boite.id,
          label: boite.code,
        }));
      } catch (error) {
        //
      }
    };
  
      const getDemande = async (id: number) => {
        return ApiService.get(`/transferts/${id}`)
        .then(({ data }) => {
             TransfertForm.value?.setFieldValue("id",data.data.id);
             TransfertForm.value?.setFieldValue("typeDemande",data.data.typeDemande);
             TransfertForm.value?.setFieldValue("abonnement",data.data.abonnement);
             TransfertForm.value?.setFieldValue("nouvelleAgence",data.data.nouvelleAgence);
             TransfertForm.value?.setFieldValue("refDemande",data.data.refDemande);
          fetchBoitesByBureau(data.data.nouvelleAgence.id);
          
          //emit('openmodal', addTransfertModalRef.value);
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
      }
  
      const btnTitle = async () => {
        if (isupdate.value) {
           title.value = "Valider un transfert";
           btntext.value = "Ajouter";
        }else{
           title.value = "Ajouter une région";
           btntext.value = "Ajouter";
        }
      }
  
      const addTransfert = async (values,{ resetForm }) => {
        values = values as Demande;
        loading.value = false;
        // if(isupdate.value) {
        //   ApiService.put(`/transferts/${values.id}`,values)
        //   .then(({ data }) => {
        //     if(data.code == 200) { 
        //       success(data.message);
        //       resetForm();
        //       hideModal(addTransfertModalRef.value);
        //       isupdate.value=false;
        //       btnTitle();
        //       emit("getAllTransferts");
        //     }
        //   }).catch(({ response }) => {
        //     error(response.message);
        //   });
        // }else{
          ApiService.post("/transferts",values)
          .then(({ data }) => {
            if(data.code == 201) { 
              Swal.fire({
                  title: 'Succès',
                  text: data.message,
                  icon: "success",
                  toast: true,
                  timer: 5000,
                  position: 'bottom-end',
                  showConfirmButton: false
              })
              success(data.message)
              resetForm();
              hideModal(addTransfertModalRef.value);
              emit("getAllTransferts");
            }
          }).catch(({ response }) => {
            error(response.message);
          });
        // }
      }; 
      return {title, btntext, regionSchema, modelesBoitesOptions, boitesOptions, addTransfert, TransfertForm, addTransfertModalRef };
    },
  };
  </script>
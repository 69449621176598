<template>
    <BreadCrumb PageTitle="Générer des boîtes postales" />
    <AddBoitePostale />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddBoitePostale from "../../components/BoitePostale/AddBoitePostale.vue";
  
    export default defineComponent({
      name: "AddBoitePostalePage",
      components: {
        BreadCrumb,
        AddBoitePostale,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonnementForm" @submit="addAbonnement" :validation-schema="abonnementSchema">
        <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Référence
                </label>
                <Field name="refAbo" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="refAbo" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Loyer
                </label>
                <Field name="loyer" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="loyer" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Montant
                </label>
                <Field name="montant" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="montant" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Durée
              </label>
              <Field name="dureeAbonnement" type="date" 
              class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
                <ErrorMessage name="dureeAbonnement" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Etat
              </label>
              <div style="display: flex;">
                  <div style="margin-right: 10px;">
                    <label>
                      <Field type="radio" name="etat" :value="true"/>
                      Actif
                    </label>
                  </div>
                  <div style="margin-right: 10px;">
                    <label>
                      <Field type="radio" name="etat" :value="false"/>
                      Non Actif
                    </label>
                  </div>
                </div>
                <ErrorMessage name="etat" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Motif
              </label>
                <QuillEditor
                  theme="snow"
                  placeholder="Write your meta description"
                  toolbar="full"
                  name="motifResiliation"
                />
                <ErrorMessage name="motifResiliation" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Date
              </label>
              <Field name="dateAbonnement" type="date" 
              class="form-control shadow-none rounded-0 text-black"/>
                <ErrorMessage name="dateAbonnement" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nombre clé
              </label>
              <Field name="nombreCle" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="nombreCle" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Ancien abonnement
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="[]" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Type de boîte
              </label>
              <Field name="typeBoite" v-slot="{ field }">
                  <Multiselect 
                    :options="['Officielle', 'Ordinaire']" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="typeBoite" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Boîte
              </label>
              <Field name="boite" v-slot="{ field }">
                  <Multiselect 
                    :options="boitePostaleOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="boite" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonné
              </label>
              <Field name="abonne" v-slot="{ field }">
                  <Multiselect 
                    :options="abonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="abonne" class="text-danger"/>
            </div>
          </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Est résilié ?
                </label>
                <Field name="estResilier" v-slot="{ field }">
                  <Multiselect 
                  :options="['Par transfert', 'Par cession', 'D\'office', 'Sur demande']" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="estResilier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse Postale
                </label>
                <Field name="adressePostale" v-slot="{ field }">
                  <Multiselect 
                  :options="adressePostaleOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="adressePostale" class="text-danger"/>
              </div>
            </div>
            
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Ajouter un abonnement
              </button>
              <router-link to="/liste-reabonnement" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';

export default defineComponent({
  name: "ReabonnementAdd",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const abonnementSchema = Yup.object().shape({
      refAbo: Yup.string().required('La référence est obligatoire'),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required('Le loyer est obligatoire'),
      montant: Yup.string().required('Le montant est obligatoire'),
      dureeAbonnement: Yup.date().required('La durée est obligatoire'),
      etat: Yup.boolean().required('L\' état est obligatoire'),
      motifResiliation: Yup.string().required('Le motif est obligatoire'),
      dateAbonnement: Yup.date().required('La date est obligatoire'),
      nombreCle: Yup.number().typeError('Veuillez entrer un nombre').required('Le nombre de clé est obligatoire'),
      abonnement: Yup.number().typeError('Veuillez entrer un nombre').required('L\' abonnement est obligatoire'),
      typeBoite: Yup.string().required('Le type est obligatoire'),
      boite: Yup.number().typeError('Veuillez entrer un nombre').required('La boîte est obligatoire'),
      abonne: Yup.number().typeError('Veuillez entrer un nombre').required('L\' abonné est obligatoire'),
      estResilier: Yup.string().required('L\'abonnement est résilié ?'),
      adressePostale: Yup.number().typeError('Veuillez entrer un nombre').required('L\' adresse postale est obligatoire'),
    });

    const abonnementForm = ref(null);
    const boitePostales = ref([]);
    const abonnes = ref(null);
    const adressePostales = ref(null);

    const boitePostaleOptions = ref([]);
    const abonneOptions = ref([]);
    const adressePostaleOptions = ref([]);

    const addAbonnement = async (formData: any) => {
      //

    };

    const fetchBoitePostales = async () => {
      try {
        const response = await axios.get('/boitePostales');
        const boitePostalesData = response.data.data;
        boitePostaleOptions.value = boitePostalesData.map((boitePostale) => ({
          value: boitePostale.id,
          label: boitePostale.refBoite,
        }));
      } catch (error) {
        //
      }
    };

    const fetchAbonnes = async () => {
      try {
        const response = await axios.get('/abonnes');
        const abonnesData = response.data.data;
        abonneOptions.value = abonnesData.map((abonne) => ({
          value: abonne.id,
          label: abonne.email,
        }));
      } catch (error) {
        //
      }
    };

    const fetchAdressePostales = async () => {
      try {
        const response = await axios.get('/adressePostales');
        const adressePostalesData = response.data.data;
        adressePostaleOptions.value = adressePostalesData.map((adressePostale) => ({
          value: adressePostale.id,
          label: adressePostale.code,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchBoitePostales();
      fetchAbonnes();
      fetchAdressePostales();
    });
    
    return { abonnementSchema, addAbonnement, abonnementForm, boitePostales, boitePostaleOptions, abonnes, abonneOptions, adressePostales, adressePostaleOptions };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
    

    <div class="card mb-25 border-0 rounded-0 bg-white">
        <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
          <div class="mb-15 d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0">Paiement des redevances </h5>
          </div>
          <div id="projectDistributionChart" class="chart">
            <canvas ref="chartCanvas"></canvas>
          </div>
        </div>
      </div>

      
</template>
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { Chart, registerables } from 'chart.js';
  import { ChartData, ChartOptions } from 'chart.js';
  import axios from 'axios';
import { format_Date } from '@/utils/utils';
  
  export default defineComponent({
    name: 'ChartJsChart',
   
    setup() {
      const chartCanvas = ref<HTMLCanvasElement | null>(null);
      let chartInstance: Chart | null = null;
      const labels = ["Janvier", "Février","Mars","Avril","Mai","Juin","Juillet"];
        const data = {
        labels: labels,
        datasets: [{
            label: 'My First Dataset',
            data: [65000, 59000, 80000, 81000, 56000, 55000, 40000],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.0
        }]
        };

        const fetchAbonnementByStatus = async () => {
            try {
                const response = await axios.get('/stat/point/paiement/redevances/derniers/jours');
                const etatDonnee = response.data.data.data;

                console.log("etatDonné )===> ", etatDonnee)
                const data: number[] = [];
                const labels: any[] = [];
                for (let i = 0; i < etatDonnee.raw.length; i++) {
                  if(etatDonnee.raw[i]["date"]){
                    data.push(parseFloat(etatDonnee.raw[i]["Total"]));
                    labels.push(format_Date(etatDonnee.raw[i]["date"]));
                  }
                }

                const dataa = {
                    labels: labels,
                    datasets: [{
                        label: 'My First Dataset',
                        data: data,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.0
                    }]
                };

                if (chartCanvas.value) {
                    const ctx = chartCanvas.value.getContext('2d');
                    if (ctx) {
                        chartInstance = new Chart(ctx, {
                        type: 'line',
                        data: dataa,
                        options: {
                                responsive: true,
                                plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Abonnement par status'
                                }
                                }
                            } as ChartOptions,
                        });
                    }
                }
                
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        const options: ChartOptions = {
            responsive: true,
            plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Abonnement par status'
            }
            }
        } as ChartOptions;
  
      onMounted(() => {

        fetchAbonnementByStatus ();
        
      });
  
      return {
        chartCanvas
      };
    }
  });
  </script>
  
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="paiementForm" @submit="addPaiement" :validation-schema="paiementSchema">
      <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement <span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'abonnement"
                  />
                </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Montant payé <span class="text-danger">*</span>
            </label>
            <Field name="montantPaye" type="text" 
              class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant payé"/>
              <ErrorMessage name="montantPaye" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Date <span class="text-danger">*</span>
            </label>
            <Field name="datePaiement" type="date" 
            class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
              <ErrorMessage name="datePaiement" class="text-danger"/>
          </div>
        </div>           
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Redevance <span class="text-danger">*</span>
              </label>
              <Field name="redevance" v-slot="{ field }">
                  <Multiselect 
                    :options="[]" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la redevance"
                  />
                </Field>
              <ErrorMessage name="redevance" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de paiement <span class="text-danger">*</span>
                </label>
                <Field name="typePaiement" v-slot="{ field }">
                    <Multiselect 
                      :options="['Abonnement', 'Réabonnement']" 
                      :searchable="true" 
                      v-model="field.value"
                      v-bind="field"
                      placeholder="Sélectionner le type de paiement"
                    />
                  </Field>
                <ErrorMessage name="typePaiement" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mode de paiement <span class="text-danger">*</span>
              </label>
              <Field name="modePaiement" v-slot="{ field }">
                  <Multiselect 
                    :options="modePaiementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le mode de paiement"
                  />
                </Field>
              <ErrorMessage name="modePaiement" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Ajouter un paiement
            </button>
            <router-link to="/liste-paiements" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'


import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
  name: "AddPaiement",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const paiementSchema = Yup.object().shape({
    datePaiement: Yup.date().default(() => new Date()).required('La date est obligatoire'),
    montantPaye: Yup.number().typeError('Entrer un nombre').required('Le montant est obligatoire'),
    abonnement: Yup.number().typeError('Entrer un nombre').required('Le reabonnement est obligatoire'),
    reabonnement: Yup.number().typeError('Entrer un nombre').required('Le reabonnement est obligatoire'),
    modePaiement: Yup.number().typeError('Entrer un nombre').required('Le mode de paiement est obligatoire'),
    redevance: Yup.string(),
    typePaiement: Yup.string().required('Le type de paiement est obligatoire'),
  });

  const paiementForm = ref(null);
  const abonnementOptions = ref([]);
  const modePaiementOptions = ref([]);
  
  const addPaiement = async (formData: any) => {
    //
  };

    const fetchAbonnements = async () => {
      try {
        const response = await axios.get('/abonnements');
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id,
          label: `${abonnement.refAbo} - [${abonnement.abonne.nomComplet}]`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchModePaiements = async () => {
      try {
        const response = await axios.get('/modepaiements');
        const modePaiementsData = response.data.data;
        modePaiementOptions.value = modePaiementsData.map((modePaiement) => ({
          value: modePaiement.id,
          label: modePaiement.code+" ["+modePaiement.libelle+"]",
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      
      fetchAbonnements();
      fetchModePaiements();
    });

  return { paiementSchema, addPaiement, paiementForm, abonnementOptions, modePaiementOptions };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="notificationForm" @submit="addNotification" :validation-schema="notificationSchema">
      <div class="row">
        <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
              Abonné <span class="text-danger">*</span>
              </label>
              <Field  v-if="estReclamation" v-model="abonneId" name="abonne" v-slot="{ field }">
                <Multiselect
                  :options="abonnesOptions"
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner un abonné"
                />
              </Field>
              <Field v-else name="abonne" v-slot="{ field }">
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonne"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  :options="async (query) => {
                   return await fetchAbonnes(query)
                  }"
                  noResultText= "Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                />
              </Field>
              <ErrorMessage name="abonne" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-12">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Message <span class="text-danger">*</span>
            </label>
            <Field name="message" cols="30" v-model="message"
                rows="12" as="textarea" placeholder="Ecrire le message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
              <textarea
                v-model="field.value"
                class="form-control shadow-none rounded-0 text-black"
              ></textarea>
            </Field>

            <Field name="demandeId" type="hidden" v-model="demandeId"
              class="form-control shadow-none rounded-0 text-black"/>

            <Field name="estReclamation" type="hidden" v-model="estReclamation"
              class="form-control shadow-none rounded-0 text-black"/>

              <ErrorMessage name="message" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Envoyer une notification
            </button>
            <router-link to="/liste-notifications" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRouter, useRoute } from 'vue-router';
import { error, success } from '@/utils/utils';
import { Notification } from '@/models/Notification';
import { Abonne } from '@/models/Demande';
import { devNull } from 'os';

export default defineComponent({
  name: "AddNotification",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const notificationSchema = Yup.object().shape({
    message: Yup.string().required('Le message est obligatoire'),
    abonne: Yup.number().typeError('Entrer un nombre').required('L\'abonne est obligatoire'),
    estReclamation: Yup.string(),
    demandeId: Yup.string(),
  });

  const router = useRouter();
  const route = useRoute();
  const notificationForm = ref<Notification>();
  const abonnesOptions = ref([]);
  const abonne = ref<null | Abonne>(null);
  const estReclamation = ref(false);
  // const abonneId = ref(1);
  const abonneId = ref<number | null>(null);
  const demandeId = ref(0);
  const message = ref('');

  const addNotification = async (values, {resetForm}) => {
    
    ApiService.post("/notifications",values)
      .then(({ data }) => {
        if(data.code == 201) { 
          success(data.message);
          resetForm();
          router.push({ name: "ListeNotificationPage" });
        }
      }).catch(({ response }) => {
        error(response.data.message);
      });
  };

  const fetchAbonnes = async (key) => {
    try {
      const response = await axios.get('/abonnes/recherche/'+key);
      const abonnesData = response.data.data;
      abonnesOptions.value = abonnesData.map((abonne) => ({
        value: abonne.id,
        label: abonne.nomComplet+' [ '+abonne.telephone+' ]',
      }));
      return abonnesOptions.value;
    } catch (e) {
      error(`Une erreur s'est produite : ${e}`);
    }
  };

  // const getAbonne = async  (id) => {
  //   ApiService.get(`/abonne/by/demande/${id}`)
  //   .then(({ data }) => {
  //       if(data.code == 200) { 
  //         const donnees = data.data.data;
  //         abonnesOptions.value = donnees.map((abonne) => ({
  //           value: abonne.id,
  //           label: abonne.nomComplet+' [ '+abonne.telephone+' ]',
  //         }));
  //         message.value= `Répondse à la demande N° : ${data.data.refDemande} `;
  //         abonneId.value = (data.data.data[0]) ? data.data.data[0].id : null;
  //         console.log('before', abonneId.value);
  //         demandeId.value = data.data.demandeId;
  //         return abonnesOptions.value;
  //       }
  //     }).catch(({ response }) => {
  //       error(response.data.message);
  //     });
  // }

  const getAbonne = async (id) => {
    try {
        const { data } = await ApiService.get(`/abonne/by/demande/${id}`);
        if (data.code === 200) { 
            const donnees = data.data.data;
            abonnesOptions.value = donnees.map((abonne) => ({
                value: abonne.id,
                label: abonne.nomComplet + ' [ ' + abonne.telephone + ' ]',
            }));
            message.value = `Répondre à la demande N° : ${data.data.refDemande}`;
            abonneId.value = (donnees.length > 0) ? donnees[0].id : null;
            demandeId.value = data.data.demandeId;
            return abonnesOptions.value;
        } else {
            error(data.message);
        }
    } catch (e) {
        error(`Une erreur s'est produite : ${e}`);
    }
}


  onMounted(async() => {
      if(route.params.id){
        await getAbonne(route.params.id);
        estReclamation.value = true;
      }
  });
  
  return { 
    notificationSchema,
    notificationForm,
    abonnesOptions,
    fetchAbonnes,
    addNotification,
    estReclamation,
    abonneId,
    demandeId,
    message
  };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div
        class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between"
      >
        <h6 class="card-title fw-bold mb-0">Les dix dernières redevances</h6>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Abo.
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Loyer
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Abonné
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Année
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
              >
                Etat
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(red, index) in redevances" :key="index">
              <th class="shadow-none lh-1 fw-bold ps-0">
                
                  {{ red.abonnement.refAbo }}
              </th>
              <td class="shadow-none lh-1 fw-medium">
                {{ separateur(red.montant) }}
                <span class="text-body-tertiary"> FCFA</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div class="d-flex align-items-center">
                  
                  {{ red.abonnement.abonne?.nomComplet }}
                </div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                {{ red.annee }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                {{ format_date(red.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium pe-0">
                <span v-if="red.statut !== 'Payée'" class="badge text-outline-danger">{{ red.statut }}</span>
                <span v-else class="badge text-outline-success">{{ red.statut }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted,reactive } from "vue";
import axios from 'axios';
import {separateur, format_date} from '../../../../utils/utils'

export default defineComponent({
  name: "ProductsOrders",
  setup: () => {
    const redevances = ref([]);
    const fetchRedevances =async () => {
      try {
        const response = await axios.get(`/etats/abonnements/dix/redevance`);
        redevances.value = response.data.data;
        return redevances.value;
      } catch (error) {
        //
      }
    }
    onMounted(() => {
      redevances.value;
      fetchRedevances();
    });
    return {
      separateur,
      redevances,
      format_date,
      fetchRedevances,
    };
  },
});
</script>
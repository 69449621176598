<template>
    <BreadCrumb PageTitle="Liste des secteurs d'activités" />
    <ListeSecteurActivite/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeSecteurActivite from "../../components/SecteurActivite/ListeSecteurActivite.vue";

    export default defineComponent({
      name: "ListeSecteurActivitePage",
      components: {
        BreadCrumb,
        ListeSecteurActivite,
      },
    });
  </script>
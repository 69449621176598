<template>
    <div class="row">
        <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
          <div class="card mb-25 border-0 rounded-0">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <h4 class="text-black fw-bold mb-10">Informations de l'abonnement</h4>
              <table class="table">
                <tbody>
                  <tr v-if="abonnement">
                    <td>ABONNEMENT</td>
                    <td>{{ abonnement?.refAbo}}</td>
                  </tr>
                  <tr>
                    <td>DEMANDEUR</td>
                    <td>{{ abonnement?.abonne?.nomComplet }}</td>
                  </tr>
                  <tr v-if="abonnement?.adresseVirtuelle">
                    <td>Adresse virtuelle</td>
                    <td>{{ abonnement?.adresseVirtuelle }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite?.adressePostale?.adresse_postale">
                    <td>Adresse postale</td>
                    <td>{{ abonnement?.boite?.adressePostale.adresse_postale }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite">
                    <td>Modele de boite</td>
                    <td>{{ abonnement?.boite?.modele?.libelle }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite">
                    <td>Boite postale</td>
                    <td>{{ abonnement?.boite?.refBoite }}</td>
                  </tr>
                  <tr v-if="abonnement?.loyer">
                    <td>Tarif</td>
                    <td>{{ abonnement?.loyer + " F CFA"  }}</td>
                  </tr>
                  <tr v-if="abonnement?.caution">
                    <td>Caution</td>
                    <td>{{ abonnement?.caution }}</td>
                  </tr>
                  <tr v-if="abonnement?.createdAt">
                    <td>Date d'abonnement</td>
                    <td>{{ format_date(abonnement?.createdAt)  }}</td>
                  </tr>
                  <tr v-if="abonnement">
                    <td>Etat abonnement</td>
                    <td >
                        <span class="badge text-bg-danger fw-medium" v-if="abonnement.estResilier">Inactif </span>
                        <span class="badge text-bg-success fw-medium" v-else>Actif</span>
                    </td>
                  </tr>
                  <tr v-if="abonnement">
                    <td>Arrierés</td>
                    <td>
                        <span class="badge text-bg-danger fw-medium" v-if="totaleArr.arrires > 0">{{totaleArr.arrires? (totaleArr.arrires+ " F CFA") : 0 + " F CFA"}}</span>
                        <span class="badge text-bg-success fw-medium" v-else>{{totaleArr.arrires? totaleArr.arrires : 0 + " F CFA"}}</span>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
          <div class="card mb-25 border-0 rounded-0">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <h4 class="text-black fw-bold mb-10">Redevances en attente</h4>
              <table class="table">
                <tbody>
                    <tr v-for="(red, index) in redevances" :key="index" >
                        <td>{{ red?.annee }}</td>
                        <td>{{ red?.montant +" Fcfa" }}</td>
                        <td>{{ red.arrieres +"Fcfa" }}</td>
                    </tr>
                  <!-- <tr v-if="demandeCession">
                    <td>Référence</td>
                    <td>{{ demandeCession?.refDemande }}</td>
                  </tr>
                  <tr v-if="abonnement?.refAbo">
                    <td>Date Demande</td>
                    <td>{{ format_date(demandeCession?.createdAt) }}</td>
                  </tr>
                    <tr v-if="demandeCession">
                        <td>Nom du bénéficiaire</td>
                        <td>{{ demandeCession.abonne ? demandeCession.abonne.nomComplet : demandeCession.nomCompletBeneficiaire }}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Categorie Abonné du bénéficaire</td>
                        <td>{{ demandeCession.categorieBeneficiaire ? demandeCession.categorieBeneficiaire.code + "["+demandeCession.categorieBeneficiaire.libelle +"]" : " "}}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Secteur d'activité</td>
                        <td>{{ demandeCession.secteurActiviteBeneficiaire ? demandeCession.secteurActiviteBeneficiaire.code + "["+demandeCession.secteurActiviteBeneficiaire.libelle +"]" : " "}}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Nouveau Tarif </td>
                        <td>{{ newLoyer ? newLoyer + " F CFA" : 0 + " F CFA"}}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Nouvelle caution </td>
                        <td>{{ newCaution ? newCaution + " F CFA" : 0 + " F CFA" }}</td>
                    </tr>
                    <tr v-if="abonnement">
                    <td>Etat</td>
                    <td>
                        <span class="badge text-bg-success fw-medium">{{demandeCession?.status}}</span>
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <div v-if="etatBouton" style="color: red !important;"> {{ messageClient  }}</div>
              <div v-if="!etatBouton" style="color: black !important;"> {{ messageClient  }}</div>
            </div>

            <div class="card-footer bg-white">
                <div class="row">
                    <div class="justify-content-between" style="display: flex;">
                        <button 
                          v-if="demande?.status !== 'Validée' "
                          :class="{disabled:etatBouton === true }"
                          class="btn btn-success"
                          href="javascript:void(0);"

                          @click="accept(demande)"
                        >
                        Acceptée
                        </button>
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import ApiService from "@/services/ApiService";
  import { RejetDemande } from "@/models/RejetDemande";
  import { Abonnements } from "@/models/Abonnement";
  import { Redevance2 } from "@/models/Redevance";
  import { error, hideModal, success, format_date,showModal } from "@/utils/utils";
  import { useRoute } from "vue-router";
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import Swal from "sweetalert2";
  import { useRouter } from 'vue-router';
  import axios from "axios";
  
  export default defineComponent({
    name: "AccetperChangementCle",
    setup: () => {
      const rejetCessionFormSchema = Yup.object().shape({
        motif: Yup.string().required('Le motif est obligatoire'),
      });
      const route = useRoute();
      const addDemandeModalRef = ref<null | HTMLElement>(null);
      const abonnement = ref<Abonnements | null>(null);
      const abonnements = ref<Array<Abonnements>>([]);
      const redevances = ref<Array<Redevance2>>([]);
      const messageClient = ref("");
  
      function getAbonnement(id: string) {
        return ApiService.get("/abonnements/"+ id)
          .then(({ data }) => {
            abonnement.value = data.data; 
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 

      function getRedevancesAbonnement(id: string){
        return ApiService.get(`/redev/non/?abonneid=${id}&`)
          .then(({ data }) => {
            redevances.value = data.data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      }

      const accept = async (demande:any) => {

          const result = await Swal.fire({
            text: "Vous êtes sur le point d'accepter le changement de clé de la boîte liée à l'abonnement " + abonnement.value?.refAbo + " de "+ abonnement.value?.abonne.nomComplet,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Accepter",
            cancelButtonText: `Annuler`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if(result.isConfirmed) {
            demande.status = "Acceptée";
            demande.dateAcceptation = new Date();
            ApiService.put('/accepte/demandes/'+demande.id, demande)
            .then((response) => {
              if (response.status === 200) {
                success(response.data.message)
                router.push({ path: "/liste-demandes" });
              }
            })
            .catch((errorr) => {
                error(errorr.response.data.message);
            });
          }
        
      } 

      

      const router = useRouter();
     
  
      const etatBouton = ref(true);

      function getDemande(id: string) {
        return ApiService.get("/demandes/"+id)
          .then(({ data }) => {
             //abonnement.value = data.data; 
             return data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 

      const newLoyer =  ref(0);
      const newCaution =  ref(0);
      const getLoyer = async (categorie:number, modele:number) => {
            if(categorie &&  modele) {
                try {
                    const response = await axios.get(`/tarifAnnuels/${categorie}/${modele}`);
                    const tarifAnnuels = response.data.data;
                    newLoyer.value = tarifAnnuels.cout;
                    newCaution.value = tarifAnnuels.caution;
                } catch (error) {
                  //
                }
            }
        }
        const idDemande = ref(0);

    function showModalEdite(model){
      showModal(model);
      idDemande.value=0;
    }

      async function getMontant(value: any) {
        return ApiService.post("cession/impaye/red",value)
          .then(({ data }) => {
            console.log("data",data.data.arrires);
             return data.data.arrires;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      }

      const demande = ref();
      const totaleArr = ref(0);
      const etatRejet = ref(false);

      onMounted( async() => {
        const id = route.params.id as string;
       
        demande.value =  await  getDemande(id);
        
        getRedevancesAbonnement(demande.value.abonnement?.abonne?.id);

        await getAbonnement(demande.value.abonnement?.id);
        totaleArr.value = await  getMontant({abonnement:abonnement.value?.id}) ?? 0;
        var idModel = 0;
        if(abonnement.value?.boite?.modele){
          idModel = abonnement.value?.boite?.modele['id'];
        }

        idDemande.value = parseInt(id);
        if(idModel){
         await  getLoyer(demande.value.categorieBeneficiaire?.id,idModel);
        }

        if((demande.value?.status == "Validée")){
          etatBouton.value = true;
        }
        console.log("totaleArr",totaleArr.value);
        if( totaleArr.value != 0  ){
          messageClient.value = "Il n'est pas possible d'accepter la demande de changement de clé si l'abonné a des arriérés en attente.";
          etatBouton.value = true;
        }else{
          messageClient.value = "Vous navez pas des arriérés en attente. Vous pouvez accepter la demande de changement de clé.";
          etatBouton.value = false;
        }

        // if( redevances.value.length == 0 ){
        //     etatBouton.value = false;
        // }
        
        if((demande.value?.status) === 'Rejetée' || (demande.value?.status == "Acceptée") || (demande.value?.status == "Validée")){
          etatRejet.value = true;
        }else{
          etatRejet.value = false;
        }
      });
  
      return { 
        abonnement,
        demande,
        redevances,
        accept,
        etatRejet,
        addDemandeModalRef,
        rejetCessionFormSchema,
        totaleArr,
        etatBouton,
        newCaution,
        idDemande,
        newLoyer,
        showModalEdite,
        getAbonnement,
        format_date,
        abonnements,
        messageClient
      };
    },
  });
  </script>
  
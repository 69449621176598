<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a v-if="checkPermission('AddModeleBoite')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#AddModeleBoiteModal"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un modèle de boîte
        </a>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un modele de boite"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                CODE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                LIBELLE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mb , index) in modelBoites" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ mb?.code }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ mb?.libelle }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('EditModeleBoite')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="editModeBoite(mb.id)">
                        <i class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier
                      </a>
                    </li>
                    <li v-if="checkPermission('DeleteModeleBoite')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="suppression(mb.id, modelBoites, 'modele_boites', ' un model de boite')">
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
       
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
  <AddModeleBoiteModal :item="selectedItem"  @close="recharger" @openmodal="show_Modal" />
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AddModeleBoiteModal from "./AddModeleBoiteModal.vue";
import { ModeleBoite } from "@/models/ModeleBoite";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression,error } from "../../utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeModeleBoite",
    components: {
      AddModeleBoiteModal,
      PaginationComponent
  },
  setup: () => {
    
    const modelBoites = ref<Array<ModeleBoite | null>>([]);
    const selectedItem = ref(0);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllModeleBoite(page_, limit_);
      } catch (error) {
        //
        
      }
    };
    function rechercher(){
      getAllModeleBoite(page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE
    
    const recharger = () => {
      getAllModeleBoite();
    };


    const show_Modal = (el) => {
      showModal(el);
      selectedItem.value = 0;
    };

    const editModeBoite = (id: number) => {
      selectedItem.value = id;
    };

    function getAllModeleBoite(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/all/modele_boites?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        modelBoites.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      getAllModeleBoite();
    });

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

const checkPermission = (name) => {
  return privileges.value.includes(name);
}

    return { modelBoites,
      checkPermission,
    format_date,
     recharger,
     show_Modal,
     editModeBoite,
     selectedItem,
     suppression,
     page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher
    };
  },
});
</script>
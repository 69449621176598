<template>
    <div class="row">
        <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
          <div class="card mb-25 border-0 rounded-0">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <h4 class="text-black fw-bold mb-10">Informations de l'abonnement</h4>
              <table class="table">
                <tbody>
                  <tr v-if="abonnement">
                    <td>ABONNEMENT</td>
                    <td>{{ abonnement?.refAbo}}</td>
                  </tr>
                  <tr>
                    <td>DEMANDEUR</td>
                    <td>{{ abonnement?.abonne?.nomComplet }}</td>
                  </tr>
                  <tr v-if="abonnement?.adresseVirtuelle">
                    <td>Adresse virtuelle</td>
                    <td>{{ abonnement?.adresseVirtuelle }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite?.adressePostale?.adresse_postale">
                    <td>Adresse postale</td>
                    <td>{{ abonnement?.boite?.adressePostale.adresse_postale }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite">
                    <td>Modele de boite</td>
                    <td>{{ abonnement?.boite?.modele?.libelle }}</td>
                  </tr>
                  <tr v-if="abonnement?.boite">
                    <td>Boite postale</td>
                    <td>{{ abonnement?.boite?.refBoite }}</td>
                  </tr>
                   <!--<tr v-if="abonnement?.loyer">
                    <td>Loyer actuel</td>
                    <td>{{ abonnement?.loyer }}</td>
                  </tr>-->
                  <tr v-if="abonnement?.loyer">
                    <td>Tarif</td>
                    <td>{{ abonnement?.loyer + " F CFA"  }}</td>
                  </tr>
                  <tr v-if="abonnement?.caution">
                    <td>Caution</td>
                    <td>{{ abonnement?.caution }}</td>
                  </tr>
                 <!-- <tr v-if="abonnement?.fraisCle">
                    <td>Frais clé</td>
                    <td>{{ abonnement?.fraisCle }}</td>
                  </tr>
                  <tr v-if="abonnement?.montant">
                    <td>Montant total</td>
                    <td>{{ abonnement?.montant }}</td>
                  </tr>-->
                 <!-- <tr v-if="abonnement?.numero">
                    <td>Numéro</td>
                    <td>{{ abonnement?.numero }}</td>
                  </tr>
                  <tr v-if="abonnement?.nombreCle">
                    <td>Nombre clé</td>
                    <td>{{ abonnement?.nombreCle }}</td>
                  </tr>
                  <tr v-if="abonnement?.typeBoite">
                    <td>Type de boîte</td>
                    <td>{{ abonnement?.typeBoite }}</td>
                  </tr>-->
                  <tr v-if="abonnement?.createdAt">
                    <td>Date d'abonnement</td>
                    <td>{{ format_date(abonnement?.createdAt)  }}</td>
                  </tr>
                  <tr v-if="abonnement">
                    <td>Etat abonnement</td>
                    <td >
                        <span class="badge text-bg-danger fw-medium" v-if="abonnement.estResilier">Inactif </span>
                        <span class="badge text-bg-success fw-medium" v-else>Actif</span>
                    </td>
                  </tr>
                  <tr v-if="abonnement">
                    <td>Arrierés</td>
                    <td>
                        <span class="badge text-bg-danger fw-medium" v-if="totaleArr.arrires > 0">{{totaleArr.arrires? totaleArr.arrires : 0 + " F CFA"}}</span>
                        <span class="badge text-bg-success fw-medium" v-else>{{totaleArr.arrires? totaleArr.arrires : 0 + " F CFA"}}</span>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
          <div class="card mb-25 border-0 rounded-0">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <h4 class="text-black fw-bold mb-10">Informations sur la demande</h4>
              <table class="table">
                <tbody>
                  <tr v-if="demandeCession">
                    <td>Référence</td>
                    <td>{{ demandeCession?.refDemande }}</td>
                  </tr>
                  <tr v-if="abonnement?.refAbo">
                    <td>Date Demande</td>
                    <td>{{ format_date(demandeCession?.createdAt) }}</td>
                  </tr>
                    <tr v-if="demandeCession">
                        <td>Nom du bénéficiaire</td>
                        <td>{{ demandeCession.abonne ? demandeCession.abonne.nomComplet : demandeCession.nomCompletBeneficiaire }}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Categorie Abonné du bénéficaire</td>
                        <td>{{ demandeCession.categorieBeneficiaire ? demandeCession.categorieBeneficiaire.code + "["+demandeCession.categorieBeneficiaire.libelle +"]" : demandeCession.abonne.categorieAbonne.libelle }}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Secteur d'activité</td>
                        <td>{{ demandeCession.secteurActiviteBeneficiaire ? demandeCession.secteurActiviteBeneficiaire.code + "["+demandeCession.secteurActiviteBeneficiaire.libelle +"]" : demandeCession.abonne.activite.libelle }}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Nouveau Tarif </td>
                        <td>{{ newLoyer ? newLoyer + " F CFA" : demandeCession.abonnement.loyer + " F CFA"}}</td>
                    </tr>
                    <tr v-if="demandeCession">
                        <td>Nouvelle caution </td>
                        <td>{{ newCaution ? newCaution + " F CFA" : 0 + " F CFA" }}</td>
                    </tr>
                    <tr v-if="abonnement">
                    <td>Etat</td>
                    <td>
                      <span class="badge text-bg-success fw-medium">{{demandeCession?.status}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="color: red !important;"> {{ messageClient  }}</div>
            </div>
            <div class="card-footer bg-white">
                <div class="row">
                    <div class="justify-content-between" style="display: flex;">
                        <button 
                          v-if="demandeCession?.status !== 'Validée'"
                          :disabled="etatBouton"
                          class="btn btn-success"
                          href="javascript:void(0);"
                          @click="accept(demandeCession)"
                        >
                        Acceptée
                        </button>
                    
                         <button
                         v-if="demandeCession?.status !== 'Validée'"
                         :disabled="etatRejet"
                         type="button"  
                         class="btn btn-danger" 
                         style="margin-left: 5%;"
                         data-bs-toggle="modal"
                        data-bs-target="#RejetCessionModal"
                         >
                         Rejeté
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <div
      class="modal fade createNewModal"
      id="RejetCessionModal"
      tabindex="-1"
      ref="addDemandeModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">Rejet d'une demande de cession</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="rejetCessionForm" @submit="rejetCession" :validation-schema="rejetCessionFormSchema">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Motif <span class="text-danger">*</span>
                  </label>
                  <Field name="motif" type="textarea"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer un motif"/>
                  <ErrorMessage name="motif" class="text-danger"/>
                </div>
              </div>
              <button 
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Confirmer rejet
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import ApiService from "@/services/ApiService";
  import { RejetDemande } from "@/models/RejetDemande";
  import { Abonnements } from "@/models/Abonnement";
  import { error, hideModal, success, format_date,showModal } from "@/utils/utils";
  import { useRoute } from "vue-router";
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import { useRouter } from 'vue-router';
  import RejetCessionModal from "./RejetCessionModal.vue";
import axios from "axios";
  
  export default defineComponent({
    name: "AcceptationCession",
    components: {
      Form,
      Field,
      ErrorMessage
      //RejetCessionModal
    },
    setup: () => {
      const rejetCessionFormSchema = Yup.object().shape({
        motif: Yup.string().required('Le motif est obligatoire'),
      });
      const route = useRoute();
      const addDemandeModalRef = ref<null | HTMLElement>(null);
      const rejetCessionForm =  ref<RejetDemande | null>(null);
      const abonnement = ref<Abonnements | null>(null);
      const abonnements = ref<Array<Abonnements>>([]);
      const messageClient = ref("");
  
      function getAbonnement(id: string) {
        return ApiService.get("/abonnements/"+ id)
          .then(({ data }) => {
            abonnement.value = data.data; 
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 
      const accept =(demande:any)=>{
      demande.status = "Accepté"
      demande.dateAcceptation = new Date();
      ApiService.put('/transfert/accepte/'+demande.id, demande)
        .then((response) => {
          if (response.status === 200) {
            success(response.data.message)
            router.push({ path: "/liste-demandes" });
          }
        })
        .catch((errorr) => {
            error(errorr.response.data.message);
        });
    }

      const router = useRouter();
      const rejetCession = async (values,{ resetForm }) => {
      values["id"]= idDemande.value;
          ApiService.put(`/rejetDemande/demande/${values.id}`,values)
          .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              resetForm();
              hideModal(addDemandeModalRef.value);
              router.push({ path: "/liste-demandes" });
            }
          }).catch(({ response }) => {
            error(response.message);
          });
      }
  
      const etatBouton = ref(false);

      function getDemande(id: string) {
        return ApiService.get("/demandes/"+id)
          .then(({ data }) => {
             abonnement.value = data.data; 
             return data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } 
      const newLoyer =  ref(0);
      const newCaution =  ref(0);
      const getLoyer = async (categorie:number, modele:number) => {
            if(categorie &&  modele) {
                try {
                    const response = await axios.get(`/tarifAnnuels/${categorie}/${modele}`);
                    const tarifAnnuels = response.data.data;
                    newLoyer.value = tarifAnnuels.cout;
                    newCaution.value = tarifAnnuels.caution;
                } catch (error) {
                  //
                }
            }
        }
        const idDemande = ref(0);

    function showModalEdite(model){
      showModal(model);
      idDemande.value=0;
    }
      async function getMontant(value: any) {
        return ApiService.post("cession/impaye/red",value)
          .then(({ data }) => {
             return data.data;
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      }
      const demandeCession = ref();
      const totaleArr = ref(0);
      const etatRejet = ref(false);
      onMounted( async() => {
        const id = route.params.id as string;
       
        demandeCession.value =  await  getDemande(id);
        await getAbonnement(demandeCession.value.abonnement?.id);
        totaleArr.value = await  getMontant({abonnement:abonnement.value?.id})
        console.log("bonjour", totaleArr.value['arrires']);
        var idModel = 0;
        if(abonnement.value?.boite?.modele){
          idModel = abonnement.value?.boite?.modele['id'];
        }
        idDemande.value = parseInt(id);
        if(idModel){
         await  getLoyer(demandeCession.value.categorieBeneficiaire?.id,idModel);
        }

        if((demandeCession.value?.status == "Validée" || (totaleArr.value['arrires'] > 0 || totaleArr.value['arrires'] != null ) )){
          messageClient.value = "Vous ne pouvez céder un abonnement si vous avez des arriérés en attente";
          etatBouton.value = true;
        }else{
          etatBouton.value = false;
        }

        if((demandeCession.value?.status) === 'Rejetée' || (demandeCession.value?.status == "Acceptée") || (demandeCession.value?.status == "Validée")){
          etatRejet.value = true;
        }else{
          etatRejet.value = false;
        }
      });
  
      return { abonnement,demandeCession,rejetCession,accept,etatRejet,addDemandeModalRef,rejetCessionFormSchema,totaleArr,etatBouton, messageClient, newCaution,idDemande,newLoyer,showModalEdite, getAbonnement, format_date, abonnements };
    },
  });
  </script>
  
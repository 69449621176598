import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";

import EcommercePage from "../pages/Dashboard/EcommercePage.vue";
import LMSCoursesPage from "../pages/Dashboard/LMSCoursesPage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage.vue";
import EmailConfirmationPage from "../pages/Authentication/EmailConfirmationPage.vue";
import StatistiqueGlobalPage from "@/pages/Etats/StatistiqueGlobalPage.vue";
import AddRemboursementPage from "@/pages/Remboursement/AddRemboursementPage.vue";
import ListeRemboursementPage from "@/pages/Remboursement/ListeRemboursementPage.vue";
import EtatColisParCasierPage from "@/pages/Etats/EtatColisParCasierPage.vue";
// import UsersListPage from "../pages/Users/UsersListPage.vue";
// import AddUserPage from "../pages/Users/AddUserPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

import MaintenancePage from "../pages/PagesInner/MaintenancePage.vue";
import NotificationsPage from "../pages/PagesInner/NotificationsPage.vue";
import ListeNotificationRedevancePage from "@/pages/Notification/ListeNotificationRedevancePage.vue";
import ListeNotificationCampagnePage from "@/pages/Notification/ListeNotificationCampagnePage.vue";
import AddNotificationRedevancePage from "@/pages/Notification/AddNotificationRedevancePage.vue";
import AddNotificationCampagnePage from "@/pages/Notification/AddNotificationCampagnePage.vue";
import ConnectedAccountsPage from "../pages/PagesInner/ConnectedAccountsPage.vue";
import BlankPage from "../pages/BlankPage.vue";
import LogoutPage from "../pages/LogoutPage.vue";
import ReabonnementAddPage from "@/pages/Reabonners/ReabonnementAddPage.vue";
import SuiviAdressePage from "@/pages/SuiviAdresse/SuiviAdressePage.vue";
import ReabonnementsListPage from "@/pages/Reabonners/ReabonnementsListPage.vue";
import AddTransfertMpostBoxPage from "@/pages/Demandes/AddTransfertMpostBoxPage.vue"
import AddDemandePage from "@/pages/Demandes/AddDemandePage.vue";
import EditDemandePage from "@/pages/Demandes/EditDemandePage.vue";
import DemandesListPage from "@/pages/Demandes/DemandesListPage.vue";
import ViewDemandePage from "@/pages/Demandes/ViewDemandePage.vue";
import AddBoitePostalePage from "@/pages/BoitePostale/AddBoitePostalePage.vue";
import EditBoitePostalePage from "@/pages/BoitePostale/EditBoitePostalePage.vue";
import ListeBoitePostalePage from "@/pages/BoitePostale/ListeBoitePostalePage.vue";
import PermissionsListing from "@/pages/permissions/PermissionsListing.vue";
import AddAbonnePage from "@/pages/Abonne/AddAbonnePage.vue";
import AddCessionPage from "@/pages/Cession/AddCessionPage.vue";
import EditAbonnePage from "@/pages/Abonne/EditAbonnePage.vue";
import ListeAbonnePage from "@/pages/Abonne/ListeAbonnePage.vue";
import ViewAbonnePage from "@/pages/Abonne/ViewAbonnePage.vue";
import AcceptationCessionPage from "@/pages/Cession/AcceptationCessionPage.vue";
import TraitementResiliationPage from "@/pages/Resiliations/TraitementResiliationPage.vue";
import TraitementReclamationPage from "@/pages/Reclamations/TraitementReclamationPage.vue";
import AccepterChangementClePage from "@/pages/Demandes/AccepterChangementClePage.vue";
import ListeCessionPage from "@/pages/Cession/ListeCessionPage.vue";
import AddRedevancePage from "@/pages/Redevance/AddRedevancePage.vue";
import GenererRedevancePage from "@/pages/Redevance/GenererRedevancePage.vue"
import EditRedevancePage from "@/pages/Redevance/EditRedevancePage.vue";
import AddAbonnementPage from "@/pages/Abonnement/AddAbonnementPage.vue";
import EditAbonnementPage from "@/pages/Abonnement/EditAbonnementPage.vue";
import ListeAbonnementPage from "@/pages/Abonnement/ListeAbonnementPage.vue";
import ViewAbonnementPage from "@/pages/Abonnement/ViewAbonnementPage.vue";
import ListeRedevancePage from "@/pages/Redevance/ListeRedevancePage.vue";
import AddNotificationPage from "@/pages/Notification/AddNotificationPage.vue";
import EditNotificationPage from "@/pages/Notification/EditNotificationPage.vue";
import ListeNotificationPage from "@/pages/Notification/ListeNotificationPage.vue";
import ViewNotificationContentPage from "../pages/PagesInner/ViewNotificationContentPage.vue";
import ViewNotificationPage from "../pages/Notification/ViewNotificationPage.vue";
import AddPaiementPage from "@/pages/Paiement/AddPaiementPage.vue";
import ListePaiementPage from "@/pages/Paiement/ListePaiementPage.vue";
import AddPaiementServicePage from "@/pages/PaiementService/AddPaiementServicePage.vue";
import ListePaiementServicePage from "@/pages/PaiementService/ListePaiementServicePage.vue";
import AddCampagnePage from "@/pages/Campagne/AddCampagnePage.vue";
import EditCampagnePage from "@/pages/Campagne/EditCampagnePage.vue";
import Add2ReabonnementPage from "@/pages/Reabonnement/Add2ReabonnementPage.vue";
import AddReabonnementPage from "@/pages/Reabonnement/AddReabonnementPage.vue";
import ListeReabonnementPage from "@/pages/Reabonnement/ListeReabonnementPage.vue";
import ListeCampagnePage from "@/pages/Campagne/ListeCampagnePage.vue";
import AddAttestationPage from "@/pages/Attestation/AddAttestationPage.vue";
import EditAttestationPage from "@/pages/Attestation/EditAttestationPage.vue";
import ListeAttestationPage from "@/pages/Attestation/ListeAttestationPage.vue";
import AddCasierPage from "@/pages/Casier/AddCasierPage.vue";
import EditCasierPage from "@/pages/Casier/EditCasierPage.vue";
import ListeCasierPage from "@/pages/Casier/ListeCasierPage.vue";
import AddEtagerePage from "@/pages/Etagere/AddEtagerePage.vue";
import EditEtagerePage from "@/pages/Etagere/EditEtagerePage.vue";
import EtatBoitePage from "@/pages/Etats/EtatBoitePage.vue";
import EtatPaiementPage from "@/pages/Etats/EtatPaiementPage.vue";
import EtatDemandePage from "@/pages/Etats/EtatDemandePage.vue";
import EtatAbonnementPage from "@/pages/Etats/EtatAbonnementPage.vue";
import EtatAbonnementPaiementPage from "@/pages/Etats/EtatAbonnementPaiementPage.vue";
import EtatMpostBoxPage from "@/pages/Etats/EtatMpostBoxPage.vue";
import ListeEtagerePage from "@/pages/Etagere/ListeEtagerePage.vue";
import AddRayonPage from "@/pages/Rayon/AddRayonPage.vue";
import EditRayonPage from "@/pages/Rayon/EditRayonPage.vue";
import ListeRayonPage from "@/pages/Rayon/ListeRayonPage.vue";
import AddColisPage from "@/pages/Colis/AddColisPage.vue";
import MoveColisPage from "@/pages/Colis/MoveColisPage.vue";
import DeliverColisPage from "@/pages/Colis/DeliverColisPage.vue";
import EditColisPage from "@/pages/Colis/EditColisPage.vue";
import ListeColisPage from "@/pages/Colis/ListeColisPage.vue";
import AddOperationPage from "@/pages/Operation/AddOperationPage.vue";
import EditOperationPage from "@/pages/Operation/EditOperationPage.vue";
import ListeOperationPage from "@/pages/Operation/ListeOperationPage.vue";
import AddTransfertPage from "@/pages/Transfert/AddTransfertPage.vue";
import ListeTransfertPage from "@/pages/Transfert/ListeTransfertPage.vue";
import ListePermissionPage from "@/pages/Permission/ListePermissionPage.vue";
import AddRolePage from "@/pages/Role/AddRolePage.vue";
import EditRolePage from "@/pages/Role/EditRolePage.vue";
import ListeRolePage from "@/pages/Role/ListeRolePage.vue";
import ViewRolePage from "@/pages/Role/ViewRolePage.vue";
import AddMpostBoxPage from "@/pages/MpostBox/AddMpostBoxPage.vue";
import ListeMpostBoxPage from "@/pages/MpostBox/ListeMpostBoxPage.vue";
import AddUserPage from "@/pages/Users/AddUserPage.vue";
import EditUserPage from "@/pages/Users/EditUserPage.vue";
import ListeUserPage from "@/pages/Users/ListeUserPage.vue";
import ProfilePage from "../pages/PagesInner/ProfilePage.vue";
import AddResiliationPage from "@/pages/Resiliations/AddResiliationPage.vue";
import ListeResiliationPage from "@/pages/Resiliations/ListeResiliationPage.vue";
import ListeJournalConnexionPage from "@/pages/Journal/ListeJournalConnexionPage.vue";
import ListeJournalOperationPage from "@/pages/Journal/ListeJournalOperationPage.vue";

import ListeRegionPage from "@/pages/Region/ListeRegionPage.vue";
import ListeCommunePage from "@/pages/Commune/ListeCommunePage.vue";
import ListeBureauPage from "@/pages/Bureau/ListeBureauPage.vue";
import ListeSecteurActivitePage from "@/pages/SecteurActivite/ListeSecteurActivitePage.vue";
import ListeCategorieAbonnePage from "@/pages/CategorieAbonne/ListeCategorieAbonnePage.vue";
import ListeModePaiementPage from "@/pages/ModePaiement/ListeModePaiementPage.vue";
import ListeParametrePage from "@/pages/Parametre/ListeParametrePage.vue";
import ListeModeleBoitePage from "@/pages/ModeleBoite/ListeModeleBoitePage.vue";
import ListeServicePage from "@/pages/Service/ListeServicePage.vue";
import ListeTypeDemandePage from "@/pages/TypeDemande/ListeTypeDemandePage.vue";
import ListeAdressePostalePage from "@/pages/AdressePostale/ListeAdressePostalePage.vue";
import ListeTarifAnnuelPage from "@/pages/TarifAnnuel/ListeTarifAnnuelPage.vue";
import ListeModeleNotificationPage from "@/pages/ModeleNotification/ListeModeleNotificationPage.vue";
import ViewModeleNotificationPage from "@/pages/ModeleNotification/ViewModeleNotificationPage.vue";
import AddTypeNotificationPage from "@/pages/TypeNotification/AddTypeNotificationPage.vue";
import ListeTypeNotificationPage from "@/pages/TypeNotification/ListeTypeNotificationPage.vue";
import AddCleTypeNotificationPage from "@/pages/CleTypeNotification/AddCleTypeNotificationPage.vue";
import ListeCleTypeNotificationPage from "@/pages/CleTypeNotification/ListeCleTypeNotificationPage.vue";
import StatistiqueMpostPage from "@/pages/Statistique/StatistiqueMpostPage.vue";
import StatistiqueCaution from "@/pages/Statistique/StatistiqueCaution.vue";
import ListeQuartierPage from "@/pages/Quartier/ListeQuartierPage.vue";
import ListeArrondissementPage from "@/pages/Arrondissement/ListeArrondissementPage.vue";
import { useAuthStore } from "@/services/auth";
import ApiService from "@/services/ApiService";

const routes = [
  
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/tableau_bord",
        name: "tableauBordPage",
        component: EcommercePage,
      },
      {
        path: "/tableau_bord1",
        name: "tableauBordPage1",
        component: LMSCoursesPage,
      },
      // {
      //   path: "/users-list",
      //   name: "UsersListPage",
      //   component: UsersListPage,
      // },
      // {
      //   path: "/add-user",
      //   name: "AddUserPage",
      //   component: AddUserPage,
      // },
      
      {
        path: "/statistique-caution",
        name: "StatistiqueCaution",
        component: StatistiqueCaution,
      },

      {
        path: "/statistique-mpost",
        name: "StatistiqueMpostPage",
        component: StatistiqueMpostPage,
      },
      {
        path: "/ajouter-reabonner",
        name: "ReabonnementAddPage",
        component: ReabonnementAddPage,
      },
      {
        path: "/suivi-adresse",
        name: "SuiviAdressePage",
        component: SuiviAdressePage,
      },
      {
        path: "/ajouter-reabonnement",
        name: "AddReabonnementPage",
        component: AddReabonnementPage,
      },
      {
        path:"/ajouter-transfert-Mpost-box/:demande",
        name:"AddTransfertMpostBoxPage",
        component: AddTransfertMpostBoxPage,
      },
      {
        path: "/ajouter-reabonnement2",
        name: "Add2ReabonnementPage",
        component: Add2ReabonnementPage,
      },
      {
        path: "/etat-boite",
        name: "EtatBoitePage",
        component: EtatBoitePage,
      },
      {
        path: "/ajouter-remboursement",
        name: "AddRemboursementPage",
        component: AddRemboursementPage,
      },
      {
        path: "/liste-remboursement",
        name: "ListeRemboursementPage",
        component: ListeRemboursementPage,
      },
      {
        path: "/etat-comptabilisation",
        name: "EtatPaiementPage",
        component: EtatPaiementPage,
      },
      {
        path: "/etat-demande",
        name: "EtatDemandePage",
        component: EtatDemandePage,
      },
      {
        path: "/etat-mpost-box",
        name: "EtatMpostBoxPage",
        component: EtatMpostBoxPage,
      },
      {
        path: "/etat-colis-casier",
        name: "EtatColisParCasierPage",
        component: EtatColisParCasierPage,
      },
      {
        path: "/etat-stat-global",
        name: "StatistiqueGlobalPage",
        component: StatistiqueGlobalPage,
      },
      {
        path: "/etat-abonnement-paiement",
        name: "EtatAbonnementPaiementPage",
        component: EtatAbonnementPaiementPage,
      },
      {
        path: "/etat-abonnement",
        name: "EtatAbonnementPage",
        component: EtatAbonnementPage,
      },
      {
        path: "/ajouter-abonnement",
        name: "AddAbonnementPage",
        component: AddAbonnementPage,
      },
      {
        path: "/liste-reabonnement",
        name: "ListeReabonnementPage",
        component: ListeReabonnementPage,
      },
      {
        path: "/modifier-abonnement/:id",
        name: "EditAbonnementPage",
        component: EditAbonnementPage,
      },
      {
        path: "/liste-abonnements",
        name: "ListeAbonnementPage",
        component: ListeAbonnementPage,
      },
      {
        path: "/view-abonnement/:id",
        name: "ViewAbonnementPage",
        component: ViewAbonnementPage,
      },
      {
        path: "/acceptation-cession/:id",
        name: "AcceptationCessionPage",
        component: AcceptationCessionPage,
      },
      {
        path: "/traitement-resiliation/:id",
        name: "TraitementResiliationPage",
        component: TraitementResiliationPage,
      },
      {
        path: "/traitement-reclamation/:id",
        name: "TraitementReclamationPage",
        component: TraitementReclamationPage,
      },
      {
        path: "/verification-changement-cle/:id",
        name: "AccepterChangementClePage",
        component: AccepterChangementClePage,
      },
      {
        path: "/ajouter-boite",
        name: "AddBoitePostalePage",
        component: AddBoitePostalePage,
      },
      {
        path: "/liste-transferts",
        name: "ListeTransfertPage",
        component: ListeTransfertPage,
      },
      {
        path: "/ajouter-cession/:id",
        name: "AddCessionPage",
        component: AddCessionPage,
      },
      {
        path: "/liste-cessions",
        name: "ListeCessionPage",
        component: ListeCessionPage,
      },
      {
        path: "/ajouter-transfert/",
        name: "AddTransfertPage",
        component: AddTransfertPage,
      },
      {
        path: "/modifier-boite/:id",
        name: "EditBoitePostalePage",
        component: EditBoitePostalePage,
      },
      {
        path: "/liste-boites",
        name: "ListeBoitePostalePage",
        component: ListeBoitePostalePage,
      },
      {
        path: "/liste-reabonnement",
        name: "ListReabonnementPage",
        component: ReabonnementsListPage,
      },
      {
        path: "/ajouter-demande",
        name: "AddDemandePage",
        component: AddDemandePage,
      },
      {
        path: "/modifier-demande/:id",
        name: "EditDemandePage",
        component: EditDemandePage,
      },
      {
        path: "/liste-demandes",
        name: "ListDemandePage",
        component: DemandesListPage,
      },
      {
        path: "/view-demande/:id",
        name: "ViewDemandePage",
        component: ViewDemandePage,
      },
      {
        path: "/notifications",
        name: "NotificationsPage",
        component: NotificationsPage,
      },
      {
        path: "/liste-regions",
        name: "ListeRegionPage",
        component: ListeRegionPage,
      },
      {
        path: "/liste-communes",
        name: "ListeCommunePage",
        component: ListeCommunePage,
      },
      {
        path: "/liste-bureaux",
        name: "ListeBureauPage",
        component: ListeBureauPage,
      },
      {
        path: "/liste-secteur_activites",
        name: "ListeSecteurActivitePage",
        component: ListeSecteurActivitePage,
      },
      {
        path: "/liste-categorie_abonnes",
        name: "ListeCategorieAbonnePage",
        component: ListeCategorieAbonnePage,
      },
      {
        path: "/liste-mode_paiements",
        name: "ListeModePaiementPage",
        component: ListeModePaiementPage,
      },
      {
        path: "/liste-parametres",
        name: "ListeParametrePage",
        component: ListeParametrePage,
      },
      {
        path: "/liste-modele_boites",
        name: "ListeModeleBoitePage",
        component: ListeModeleBoitePage,
      },
      {
        path: "/liste-services",
        name: "ListeServicePage",
        component: ListeServicePage,
      },
      {
        path: "/liste-type_demandes",
        name: "ListeTypeDemandePage",
        component: ListeTypeDemandePage,
      },
      {
        path: "/liste-adresse_postales",
        name: "ListeAdressePostalePage",
        component: ListeAdressePostalePage,
      },
      {
        path: "/liste-tarif_annuels",
        name: "ListeTarifAnnuelPage",
        component: ListeTarifAnnuelPage,
      },
      {
        path: "/ajouter-abonne",
        name: "AddAbonnePage",
        component: AddAbonnePage,
      },
      {
        path: "/modifier-abonne/:id",
        name: "EditAbonnePage",
        component: EditAbonnePage,
      },
      {
        path: "/liste-abonnes",
        name: "ListeAbonnePage",
        component: ListeAbonnePage,
      },
      {
        path: "/view-abonne/:id",
        name: "ViewAbonnePage",
        component: ViewAbonnePage,
      },
      {
        path: "/ajouter-redevance",
        name: "AddRedevancePage",
        component: AddRedevancePage,
      },
      {
        path: "/generer-redevance",
        name: "GenererRedevancePage",
        component: GenererRedevancePage,
      },
      {
        path: "/modifier-redevance/:id",
        name: "EditRedevancePage",
        component: EditRedevancePage,
      },
      {
        path: "/liste-redevances",
        name: "ListeRedevancePage",
        component: ListeRedevancePage,
      },
      {
        path: "/ajouter-notification/:id?",
        name: "AddNotifiationPage",
        component: AddNotificationPage,
      },
      {
        path: "/modifier-notification/:id",
        name: "EditNotificationPage",
        component: EditNotificationPage,
      },
      {
        path: "/liste-notifications",
        name: "ListeNotificationPage",
        component: ListeNotificationPage,
      },
      {
        path: "/view-notification/:id",
        name: "ViewNotificationPage",
        component: ViewNotificationPage,
      },
      {
        path: "/view-notification/:id",
        name: "ViewNotificationContentPage",
        component: ViewNotificationContentPage,
      },
      {
        path: "/ajouter-notification-redevance",
        name: "AddNotificationRedevancePage",
        component: AddNotificationRedevancePage,
      },
      {
        path: "/notification-redevance",
        name: "ListeNotificationRedevancePage",
        component: ListeNotificationRedevancePage,
      },
      {
        path: "/ajoute-notification-campagne",
        name: "AddNotificationCampagnePage",
        component: AddNotificationCampagnePage,
      },
      {
        path: "/notification-campagne",
        name: "NotificationCampagnePage",
        component: ListeNotificationCampagnePage,
      },
      {
        path: "/ajouter-paiement",
        name: "AddPaiementPage",
        component: AddPaiementPage,
      },
      {
        path: "/liste-paiements",
        name: "ListePaiementPage",
        component: ListePaiementPage,
      },
      {
        path: "/ajouter-paiement_service",
        name: "AddPaiementServicePage",
        component: AddPaiementServicePage,
      },
      {
        path: "/liste-paiement_services",
        name: "ListePaiementServicePage",
        component: ListePaiementServicePage,
      },
      {
        path: "/ajouter-campagne",
        name: "AddCampagnePage",
        component: AddCampagnePage,
      },
      {
        path: "/modifier-campagne/:id",
        name: "EditCampagnePage",
        component: EditCampagnePage,
      },
      {
        path: "/liste-campagnes",
        name: "ListeCampagnePage",
        component: ListeCampagnePage,
      },
      {
        path: "/verify/search-document",
        name: "AddAttestationPage",
        component: AddAttestationPage,
      },
      {
        path: "/liste-attestations",
        name: "ListeAttestationPage",
        component: ListeAttestationPage,
      },
      {
        path: "/liste-modele_notifications",
        name: "ListeModeleNotificationPage",
        component: ListeModeleNotificationPage,
      },
      {
        path: "/view-modeleNotification/:id",
        name: "ViewModeleNotificationPage",
        component: ViewModeleNotificationPage,
      },
      {
        path: "/ajouter-type-notifications",
        name: "AddTypeNotificationPage",
        component: AddTypeNotificationPage,
      },
      {
        path: "/liste-type-notifications",
        name: "ListeTypeNotificationPage",
        component: ListeTypeNotificationPage,
      },
      {
        path: "/ajouter-cle-type-notifications",
        name: "AddCleTypeNotificationPage",
        component: AddCleTypeNotificationPage,
      },
      {
        path: "/liste-cle-type-notifications",
        name: "ListeCleTypeNotificationPage",
        component: ListeCleTypeNotificationPage,
      },
      {
        path: "/ajouter-casier",
        name: "AddCasierPage",
        component: AddCasierPage,
      },
      {
        path: "/modifier-casier/:id",
        name: "EditCasierPage",
        component: EditCasierPage,
      },
      {
        path: "/liste-casiers",
        name: "ListeCasierPage",
        component: ListeCasierPage,
      },
      {
        path: "/ajouter-etagere",
        name: "AddEtagerePage",
        component: AddEtagerePage,
      },
      {
        path: "/modifier-etagere/:id",
        name: "EditEtagerePage",
        component: EditEtagerePage,
      },
      {
        path: "/liste-etageres",
        name: "ListeEtagerePage",
        component: ListeEtagerePage,
      },
      {
        path: "/ajouter-rayon",
        name: "AddRayonPage",
        component: AddRayonPage,
      },
      {
        path: "/modifier-rayon/:id",
        name: "EditRayonPage",
        component: EditRayonPage,
      },
      {
        path: "/liste-rayons",
        name: "ListeRayonPage",
        component: ListeRayonPage,
      },
      {
        path: "/ajouter-colis",
        name: "AddColisPage",
        component: AddColisPage,
      },
      {
        path: "/move-colis",
        name: "MoveColisPage",
        component: MoveColisPage,
      },
      {
        path: "/deliver-colis",
        name: "DeliverColisPage",
        component: DeliverColisPage,
      },
      {
        path: "/modifier-colis/:id",
        name: "EditColisPage",
        component: EditColisPage,
      },
      {
        path: "/liste-colis",
        name: "ListeColisPage",
        component: ListeColisPage,
      },
      {
        path: "/ajouter-operation",
        name: "AddOperationPage",
        component: AddOperationPage,
      },
      {
        path: "/modifier-operation/:id",
        name: "EditOperationPage",
        component: EditOperationPage,
      },
      {
        path: "/liste-operations",
        name: "ListeOperationPage",
        component: ListeOperationPage,
      },
      {
        path: "/liste-privileges",
        name: "ListePermissionPage",
        component: ListePermissionPage,
      },
      { 
        path: "/ajouter-role",
        name: "AddRolePage",
        component: AddRolePage,
      },
      {
        path: "/modifier-role/:id",
        name: "EditRolePage",
        component: EditRolePage,
      },
      {
        path: "/liste-roles",
        name: "ListeRolePage",
        component: ListeRolePage,
      },
      {
        path: "/view-role/:id",
        name: "ViewRolePage",
        component: ViewRolePage,
      },
      {
        path: "/liste-journal-connexion",
        name: "ListeJournalConnexionPage",
        component: ListeJournalConnexionPage,
      },
      {
        path: "/liste-journal-operation",
        name: "ListeJournalOperationPage",
        component: ListeJournalOperationPage,
      },
      {
        path: "/ajouter-mpostbox",
        name: "AddMpostBoxPage",
        component: AddMpostBoxPage,
      },
      {
        path: "/liste-mpostboxs",
        name: "ListeMpostBoxPage",
        component: ListeMpostBoxPage,
      },
      {
        path: "/ajouter-user",
        name: "AddUserPage",
        component: AddUserPage,
      },
      {
        path: "/modifier-user/:id",
        name: "EditUserPage",
        component: EditUserPage,
      },
      {
        path: "/liste-users",
        name: "ListeUserPage",
        component: ListeUserPage,
      },
      {
        path: "/profile",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/liste-resiliations",
        name: "ListeResiliationPage",
        component: ListeResiliationPage,
      },
      {
        path: "/ajouter-resiliation",
        name: "AddResiliationPage",
        component: AddResiliationPage,
      },
      {
        path: "/liste-quartiers",
        name: "ListeQuartierPage",
        component: ListeQuartierPage,
      },
      {
        path: "/liste-arrondissements",
        name: "ListeArrondissementPage",
        component: ListeArrondissementPage,
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
      },
      {
        path: "/email-confirmation",
        name: "EmailConfirmationPage",
        component: EmailConfirmationPage,
      },
      {
        path: "/connected-accounts",
        name: "ConnectedAccountsPage",
        component: ConnectedAccountsPage,
      },
      {
        path: "/maintenance",
        name: "MaintenancePage",
        component: MaintenancePage,
      },
      {
        path: "/logout",
        name: "LogoutPage",
        component: LogoutPage,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
  //history: createWebHistory(),
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // verify auth token before each page change
  //authStore.verifyAuth();
  
  // before page access check if page requires authentication
  if(to.meta.middleware == "auth") {
    ApiService.setHeader();
    if (authStore.isAuthenticated) {
      next();
    } else {
      next({ name: "LoginPage" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});


export default router;

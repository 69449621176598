<template>
    <BreadCrumb PageTitle="Liste des arrondissements" />
    <ListeArrondissement/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeArrondissement from "../../components/Arrondissement/ListeArrondissement.vue";

    export default defineComponent({
      name: "ListeArrondissementPage",
      components: {
        BreadCrumb,
        ListeArrondissement,
      },
    });
  </script>
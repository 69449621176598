<template>
    <BreadCrumb PageTitle="Vérification de la demande de résiliation" />
    <TraitementResiliation />
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import TraitementResiliation from "../../components/Resiliations/TraitementResiliation.vue";
  
    export default defineComponent({
      name: "TraitementResiliationPage",
      components: {
        BreadCrumb,
        TraitementResiliation,
      },
    });
  </script>
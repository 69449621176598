<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonneForm"   @submit="editAbonne" :validation-schema="abonneSchema" :initial-values="abonneForm">
        <div class="row">
        <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie d'abonné <span class="text-danger">*</span>
                </label>
                <Field name="categorieAbonne" v-slot="{ field }">
                  <Multiselect 
                    :options="categorieAbonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la catégorie de l'abonné"
                    @select="handleObjetInput"
                  />
                </Field>
                <ErrorMessage name="categorieAbonne" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  {{ label1 }} <span class="text-danger">*</span>
                </label>
                <Field name="nomComplet" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="nomComplet" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  {{ label2 }}
                </label>
                <Field name="dateNaissance" type="date" 
                class="form-control shadow-none rounded-0 text-black"/>
                  <ErrorMessage name="dateNaissance" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Téléphone <span class="text-danger">*</span>
              </label>
              <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Entrer le numéro de téléphone"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
              <ErrorMessage name="telephone" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4" v-if="showAdditionalFields">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Sexe <span class="text-danger">*</span>
              </label>
              <Field  name="sexe"  type="text"  v-slot="{ field }">
                <Multiselect
                  :searchable = "true"
                  :options = "['Masculin', 'Féminin']"
                  v-model = "field.value"
                  v-bind = "field"
                  placeholder="Sélectionner le sexe"
                />
              </Field>
                <ErrorMessage name="sexe" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Pays de résidence <span class="text-danger">*</span>
                </label>
                <Field  name="nationalite"  v-slot="{ field }">
                  <Multiselect
                    :options="countries"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le pays de résidence"
                  />
                </Field>  
                <ErrorMessage name="nationalite" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Secteur d'activité <span class="text-danger">*</span>
                </label>
                <Field  name="activite"  v-slot="{ field }">
                  <Multiselect
                    :options="secteurActiviteOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le secteur d'activité"
                  />
                </Field>
                <ErrorMessage name="activite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4" v-if="showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Profession
                </label>
                <Field name="profession" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le profession"/>
                  <ErrorMessage name="profession" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email
              </label>
              <Field name="email" type="email"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l' email"/>
                <ErrorMessage name="email" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Département de résidence
              </label>
              <Field name="departementResidence" type="text" 
              class="form-control shadow-none rounded-0 text-black" placeholder="Entrer le département de résidence"/>
                <ErrorMessage name="departementResidence" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse de résidence
              </label>
              <Field name="adresseResidence" type="text" 
              class="form-control shadow-none rounded-0 text-black" placeholder="Entrer l' adresse de résidence"/>
                <ErrorMessage name="adresseResidence" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4" v-if="showAdditionalFields">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Ville de résidence
              </label>
              <Field name="villeResidence" type="text" 
              class="form-control shadow-none rounded-0 text-black" placeholder="Entrer la ville de résidence"/>
                <ErrorMessage name="villeResidence" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Modifier un abonné
              </button>
              <router-link to="/liste-abonnes" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { error, success } from '@/utils/utils';
import { countries } from './countries';
import { useRoute, useRouter } from 'vue-router';
import { Abonne } from '@/models/Abonne';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default defineComponent({
    name: "EditAbonne",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  setup: () => {
    const abonneSchema = Yup.object().shape({
      nomComplet: Yup.string().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le nom et le prénom sont obligatoires")
          : schema.required("La dénomination est obligatoire")
      }),
      nationalite: Yup.string().required('Le pays de résidence est obligatoire'),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
      email: Yup.string().email("Veuillez renseigner un mail valide").notRequired(),
      //password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
      sexe: Yup.string().notRequired().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le sexe est obligatoire")
          : schema
      }),
      // dateNaissance: Yup.string().when([], (value, schema) => {
      //   return showAdditionalFields.value
      //     ? schema.notRequired()
      //     : schema.notRequired()
      // }),
      dateNaissance: Yup.date().notRequired(),
      profession: Yup.string().notRequired(),
      adresseResidence: Yup.string().notRequired(),
      villeResidence: Yup.string().notRequired(),
      departementResidence: Yup.string().notRequired(),
      categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonné est obligatoire'),
      activite: Yup.number().typeError('Veuillez entrer un nombre').required('Le secteur d\'activité est obligatoire'),
    });

    const abonneForm = ref<Abonne>();
    const categorieAbonnes = ref(null);
    const categorieAbonneOptions = ref([]);
    const secteurActivites = ref(null);
    const secteurActiviteOptions = ref([]);
    const countriesRef = ref(countries);
    const passwords = ref<string>("");
    const router = useRouter();
    const route = useRoute();
    const  codepay= ref();
    const  nationalnumlber= ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);
    const  numberPhone=ref();
    const showAdditionalFields = ref(false);
    const categorieAbonneId = ref(null);

    const handleObjetInput = (selectedValue) => {
      //categorieAbonneId.value = selectedValue.id;
      showAdditionalFields.value = selectedValue === 1
    }

    watch(categorieAbonneId, (newVal) => {
      showAdditionalFields.value = newVal === 1;
    });


    const label1 = computed(() => {
      return showAdditionalFields.value && categorieAbonneId.value === 1 ? 'Nom et prénom' : 'Dénomination';
    });

    const label2 = computed(() => {
      return showAdditionalFields.value && categorieAbonneId.value === 1 ? 'Date de naissance' : 'Date de création';
    });
    // function onInput(phone, phoneObject, input) {
    // }

    function validate(phoneObject) {
      validPhone.value=phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value= phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value= phoneObject.nationalNumber;
        numberPhone.value=phoneObject.number;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
        numberPhone.value="";
      }
    }

    onMounted(() => {
      if(route.params.id) {
        getAbonne(parseInt(route.params.id as string));
      }
      fetchCategorieAbonnes();
      fetchSecteurActivites();
      passwords.value=password();
    });

    
    function getAbonne(id:number) {
      ApiService.get("/abonnes/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            if (key === 'categorieAbonne') {
              categorieAbonneId.value = data.data[key].id;
            }
            abonneForm.value?.setFieldValue(key, 
              (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
            );
          }
      })
      .catch(({ response }) => {
        error(response.message);
          //router.push({ name: "abonneForms-liste" });
      });
    } 
    
    function password():string{
      let charactersArray = 'a-z,A-Z,0-9,#'.split(',')
      let CharacterSet = '';
      let password = '';
      let size = 12;

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }

      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }

      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '@$!%*?&#'
      }

      for (let i = 0; i < size; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }

      return password
    }


    const editAbonne = async (values, {resetForm}) => {
      values["telephone"] = numberPhone.value;
      ApiService.put("/abonnes/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeAbonnePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchSecteurActivites = async () => {
      try {
        const response = await axios.get('/secteurActivites');
        const secteurActivitesData = response.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: secteurActivite.libelle,
        }));
      } catch (error) {
        //
      }
    };

    return { abonneSchema, editAbonne, abonneForm, categorieAbonnes, categorieAbonneOptions, secteurActivites, secteurActiviteOptions, countries: countriesRef, telephone,
      validPhone,
      validate,
      handleObjetInput, 
      showAdditionalFields,
      label1,
      label2 };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/verify/search-document"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Vérifier attestation
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une attestation"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Reférence attestation
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
               Demandeur
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date Edition
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date Expiration
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Reférence Demande
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="(at, index) in attestations" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
               {{ at.refAttestation }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
               {{ at.abonnement.abonne.nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(at.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(at.dateExpiration) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ at.abonnement.refAbo }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item d-flex align-items-center"
                          @click="openNewWindow(at)"
                        >
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Télécharger
                        </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class = "pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref,onMounted } from "vue";
import { defineComponent } from "vue";
// import AddAttestation from "./AddAttestation.vue";
import { format_date,suppression,format_Date, hideModal, showModal,separateur, error,success,telechargerFichier2,decouperEtJoindre,getUrlApiForFiles,openAndPrint } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import ApiService from "@/services/ApiService";
import { Attestation } from "@/models/Attestation";
import Swal from "sweetalert2";

export default defineComponent({
    name: "ListeAttestation",
    components: {
     PaginationComponent,
  },
  setup: () => {
    const attestations = ref<Array<Attestation>>([]);
    onMounted(() => {
      getAllAttestation();
    });

    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);
    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllAttestation(page_, limit_);
      } catch (error) {
        //
      }
    };

    function openNewWindow(data:any){
      openAndPrint(getUrlApiForFiles("Attestation",decouperEtJoindre(data.refAttestation,"-",3)+".pdf"));
    }

    function getAllAttestation(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/attestations?page=${page}&limit=${limi}&mot=${searchTerm}`)
        .then(({ data }) => {
          attestations.value = data.data.data
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
         //
        });
    }
    return { 
      attestations, 
      page, 
      totalPages,
      limit,
      format_Date,
      totalElements,
      openNewWindow,
      handlePaginate, 
    };
  },
});

</script>
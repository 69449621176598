<template>
    <BreadCrumb PageTitle="Ajouter un type de notification" />
    <AddTypeNotificationModal />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddTypeNotificationModal from "../../components/TypeNotification/AddTypeNotificationModal.vue";
  
    export default defineComponent({
      name: "AddTypeNotificationPage",
      components: {
        BreadCrumb,
        AddTypeNotificationModal,
      },
    });
  </script>
<template>

    <div class="card mb-15 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                    Adresse Postale<span class="text-danger">*</span>
                    </label>
                    <Field name="abonnement" v-model="abonnementSelected" v-slot="{ field }">
                        <Multiselect
                        v-model="field.value"
                        v-bind="field"
                        placeholder="Renseigner l'adresse postale ici"
                        :filter-results="false"
                        :min-chars="3"
                        :resolve-on-load="false"
                        :delay="0"
                        :searchable="true"
                        :options-limit="300"
                        :no-options-text=noOptionsTextAbb
                        :options="async (query) => {
                        return await fetchAbonnements(query)
                        }"
                        noResultText= "Aucun enregistrement trouvé"
                        />
                    </Field>
                    <ErrorMessage name="abonnement" class="text-danger"/>
                </div>
            </div>
        
            <div v-if="estAdresseActuelle" class="list-item pt-5 pb-5 pt-md-20 pb-md-5 ps-20 pe-5 position-relative d-sm-flex justify-content-between align-items-center bg-ecf3f2"
            >
                <div class="content">
                    <div class="d-flex align-items-center mb-5">
                    <h6 class="fw-bold mb-0">
                        Adresse actuelle
                    </h6>
                    </div>
                    <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                        Agence {{ abonnementData?.bureau?.adresse }}
                    </span>
                </div>
            </div>

        </div>
      </div>
    </div>

    <div class="row" v-show="datatrue" >
        <div class="col-md-4"> 
            <div class="card mb-25 border-0 pt-2 rounded-2 welcome-lms-courses-box">
                <div class="card-body pe-15 ps-15 letter-spacing">
                    <div class="row align-items-center p-0">
                        <div class="content p-0">
                            <h5 class="fw-bold mb-2 ms-2 mt-2 text-white">ABONNE</h5>
                            <table class="table">
                            <thead>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">NOMS</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementData?.abonne?.nomComplet }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">CATEGORIE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementData?.abonne?.categorieAbonne?.libelle }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">TELEPHONE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementData?.numero }}</span></td>
                                </tr>
                            </thead>
                            </table>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4" v-if="estAbonnementPhysique"> 
            <div class="card mb-25 border-0 pt-0 rounded-2 welcome-lms-courses-box2">
                <div class="card-body pe-15 ps-15 letter-spacing">
                    <div class="row align-items-center p-0">
                        <div class="content p-0">
                            <h5 class="fw-bold mb-2 ms-2 mt-0 text-white">BP PHYSIQUE</h5>
                            <table class="table" >
                            <thead>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">ADRESSE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{  abonnementPhysique?.boite?.adressePostale?.adresse_postale }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">AGENCE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementPhysique?.bureau?.adresse }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">ETAT</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementPhysique?.etat }}</span></td>
                                </tr>
                            </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4" v-if="estAbonnementMpostBox" > 
            <div class="card mb-25 border-0 pt-2 rounded-2 welcome-lms-courses-box">
                <div class="card-body pe-15 ps-15 letter-spacing">
                    <div class="row align-items-center p-0">
                        <div class="content p-0">
                            <h5 class="fw-bold mb-2 ms-2 mt-2 text-white">BP MPOSTBOX</h5>
                            <table class="table" style="background-color: none !important;">
                            <thead>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">ADRESSE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ (abonnementVirtuelle?.adresseVirtuelle) ? abonnementVirtuelle?.adresseVirtuelle : "" }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">AGENCE</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementVirtuelle?.bureau?.adresse }}</span></td>
                                </tr>
                                <tr>
                                    <td> <h6 class=" mb-1 ms-2 text-white fss-7">ETAT</h6></td>
                                    <td><span class="fw-bold text-white fs-6">{{ abonnementVirtuelle?.etat }}</span></td>
                                </tr>
                            </thead>
                            </table>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-25 border-0 rounded-0 bg-white recent-activity-box">
        <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
        <div
            class="mb-15 mb-md-20 mb-xxxl-25 d-flex align-items-center justify-content-between"
        >
            <h5 class="card-title fw-bold mb-0">Historique</h5>
        </div>
        <ul class="list ps-0 mb-0 list-unstyled">
            
            
            <li v-for="(el, index) in historique" :key="index"  class="position-relative fw-medium text-dark-emphasis">
                <div  class="icon position-absolute start-0 rounded-circle text-center">
                    <i class="ph-duotone ph-check-circle"></i>
                </div>
                <span class="text-black fw-bold">{{ el.titre }}</span>  
                <!--<span class="text-black fw-bold">“The Dashboard”</span>
                <span class="d-block fs-13 mt-2">12:00 PM</span>-->
                <p class="fs-md-15 text-paragraph lh-base mb-0 fw-normal mt-5">
                    {{ el.description }}
                </p>
                <span class="d-block fs-13 mt-0">{{ format_date(el.dateOperation) }}</span>
            </li>
            
        </ul>
        <div class="position-relative mt-20">
            <!-- <button
            class="btn btn-outline-primary bg-white btn-sm copy-btn position-absolute mt-10 me-10 top-0 end-0"
            data-clipboard-target="#basicTimelineCode"
            >
            Copy
            </button> -->
        
        </div>
        </div>
        </div>

    </div>

  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref, watch  } from "vue";
  import ApiService from "@/services/ApiService";
  import { format_date, showModal, suppression,separateur, error, } from "../../utils/utils";
  import { Form, Field, ErrorMessage, useForm, useField } from 'vee-validate';
  import { Service } from "@/models/Service";
  import JwtService from "@/services/JwtService";
  import Multiselect from '@vueform/multiselect';
  import axios from 'axios';
import { Abonnement } from "@/models/Abonnement";
import { Abonne } from "@/models/Abonne";
  
  export default defineComponent({
    name: "SuiviAdresse",
    components: {
        Multiselect,
        Field,
    },
    setup: () => {
  
      onMounted(() => {
        // getAllServices();
      });
  
      const services = ref<Array<Service>>([]);
      const idservice = ref(0);
      const abonnementOptions = ref([]);
      const noOptionsTextAbb=ref("Tapez au moins trois caractères");
      const nooptions="Aucun enregistrement trouvé";
      const noResultText="Aucun enregistrement trouvé";
      const abonnementSelected = ref('');
      const abonnementData = ref(null);
      const datatrue = ref(false);
      const estAbonnementPhysique = ref(false);
      const estAbonnementMpostBox = ref(false);
      const estAdresseActuelle = ref(false);
      const historique = ref<{titre: string; description: string;dateOperation: string;}[]>([]);
      const abonnementVirtuelle = ref(null);
      const abonnementPhysique = ref(null);
  
      const fetchAbonnements = async (key) => {  
        try {
            const response = await axios.get(`/abonnements/suivi/${key}`);
            const abonnementsData = response.data.data;
            console.log("abonnementsData", abonnementsData);
            abonnementOptions.value = abonnementsData.map((abonnement) => ({
                value: abonnement.id+'-'+abonnement.abonne.id,
                label: `${abonnement.abonne.nomComplet} - [${abonnement?.boite?.adressePostale?.adresse_postale ?? abonnement?.adresseVirtuelle }] - ${abonnement.bureau.nom}`,
            }));
            if (abonnementOptions.value.length==0) {
                noOptionsTextAbb.value=noResultText;
            }else{
                noOptionsTextAbb.value='Tapez au moins trois caractères';
            }
            return abonnementOptions.value;
        } catch (error) {
            //
        }
      }

      watch(abonnementSelected, async (newValue, oldValue) => {
        
        if(newValue){
            const val = await fetchAbonnement(newValue.split('-')[0]); 
            abonnementData.value = val;
            datatrue.value = true;
        }else{
            console.log("desole", newValue);
            abonnementPhysique.value = null;
            abonnementVirtuelle.value = null;
            estAdresseActuelle.value = false;
            estAbonnementPhysique.value = false;
            datatrue.value = false;
            historique.value = [];
        }


        // if(newValue ==""){
        // }

      });


      // Ici je recherhce un abonnement lié à l'adresse saisie
      const fetchAbonnement = async (key) => {
      if(key){
        try {
            const response = await axios.get('/abonnement/suivi/' + key);
            const abonnementsData = response.data.data;
            estAdresseActuelle.value = true;

            if(abonnementsData.estMPostBox){
                estAbonnementMpostBox.value = true;
                abonnementVirtuelle.value = abonnementsData;

                if(abonnementsData.abonnement){
                    // ici je reccupere l'abonnement physique associé au MPostBox
                    estAbonnementPhysique.value = true;
                    abonnementPhysique.value = abonnementsData.abonnement;

                    const element = {
                        titre: "Création",
                        description: "Abonnement à une boite physique "+abonnementsData?.abonnement?.boite?.adressePostale?.adresse_postale+" au nom de "+abonnementsData?.abonnement?.abonne?.nomComplet,
                        dateOperation: abonnementsData?.abonnement?.createdAt
                    };
                    historique.value.push(element);
                    //traitement des transferts
                    if(abonnementsData?.abonnement?.transferts.length > 0){
                        for(let i =0; i< abonnementsData?.abonnement?.transferts.length; i++ ){
                            const element_ = {
                                titre: "Tranfert de boite",
                                description: "Transfert de la boite "+ abonnementsData?.abonnement?.transferts[i].boite?.adressePostale?.adresse_postale +" de l'agence "+
                                abonnementsData?.abonnement?.transferts[i].ancienneAgence?.adresse +" vers l'agence "+ abonnementsData?.abonnement?.transferts[i].nouvelleAgence?.adresse+
                                "donnant droit à une boite virtuelle"+ abonnementsData?.adresseVirtuelle,
                                dateOperation: abonnementsData?.abonnement?.transferts[i].createdAt
                            }
                            historique.value.push(element_);
                        }
                    }
                    

                }else{
                    // cas ou le mpostbox a ete cree directement
                    const element = {
                        titre: "Création",
                        description: "Abonnement à une boite virtuelle "+abonnementsData?.adresseVirtuelle+" au nom de "+abonnementsData?.abonne?.nomComplet,
                        dateOperation: abonnementsData?.createdAt
                    }
                    historique.value.push(element);
                }

            }else{
                estAbonnementPhysique.value = true;
                abonnementPhysique.value = abonnementsData;
                if(abonnementsData.abonnement){
                    // ici il s'agit d'un abonne physique associé a un autre physique
                    const element = {
                        titre: "Création",
                        description: "Abonnement à une boite physique "+abonnementsData?.abonnement?.boite?.adressePostale?.adresse_postale+" au nom de "+abonnementsData?.abonnement?.abonne?.nomComplet,
                        dateOperation: abonnementsData?.abonnement?.createdAt
                    }
                    historique.value.push(element);
                    //nous verifions l'etat de l'abonnement
                    if(abonnementsData.abonnement.estResilier){
                        const element = {
                            titre: "Cession",
                            description: "Cession de la boite "+abonnementsData?.abonnement?.boite?.adressePostale?.adresse_postale+" de l'abonné "+abonnementsData?.abonnement?.abonne?.nomComplet +" à l'abonne "+ abonnementsData?.abonne?.nomComplet,
                            dateOperation: abonnementsData?.createdAt
                        }
                        historique.value.push(element);
                    }
                }
                if(abonnementsData.abonne){
                    const element = {
                        titre: "Création",
                        description: "Abonnement à une boite physique "+abonnementsData?.boite?.adressePostale?.adresse_postale+" au nom de "+abonnementsData?.abonne?.nomComplet,
                        dateOperation: abonnementsData?.createdAt
                    }
                    historique.value.push(element);
                }
            }

            console.log("test ", abonnementsData.transferts.length);
            if(abonnementsData.transferts > 0){
                for(let i =0; i< abonnementsData.transferts.length; i++ ){
                    const element = {
                        titre: "Tranfert de boite",
                        description: "Transfert de la boite "+ abonnementsData.transferts[i].abonnement?.boite?.adressePostale?.adresse_postale +" de l'agence "+ abonnementsData.transferts[i].ancienneAgence?.adresse +
                        " vers l'agence "+ abonnementsData.transferts[i].nouvelleAgence?.adresse,
                        dateOperation: abonnementsData.transferts[i].createdAt
                    }
                    historique.value.push(element);
                }
            }

            // console.log("yes", abonnementsData.transferts.length);
            console.log("abonnement lié", abonnementsData);
            return abonnementsData;
        } catch (error) {
          //
        }
      }
      };
  
  
      return { 
        suppression,
        services,
        format_date,
        idservice,
        fetchAbonnements,
        fetchAbonnement,
        separateur,
        noResultText,
        noOptionsTextAbb,
        abonnementSelected,
        abonnementData,
        datatrue,
        estAdresseActuelle,
        estAbonnementPhysique,
        estAbonnementMpostBox,
        abonnementVirtuelle,
        abonnementPhysique,
        historique
      };
    },
  
   
  });
  </script>

<style scoped>
    .table{
        background-color: none !important;
        --bs-table-bg: none !important;
    }
    table{
        background-color: none !important;
    }

    .fss-7{
        font-size: 13px !important;
    }

    table thead tr td {
        /* border: 2px solid red; */
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 0px !important;
        background-color: none !important;
    }
</style>
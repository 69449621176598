<template>
    <BreadCrumb PageTitle="Finalisez le transfert" />
    <AddTransfertMpostBox />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddTransfertMpostBox from "../../components/Demandes/DemandesList/AddTransfertMpostBox.vue";
  
    export default defineComponent({
      name: "AddTransfertMpostBoxPage",
      components: {
        BreadCrumb,
        AddTransfertMpostBox,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Créer un abonné" />
    <AddAbonne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddAbonne from "../../components/Abonne/AddAbonne.vue";
  
    export default defineComponent({
      name: "AddAbonnePage",
      components: {
        BreadCrumb,
        AddAbonne,
      },
    });
  </script>
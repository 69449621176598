<template>
  <div
    class="modal fade createNewModal"
    id="AddAdressePostaleModal"
    tabindex="-1"
    ref="addAdressePostaleModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="AdressePostaleForm" @submit="addAdressePostale" :validation-schema="adressePostaleSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse Postale <span class="text-danger">*</span>
                </label>
                <Field name="adresse_postale" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer une adresse"/>
                <ErrorMessage name="adresse_postale" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch,onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import  Swal  from "sweetalert2";
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { AdressePostale } from '@/models/AdressePostale';



export default {
  name: "AddAdressePostaleModal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props:{
    id: {
      type: Number,
      required: true,
      default:0
    },
  },
  emits: ["getAllAdressePostales",'openmodal'],

  setup: (props, { emit }) => {

    const loading = ref<boolean>(false);
    const adressePostaleSchema = Yup.object().shape({
      adresse_postale: Yup.string().required('L\'adresse postale est obligatoire'),
    });


    const adressePostalenew = ref(props.id);
    const adressePostaleForm =  ref<AdressePostale | null>(null);
    const addAdressePostaleModalRef = ref<null | HTMLElement>(null);
    const title = ref('Ajouter une adresse postale');
    const btntext = ref('Ajouter');
    const isupdate=ref(false);
    watch(() => props.id , (newValue) => {   
      if (newValue!=0) {
        getAdressePostale(newValue);
        isupdate.value=true;
      }
      btnTitle();
    });

    const getAdressePostale = async (id: number) => {
      return ApiService.get("/adressePostales/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        // adressePostaleForm.value?.setFieldValue("id",data.data.id);
        // adressePostaleForm.value?.setFieldValue("code",data.data.adresse_postale);
        for (const key in donnees) {
          adressePostaleForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addAdressePostaleModalRef.value);
      })
      .catch(({ response }) => {
          error(response.data.message);
        });
    }

    const btnTitle = async () => {
      if (isupdate.value) {
         title.value = "Modifier l'adresse postale";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une adresse postale";
         btntext.value = "Ajouter";
      }
    }


    const addAdressePostale = async (values,{ resetForm }) => {
      values = values as AdressePostale;
      loading.value = false;
      if(isupdate.value) {
        ApiService.put(`/adressePostales/${values.id}`,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            hideModal(addAdressePostaleModalRef.value);
            isupdate.value=false;
            btnTitle();
            emit("getAllAdressePostales");
          }
        }).catch(({ response }) => {
          error(response.message);
        });
      }else{
        ApiService.post("/adressePostales",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message)
            resetForm();
            hideModal(addAdressePostaleModalRef.value);
            emit("getAllAdressePostales");
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }
    }; 

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isupdate.value=false;
        formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return {title,btntext, adressePostaleSchema, addAdressePostale, adressePostaleForm,addAdressePostaleModalRef,adressePostalenew,
      resetValue };
  },
};
</script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="cessionForm" @submit="addCession" :validation-schema="cessionSchema" :initial-values="cessionForm">
      <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
               Adresse Postale <span class="text-danger">*</span>
              </label>
              <Field name="adressePostale" v-model="adresse" readonly="true" class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse"/>
              <ErrorMessage name="adressePostale" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Catégorie Abonné <span class="text-danger">*</span>
              </label>
              <Field  name="categorieAbonne" v-slot="{ field }">
                <Multiselect 
                  :options="categorieAbonnesOptions" 
                  :searchable="true" 
                  :disabled="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner la catégorie de l'abonné"
                />
              </Field>
              <ErrorMessage name="categorieAbonne" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
              Abonné <span class="text-danger">*</span>
              </label>
              <Field name="abonneold" v-model="abonneOldId" v-slot="{ field }">
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonné"
                  :searchable="true"
                  :options="abonnesOldOptions"
                  noResultText= "Aucun enregistrement trouvé"
                />
              </Field>
              <ErrorMessage name="abonne" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4 d-none">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
              Abonné <span class="text-danger">*</span>
              </label>
              <Field name="abonne" v-model="abonneId" v-slot="{ field }">
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonné"
                  :searchable="true"
                  :options="abonnesOptions"
                  noResultText= "Aucun enregistrement trouvé"
                />
              </Field>
              <ErrorMessage name="abonne" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Demande
              </label>
              <Field name="demande" v-model="demandeId" v-slot="{ field }" readonly="true">
                <Multiselect 
                  :disabled="true"
                  :filter-results="false"
                  :resolve-on-load="false"
                  :searchable="true"
                  :value="demandeId"
                  :options="demandesOptions"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner la demande"
                />
              </Field>
              <ErrorMessage name="demande" class="text-danger"/>
            </div>
          </div>
        
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Loyer mensuel
              </label>
              <Field readonly="true" v-model="loyert" name="loyer" type="text" aria-disabled="true" 
              class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel"/>
                <ErrorMessage name="loyer" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bénéficiaire
              </label>
              <Field v-model="nomCompletBeneficiaire" name="nomCompletBeneficiaire" type="text" readonly="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Montant total l'abonnement"/>
                <ErrorMessage name="nomCompletBeneficiaire" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Créer une cession
              </button>
              <router-link to="/liste-cessions" 
                  class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                  <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted,onBeforeMount,nextTick, reactive  } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRoute, useRouter } from 'vue-router';
import { error, success } from '@/utils/utils';
import { Abonnement } from '@/models/Abonnement';
import { Region } from '@/models/Region';
import { Cession } from '@/models/Cession';
import { OptionModel } from '@/models/Abonne';
import { OptionModelD } from '@/models/Demande';

export default defineComponent({
  name: "AddCession",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},

setup: () => {
    const cessionSchema = Yup.object().shape({
    nomCompletBeneficiaire: Yup.string().required("Le bénéficiaire est obligatoire"),
    abonne: Yup.number().typeError('Veuillez entrer un nombre'),
    abonneold: Yup.number().typeError('Veuillez entrer un nombre'),
    categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre'),
    demande: Yup.number().typeError('Veuillez selectionner une demande'),
    adressePostale: Yup.string().typeError('Veuillez entrer un nombre').required('L\'adresse postale est obligatoire'),
    loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
  });

  const  options =  [
    { value: '', label: 'Selectionnez un type'},
    { value: 'Ordinaire', label: 'Ordinaire' }, 
    { value: 'Officielle', label: 'Officielle'}
 ];

  const adresse = ref("");
  const router = useRouter();
  const regionOptions = ref<Array<Region>>();
  const abonnementForm = ref<Abonnement>();
  const cessionForm = ref<Cession>();
  const categorieAbonnes = ref(null);
  const abonnesOptions = ref<Array<OptionModel>>();
  const abonnesOldOptions = ref<Array<OptionModel>>();
  const boitesOptions = ref<Array<OptionModel>>();
  const adressePostalesOptions = ref<Array<OptionModel>>();
  const categorieAbonnesOptions = ref([]);
  const demandesOptions = ref<Array<OptionModelD>>();
  const bureauxOptions = ref<Array<OptionModel>>();
  const modelesBoitesOptions = ref([]);
  const servicesOptions= ref([]);

  const bureauselect = ref();
  const demandeId = ref();
  const abonneId = ref();
  const abonneOldId = ref();
  const boiteId = ref();
  const modelselect = ref();
  const cateselect = ref();
  const boitesselect=ref();
  const loyert = ref(0);
  const cautionval = ref(0);
  const fraiscleval= ref(5000);
  const nomCompletBeneficiaire = ref("");
  const montanttotalval = ref(0);
  const nombreCle = ref(3);
  const typeBoite = ref("Ordinaire");
  const route = useRoute();
  const agenceVal = ref();
  const ids =  reactive({
    agenceId:"",
    regionId:"",
    boiteId:"",
    demandeId:"",
    adressePostaleId:""
  });

  // const router = useRouter();

   const addCession = async (values, {resetForm}) => {
    ApiService.post("/cessions",values)
      .then(({ data }) => {
        if(data.code == 201) { 
          success(data.message);
          resetForm();
          router.push({ name: "ListeCessionPage" });
        }
      }).catch(({ response }) => {
        error(response.data.message);
      });
  };
  const deman = ref();

  const fetchAdressePostale = async (key) => {
    try {
      const response = await axios.get('/adressePostales/recherche/'+key);
      const adressePostaleData = response.data.data.data;
      adressePostalesOptions.value = adressePostaleData.map((adresse) => ({
        value: adresse.id,
        label: adresse.adresse_postale,
      }));
      return adressePostalesOptions.value;
    } catch (error) {
      //
    }
  };

  const fetchAdressePostale1 = async (key) => {
    try {
      const response = await axios.get('/adressePostales/i/'+key);
      const adressePostaleData = response.data.data.data;
      adressePostalesOptions.value = adressePostaleData.map((adresse) => ({
        value: adresse.id,
        label: adresse.adresse_postale,
      }));
      return adressePostalesOptions.value;
    } catch (error) {
      //
    }
  };

  const fetchAbonnes = async (key) => {
    try {
      const response = await axios.get('/abonnes/recherche/'+key);
      const abonnesData = response.data.data;
      abonnesOptions.value = abonnesData.map((abonne) => ({
        value: abonne.id,
        label: abonne.nomComplet+' [ '+abonne.telephone+' ]',
      }));
      return abonnesOptions.value;
    } catch (error) {
      //
    }
  };

  const fetchRegions = async () => {
    try {
      const response = await axios.get('/communes');
      const regionData = response.data.data.data;
      regionOptions.value = regionData.map((region) => ({
        value: region.id,
        label: region.code+' [ '+region.libelle+' ]',
      }));
      return regionOptions.value;
    } catch (error) {
      //
    }
  };

  const fetchAgences1 = async (key) => {
    try {
      const response = await axios.get(`/communes/${key}`);
      const bureauxData = response.data.data.data;
      bureauxOptions.value = bureauxData.map((com) => ({
        value: com.id,
        label: com.libelle,
      }));
      return  bureauxOptions.value;
    } catch (error) {
      //
    }
  };

  const fetchBoites = async () => {
    if(bureauselect.value &&  modelselect.value) {
      try {
        const response = await axios.get(`/boitePostales/recherche/${bureauselect.value}/${modelselect.value}`);
        const boitesData = response.data.data;
        boitesOptions.value = boitesData.map((boite) => ({
          value: boite.id,
          label: boite.refBoite,
        }));
        return  boitesOptions.value;
      } catch (error) {
        //
      }
    }
  };

  const fetchModeles = async () => {
    try {
      const response = await axios.get('/modele_boites');
      const modelesData = response.data.data.data;
      modelesBoitesOptions.value = modelesData.map((boite) => ({
        value: boite.id,
        label: boite.code+" [ "+ boite.libelle+" ]",
      }));
    } catch (error) {
      //
    }
  };

  const fetchCategorie = async () => {
    try {
      const response = await axios.get('/categorieAbonnes');
      const categorieAbonnesData = response.data.data.data;
      categorieAbonnesOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
        value: categorieAbonne.id,
        label: categorieAbonne.libelle,
      }));
      return categorieAbonnesOptions.value;
    } catch (error) {
      //
    }
  };
 
  const getDemande = async (id:number) =>{
      
   await ApiService.get(`/demandes/${id}`)
        .then(({ data }) => {
           if(data.data.abonnement.boite){
             adresse.value = data.data.abonnement.boite.adressePostale?.adresse_postale;
           }
           deman.value = data.data;
           demandeId.value = data.data.id;
           cessionForm.value?.setFieldValue("abonnement",data.data.abonnement.id);
           cessionForm.value?.setFieldValue("id",data.data.id);
           cessionForm.value?.setFieldValue("categorieAbonne", (data.data.categorieBeneficiaire)? data.data.categorieBeneficiaire.id : data.data.abonne.categorieAbonne.id);
           cessionForm.value?.setFieldValue("loyer",data.data.abonnement.loyer);
           cessionForm.value?.setFieldValue("montant",data.data.abonnement.montant);
           cessionForm.value?.setFieldValue("nomCompletBeneficiaire",(data.data.nomCompletBeneficiaire) ? data.data.nomCompletBeneficiaire :
           data.data.abonne.nomComplet
          );
          // var abonne = data.data.abonne?  [{ value:data.data.abonne.id,label:data.data.abonne.nomComplet} ] : [];
          
          if(data.data.abonne){
            var leBeneficiaire = data.data.abonne;
            abonnesOptions.value = [{
              value: leBeneficiaire.id,
              label: leBeneficiaire.nomComplet
            }];
          }
          console.log("deschanel", data.data);

          var oldAbonne = data.data.abonnement.abonne;
          abonnesOldOptions.value = [{
            value: oldAbonne.id,
            label: oldAbonne.nomComplet
          }];

          abonneId.value =  data.data.abonne ? data.data.abonne.id : null;
          abonneOldId.value = data.data.abonnement.abonne ? data.data.abonnement.abonne.id : null;

          demandesOptions.value = [{
            value: data.data.id,
            label: data.data.refDemande
          }];
          
          return "ok";
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 


  const getLoyerAndBoitBureau= (val) => {
    boitesselect.value="";
    boitesOptions.value=[];
    bureauselect.value=val;
    fetchBoites();
    getLoyer();
  }

  const getLoyerAndBoitModel= (val) => {
    boitesselect.value="";
    boitesOptions.value=[];
    modelselect.value=val;
    fetchBoites();
    getLoyer();
    
  }

  const getLoyerAndBoitCate= (val) => {
    cateselect.value=val;
    fetchBoites();
    getLoyer();
  }
  const getLoyer = async () => {
    if(cateselect.value &&  modelselect.value) {
      try {
        const response = await axios.get(`/tarifAnnuels/${cateselect.value}/${modelselect.value}`);
        const tarifAnnuels = response.data.data;
        loyert.value = tarifAnnuels.cout;
        
      } catch (error) {
        //
      }
    }
  }

  const fetchDemandes1 = async (key) => {
    try {
      const response = await axios.get('/demandes/i/'+key);
    } catch (error) {
      //
    }
  };

  onMounted( async () => {
    await  fetchCategorie();
    await  fetchModeles();
    await  fetchRegions();
    console.log("idabonne", abonneOldId.value);
    console.log("idoldabonne", abonneId.value);
    if(route.params.id) {
      await getDemande(parseInt(route.params.id as string));
    }
  });

  const initializeComponent = async () => {
      if (route.params.id) {
        await getDemande(parseInt(route.params.id as string));
      }
    };
    (async () => {
      await initializeComponent();
    })();

  return {
    cessionForm,
    loyert,
    bureauselect,
    cateselect,
    modelselect, 
    boitesselect,
    montanttotalval,
    cautionval,
    fraiscleval,
    nombreCle,
    demandeId,
    ids,
    getDemande,
    regionOptions,
    getLoyerAndBoitBureau,
    getLoyerAndBoitModel,
    getLoyerAndBoitCate, 
    getLoyer, 
    fetchDemandes1,
    fetchAgences1,
    fetchAdressePostale1,
    typeBoite,
    adresse,
    fetchAdressePostale,  
    options,
    cessionSchema,
    modelesBoitesOptions,
    adressePostalesOptions,
    addCession,
    fetchAbonnes, 
    boiteId,
    abonneId,
    abonneOldId,
    nomCompletBeneficiaire,
    abonnementForm, 
    categorieAbonnes,
    bureauxOptions,
    boitesOptions,
    abonnesOptions,
    abonnesOldOptions,
    categorieAbonnesOptions,
    demandesOptions,
    servicesOptions,
    agenceVal
  };
},
});
</script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="casierForm" @submit="addCasier" :validation-schema="casierSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Etagères <span class="text-danger">*</span>
                </label>
                <Field  name="etagere"  v-slot="{ field }">
                  <Multiselect
                    :options="etagereOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner l'étagère"
                  />
                </Field>  
                <ErrorMessage name="etagere" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Créer un casier
            </button>
            <router-link to="/liste-casiers" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Casier } from '@/models/Casier';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';

export default defineComponent({
    name: "AddCasier",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const casierSchema = Yup.object().shape({
      code: Yup.string().required('Le code est obligatoire').max(5, "Vous ne devez pas dépasser cinq caractères"),
      etagere: Yup.string().required('L\' étagère est obligatoire'),
    });

    const casierForm = ref<Casier | null>(null);
    const etagereOptions = ref([]);
    const router = useRouter();

    const addCasier = async (values, {resetForm}) => {
      ApiService.post("/casiers",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeCasierPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchEtageres = async () => {
      ApiService.get("/etageres")
      .then(({ data }) => {
        const donnees = data.data.data;
        etagereOptions.value = donnees.map((etagere) => {
          return {
            value: etagere.id,
            label: `${etagere.code} - ${etagere.description}`,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      fetchEtageres();
    });

    return { casierSchema, addCasier, casierForm, etagereOptions };
  },
});
</script>
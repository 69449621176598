<template>
    <BreadCrumb PageTitle="Livrer un colis" />
    <DeliverColis />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import DeliverColis from "../../components/Colis/DeliverColis.vue";
  
    export default defineComponent({
      name: "DeliverColisPage",
      components: {
        BreadCrumb,
        DeliverColis,
      },
    });
  </script>
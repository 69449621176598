<template>
    <BreadCrumb PageTitle="Liste des étagères" />
    <ListeEtagere/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeEtagere from "../../components/Etagere/ListeEtagere.vue";

    export default defineComponent({
      name: "ListeEtagerePage",
      components: {
        BreadCrumb,
        ListeEtagere,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Ajouter une cession" />
    <AddCession />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddCession from "../../components/Cession/AddCession.vue";
  
    export default defineComponent({
      name: "AddCessionPage",
      components: {
        BreadCrumb,
        AddCession,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Modifier une redevance" />
    <EditRedevance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditRedevance from "../../components/Redevance/EditRedevance.vue";
  
    export default defineComponent({
      name: "EditRedevancePage",
      components: {
        BreadCrumb,
        EditRedevance,
      },
    });
  </script>
import { format_date, showModal,error,success, suppression } from "../utils/utils";
import Swal from "sweetalert2";
import {openKkiapayWidget,addKkiapayListener,removeKkiapayListener} from "kkiapay";
// import { EventName } from "kkiapay/dist/src/typings";

export class KKIAPAY {
  //static privateKey =  "a7149dafb5f919efba5aed5e3722f1e49fea612d";
 // static privateKey = "d8115fa0652411efbf02478c5adba4b8"; //"ed4abc20b05911ee83820ffb85e01251";
  //static privatekey =  "a7149dafb5f919efba5aed5e3722f1e49fea612d";
  static privateKey = "a7149dafb5f919efba5aed5e3722f1e49fea612d" ;// "bbcb2b106aaf11efa016f944ed967890"; //"ed4abc20b05911ee83820ffb85e01251";
 //  static privateKey = "bbcb2b106aaf11efa016f944ed967890"; //"ed4abc20b05911ee83820ffb85e01251";
  static typeError = "RAS";
  static idTransaction = 0;

  static async successHandler(response: any) {
    this.typeError = "SUCCESS";
    this.idTransaction = response.transactionId;
    return {check:{typeError:this.typeError,idTransaction:response.transactionId}, response};
  }

  static async check (){
    return {typeError:this.typeError,idTransaction:this.idTransaction};
  }

  static async failHandler(response: any) {
    this.typeError = "ECHOUE";
    this.idTransaction = response.transactionId;
    return {check:{typeError:this.typeError,idTransaction:response.transactionId}, response};
  }

  static kkia(montant: number) {
    openKkiapayWidget({
      amount: montant,
      position: "center",
      theme: '#f3c148',
      api_key: this.privateKey, //this.privatekey,
      sandbox: false,
      phone: "",
    });
  }
    
  // static addKkiapayListenerWrapper(eventType: EventName,handler: (response: any) => Promise<any>) {
  //   addKkiapayListener(eventType, handler);
  // }

}

<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonneForm" @submit="addTransfert" :validation-schema="transfertSchema">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Demande
              </label>
              <Field name="demande" v-slot="{ field }">
                <Multiselect 
                  :options="demandesOptions" 
                  :searchable="true" 
                  v-model="field.value"
                  v-bind="field"
                  @change="onChange"
                  placeholder="Sélectionner la demande"
                />
              </Field>
              <ErrorMessage name="demande" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Abonnement <span class="text-danger">*</span>
                </label>
                <Field name="bureau" v-slot="{ field }">
                  <Multiselect 
                    :options="bureauxOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'agence"
                  />
                </Field>
                <ErrorMessage name="bureau" class="text-danger"/>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Model de Boite <span class="text-danger">*</span>
                </label>
                <Field name="modeleBoite" v-slot="{ field }">
                  <Multiselect 
                    :options="modelesBoitesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le modèle de la boîte"
                  />
                </Field>
                <ErrorMessage name="modeleBoite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                 Adresse Postale <span class="text-danger">*</span>
                </label>
                <Field name="adressePostale" v-slot="{ field }">
                  <Multiselect 
                    :options="adressePostalesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'adresse postale"
                  />
                </Field>
                <ErrorMessage name="adressePostale" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Boite <span class="text-danger">*</span>
                </label>
                <Field name="boite" v-slot="{ field }">
                  <Multiselect 
                    :options="boitesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la boîte"
                  />
                </Field>
                <ErrorMessage name="boite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Type de boite <span class="text-danger">*</span>
                </label>
                <Field name="typeBoite" v-slot="{ field }">
                  <Multiselect 
                    :options="options" 
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type de boîte"
                  />
                </Field>
                <ErrorMessage name="typeBoite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Ancienne Agence <span class="text-danger">*</span>
                </label>
                <Field name="ancienneAgence" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'abonné"
                  />
                </Field>
                <ErrorMessage name="ancienneAgence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Nouvelle Agence <span class="text-danger">*</span>
                </label> 
                <Field name="nouvelleAgence" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnesOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'abonné"
                  />
                </Field>
                <ErrorMessage name="nouvelleAgence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                 Nombre de clé
                </label>
                <Field name="nombreCle" type="text" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Sélectionner le nombre de clé"/>
                <ErrorMessage name="nombreCle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Créer un transfert
              </button>
              <router-link to="/liste-transferts" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler
              </router-link>
            </div>
          </div>
          </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup' ;
import axios from 'axios';
import { Abonnement } from '@/models/Abonnement';
import ApiService from '../../services/ApiService';
import Swal from 'sweetalert2';
import { hideModal } from '@/utils/utils';
import { useRouter } from 'vue-router';


export default defineComponent({
    name: "AddTransfert",
    components: {
    Form,
     Field,
     ErrorMessage,
     Multiselect,
  },
  data() {
  return {
    
  };
},
  setup: (props, { emit }) => {
    const transfertSchema = Yup.object().shape({
      adressePostale: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      nouvelleAgence: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      ancienneAgence: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      demande: Yup.number().typeError('Veuillez selectionner une demande'),
      nombreCle:Yup.number().typeError('Veuillez entrer un nombre').max(5).required(`Le nombre de clé est obligatoire`),
      boite: Yup.number().typeError('Veuillez entrer un nombre').required('La boîte postale est obligatoire'),
      typeBoite:Yup.string().typeError('Veuillez entrer une chaine de caractère').required(`Le type de boite est obligatoire`),
      // categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie est obligatoire'),
      // abonne: Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
      // demande: Yup.number().typeError('Veuillez selectionner une demande'),
      // adressePostale: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      // bureau: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      // boite: Yup.number().typeError('Veuillez entrer un nombre').required('La boite postale est obligatoire'),
      // loyer:Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
      // caution:Yup.number().typeError('Veuillez entrer un caution').required(`La caution est obligatoire`),
      // montant:Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant est obligatoire`),
      // nombreCle:Yup.number().typeError('Veuillez entrer un nombre').max(5).required(`Le nombre de clé est obligatoire`),
      // typeBoite:Yup.string().typeError('Veuillez entrer une chaine de caractère').required(`Le type de boite est obligatoire`),
    });

    onMounted(() => {
      fetchAbonnes();
      fetchBoites();
      fetchDemandes();
      fetchAgences();
      fetchCategorie()
      fetchModeles();
      fetchAdressePostale();
    });
    const  options =  [
    { value: '', label: 'Selectionnez un type' }  ,
    { value: 'Ordinaire', label: 'Ordinaire' } , 
    { value: 'Spécifique', label: 'Spécifique' }
   ];
    const router = useRouter();
    const abonnementForm = ref(null);
    const categorieAbonnes = ref(null);
    const abonnesOptions = ref([]);
    const nouvellesAgencesOptions = ref([]);
    const AnciennesAgencesOptions = ref([]);
    const boitesOptions = ref([]);
    const adressePostalesOptions = ref([]);
    const categorieAbonnesOptions = ref([]);
    const demandesOptions = ref([]);
    const bureauxOptions = ref([]);
    const modelesBoitesOptions = ref([]);
    const  success = (message) => {
      Swal.fire({
        title: 'Succès',
        text: message,
        icon: "success",
        toast: true,
        timer: 5000,
        position: 'top-right',
        showConfirmButton: false,
      });
    }


    const error = (message) => {
      Swal.fire({
        title: 'Erreur',
        text: message,
        icon: "error",
        toast: true,
        timer: 5000,
        position: 'top-right',
        showConfirmButton: false
      });
    }

     const addTransfert = async (values, {resetForm}) => {
      ApiService.post("/transferts",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            
            emit('getalltransfert');
            
            // router.push({ name: "ListeTarnsfertPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };



    const fetchAdressePostale = async () => {
      try {
        const response = await axios.get('/adressePostales');
        const adressePostaleData = response.data.data;
        adressePostalesOptions.value = adressePostaleData.map((adresse) => ({
          value: adresse.id,
          label: adresse.adresse_postale,
        }));
      } catch (error) {
        //
      }
    };
    const fetchAbonnes = async () => {
      try {
        const response = await axios.get('/abonnes');
        const abonnesData = response.data.data;
        abonnesOptions.value = abonnesData.map((abonne) => ({
          value: abonne.id,
          label: abonne.nomComplet,
        }));
      } catch (error) {
        //
      }
    };
    const fetchAgences = async () => {
      try {
        const response = await axios.get('/bureaus');
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
      } catch (error) {
        //
      }
    };

    const fetchBoites = async () => {
      try {
        const response = await axios.get('/boitePostales');
        const boitesData = response.data.data;
        boitesOptions.value = boitesData.map((boite) => ({
          value: boite.id,
          label: boite.refBoite,
        }));
      } catch (error) {
        //
      }
    };

    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_boites');
        const modelesData = response.data.data;
        modelesBoitesOptions.value = modelesData.map((boite) => ({
          value: boite.id,
          label: boite.code,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCategorie = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data;
        categorieAbonnesOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };
    const fetchDemandes = async () => {
      try {
        const response = await axios.get(`/demandes/recherche/statut/2/${"En attente"}`);
        const demandesData = response.data.data;
        demandesOptions.value = demandesData.map((demande) => ({
          value: ref(demande.id),
          label: ref(demande.refDemande),
        }));
      } catch (error) {
        //
      }
    };


    return {options,transfertSchema,modelesBoitesOptions,adressePostalesOptions,addTransfert, abonnementForm, categorieAbonnes,bureauxOptions,boitesOptions,abonnesOptions,categorieAbonnesOptions,demandesOptions};
  },
  methods: {
        onChange() {
            //
        }
    }
});
</script>


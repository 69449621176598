<template>
  <div
    class="modal fade createNewModal"
    id="AddAbonneModal"
    tabindex="-1"
    aria-hidden="true"
    ref="addAbonneModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">Ajouter un abonné</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="abonneForm" @submit="addAbonne" :validation-schema="abonneSchema">
        <div class="row">
          <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie d'abonné <span class="text-danger">*</span>
                </label>
                <Field name="categorieAbonne" v-model="categorieAbonneId" v-slot="{ field }">
                  <Multiselect 
                    :options="categorieAbonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la catégorie de l'abonné"
                    @select="handleObjetInput"
                  />
                </Field>
                <ErrorMessage name="categorieAbonne" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  {{ label1 }} <span class="text-danger">*</span>
                </label>
                <Field name="nomComplet" type="text" 
                class="form-control shadow-none fs-md-15 text-black" :placeholder="placeholder1"/>
                <ErrorMessage name="nomComplet" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email
               <span style="float: right;" @click="sendOtp(true)" class="align-items-center">
                <i class="flaticon-send lh-1 me-8 position-relative top-1"></i>Vérifier le mail 
              </span>  
              </label>
              <Field name="email" type="email" v-model="email"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l' email"/>
                <ErrorMessage name="email" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Téléphone <span class="text-danger">*</span>
              </label>
              <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Entrer le numéro de téléphone"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
              <ErrorMessage name="telephone" class="text-danger"/>
              <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-4" v-if="showAdditionalFields">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Sexe <span class="text-danger">*</span>
              </label>
              <Field  name="sexe"  type="text"  v-slot="{ field }">
                <Multiselect
                  :searchable = "true"
                  :options = "['Masculin', 'Féminin']"
                  v-model = "field.value"
                  v-bind = "field"
                  placeholder="Sélectionner le sexe"
                />
              </Field>
                <ErrorMessage name="sexe" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Pays de résidence <span class="text-danger">*</span>
                </label>
                <Field  name="nationalite"  v-slot="{ field }" v-model="defaultCountriy">
                  <Multiselect
                    :options="countries"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le pays de résidence"
                  />
                </Field>  
                <ErrorMessage name="nationalite" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Secteur d'activité <span class="text-danger">*</span>
                </label>
                <Field  name="activite"  v-slot="{ field }">
                  <Multiselect
                    :options="secteurActiviteOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le secteur d'activité"
                  />
                </Field>
                <ErrorMessage name="activite" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone"
              >
                  Créer un abonné
              </button>
            </div>
          </div>
        </div>
      </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { error, success, hideModal } from '@/utils/utils';
import { countries } from '../Abonne/countries';
import { Abonne, CategorieAbonne } from '@/models/Abonne';
import { useRouter } from 'vue-router';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default ({
    name: "AddAbonneModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {

    const abonneSchema = Yup.object().shape({
      nomComplet: Yup.string().required('Le nom et le prénom sont obligatoires'),
      nationalite: Yup.string().required('Le pays de résidence est obligatoire'),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
      dateNaissance: Yup.date().notRequired(),
      email: Yup.string().email("Veuillez renseigner un mail valide").notRequired(),
      //password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
      sexe: Yup.string().notRequired().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le sexe est obligatoire")
          : schema
      }),
      profession: Yup.string().notRequired(),
      adresseResidence: Yup.string().notRequired(),
      villeResidence: Yup.string().notRequired(),
      departementResidence: Yup.string().notRequired(),
      categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonné est obligatoire'),
      activite: Yup.number().typeError('Veuillez entrer un nombre').required('Le secteur d\'activité est obligatoire'),
    });

    const abonneForm = ref<Abonne | null>(null);
    const categorieAbonnes = ref(null);
    const addAbonneModalRef = ref<null | HTMLElement>(null);
    const abonnesOptions = ref<{ value: any; label: string }[]>([]);
    const categorieAbonneOptions = ref<CategorieAbonne[]>([]);
    const secteurActivites = ref(null);
    const secteurActiviteOptions = ref([]);
    const router = useRouter();
    const countriesRef = ref(countries);
    const codepay= ref();
    const nationalnumlber= ref();
    const telephone=ref();
    const email=ref();
    const validPhone=ref<boolean>(false);
    const defaultCountriy = 'Bénin';
    const  numeroNational = ref();

    const showAdditionalFields = ref(true);
    const handleObjetInput = (selectedValue) => {
      showAdditionalFields.value = selectedValue === 1;
    }
    const categorieAbonneId = ref(1);

    const label1 = computed(() => {
      return showAdditionalFields.value ? 'Nom et prénom' : 'Dénomination';
    });

    const placeholder1 = computed(() => {
      return showAdditionalFields.value ? 'Entrer le nom et prénom' : 'Entrer la dénomination';
    });

    const label2 = computed(() => {
      return showAdditionalFields.value ? 'Date de naissance' : 'Date de création';
    });

    const placeholder2 = computed(() => {
      return showAdditionalFields.value ? 'Choisisser la date de naissance' : 'Choisisser la date de création';
    });

    function validate(phoneObject) {
      validPhone.value=phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value= phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value= phoneObject.nationalNumber;
        numeroNational.value = phoneObject.nationalNumber;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
      }
    }
    
    onMounted(() => {
      fetchCategorieAbonnes();
      fetchSecteurActivites();
    });

    const addAbonne = async (values, { resetForm }) => {
      values["numeroNational"] = numeroNational.value;
    ApiService.post("/abonnes", values)
      .then(({ data }) => {
        if (data.code == 201) {
          success(data.message);
          resetForm();
          hideModal(addAbonneModalRef.value);
          emit('close');
          emit('getabonne',data.data);
        }
      }).catch(({ response }) => {
        error(response.data.message);
      });
  };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchSecteurActivites = async () => {
      try {
        const response = await axios.get('/secteurActivites');
        const secteurActivitesData = response.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: secteurActivite.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
      formFields.forEach(field => {
        field.value = '';
      });
    };

    const sendOtp = async (i) => {
    const dataToSend = {
      telephone: telephone.value,
      email: email.value,
      estEmail: i,
      };
      console.log('data', dataToSend)
      ApiService.post("/abonnements/verify/abonne/number",dataToSend)
        .then(({ data }) => {
          console.log(data,"data.codedata.code");
          if(data.code == 200) { 
            success(data.message);
          }
        }).catch(({ response }) => {
          console.log("Mauvais",response);
          error(response.data.message);
        });
    };

    return { 
      abonneSchema,
      addAbonne,
      abonneForm, 
      categorieAbonnes, 
      categorieAbonneOptions, 
      secteurActivites, 
      secteurActiviteOptions, 
      countries: countriesRef, 
      telephone,
      validPhone,
      validate,
     // onInput,
      handleObjetInput, 
      showAdditionalFields,
      label1,
      label2,
      placeholder1,
      placeholder2,
      addAbonneModalRef,
      categorieAbonneId,
      defaultCountriy,
      numeroNational,
      resetValue,
      sendOtp,
      email,
      };
  }
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
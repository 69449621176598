<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing z-0 ">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link v-if="checkPermission('AddMpostBox')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-mpostbox"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Faire un abonnement mpostbox
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un abonnement mpostbox"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">

      <!-- ################################################################### -->
      <!-- ################################################################### -->
      <div class="col-md-12">
      <div class="row">
        <!-- <form class="row" @submit.prevent="rechercher"> -->
          
          <!-- <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
               Mot clé
              </label>
              <Field 
                style="max-height: 42px;"
                v-model="searchTerm"
                @keyup="rechercher" 
                name="mot" 
                type="text" 
                aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" 
                placeholder="Recherche par mot clé"/>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modele de boîte <span class="text-danger">*</span>
              </label>
              <Field v-model="_modeleBoite"   name="modeleBoite" v-slot="{ field }">
                <Multiselect
                  :options="modeleOptions" 
                  :searchable="true"
                  v-bind="field"
                  placeholder="Sélectionner un modele"
                />
              </Field>
              <ErrorMessage name="modeleBoite" class="text-danger"/>
            </div>
          </div>

          <div class="col">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Catégorie Abonné <span class="text-danger">*</span>
              </label>
              <Field v-model="_categorieAbonne" name="categorieAbonne" v-slot="{ field }">
                <Multiselect
                  :options="categorieAbonneOptions" 
                  :searchable="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner le type de boîte"
                />
              </Field>
              <ErrorMessage name="categorieAbonne" class="text-danger"/>
            </div>
          </div>

          <div class="col" v-if="!etatAgence">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau <span class="text-danger">*</span>
              </label>
              <Field v-model="_bureau" name="bureau" v-slot="{ field }">
                <Multiselect
                  :options="bureauOptions" 
                  :searchable="true"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionner un bureau"
                />
              </Field>
              <ErrorMessage name="bureau" class="text-danger"/>
            </div>
          </div> -->

        <!-- </form> -->
      </div>
      </div>
      <!-- #################################################################################################"" -->
      <!-- #################################################################################################"" -->
      
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Référence
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Adresse virtuelle
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ABONNE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Numéro
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                loyer
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Bureau
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Etat
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Date
              </th>
              <th scope="col"  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(abonne, index) in abonnements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ abonne.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ abonne.adresseVirtuelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.abonne.nomComplet }}
              </td>
             
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.numero }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ separateur(abonne.loyer) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ abonne.bureau?.nom }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span class="badge text-outline-primary">{{ abonne.etat }}</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(abonne.createdAt)  }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0 z-0"
              >
              <div class="dropdown z-3">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('ViewAbonnement')">
                      <router-link :to="{ name: 'ViewAbonnementPage', params: { id: abonne.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i>Détails</router-link>
                    </li>
                    <li v-if="abonne.etat == 'En attente' && checkPermission('ValidAbonnement')">
                          <a
                            class="dropdown-item d-flex align-items-center"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#ValideAbonnementModal"
                            @click="openValidAbonnementModal(abonne)"
                          >
                          <i class="flaticon-check lh-1 me-8 position-relative top-1"></i>
                            Valider l'abonnement
                          </a>
                        </li>
                    <!-- <li v-if="checkPermission('DeleteAbonnementMpostbox')">
                      <a @click="suppression(abonne.id,abonnements,'abonnements','abonnment')"
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" >
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li> -->
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center z-1">
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>

  <div
    class="modal fade createNewModal"
    id="ValideAbonnementModal"
    tabindex="-1"
    ref="addValidAbonnementModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40" style="width:550px;">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">
        </h5>
        </div>

        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <div class="text-center">
          <h5>Vous êtes sur le point de valider l'abonnement: </h5>
          <h5>{{ selectedAbonnement?.refAbo }} de {{ selectedAbonnement?.abonne?.nomComplet }}</h5>
          <br>
            <button
            class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-primary"
            type="submit"
            @click="valideAbonnement()"
            >
                Validez
            </button>
            <router-link to="#" 
            data-bs-dismiss="modal"
                class="btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>

</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnements } from "@/models/Abonnement";
import { format_date, suppression,separateur, error, hideModal,success } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeMpostBox",
    components: {
        PaginationComponent
  },
  setup: () => {
    onMounted(() => {
      getAllAbonnements();
    });

    const abonnements = ref<Array<Abonnements>>([]);


    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllAbonnements(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllAbonnements(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllAbonnements(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/mpostbox?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          (data);
          abonnements.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          //error(response.data.message)
      });
    }

    const addValidAbonnementModalRef = ref<null | HTMLElement>(null);
    const abonnement = ref<Abonnements>();

    const valideAbonnement = () => {
    const dataToSend = {
      id : selectedAbonnement?.value?.id.toString()      };
      ApiService.put("/valide/abonnements/"+ selectedAbonnement?.value?.id.toString(), dataToSend)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            hideModal(addValidAbonnementModalRef.value);
            getAllAbonnements();
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const selectedAbonnement = ref<Abonnements | undefined>(undefined);

    const openValidAbonnementModal = (abonne: Abonnements) => {
      selectedAbonnement.value = { ...abonne };
    };
    
    const privileges = ref<Array<string>>(JwtService.getPrivilege());

  const checkPermission = (name) => {
    return privileges.value.includes(name);
  }

    return { abonnements,
      checkPermission,
      format_date,
      suppression,
      getAllAbonnements,
      page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
      separateur,
      searchTerm,
      valideAbonnement,
      selectedAbonnement,
      abonnement,
      openValidAbonnementModal,
      addValidAbonnementModalRef
     };
  },
});
</script>
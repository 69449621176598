<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="casierForm" @submit="editCasier" :validation-schema="casierSchema" :initial-values="casierForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Référence <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Etagère <span class="text-danger">*</span>
                </label>
                <Field  name="etagere"  v-slot="{ field }">
                  <Multiselect
                    :options="etagereOptions"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                  />
                </Field>  
                <ErrorMessage name="etagere" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Etat</label>
                <div style="display: flex;">
                  <div style="margin-right: 10px;">
                    <label>
                      <Field type="radio" name="estVide"  :value="true"/>
                      Vide
                    </label>
                  </div>
                  <div>
                    <label>
                      <Field type="radio" name="estVide"  :value="false"/>
                      Non vide
                    </label>
                  </div>
                </div>
                <ErrorMessage name="estVide" class="text-danger"/>
              </div>
            </div> -->
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier un casier
            </button>
            <router-link to="/liste-casiers" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { error, success } from '@/utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Casier } from '@/models/Casier';

export default defineComponent({
    name: "EditCasier",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const casierSchema = Yup.object().shape({
      code: Yup.string().required('Le code est obligatoire').max(5, "Vous ne devez pas dépasser cinq caractères"),
      //estVide: Yup.boolean().notRequired(),
      etagere: Yup.string().required('L\'étagère est obligatoire'),
    });

    const casierForm = ref<Casier>();
    const etagereOptions = ref([]);
    const router = useRouter();
    const route = useRoute();

    function getCasier(id:number) {
      ApiService.get("/casiers/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            casierForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editCasier = async (values, {resetForm}) => {
      ApiService.put("/casiers/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeCasierPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    const fetchEtageres = async () => {
      ApiService.get("/etageres")
      .then(({ data }) => {
        const donnees = data.data.data;
        etagereOptions.value = donnees.map((etagere) => {
          return {
            value: etagere.id,
            label: `${etagere.code} - ${etagere.description}`,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      if(route.params.id) {
        getCasier(parseInt(route.params.id as string));
      }
      fetchEtageres();
    });

    return { casierSchema, editCasier, casierForm, etagereOptions };
  },
});
</script>
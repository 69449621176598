<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-redevance" v-if="checkPermission('AddRedevance')"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Faire une redevance
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une redevance"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >

        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                REFERENCE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ANNEE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNEMENT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                LOYER
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ARRIERES
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE DE REDEVANCE
              </th>
              
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >ACTION</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(re , index) in redevances" :key="index" >
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.refRedevance }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.annee }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.abonnement ? re.abonnement.refAbo : '' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.abonnement.abonne ? re.abonnement.abonne.nomComplet : '' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{separateur(re.montant) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(re.arrieres)  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(re.dateRedevance) }}
              </td>
              <td  class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0">
                <div class="dropdown">
                    <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                        <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                    </span>
                    <ul class="dropdown-menu">
                      <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="openNewWindow(re)"
                        v-if="re.status !='Payée' "
                      >
                        <i
                          class="flaticon-money lh-1 me-8 position-relative top-1"
                        ></i>
                        Aperçu Redevance
                      </a>
                    </li>
                    <!-- <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Détails
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="suppression(re.id, redevances, 'redevances', ' la redevance')"
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Supprimer
                      </a>
                    </li>-->
                    </ul>
                </div>
              </td> 
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
       
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Paiement } from "@/models/Paiement";
import { Redevance } from "@/models/Redevance";
import { defineComponent, onMounted, ref,reactive,toRef, watch } from "vue";
import AddBoitePostale from "./AddBoitePostale.vue";
import ApiService from "@/services/ApiService";
import { format_date, showModal,error,success, suppression, separateur,openAndPrint,getUrlApiForFiles } from "../../utils/utils";
import { useRouter } from 'vue-router';
import {openKkiapayWidget,addKkiapayListener,removeKkiapayListener} from "kkiapay";
import {KKIAPAY} from  '@/models/KKIAPAY'
import Swal from "sweetalert2";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "ListeRedevance",
  components: {
    PaginationComponent
  },
  setup() {
    
    const redevanceForm = ref<null | Redevance>(null);
    const router = useRouter();
    const redevances = ref<Array<Redevance>>([]);
    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllRedevance(page_, limit_);
      } catch (error) {
        //
      }
    };
    function rechercher(){
      getAllRedevance(page.value, limit.value, searchTerm.value );
    }

    function openNewWindow(data:any) {
      openAndPrint(getUrlApiForFiles("Redevance",data.refRedevance + ".pdf"));
    }
    // END PAGINATE

      async function chargement (donnee:any, infoTransaction:any){
        var paiement = {};
        paiement = {
          abonnement: donnee.abonnement.id,
          redevance: donnee.id,
          modePaiement: 1,
          montantRecu: donnee.abonnement.id,
          montantPaye: donnee.abonnement.montant,
          montantRestant: 0,
          transactionId: infoTransaction
        };
          if( infoTransaction.typeError === "ECHOUE"){
           await ApiService.post("/paiement_redevance",paiement)
              .then(({ data }) => {
                if(data.code == 201) { 
                  success(data.message)
                  KKIAPAY.typeError = "RAS";
                  KKIAPAY.idTransaction = 0;
                }
              }).catch(({ response }) => {
                error("Oups il y a eu une erreur")
                KKIAPAY.typeError = "RAS";
                KKIAPAY.idTransaction = 0;
              });
            error("Oups il y a eu une erreur")
            }else{
              if( check.typeError === "SUCCESS"){
                await ApiService.post("/paiement_redevance",paiement)
                .then(({ data }) => {
                  if(data.code == 201) { 
                    success(data.message)
                    KKIAPAY.typeError = "RAS";
                    KKIAPAY.idTransaction = 0;
                    getAllRedevance();
                  }
                }).catch(({ response }) => {
                  error("Oups il y a eu une erreur")
                  KKIAPAY.typeError = "RAS";
                  KKIAPAY.idTransaction = 0;
                });
            }
          }
      }

      async function successHandler(response, values) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await chargement(values,check.typeError);
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }


      const payer = async (redev) => {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + redev.abonnement.montant + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          if (result.isConfirmed) {
            KKIAPAY.kkia(redev.abonnement.montant);
            await new Promise<void>((resolve) => {
              addKkiapayListener('failed', (event) => {
                failHandler(event);
                resolve();
              });

              addKkiapayListener('success', (event) => {
                successHandler(event, redev);
                resolve();
              });

              const checkRef = toRef(check);

              let resolved = false;

              const intervalId = setInterval(() => {
                if (!resolved && checkRef.value.typeError !== "RAS") {
                  clearInterval(intervalId);
                  resolved = true;
                  resolve();
                }
              }, 500);
            });
          }

        } catch (error) {
          //
        }
      };

      const getAllRedevance = async (page = 1, limi = 10, searchTerm = '') => {
        return ApiService.get(`/redev/non?page=${page}&limit=${limi}&mot=${searchTerm}&`)
          .then((response) => {
            if(response.status === 200){
              redevances.value = response.data.data.data;
              totalPages.value = response.data.data.totalPages;
              limit.value = response.data.data.limit;
              totalElements.value = response.data.data.totalElements;
            }
          })
          .catch((reponse) => {
            error(reponse.data.message);
          });
      };

      const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }
    

    onMounted(() => {
      getAllRedevance();
    });

    return { KKIAPAY,
    chargement,
    redevances,
    redevanceForm,
    router,
    payer,
    format_date,
    suppression,
    page, 
    totalPages,
    limit,
    openNewWindow,
    totalElements,
    handlePaginate,
    searchTerm,
    separateur,
    rechercher,
    checkPermission,
    };
  },
});
</script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <!----><a v-if="checkPermission('AddConfiguration')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#AddParametreModal"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
         Ajouter une configuration
        </a>
        <!---->
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une configuration"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>

    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Fichier
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                CLE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                VALEUR
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Description
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mp, index) in parametres" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                <!-- <iframe :src="getUrlApiForFiles('parametres',mp.fichier)" frameborder="0" width="50px" height="50px"></iframe> -->
                <button v-if="mp.fichier" @click="openPdfNewWindow(mp.fichier)" class="btn btn-primary">Voir le fichier</button>
                <p v-else>Aucun fichier</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ mp.cle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ mp.valeur }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ mp.designation }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="editParametre(mp.id)"
                      >
                        <i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="suppression(mp.id, parametres, 'Parametres', 'un parametre')"
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
  <AddParametreModal :item="selectedItem"  @close="recharger" @openmodal="show_Modal" />
</template>


<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AddParametreModal from "./AddParametreModal.vue";
import { Parametre } from "../../models/Parametre";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression, error, getUrlApiForFiles, openAndPrint } from "../../utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeParametre",
    components: {
      AddParametreModal,
      PaginationComponent
    },
  setup(){
    
    const parametres = ref<Array<Parametre>>([]);
    const selectedItem = ref(0);
    const Parametrenew = ref<Parametre | null>(null);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllParametres(page_, limit_);
      } catch (error) {
        //
      }
    };
    function rechercher(){
      getAllParametres(page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE
    
    const recharger = () => {
      getAllParametres();
    };


    const show_Modal = (el) => {
      showModal(el);
      selectedItem.value = 0;
    };

    const editParametre = (id: number) => {
      selectedItem.value = id;
    };

    const fichier= ref("")
    function getAllParametres(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/parametres?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        parametres.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
        fichier.value = data.data.data.fichier;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }
    const pdfUrl = ref('');

    const openPdfNewWindow = (pdfFileName) => {
      pdfUrl.value = getUrlApiForFiles('parametres', pdfFileName);
      console.log(pdfUrl.value);
      window.open(pdfUrl.value, '_blank');
    };

    onMounted(() => {
      getAllParametres();
    });

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

const checkPermission = (name) => {
  return privileges.value.includes(name);
}

    return { parametres,
      checkPermission,
     format_date,
     recharger,
     show_Modal,
     editParametre,
     selectedItem ,
     suppression,
     page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher,
      getUrlApiForFiles,
      fichier,openPdfNewWindow,
  };
  },
});
</script>
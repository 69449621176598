<template>
    <BreadCrumb PageTitle="Liste des quartiers" />
    <ListeQuartier/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeQuartier from "../../components/Quartier/ListeQuartier.vue";

    export default defineComponent({
      name: "ListeQuartierPage",
      components: {
        BreadCrumb,
        ListeQuartier,
      },
    });
  </script>
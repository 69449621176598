<template>
    <BreadCrumb PageTitle="Modifier un abonnement" />
    <EditAbonnement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditAbonnement from "../../components/Abonnement/EditAbonnement.vue";
  
    export default defineComponent({
      name: "EditAbonnementPage",
      components: {
        BreadCrumb,
        EditAbonnement,
      },
    });
  </script>
<template>
  <BreadCrumb PageTitle="Détails de la demande" />
  <ViewDemande />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewDemande from "../../components/Demandes/DemandesList/ViewDemande.vue";

  export default defineComponent({
    name: "ViewDemandePage",
    components: {
      BreadCrumb,
      ViewDemande,
    },
  });
</script>
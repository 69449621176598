<template>
  <div
    class="modal fade createNewModal"
    id="AddRegionModal"
    tabindex="-1"
    ref="addRegionModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="regionForm" @submit="addRegion" :validation-schema="regionSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libelle <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          @click="resetValue()"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { Region } from '@/models/Region';


export default {
  name: "AddRegionModal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props:{
    id: {
      type: Number,
      required: true,
      default:0
    },
  },
  emits: ["getAllRegions",'openmodal'],

  setup: (props, { emit }) => {

    const loading = ref<boolean>(false);
    const regionSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      code: Yup.string().max(5, 'Vous ne devez pas dépasser cinq caractères').required('Le code est obligatoire'),
    });


    const regionnew = ref(props.id);
    const regionForm =  ref<Region | null>(null);
    const addRegionModalRef = ref<null | HTMLElement>(null);
    const title = ref('Ajouter une région');
    const btntext = ref('Ajouter');
    const isupdate=ref(false);
    watch(() => props.id , (newValue) => {   
      if (newValue!=0) {
        getRegion(newValue);
        isupdate.value=true;
      }
      btnTitle();
    });

    const getRegion = async (id: number) => {
      return ApiService.get("/regions/"+id)
      .then(({ data }) => {
        regionForm.value?.setFieldValue("id",data.data.id);
        regionForm.value?.setFieldValue("code",data.data.code);
        regionForm.value?.setFieldValue("libelle",data.data.libelle);
        emit('openmodal', addRegionModalRef.value);
      })
      .catch(({ response }) => {
        error(response.data.msg)
      });
    }

    const btnTitle = async () => {
      if (isupdate.value) {
         title.value = "Modifier la région";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une région";
         btntext.value = "Ajouter";
      }
    }

    const addRegion = async (values,{ resetForm }) => {
      values = values as Region;
      loading.value = false;
      if(isupdate.value) {
        ApiService.put(`/regions/${values.id}`,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            hideModal(addRegionModalRef.value);
            isupdate.value=false;
            btnTitle();
            emit("getAllRegions");
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }else{
        ApiService.post("/regions",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message)
            resetForm();
            hideModal(addRegionModalRef.value);
            emit("getAllRegions");
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }
    }; 

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
      isupdate.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return {title,btntext, regionSchema, addRegion, regionForm,addRegionModalRef,regionnew, resetValue };
  },
};
</script>
<template>
  <BreadCrumb PageTitle="Liste des demandes" />
  <DemandesList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import DemandesList from "../../components/Demandes/DemandesList/DemandesList.vue";


export default defineComponent({
  name: "DemandesListPage",
  components: {
    BreadCrumb,
    DemandesList,
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Ajouter un transfert" />
    <AddTransfert />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddTransfert from "../../components/Transfert/AddTransfert.vue";
  
    export default defineComponent({
      name: "AddTransfertPage",
      components: {
        BreadCrumb,
        AddTransfert,
      },
    });
  </script>
<template>
  <div
    class="modal fade createNewModal"
    id="AddModeleNotificationModal"
    tabindex="-1"
    aria-hidden="true"
    ref="addModeleNotificationModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{title}}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="modeleNotificationForm" @submit="addModeleNotification" :validation-schema="modeleNotificationSchema">
          <div class="row">
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Canal
                </label>
                <Field name="canal" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le canal"/>
                <ErrorMessage name="canal" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  CODE
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div> -->
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libellé
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div> -->
          <!-- <div class="col-md-12">
              <br>
              <div class="form-check mt-3 mb-15 mb-sm-20 mb-md-25">
                <label for="estActif" class="form-check-label fw-medium position-relative top-1">
                  Est Actif 
                </label>
                <Field 
                id="estActif"
                name="estActif" 
                type="checkbox" 
                v-model="estActif"
                :unchecked-value="false"
                :checked-value="true"
                @change="onCheckee($event)"
                class="form-check-input shadow-none" />
                <ErrorMessage name="estActif" class="text-danger" />
              </div>
          </div> -->
          <div class="col-md-4">
              <br>
              <div class="form-check mt-3 mb-15 mb-sm-20 mb-md-25">
                <label for="estActif" class="form-check-label fw-medium position-relative top-1">
                  Est Actif ?:
                </label> <br>
                <div 
                style="float: left;">
                  <label for="estActif" class="form-check-label fw-medium position-relative top-1" style="float: left;">
                    Oui 
                  </label> 
                  <Field 
                  name="estActif" 
                  type="radio"
                  :value="true"
                  v-model="estActif"
                    />
                </div>
              <div style="float: right;">
                <label for="estActif" class="form-check-label fw-medium position-relative top-1"  >
                  Non 
                </label>  
                <Field 
                name="estActif" 
                type="radio"
                :value="false"
                v-model="estActif"
                 />
              </div>
                <ErrorMessage name="estActif" class="text-danger" />
              </div>
          </div>

          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Type de notification <span class="text-danger">*</span>
              </label>
              <Field name="typeNotification" v-slot="{ field }">
                  <Multiselect 
                    :options="typeOptions" 
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    @change="onTypeChange($event)"
                    placeholder="Sélectionner un type"
                  />
              </Field>
              <ErrorMessage name="typeNotification" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Canal <span class="text-danger">*</span>
                </label>
                <Field name="canal" v-slot="{ field }">
                  <Multiselect 
                    mode="tags"
                    :close-on-select="false"
                    :options="canalOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le canal"
                  />
                </Field>
                <ErrorMessage name="canal" class="text-danger"/>
              </div>
            </div>

          <div v-show="informationShow" class="col-md-12">
            <div class="alert alert-primary alert-dismissible fs-md-15 fs-lg-16" role="alert">
              Pour le type de modèle <strong>{{ typeName }}</strong>, veuillez utiliser les balises suivantes pour personnaliser le contenu de votre message : <br>
              <div v-for="(ct, index) in cletypeOptions" :key="index">
                <strong>{{ ct.value }}</strong> : {{ ct.label }} <br>
              </div>
              <!-- <button type="button" class="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close"></button> -->
            </div>
          </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Contenu
                </label>
                <Field name="contenu" as="textarea" v-slot="{ field }" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                    v-model="field.value"
                    cols="90"
                    rows="6"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="Entrer la Contenu"
                  ></textarea>
                </Field>
                <ErrorMessage name="contenu" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{btntext}}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm, useField } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { QuillEditor } from '@vueup/vue-quill';
import { Quill } from 'quill';
import { ModeleNotification } from '@/models/ModeleNotification';
import { success, error, hideModal } from '@/utils/utils';
import { CleTypeNotification } from '@/models/CleTypeNotification';
import { isNullOrUndefined } from 'util';

  export default defineComponent({
    name: "AddModeleNotificationModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
  },
  setup: (props, { emit }) => {
    const modeleNotificationSchema = Yup.object().shape({
      canal: Yup.array().required('Le canal est obligatoire'),
      // code: Yup.string().required('Le code est obligatoire'),
      contenu: Yup.string().required('Le contenu est obligatoire'),
      estActif: Yup.boolean(),
      typeNotification: Yup.string().required('Le type est obligatoire'),
    });

    const modeleNotificationForm = ref<null | ModeleNotification>(null);
    const typeOptions = ref<{value: string, label: string}[]>([]);
    const cletypeOptions = ref<CleTypeNotification | null >(null);
    const myQuillEditor = ref<InstanceType<typeof Quill> | null>(null);
    const isUPDATE = ref(false);
    const addModeleNotificationModalRef = ref<null | HTMLElement>(null);
    const typeName = ref('');
    const informationShow = ref(false);
    var notActif = ref(false);
    var estActif = ref(true);
    const canalOptions = ref([
      { value: 'SMS', label: 'SMS' },
      { value: 'Email', label: 'Email' },
    ]);
    const title = ref('Ajouter un modèle de notification');
    const btntext = ref('Ajouter')

    const onTypeChange = (value) => {
      const type = typeOptions.value.find((type) => type.value == value);
      typeName.value = type?.label ?? '';
      informationShow.value = true;

      if(value !== undefined){

      ApiService.get("/cleTypeNotifications/type/"+value)
      .then(({ data }) => {
        const donnee = data.data;
        if(data.data.length > 0){
            cletypeOptions.value = donnee.map((cle: any) => {
              return {
                label: cle.valeur,
                value: cle.cle,
              };
            })
        }
      })
      .catch((response) => {
        error(response.data.message);
      });

      }

    }

    const changeEtat = async () => {
      estActif.value = !estActif.value;
    }

    watch(() => props.item , (newValue) => {
      if(newValue!=0){
        getModelNotification(newValue);
        isUPDATE.value = true;
        btnTitle();
      }
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le modèle de notification";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un modèle de notification";
         btntext.value = "Ajouter";
      }
    }

    const getModelNotification = async (id: number) => {
      return ApiService.get("/modeleNotification/"+id)
        .then(({ data }) => {
          const donnees = data.data;
          // map dataol
          for (const key in donnees) {
            modeleNotificationForm.value?.setFieldValue(key, 
              (typeof donnees[key] === 'object' && donnees[key] !== null) ? 
              ( (Array.isArray(donnees[key]) && donnees[key].length > 0) ? donnees[key] : donnees[key].id) : 
              donnees[key]
            );
          }
          emit('openmodal', addModeleNotificationModalRef.value);
        })
        .catch((response) => {
          error(response.data.message);
        });
    };

    const addModeleNotification = async (values: any, modeleNotificationForm) => {
      values = values as ModeleNotification;
      if(isUPDATE.value){
        if(estActif.value){
        values["estActif"] =  estActif.value;
          }
          else{
            values["estActif"] =  notActif.value;
          }
        ApiService.put("/modeleNotification/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              modeleNotificationForm.resetForm();
              hideModal(addModeleNotificationModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/modeleNotification",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              modeleNotificationForm.resetForm();
              hideModal(addModeleNotificationModalRef.value);
              isUPDATE.value = false;
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    };
    
    const fetchTypeOptions = async () => {
      ApiService.get("/typeNotifications")
        .then(({ data }) => {
          // map data
          const donnees = data.data;
          typeOptions.value = donnees.map((type: any) => {
            return {
              label: type.libelle,
              value: type.id,
            };
          });
        })
        .catch((response) => {
          error(response.data.message);
        });
    };
    

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
        formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    onMounted(() => {
      fetchTypeOptions();
      // onTypeChange;
    });

    return { 
      modeleNotificationSchema,
      addModeleNotification,
      typeOptions,
      addModeleNotificationModalRef,
      estActif,
      notActif,
      modeleNotificationForm,
      myQuillEditor,
      typeName,
      onTypeChange,
      changeEtat,
      cletypeOptions,
      informationShow,
      canalOptions,
      btntext,
       title,
       resetValue
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import ApiService from "./services/ApiService";
import { dateFilter } from "vue-date-fns";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css";
import "sweetalert2/dist/sweetalert2.css";
import "@vueform/multiselect/themes/default.css";
import "./assets/custom.scss";
import 'sweetalert2/dist/sweetalert2.min.css';
import { initVeeValidate } from "./utils/vee-validate";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
// Vue.filter("date", dateFilter);
const i18n = createI18n({
    numberFormats:{
        'fr-FR': {
            currency: {
              style: 'currency', currency: 'FR', notation: 'standard'
            },
            decimal: {
              style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
            },
            percent: {
              style: 'percent', useGrouping: false
            }
        }, 
    }
})
const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(createPinia());
app.use(VueApexCharts);
app.use(BootstrapVueNext); 

app.component('QuillEditor', QuillEditor)
.use(Vue3Prism)
initVeeValidate();
ApiService.init(app);
app.mount("#app");


<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-paiement_service"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        Faire un paiement
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un paiement de service"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Référence
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Montant payé
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Mode de Paiement
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Abonné
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Abonnement
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Service 
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Date de paiement
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(servpa, index) in paiements" :key="index">
              <td class="shadow-none lh-1 fw-medium">
                {{ servpa.refPaiement }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ separateur(servpa.montantPaye) }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ servpa.modePaiement?.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ servpa.abonnement.abonne?.nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ servpa.abonnement.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ servpa.service.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{  format_date(servpa.createdAt)}}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"  @click="openNewWindow(servpa)" href="javascript:void(0);">
                        <i class="flaticon-send lh-1 me-8 position-relative top-1" ></i>
                         Reçu de paiement
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/services/ApiService";
import { Paiement } from "@/models/Paiement";
import { suppression, format_date,separateur,error,getUrlApiForFiles,openAndPrint } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListePaiementService",
    components: {
      PaginationComponent
  },
  setup: () => {
    
    onMounted(() => {
      getAllPaiementServices();
    });

    const paiements = ref<Array<Paiement>>([]);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    function openNewWindow(data:any) {
      openAndPrint(getUrlApiForFiles("Recu",data.refPaiement + ".pdf"));
    }

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllPaiementServices(page_, limit_);
      } catch (error) {
        //        
      }
    };
    function rechercher(){
      getAllPaiementServices(page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE

    function getAllPaiementServices(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/paiement/with/service?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          paiements.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          // return data.data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

  const checkPermission = (name) => {
    return privileges.value.includes(name);
  }
    return {
        checkPermission,
        suppression,
        format_date,
        paiements,
        getAllPaiementServices,
        separateur,
        page, 
        totalPages,
        limit,
        openNewWindow,
        totalElements,
        handlePaginate,
        searchTerm,
        rechercher
    };
  },
});
</script>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_PolarAreaChart = _resolveComponent("PolarAreaChart")!
  const _component_PerformanceChart = _resolveComponent("PerformanceChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Statistique M-post Box" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.etatDemandeLibelle.length > 0)
          ? (_openBlock(), _createBlock(_component_PolarAreaChart, {
              key: 0,
              colors: _ctx.colorsDemandeLibelle,
              labels: _ctx.etatDemandeLibelle,
              serie: _ctx.etatDemandeDonne,
              nom: "Nombre de demandes par type de demande "
            }, null, 8, ["colors", "labels", "serie"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.etatDemandeStatusDonne.length > 0)
          ? (_openBlock(), _createBlock(_component_PerformanceChart, {
              key: 0,
              colors: _ctx.colors,
              labels: _ctx.etatDemandeStatusLibelle,
              project: _ctx.etatDemandeStatusDonne,
              nom: "Nombre de demandes par statut "
            }, null, 8, ["colors", "labels", "project"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
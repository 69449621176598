<template>
    <BreadCrumb PageTitle="Modifier une notification" />
    <EditNotification />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditNotification from "../../components/Notification/EditNotification.vue";
  
    export default defineComponent({
      name: "EditNotificationPage",
      components: {
        BreadCrumb,
        EditNotification,
      },
    });
  </script>
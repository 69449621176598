<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-reabonnement2"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Faire un réabonnement
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un element"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Référence
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Montant payé
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Mode de Paiement
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Année
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Abonné
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Abonnement
              </th>
              <!--<th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Redevance
              </th>-->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Date de paiement
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(redevpa, index) in paiements" :key="index">
              <td class="shadow-none lh-1 fw-medium">
                {{ redevpa.refRedevance }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ separateur(redevpa.montant) }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ redevpa.paiement.modePaiement.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ redevpa.annee }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ redevpa.abonnement?.abonne?.nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ redevpa.abonnement?.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{  format_date(redevpa.paiement.createdAt)}}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click="openNewWindow(redevpa)" type="button"
                        class="dropdown-item d-flex align-items-center" >
                        <i class="flaticon-send lh-1 me-8 position-relative top-1" ></i>
                         Reçu de paiement
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/services/ApiService";
import { Paiement } from "@/models/Paiement";
import { suppression, format_date,separateur, error,telechargerFichier2,getUrlApiForFiles,openAndPrint } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListePaiement",
    components: {
      PaginationComponent
  },
  setup: () => {
    
    onMounted(() => {
      getAllPaiementRedevances();
    });

    const paiements = ref<Array<Paiement>>([]);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllPaiementRedevances(page_, limit_);
      } catch (error) {
        //
        
      }
    };
    function rechercher(){
      getAllPaiementRedevances(page.value, limit.value, searchTerm.value );
    }

    function getAllPaiementRedevances(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/paiement/with/redevance?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          paiements.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return paiements.value;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }

    function openNewWindow(data:any){
      openAndPrint(getUrlApiForFiles("Recu",data.paiement?.refPaiement + ".pdf"));
    }

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }
    const lien = ref(getUrlApiForFiles(null,null));
    const generation = (data:any) => {
      lien.value = getUrlApiForFiles("Recu",data.paiement?.refPaiement + ".pdf");
    }

    return {
      suppression,
      checkPermission,
      format_date,
      paiements,
      getAllPaiementRedevances,
      separateur,
      page, 
      totalPages,
      telechargerFichier2,
      limit,
      lien,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher,
      generation,
      openNewWindow
      
    };
  },
});
</script>
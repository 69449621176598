<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="colisForm" @submit="moveColis" :validation-schema="colisSchema">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Casier (Source)<span class="text-danger">*</span>
                </label>
                <Field  name=""  v-slot="{ field }">
                  <Multiselect
                    v-model = "field.value"
                    v-bind = "field"
                    :options="casiersOptions"
                    :searchable="true"
                    @change="casierChange($event)"
                    placeholder="Rechercher un casier"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins deux caractères"
                  />
                </Field>  
                <ErrorMessage name="" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Colis<span class="text-danger">*</span>
                </label>
                <Field  name="colis"  v-slot="{ field }">
                  <Multiselect
                    mode="tags"
                    :multiple="true"
                    :no-options-text="nooptions"
                    v-model = "field.value"
                    v-bind = "field"
                    :options="colisOptions"
                    @select="getColis(field.value)"
                    placeholder="Selectionner un colis"
                  />
                </Field>  
                <ErrorMessage name="colis" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Casier (Destination) <span class="text-danger">*</span>
                </label>
                <Field  name="casier"  v-slot="{ field }">
                  <Multiselect
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Rechercher un casier"
                    :filter-results="false"
                    :min-chars="3"
                    :resolve-on-load="false"
                    :delay="0"
                    :searchable="true"
                    :options-limit="300"
                    :options="async (query) => {
                      return await fetchCasiersOnly(query)
                    }"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="casier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Informations supplémentaires
                </label>
                <Field name="infoSupp" as="textarea"  cols="30"
                  rows="12" placeholder="Entrer les infos supplémentaitres" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                class="form-control shadow-none rounded-0 text-black"
                  v-model="field.value"
                ></textarea>
              </Field>
                <ErrorMessage name="infoSupp" class="text-danger"/>
              </div>
            </div>
            
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Déplacer le colis
            </button>
            <router-link to="/liste-colis" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Colis } from '@/models/Colis';
import * as Yup from 'yup';
import axios from 'axios';
import Multiselect from '@vueform/multiselect'


export default defineComponent({
    name: "MoveColis",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const colisSchema = Yup.object().shape({
      infoSupp: Yup.string().notRequired(),
      source: Yup.string().notRequired(),
      casier: Yup.string().required('Le casier est obligatoire'),
      colis: Yup.array().required('Le colis est obligatoire'),
    });

    const colisForm =  ref<Colis | null>((null));
    //const casier = ref(0);
    const router = useRouter();
    const casiersOptions = ref([]);
    const casierOptions = ref([]);
    const colisOptions = ref([]);
    const nooptions="La liste est vide";
    const noResultText="Aucun enregistrement trouvé";
    const coliselect = ref();

    const fetchCasiers = async () => {
      ApiService.get("/recuperation/casier")
      .then(({ data }) => {
        const donnees = data.data.data;
        casiersOptions.value = donnees.map((casier) => {
          return {
            label: `${casier.code} - ${casier.etagere.code}`,
            value: casier.id,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchColis = async (key) => {
      try {
        const response = await axios.get('/colis/'+key);
        const colisData = response.data.data;
        colisOptions.value = colisData.map((colis) => ({
          value: colis.id,
          label: colis.refColis,
        }));
        return  colisOptions.value;
      } catch (error) {
        //
      }
    };

    const getColis= (val) => {
      coliselect.value=val;
    }

    function casierChange(value) {
      colisOptions.value =[];
      ApiService.get('/casiers/colis/'+value)
        .then(({ data }) => {
            const donne = data.data;
            if(data.data.length > 0) {
              colisOptions.value = donne.map((operation: any) => {
                return {
                  label: operation.colis.refColis,
                  value: operation.colis.id,
                };
              });
            }
        })
        .catch((response) => {
          error(response.data.message);
        });
      }

    const fetchCasiersOnly = async (key) => {
      try {
        const response = await axios.get('/casiers/recherche/'+key);
        const casiersData = response.data.data;
        casierOptions.value = casiersData.map((casier) => ({
          value: casier.id,
          label: `${casier.code} - ${casier.etagere.code}`,
        }));
        return casierOptions.value;
      } catch (error) {
        //
      }
    }

    const moveColis = async (values, {resetForm}) => {
      ApiService.post('/colis/move',values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeColisPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    onMounted(() => {
      fetchCasiers()
    });

    return { colisSchema, moveColis, colisForm,fetchCasiersOnly,coliselect,
      casierChange,getColis,nooptions, fetchColis, noResultText, casiersOptions,colisOptions};
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Liste des catégories d'abonnés" />
    <ListeCategorieAbonne/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCategorieAbonne from "../../components/CategorieAbonne/ListeCategorieAbonne.vue";

    export default defineComponent({
      name: "ListeCategorieAbonnePage",
      components: {
        BreadCrumb,
        ListeCategorieAbonne,
      },
    });
  </script>
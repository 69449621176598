<template>
    <BreadCrumb PageTitle="Liste des redevances" />
    <ListeRedevance />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeRedevance from "../../components/Redevance/ListeRedevance.vue";
  
  
  export default defineComponent({
    name: "ListeRedevancePage",
    components: {
      BreadCrumb,
      ListeRedevance,
    },
  });
  </script>
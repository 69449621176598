<template>
    <BreadCrumb PageTitle="Liste du journal connexion" />
    <ListeJournalConnexion/>
</template>
  
<script lang="ts">
    import { defineComponent } from "vue";

    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeJournalConnexion from "../../components/Journal/ListeJournalConnexion.vue";


    export default defineComponent({
        name: "ListeJournalConnexionPage",
        components: {
        BreadCrumb,
        ListeJournalConnexion,
        },
    });
</script>
<template>
    <BreadCrumb PageTitle="Etats des paiements"/>
    <EtatPaiement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatPaiement from "../../components/Etats/EtatPaiement.vue";

  
    export default defineComponent({
      name: "EtatPaiementPage",
      components: {
        BreadCrumb,
        EtatPaiement,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des bureaux" />
    <ListeBureau/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeBureau from "../../components/Bureau/ListeBureau.vue";

  
    export default defineComponent({
      name: "ListeBureauPage",
      components: {
        BreadCrumb,
        ListeBureau,
      },
    });
  </script>
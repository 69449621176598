<template>
    <BreadCrumb PageTitle="Liste des opérations" />
    <ListeOperation/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeOperation from "../../components/Operation/ListeOperation.vue";

    export default defineComponent({
      name: "ListeOperationPage",
      components: {
        BreadCrumb,
        ListeOperation,
      },
    });
  </script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <StatsCaDerniersJours />
    </div>
    <div class="col-lg-6">
      <StatCaDesAbonnementAnnee />
    </div>
    <div class="col-lg-6">
      <WebsiteVisitors />
    </div>
    <div class="col-lg-6">
      <div class="card mb-25 border-0 rounded-0 bg-white">
        <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
          <div class="mb-15 d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0">Etat d'abonnement</h5>

          </div>
          <div id="projectDistributionChart" class="chart">
           <ChartJsChart v-if="chartDat" :chartData="chartDat" :chartOptions="chartOptions" />
            <div v-else>Loading...</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted,ref } from "vue";
import StatsCaDerniersJours from "../Graphes/StatsCaDerniersJours.vue";
import StatCaDesAbonnementAnnee from "../Graphes/StatCaDesAbonnementAnnee.vue";
import WebsiteVisitors from "../Dashboard/Ecommerce/WebsiteVisitors.vue"
import ChartJsChart from "./ChartJsChart.vue";
import { suppression, error, format_date,generateRandomColor,randomColorName,randomColor, format_Date, separateur } from "@/utils/utils";
import { ChartData, ChartOptions } from 'chart.js';
import axios from "axios";

export default defineComponent({
  name: "StatistiqueGlobal",
  components: {
    StatsCaDerniersJours,
    StatCaDesAbonnementAnnee,
    WebsiteVisitors,
    ChartJsChart
  },
  setup (){
    const chartDat =ref<ChartData | null>(null);
    const etatLibelle = ref<string[]>([]);
    const etatDonne = ref<number[]>([]);
    const etatCouleur = ref<string[]>([]);

    const fetchAbonnementByStatus = async () => {
      try {
        const response = await axios.get('/status/abonnements/0');
        const etatDonnee = response.data.data.data;
        const backCouleur: string[] = [];
        etatLibelle.value = [];
        etatDonne.value = [];
        etatCouleur.value = [];

        for (let i = 0; i < etatDonnee.raw.length; i++) {
          etatLibelle.value.push(etatDonnee.raw[i]["a_etat"] == null ? "NEANT" : etatDonnee.raw[i]["a_etat"]);
          backCouleur.push(randomColor());
          etatDonne.value.push(parseInt(etatDonnee.raw[i]["nombreAbonnment"]));
          etatCouleur.value.push(randomColor());
        }

        const chartEle = {
          labels: Object.entries(etatLibelle.value).map(([key, value]) => (value)),
          datasets: [{
            label: '# of Votes',
            data: Object.entries(etatDonne.value).map(([key, value]) => (value)),
            backgroundColor: backCouleur,
            borderColor: Object.entries(etatCouleur.value).map(([key, value]) => (value)),
            borderWidth: 1
          }]
        };
        chartDat.value = chartEle
       return chartDat.value;
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };


    onMounted( async () => {
      await fetchAbonnementByStatus();
    });


    return { 
      chartDat,
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Abonnement par status'
          }
        }
      } as ChartOptions
      
    }

  }
});
</script>
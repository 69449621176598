<template>
  <BreadCrumb PageTitle="Faire un abonnement" />
  <ReabonnementAdd />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ReabonnementAdd from "../../components/Reabonners/AddReabonnement/ReabonnementAdd.vue";

  export default defineComponent({
    name: "ReabonnementAddPage",
    components: {
      BreadCrumb,
      ReabonnementAdd,
    },
  });
</script>
<template>
    <BreadCrumb PageTitle="Ajouter un casier" />
    <AddCasier />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddCasier from "../../components/Casier/AddCasier.vue";
  
    export default defineComponent({
      name: "AddCasierPage",
      components: {
        BreadCrumb,
        AddCasier,
      },
    });
  </script>
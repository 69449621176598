<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="etagereForm" @submit="editEtagere" :validation-schema="etagereSchema" :initial-values="etagereForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Description
                </label>
                <Field name="description" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la desciption"/>
                <ErrorMessage name="description" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier une étagère
            </button>
            <router-link to="/liste-etageres" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, success } from '@/utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Etagere } from '@/models/Etagere';

export default defineComponent({
    name: "EditEtagere",
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const etagereSchema = Yup.object().shape({
      code: Yup.string().required('Le code est obligatoire').max(5, "Vous ne devez pas dépasser cinq caractères"),
      description: Yup.string().notRequired(),
    });

    const etagereForm = ref<Etagere>();
    const router = useRouter();
    const route = useRoute();

    function getEtagere(id:number) {
      ApiService.get("/etageres/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            etagereForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editEtagere = async (values, {resetForm}) => {
      ApiService.put("/etageres/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeEtagerePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    onMounted(() => {
      if(route.params.id) {
        getEtagere(parseInt(route.params.id as string));
      }
    });

    return { etagereSchema, editEtagere, etagereForm};
  },
});
</script>
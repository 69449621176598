<template>
    <BreadCrumb PageTitle="Ajouter une étagère" />
    <AddEtagere />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddEtagere from "../../components/Etagere/AddEtagere.vue";
  
    export default defineComponent({
      name: "AddEtagerePage",
      components: {
        BreadCrumb,
        AddEtagere,
      },
    });
  </script>
<template>
  <div
    class="modal fade createNewModal"
    id="AddBureauModal"
    tabindex="-1"
    aria-hidden="true"
    ref="addBureauModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="bureauForm" @submit="addBureau" :validation-schema="bureauSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Bureau <span class="text-danger">*</span>
                </label>
                <Field name="type" v-slot="{ field }" v-model="rels">
                  <Multiselect 
                    :options="typeOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner un type"
                    @select="handleObjetInput"
                  />
                </Field>
                <ErrorMessage name="type" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune <span class="text-danger">*</span>
              </label>
              <Field name="commune" v-slot="{ field }">
                <Multiselect 
                    v-model = "field.value"
                    v-bind = "field"
                    :options="communesOptions"
                    :searchable="true"
                    @change="communeChange($event)"
                    placeholder="Rechercher une commune"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins deux caractères"/>
              </Field>
              <ErrorMessage name="commune" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-6" v-if="showAdditionalFields">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Agence <span class="text-danger">*</span>
              </label>
              <Field  name="bureau" v-slot="{ field }">
                <Multiselect 
                    :no-options-text="nooptions"
                    v-model = "field.value"
                    v-bind = "field"
                    :options="bureauxOptions"
                    placeholder="Selectionner un bureau"
                 />
              </Field>
              <ErrorMessage name="bureau" class="text-danger"/>
            </div>
          </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Code du bureau <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code du bureau"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Téléphone du bureau <span class="text-danger">*</span>
                </label>
                <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Entrer le numéro de téléphone"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
                <ErrorMessage name="telephone" class="text-danger"/>
                <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Nom du bureau <span class="text-danger">*</span>
                </label>
                <Field name="nom" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom du bureau"/>
                <ErrorMessage name="nom" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Email du bureau <span class="text-danger">*</span>
                </label>
                <Field name="email" type="email" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse email du bureau"/>
                <ErrorMessage name="email" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse du bureau <span class="text-danger">*</span>
                </label>
                <Field name="adresse" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse du bureau"/>
                <ErrorMessage name="adresse" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit" :disabled="!validPhone"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import Multiselect from '@vueform/multiselect'
import { error, hideModal, success } from '@/utils/utils';
import { Bureau } from '@/models/Bureau';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import axios from 'axios';


export default ({
    name: "AddBureauModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const bureauSchema = Yup.object().shape({
      nom:       Yup.string().required('Le nom est obligatoire'),
      email:     Yup.string().email("Entrer un mail valide").required('L\'email est obligatoire'),
      code:      Yup.string().required('Le code est obligatoire').max(2, "Vous ne devez pas dépasser deux caractères"),
      adresse:   Yup.string().required('L\'adresse est obligatoire'),
      type:      Yup.string().required('Le type est obligatoire'),
      telephone: Yup.string().required('Le numéro de téléphone est obligatoire'),
      commune:    Yup.string().required('La commune est obligatoire'),
    });

    const bureauForm = ref<Bureau | null>(null);
    const router = useRouter();
    const addBureauModalRef = ref<null | HTMLElement>(null);
    const isUPDATE = ref(false);
    const title = ref('Ajouter un bureau');
    const btntext = ref('Ajouter');
    const communeOptions = ref([]);
    const rels = ref('Agence');
    const showAdditionalFields = ref(false);
    const nooptions="La liste est vide";
    const noOptionsTextAdd=ref("Tapez au moins deux caractères");
    const noOptionsTextAbb=ref("Tapez au moins trois caractères");
    const communesOptions = ref([]);

    const bureauxOptions = ref([]);

    const handleObjetInput = (selectedValue) => {
      showAdditionalFields.value = selectedValue === 'Relais';
    }

    const typeOptions = ref([
      { value: 'Agence', label: 'Agence' },
      { value: 'Relais', label: 'Relais' },
    ]);
    // ref props
    const localItem = ref(props.item);
    const  codepay= ref();
    const  nationalnumlber= ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);
    
    watch(() => props.item , (newValue) => {
      getBureau(newValue);
      isUPDATE.value = true;
      btnTitle();
      
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le bureau";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un bureau";
         btntext.value = "Ajouter";
      }
    }

    // get all communes
    const fectCommunes = async (id: number) => {
    ApiService.get("/communes")
    .then(({ data }) => {
      const donnees = data.data.data;
      communeOptions.value = donnees.map((commune) => {
        return {
          value: commune.id,
          label: commune.libelle,
        }
      });
    })
    .catch(({ response }) => {
      error(response.data.message)
    });
    }

    const getBureau = async (id: number) => {
      if(id){
        return ApiService.get("/bureaus/"+id)
        .then(({ data }) => {
          // map data in form
          const donnees = data.data;
          for (const key in donnees) {
            bureauForm.value?.setFieldValue(key, 
            (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
            );
          }
          emit('openmodal', addBureauModalRef.value);

        })
        .catch(({ response }) => {
          error(response.data.message)
        });
      }
      
    }

    const addBureau = async (values: any ,bureauForm) => {
      
      values = values as Bureau;
      if(isUPDATE.value){
        ApiService.put("/bureaus/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              localItem.value = 0;
              bureauForm.resetForm();
              hideModal(addBureauModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        
        ApiService.post("/bureaus",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              bureauForm.resetForm();
              hideModal(addBureauModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
      
    };

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value=phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value= phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value= phoneObject.nationalNumber;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
      }
    }

    function communeChange(value) {
      if(value){
        bureauxOptions.value =[];
        ApiService.get('/bureaux/commune/'+value)
          .then(({ data }) => {
              const donnee = data.data;
              if(donnee.length > 0) {
                bureauxOptions.value = donnee.map((bureau: any) => {
                  return {
                    label: bureau.nom,
                    value: bureau.id,
                  };
                });
              }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      }
     
    }

  const fetchCommune = async () => {

      ApiService.get('/communes')
        .then(({ data }) => {
            const donneee = data.data.data;
            communesOptions.value = donneee.map((commune: any) => {
              return {
                label: commune.libelle,
                value: commune.id,
              };
            });
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    const fetchAgences = async (key) => {
    try {
      const response = await axios.get('/bureaus/recherche/'+key);
      const bureauxData = response.data.data;
      bureauxOptions.value = bureauxData.map((bureau) => ({
        value: bureau.id,
        label: bureau.nom,
      }));
      return  bureauxOptions.value;
    } catch (error) {
      //
    }
  };

  const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    onMounted(() => {
      fetchCommune()
    });

    return { 
      addBureauModalRef, 
      bureauForm, 
      bureauSchema, 
      addBureau, 
      communeOptions, 
      typeOptions, 
      telephone,
      validPhone,
      validate,
      onInput,
      title,btntext,
      bureauxOptions,
      fetchAgences,
      rels,
      showAdditionalFields,
      handleObjetInput,
      communeChange ,
      communesOptions,
      nooptions,
      resetValue
    };

  }
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="boitePostaleForm" @submit="addBoitePostale" :validation-schema="boitePostaleSchema">
        <div class="row">

          <div v-show="informationShow" class="col-md-12">
            <div class="alert alert-primary alert-dismissible fs-md-15 fs-lg-16" role="alert">
              <div v-if="_totalBoite == '0001'">
                Le bureau <strong>{{ _bureau }}</strong>, dispose d'une boîte dont le numéro est le 0001 <br>
              </div>
              <div v-else>
                <div v-if="_totalBoite == '0000'">
                  Le bureau <strong>{{ _bureau }}</strong>, ne dispose pas encore de boite <br>
                </div>
                <div v-else>
                  Le bureau <strong>{{ _bureau }}</strong>, dispose des boîtes numérotées de 0001 à {{ _totalBoite}} <br>
                </div>
              </div>
              <button type="button" class="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>

          <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Bureau <span class="text-danger">*</span>
                </label>
                <Field name="bureau" v-slot="{ field }">
                  <Multiselect 
                    :options="bureauOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    @change="changeBureau($event)"
                    @open="onOpen"
                    placeholder="Sélectionner le bureau"
                  />
                </Field>
                <ErrorMessage name="bureau" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Modèle de boîte <span class="text-danger">*</span>
                </label>
                <Field name="modele" v-slot="{ field }">
                  <Multiselect 
                    :options="modeleOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le modèle de boîte"
                  />
                </Field>
                <ErrorMessage name="modele" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Nombre de boîte <span class="text-danger">*</span>
                </label>
                <Field name="nbrBoite" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de boîte"/>
                  <ErrorMessage name="nbrBoite" class="text-danger"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Couleur
                </label>
                <Field name="couleur" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la couleur"/>
                  <ErrorMessage name="couleur" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Dimension
                </label>
                <Field name="dimension" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la dimension"/>
                  <ErrorMessage name="dimension" class="text-danger"/>
              </div>
            </div>
          
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <div class="btn-group me-20" style="max-height: 45px;">
                <button class="default-btn mh-3  transition border-0 lh-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success" 
                type="submit" >
                Générer des boîtes postales
                </button>
              </div>
              <router-link to="/liste-boites" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">

import { onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import  Swal  from "sweetalert2";
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { hideModal, success, error } from '@/utils/utils';
import { BoitePostale } from '@/models/BoitePostale';
import Multiselect from '@vueform/multiselect'
import router from '@/router';

export default ({
  name: "AddBoitePostale",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const boitePostaleSchema = Yup.object().shape({
      couleur: Yup.string().notRequired(),
      dimension: Yup.string().notRequired(),
      nbrBoite: Yup.number().typeError('Entrer des chiffres').required('Le nombre de boîte est obligatoire'),
      bureau: Yup.string().required('Le bureau est obligatoire'),
      modele: Yup.number().typeError('Veuillez entrer un nombre').required('Le modèle est obligatoire'),
      // estOfficiel: Yup.boolean().required('La boîte est offcielle ?'),
    });

    const boitePostaleForm = ref<null | BoitePostale>(null);
    // const bureaux = ref<Bureau | null>(null);
    const bureauOptions = ref([]);
    // const modeles = ref<Modele | null>(null);
    const modeleOptions = ref([]);
    const addBoitePostaleRef = ref<null | HTMLElement>(null);
    const enregistrerEtContinuer = ref(false);
    const informationShow = ref(false);
    const _bureau = ref('');
    const _totalBoite = ref('');

    const changeBureau = async (e) => {
      if(e == null) return;
      informationShow.value = false;
      const nombre = e.split('-')[1];
      _bureau.value = e.split('-')[2];
      _totalBoite.value = nombre.padStart(4, '0');
      informationShow.value = true;
    }

    const onOpen = async () => {
      informationShow.value = false;
    }

    const addBoitePostale = async (values: any) => {
      values = values as BoitePostale;
      values.bureau = values.bureau.split('-')[0];
        ApiService.post("/boitePostales",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              router.push({ name: 'ListeBoitePostalePage' });
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
    }

    const fetchBureaux = async () => {
      ApiService.get("/bureaus")
      .then(({ data }) => {
        const donnees = data.data.data;
        bureauOptions.value = donnees.map((bureau) => {
          return {
            value: bureau.id+'-'+((bureau.boites.length) ? bureau.boites.length : 0)+'-'+bureau.nom,
            label: bureau.nom,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchModeles = async () => {
      ApiService.get("/modele_boites")
      .then(({ data }) => {
        const donnees = data.data;
        modeleOptions.value = donnees.map((modele) => {
          return {
            value: modele.id,
            label: modele.libelle,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      fetchBureaux();
      fetchModeles();
    });

    return { boitePostaleSchema,
      addBoitePostale,
      boitePostaleForm,
      modeleOptions,
      bureauOptions,
      informationShow,
      changeBureau,
      _bureau,
      _totalBoite,
      onOpen
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Faire une redevance" />
    <AddRedevance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddRedevance from "../../components/Redevance/AddRedevance.vue";
  
    export default defineComponent({
      name: "AddRedevancePage",
      components: {
        BreadCrumb,
        AddRedevance,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Vérification de la demande" />
    <TraitementReclamation />
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import TraitementReclamation from "../../components/Reclamations/TraitementReclamation.vue";
  
    export default defineComponent({
      name: "TraitementReclamationPage",
      components: {
        BreadCrumb,
        TraitementReclamation,
      },
    });
  </script>
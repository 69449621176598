<template>
  <div
    class="modal fade createNewModal"
    id="AddCleTypeNotificationModal"
    tabindex="-1"
    ref="addCleTypeNotificationModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="cleTypeNotificationForm" @submit="addCleTypeNotification" :validation-schema="cleTypeNotificationSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  TYPE <span class="text-danger">*</span>
                </label>
                <Field name="typeNotification" v-slot="{ field }">
                  <Multiselect 
                    :options="typeOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type"
                  />
                </Field>
                <!-- <Field name="type" as="select" type="text" 
                class="form-select shadow-none fw-semibold rounded-0">
                <option value="agence">Agence</option>
                <option value="relais">Relais</option>
                </Field> -->
                <ErrorMessage name="typeNotification" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Clé <span class="text-danger">*</span>
                </label>
                <Field name="cle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la clé"/>
                <ErrorMessage name="cle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Valeur <span class="text-danger">*</span>
                </label>
                <Field name="valeur" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la valeur"/>
                <ErrorMessage name="valeur" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
            <!-- <button type="button" @click="resetForm()">Reset</button> -->
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        
      </div>
    </div>
  </div>
  <div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import Multiselect from '@vueform/multiselect'
import { error, hideModal, showModal, success } from '@/utils/utils';
import { CleTypeNotification } from '@/models/CleTypeNotification';

export default {
  name: "AddCleTypeNotificationModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      required: true
    },
  },
  setup(props, { emit }){

    const cleTypeNotificationSchema = Yup.object().shape({
      cle: Yup.string().required('La clé est obligatoire'),
      valeur: Yup.string().required('La valeur est obligatoire'),
      typeNotification: Yup.string().required('Le type est obligatoire'),
    });
    

    let cleTypeNotificationForm = ref<CleTypeNotification | null>(null);
    const router = useRouter();
    const addCleTypeNotificationModalRef = ref<null | HTMLElement>(null);
    const modepaiementnew = ref<CleTypeNotification | null>(null);
    const typeOptions = ref([]);
    const isUPDATE = ref(false);
    const title = ref("Ajouter une clé type de notification");
    const btntext = ref("Ajouter");

    watch(() => props.item , (newValue) => {
      if(newValue !== 0){
        getCleTypeNotification(newValue);
        isUPDATE.value = true;
        title.value = "Modifier une clé type de notification";
        btntext.value = "Modifier";
      }
    });

    const getCleTypeNotification = async (id: number) => {
      return ApiService.get("/cleTypeNotifications/"+id)
      .then(({ data }) => {
        for (const key in data) {
            cleTypeNotificationForm.value?.setFieldValue(key, 
              (typeof data[key] === 'object' && data[key] !== null) ? data[key].id : data[key]
            );
        }
        emit('openmodal', addCleTypeNotificationModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fectchTypeNotification = async () => {
      return ApiService.get("/typeNotifications")
      .then(({ data }) => {
        // typeOptions.value = data.data;
        // map data into vueform multiselect
        typeOptions.value = data.data.map((type) => {
          return {
            value: type.id,
            label: type.libelle,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addCleTypeNotification = async (values: any ,cleTypeNotificationForm) => {
      
      values = values as CleTypeNotification;
      if(isUPDATE.value){
        ApiService.put("/cleTypeNotifications/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              cleTypeNotificationForm.resetForm();
              hideModal(addCleTypeNotificationModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/cleTypeNotifications",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              cleTypeNotificationForm.resetForm();
              hideModal(addCleTypeNotificationModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
      
    };

    onMounted(() => {
      fectchTypeNotification();
    });

    return { title,btntext, cleTypeNotificationSchema, addCleTypeNotification, cleTypeNotificationForm, addCleTypeNotificationModalRef, typeOptions };
  },
};
</script>
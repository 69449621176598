<template>
    <BreadCrumb PageTitle="Liste des services" />
    <ListeService />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeService from "../../components/Service/ListeService.vue";

  
    export default defineComponent({
      name: "ListeServicePage",
      components: {
        BreadCrumb,
        ListeService,
      },
    });
  </script>
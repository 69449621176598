<template>
    <BreadCrumb PageTitle="Etats des boîtes" />
    <EtatBoite />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatBoite from "../../components/Etats/EtatBoite.vue";

  
    export default defineComponent({
      name: "EtatBoitePage",
      components: {
        BreadCrumb,
        EtatBoite,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="colisForm" @submit="addColis" :validation-schema="colisSchema">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Référence <span class="text-danger">*</span>
                </label>
                <Field name="refColis" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la référence"/>
                <ErrorMessage name="refColis" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Casier <span class="text-danger">*</span>
                </label>
                <Field  name="casier"  v-slot="{ field }">
                  <Multiselect
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Rechercher un casier"
                    :filter-results="false"
                    :min-chars="3"
                    :resolve-on-load="false"
                    :delay="0"
                    :searchable="true"
                    :options-limit="300"
                    :options="async (query) => {
                      return await fetchCasiers(query)
                    }"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="casier" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Abonnement <span class="text-danger">*</span>
                </label>
                <Field  name="abonnement"  v-slot="{ field }">
                  <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonnement"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  :options="async (query) => {
                   return await fetchAbonnements(query)
                  }"
                  noResultText= "Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="abonnement" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Informations supplémentaires
                </label>
                <Field name="infoSupp" as="textarea"  cols="30"
                  rows="12" placeholder="Entrer les infos supplémentaitres" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                class="form-control shadow-none rounded-0 text-black"
                  v-model="field.value"
                ></textarea>
              </Field>
                <ErrorMessage name="infoSupp" class="text-danger"/>
              </div>
            </div>
            
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Réceptionner un colis
            </button>
            <router-link to="/liste-colis" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Colis } from '@/models/Colis';
import * as Yup from 'yup';
import axios from 'axios';
import Multiselect from '@vueform/multiselect'


export default defineComponent({
    name: "AddColis",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const colisSchema = Yup.object().shape({
      refColis: Yup.string().required('La référence est obligatoire'),
      infoSupp: Yup.string().notRequired(),
      abonnement: Yup.string().required('L\'abonnement est obligatoire'),
      casier: Yup.string().required('Le casier est obligatoire'),
    });

    const colisForm =  ref<Colis | null>(null);
    const abonnements = ref(null);
    const abonnement = ref(0);
    const casier = ref(0);
    const abonnementOptions = ref([]);
    const router = useRouter();
    const casierOptions = ref([]);

    const addColis = async (values, {resetForm}) => {
      
      ApiService.post("/colis",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeColisPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    // const fetchAbonnements = async () => {
    //   try {
    //     const response = await axios.get('/abonnements');
    //     const abonnementsData = response.data.data;
    //     abonnementOptions.value = abonnementsData.map((abonnement) => ({
    //       value: abonnement.id,
    //       label: `${abonnement.refAbo} - [${abonnement.adressePostale.adresse_postale}] - [${abonnement.abonne.nomComplet}]`,
    //     }));
    //   } catch (error) {
    //   }
    // };

    watch(abonnement, (value) => {
      //
  });

    const fetchCasiers = async (key) => {  
      try {
        const response = await axios.get('/casiers/recherche/'+key);
        const casiersData = response.data.data;
        casierOptions.value = casiersData.map((casier) => ({
          value: casier.id+'-'+casier.code,
          label: `${casier.code} - ${casier.etagere.code}`,
        }));
        return casierOptions.value;
      } catch (error) {
        //
      }
    }

    const fetchAbonnements = async (key) => {  
      try {
        const response = await axios.get('/abonnements/recherche/'+key);
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          // value: abonnement.id,
          // label: abonnement.abonne.nomComplet+' [ '+abonnement.boite.refBoite+' ]',
          value: abonnement.id+'-'+abonnement.loyer,
          label: `${abonnement.refAbo} - [${abonnement.abonne.nomComplet}]`,
        }));
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }

    return { colisSchema, addColis, fetchCasiers, colisForm, abonnements, fetchAbonnements, abonnementOptions, casierOptions, casier};
  },
});
</script>
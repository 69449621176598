<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link v-if="checkPermission('AddNotification')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-notification"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Envoyer une notification
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une notification"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Message
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Destinataire
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Date d'envoi
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Etat
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Statut
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(notification, index) in notifications" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis ps-0">
                <a href="#"
                  class="text-decoration-none text-black">
                  {{ (notification.message.length > 60) ? notification.message.substring(0, 60) + '...' : notification.message }}
                </a>
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                {{ notification?.abonne?.nomComplet  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                {{ format_date(notification.createdAt)  }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="notification.estEnvoyer == true" class="badge text-outline-primary"> Envoyer </span>
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span class="badge text-outline-info">
                  {{ notification.estLu == false ? "Non lue" : "Lue" }}
                </span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link 
                      class="dropdown-item d-flex align-items-center"
                        :to="{ name: 'ViewNotificationContentPage', params: { id: notification.id } }"
                      >
                      <i class="flaticon-view position-relative ms-5 fs-12"></i>
                      Détail
                      </router-link>
                    </li>
                    <!--<a
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(notification.id,notifications,'notifications',`la notification ${notification.id}`)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                    </a>-->
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import Swal from "sweetalert2";
import { Notification } from "@/models/Notification";
import { format_date, suppression, success, error } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "ListeNotification",
  components: {
    PaginationComponent
  },
  setup: () => {
    const notifications = ref<Array<Notification>>([]);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllNotifications(page_, limit_);
      } catch (error) {
        //
        
      }
    };
    function rechercher(){
      getAllNotifications(page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE

    onMounted(() => {
        getAllNotifications();
    });

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    function getAllNotifications(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/notifications?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          notifications.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
          error(response.data.msg);
        });
    }

    return { 
      checkPermission,
        format_date,
        notifications,
        getAllNotifications,
        suppression,
        page,
        totalPages,
        limit,
        totalElements,
        handlePaginate,
        searchTerm,
        rechercher
     };
  }
});
</script>
<template>
    <div class="card mb-10 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white align-items-center p-15 p-sm-20 p-md-25">
        <div class="row">
            <div class="col-md-12 col-sm-12 mb-5">
              <div class="accordion" id="basicAccordion">
                  <div class="accordion-item mb-0">
                      <button class="accordion-button shadow-none fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#basicCollapseOne" aria-expanded="true" aria-controls="basicCollapseOne">
                      La vue graphique
                      </button>
                      <div id="basicCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#basicAccordion">
                          <div class="accordion-body row">
                            <DemandeChart />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="d-sm-flex align-items-center" style="width:100%;">
                <Form style="width:100%;" class="search-box position-relative me-15">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group mb-15 mt-30 mb-sm-20 mb-md-25">
                                <Field name="recherche" type="text"  v-model="recherche"
                                class="form-control shadow-none text-black rounded-0 border-0" placeholder="Rechercher"/>
                                <ErrorMessage name="recherche" class="text-danger"/>
                            </div>
                        </div>
                        
                        <div class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Type Demande <span class="text-danger">*</span>
                                </label>
                                <Field name="type" v-model="type" v-slot="{ field }">
                                    <Multiselect 
                                    :options="typesDemandesOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    v-bind="field"
                                    no-results-text="Aucun type de demande trouvé"
                                    placeholder="Sélectionner un type"
                                    />
                                </Field>
                                <ErrorMessage name="type" class="text-danger"/>
                            </div>
                        </div>
                        <div v-if="!etatAgence"  class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Agence <span class="text-danger">* </span>
                                </label>
                                <Field name="bureau" v-model="bureau" v-slot="{ field }">
                                    <Multiselect 
                                    :options="bureausBoitesOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    no-results-text="Aucune agence trouvée"
                                    v-bind="field"
                                    placeholder="Sélectionner une agence"
                                    />
                                </Field>
                                <ErrorMessage name="bureau" class="text-danger"/>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                <label class="d-block text-black fw-semibold mb-10">
                                    Statut <span class="text-danger">*</span>
                                </label>
                                <Field name="option" v-model="etat" v-slot="{ field }">
                                    <Multiselect 
                                    :options="etatsOptions" 
                                    :searchable="true" 
                                    v-model="field.value"
                                    no-results-text="Aucun état trouvé"
                                    v-bind="field"
                                    placeholder="Sélectionner un statut"
                                    />
                                </Field>
                                <ErrorMessage name="option" class="text-danger"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-10 col-3">
                            <div  class="form-group mb-15 mt-50 mb-sm-20 mb-md-25" >
                            </div>
                           
                        </div>

                    </div>
                </Form>
            </div>
           
          </div>
        </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  REFERENCE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >TYPE DE DEMANDE</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  DATE
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >ABONNEMENT</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >ABONNE</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >AGENCE</th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >STATUT</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(dem , index) in demandes" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{dem.refDemande}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ dem.typeDemande.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(dem.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ dem.abonnement? dem.abonnement.refAbo : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{  dem.abonnement? (dem.abonnement.abonne? dem.abonnement.abonne.nomComplet :"") :"" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ dem.abonnement ? dem.abonnement?.boite?.bureau?.nom : "AUCUN" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="dem.status === 'En attente'" class="badge text-outline-info">{{ dem.status }}</span>
                <span v-else class="badge text-outline-success">{{ dem.status }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
        >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
    import { defineComponent, onMounted,watch, ref} from "vue";
    import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
    import Swal from "sweetalert2";
    import axios from 'axios';
    import ApiService from "@/services/ApiService";
    import { suppression,error,format_date } from "@/utils/utils";
    import PaginationComponent from '@/components/Utilities/Pagination.vue';
    import Multiselect from '@vueform/multiselect';
    import JwtService from "@/services/JwtService";
    import DemandeChart from '@/components/Charts/Charts/DemandeChart.vue';

  
export default({
  name: "EtatDemande",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    PaginationComponent,
    DemandeChart
    },
    setup(){
        const modelesBoitesOptions = ref();
        const bureausBoitesOptions = ref();
        const typesDemandesOptions = ref();
        const type = ref();
        const demandes = ref([]);
        const recherche = ref("");
        const etat = ref("");
        const bureau = ref();
        const etatsOptions =[
            { value: '', label: 'Selectionnez un état'},
            {label: "En attente",value:"En attente"}, 
            { value: 'Acceptée', label: 'Acceptée'},
            { value: 'Validée', label: 'Validée'},
            { value: 'En cours', label: 'En cours'},
            { value: 'Rejetée', label: 'Rejetée'},
        ];
        const etatAgence = ref(true);
        if(JwtService.getUserCheckIfAllAgence() == "true"){
          etatAgence.value = false;
        }
        const searchTerm = ref('');
        const page = ref(1);
        const totalPages = ref(0);
        const limit = ref(10);
        const totalElements = ref(0);
        const action = async (page = 1, limi = 10, searchTerm = '')=>{

            const element = {
                recherche:recherche.value,
                etat:etat.value,
                bureau:bureau.value,
                type:type.value,
                page:page,
                totalPages:totalPages.value,
                limit:limit.value,
                totalElements:totalElements.value,
                mot:searchTerm
            }
            return await ApiService.post("/etats/demandes",element)
                .then((response) => {
                  //
                if (response.status === 200) {
                  
                    demandes.value = response.data.data.data;
                    totalPages.value = response.data.data.totalPages;
                    limit.value = response.data.data.limit;
                    totalElements.value = response.data.data.totalElements;
                }
                })
                .catch((errorr) => {
                    error(errorr.response.data.message);
                });
            
        }

        const fetchModeles = async () => {
            try {
                
            const response = await axios.get('/modele_boites');
            const modelesData = response.data.data;
            modelesBoitesOptions.value = modelesData.map((boite) => ({
                value: boite.id,
                label: boite.code+" [ "+ boite.libelle+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const fetchBureaus = async () => {
            try {
            const response = await axios.get('/bureaus');
            const bureausData = response.data.data.data;
            bureausBoitesOptions.value = bureausData.map((bureau) => ({
                value: bureau.id,
                label: bureau.code+" [ "+ bureau.nom+" ]",
            }));
            } catch (error) {
              //
            }
        };
        const handlePaginate = ({ page_, limit_ }) => {
          try {
            page.value = page_;
            action(page_, limit_);
          } catch (error) {
            //
            
          }
        };
        const fetchtypes = async () => {
            try {
            const response = await axios.get('/typedemandes');
            const typesData = response.data.data;
            typesDemandesOptions.value = typesData.map((type) => ({
                value: type.id,
                label: type.code+" [ "+ type.libelle+" ]",
            }));
            } catch (error) {
              //
            }
        };
    onMounted(() => {
        fetchModeles();
        fetchBureaus();        
        fetchtypes();
        action(1, 10);
    });
       
    watch(bureau, (newValue, oldValue) => {
       bureau.value = newValue;
       action(1, 10);
    });
    watch(type, (newValue, oldValue) => {
       type.value = newValue;
       action(1, 10);
    });


    watch(etat, (newValue, oldValue) => {
        etat.value = newValue;
        action(1, 10);
    });
    watch(recherche, (newValue, oldValue) => {
        recherche.value = newValue;
        action(1, 10);
    });
     
  
      return { suppression,format_date,page,totalPages,limit, totalElements,handlePaginate,type,etatAgence,typesDemandesOptions, bureau,fetchModeles,fetchtypes,demandes,bureausBoitesOptions,action,recherche,etat,etatsOptions,modelesBoitesOptions };
    },

  });
  </script>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_PolarAreaChart = _resolveComponent("PolarAreaChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Statistique des garanties" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.etatLibelleCat.length > 0 && _ctx.etatDonneCat.length > 0)
          ? (_openBlock(), _createBlock(_component_PieChart, {
              key: 0,
              colors: _ctx.colors,
              labels: _ctx.etatLibelleCat,
              project: _ctx.etatDonneCat,
              nom: "Total des garanties par catégorie d'abonnés"
            }, null, 8, ["colors", "labels", "project"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.etatCautionDonne.length > 0)
          ? (_openBlock(), _createBlock(_component_PolarAreaChart, {
              key: 0,
              colors: _ctx.colorsDemandeLibelle,
              labels: _ctx.etatCautionLibelle,
              serie: _ctx.etatCautionDonne,
              nom: "Total des garanties par modèle de boîtes"
            }, null, 8, ["colors", "labels", "serie"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
<template>

  <div v-show="messagePromotion != ''" class="alert alert-primary fs-md-15 fs-lg-16" role="alert">
    {{ messagePromotion }}
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonneForm" @submit="payer" :validation-schema="abonnementSchema">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune <span class="text-danger">*</span>
              </label>
              <Field name="commune" v-model="communeSelect" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :searchable="true"
                  @change="communeChange($event)" placeholder="Rechercher une commune"
                  noResultText="Aucun enregistrement trouvé" noOptionsText="Tapez au moins caractères caractères" />
              </Field>
              <ErrorMessage name="commune" class="text-danger" />
            </div>
          </div>

          <!--  <div v-else class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune <span class="text-danger">*</span>
              </label>
              <Field name="commune" v-model="communeSelect" v-slot="{ field }">
                <Multiselect 
                    v-model = "field.value"
                    v-bind = "field"
                    :options="communesOptions"
                    @change="communeChange($event)"
                    placeholder="Rechercher une commune"
                    noResultText= "Aucun enregistrement trouvé"
                    />
              </Field>
              <ErrorMessage name="commune" class="text-danger"/>
            </div>
        </div>-->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Agence <span class="text-danger">*</span>
              </label>
              <Field name="bureau" v-model="bureauselect" v-slot="{ field }">
                <Multiselect :no-options-text="nooptions" v-model="field.value" v-bind="field" :options="bureauxOptions"
                  @select="getLoyerAndBoitBureau(field.value)" placeholder="Selectionner un bureau" />
              </Field>
              <ErrorMessage name="bureau" class="text-danger" />
            </div>
          </div>
          <!--<div v-else class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Agence <span class="text-danger">*</span>
              </label>
              <Field  name="bureau" v-model="bureauselect" v-slot="{ field }">
                <Multiselect 
                    :no-options-text="nooptions"
                    v-model = "field.value"
                    v-bind = "field"
                    @select="getLoyerAndBoitBureau(field.value)"
                    placeholder="Selectionner un bureau"
                 />
              </Field>
              <ErrorMessage name="bureau" class="text-danger"/>
            </div>
          </div>-->
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modèle de boîte <span class="text-danger">*</span>
              </label>
              <Field name="modeleBoite" v-slot="{ field }">
                <Multiselect :options="modelesBoitesOptions" :searchable="true" v-model="field.value" v-bind="field"
                  :no-results-text="noResultText" @select="getLoyerAndBoitModel(field.value)"
                  placeholder="Sélectionner le modèle de la boîte" />
              </Field>
              <ErrorMessage name="modeleBoite" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Boîte <span class="text-danger">*</span>
              </label>
              <Field v-model="boitesselect" name="boite" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :searchable="true"
                  placeholder="Sélectionner une boite" :options=boitesOptions :no-options-text="noOptionsTextBoi"
                  @select="modelChange" />
              </Field>
              <ErrorMessage name="boite" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse Postale <span class="text-danger">*</span>
              </label>
              <Field v-model="adressePostaleC" readonly="true" name="adressePostale" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse postale" />
              <ErrorMessage name="adressePostale" class="text-danger" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonné <span class="text-danger">*</span>
                <a style="float: right;" v-if="!selectedAbonne"
                  class="default-btn transition border-0 fw-medium text-white rounded-1 bg-success d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                  data-bs-toggle="modal" data-bs-target="#AddAbonneModal" @click="toggleAbonne">
                  <i class="flaticon-plus position-relative ms-5 fs-12"></i>
                  Ajouter un abonné
                </a>
                <button style="float: right;" v-if="selectedAbonne"
                  class="default-btn transition border-0 fw-medium text-white rounded-1 bg-success d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                  @click="toggleAbonne">
                  <i class="flaticon-plus position-relative ms-5 fs-12"></i>
                  Rechercher un abonné
                </button>
              </label>
              <Field name="abonne" v-slot="{ field }" v-if="!selectedAbonne" v-model="abonneCharger">
                <Multiselect v-model="field.value" v-bind="field" placeholder="Rechercher un abonné"
                  :filter-results="false" :min-chars="3" :resolve-on-load="false" :delay="0" :searchable="true"
                  :options-limit="300" :options="async (query) => {
                    return await fetchAbonnes(query)
                  }" @select="getLoyerAndBoitCate(field.value)" :no-options-text=noOptionsTextAbb />
              </Field>
              <Field name="abonne" v-model="selectedAbonneId" v-slot="{ field }" v-else>
                <Multiselect v-model="field.value" :options="abonnesOps" @select="getLoyerAndBoitCate(field.value)"
                  placeholder="Sélectionner un abonné" />
              </Field>
              <ErrorMessage name="abonne" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Type de boîte <span class="text-danger">*</span>
              </label>
              <Field v-model="defautOp" name="typeBoite" v-slot="{ field }">
                <Multiselect :options="options" :searchable="true" v-model="field.value" v-bind="field"
                  placeholder="Sélectionner le type de boîte" />
              </Field>
              <ErrorMessage name="typeBoite" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Téléphone <span class="text-danger">*</span>
                <span style="float: right;" @click="sendOtp(false)" class="align-items-center" v-if="showOtp">
                  <i class="flaticon-send lh-1 me-8 position-relative top-1"></i>Vérifier le téléphone
                </span>
              </label>
              <Field name="telephone" v-model="telP" v-slot="{ field }">
                <vue-tel-input placeholder="Entrer le numéro de téléphone" v-model="field.value" v-bind="field"
                  defaultCountry="BJ" mode="international" @validate="validate"
                  class="shadow-none fs-md-15 text-black py-2">
                </vue-tel-input>
              </Field>
              <ErrorMessage name="telephone" class="text-danger" />
              <div v-if="!validPhone" class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-4" v-if="showOtp">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Code OTP
              </label>
              <Field name="code" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Renseigner le code OTP" />
              <ErrorMessage name="code" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Catégorie d'abonné <span class="text-danger">*</span>
              </label>
              <Field v-model="categorieCh" readonly="true" name="categorieAbonne" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Catégorie d'abonné" />
              <ErrorMessage name="categorieAbonne" class="text-danger" />
            </div>
          </div>

          <div class="col-md-12 mb-md-25">
            <div class="accordion" id="basicAccordion">
              <div class="accordion-item mb-0">
                <button class="accordion-button shadow-none fw-medium" type="button" data-bs-toggle="collapse"
                  data-bs-target="#basicCollapseOne" aria-expanded="true" aria-controls="basicCollapseOne">
                  Adresse complète à utiliser en cas de domiciliation de la boîte et livraison de colis
                </button>
                <div id="basicCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#basicAccordion">
                  <div class="accordion-body row">
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Arrondissement
                        </label>
                        <Field name="arrondissement" v-slot="{ field }">
                          <Multiselect :options="arrondissementOptions" :searchable="true" track-by="label"
                            label="label" v-model="selectedArrondissement" v-bind="field"
                            noOptionsText="Sélectionner d'abord une commune" @change="arrondissementChange($event)"
                            placeholder="Sélectionner l' arrondissement" />
                        </Field>
                      </div>
                      <ErrorMessage name="arrondissement" class="text-danger" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Quartier
                        </label>
                        <Field name="quartier" v-slot="{ field }">
                          <Multiselect :options="quartierOptions" :searchable="true" track-by="label" label="label"
                            v-model="selectedQuartier" v-bind="field"
                            noOptionsText="Sélectionner d'abord un arrondissement"
                            placeholder="Sélectionner le quartier" />
                        </Field>
                      </div>
                      <ErrorMessage name="quartier" class="text-danger" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          N° Carré / Maison
                        </label>
                        <Field name="adresseMaisonCarre" type="text" aria-disabled="true"
                          class="form-control shadow-none fs-md-15 text-black"
                          placeholder="C/015 Maison Tchatchabloukou" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Directive
                        </label>
                        <Field name="adresseDirective" type="text" aria-disabled="true"
                          class="form-control shadow-none fs-md-15 text-black"
                          placeholder="Non loin de la pharmacie AKONSOMBO" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Durée de l'abonnement <span class="text-danger">*</span>
              </label>
              <Field name="dureeAbonnement" v-model="dureeAbonnement" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la durée de l'abonnement" />
              <ErrorMessage name="dureeAbonnement" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nombre de clé
              </label>
              <Field v-model="nombreCle" readonly="true" name="nombreCle" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de clé" />
              <ErrorMessage name="nombreCle" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Loyer année encours
              </label>
              <Field readonly="true" v-model="loyerAnneeEncours" name="loyerAnneeEncours" type="text"
                aria-disabled="true" class="form-control shadow-none fs-md-15 text-black" placeholder="0" />
              <ErrorMessage name="loyerAnneeEncours" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Loyer annuel
              </label>
              <Field readonly="true" v-model="loyert" name="loyer" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel" />
              <ErrorMessage name="loyer" class="text-danger" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant caution
              </label>
              <Field v-model="cautionval" readonly="true" name="caution" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant caution" />
              <ErrorMessage name="caution" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Frais des clefs
              </label>
              <Field v-model="fraiscleval" readonly="true" name="fraisCle" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Frais des clefs" />
              <ErrorMessage name="fraisCle" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant remise
              </label>
              <Field v-model="montantRemise" readonly="true" name="montantRemise" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant remise" />
              <ErrorMessage name="montantRemise" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant Total de l'abonnement
              </label>
              <Field v-model="montanttotalval" readonly="true" name="montant" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Montant total l'abonnement" />
              <ErrorMessage name="montant" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone">
                Créer un abonnement
              </button>
              <router-link to="/liste-abonnements" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler
              </router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
  <AddAbonneModal @getabonne="getAbonne" />

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, toRef, watch, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import Swal from "sweetalert2";
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success, format_date, showModal, getUrlApiForFiles, openAndPrint } from '@/utils/utils';
import { Abonnement } from '@/models/Abonnement';
import { Option } from "@/models/Option";
import AddAbonneModal from "./AddAbonneModal.vue";
import { Abonne } from '@/models/Abonne';
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import JwtService from "@/services/JwtService";
import { KKIAPAY } from '@/models/KKIAPAY';

export default defineComponent({
  name: "AddAbonnement",
  components: {
    AddAbonneModal,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  setup: () => {
    const abonnementSchema = Yup.object().shape({
      categorieAbonne: Yup.string().required('La catégorie est obligatoire'),
      abonne: Yup.number().required(`L'abonné est obligatoire`),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
      // demande: Yup.number().typeError('Veuillez selectionner une demande'),
      adressePostale: Yup.string().required('L\'adresse postale est obligatoire'),
      bureau: Yup.number().typeError('Veuillez entrer un nombre').required('L\' agence est obligatoire'),
      commune: Yup.number().typeError('Veuillez entrer un nombre').required('La commune est obligatoire'),
      boite: Yup.string().required('La boîte postale est obligatoire'),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`Le loyer est obligatoire`),
      loyerAnneeEncours: Yup.number().typeError('Veuillez entrer un nombre').required(`Le loyer est obligatoire`),
      caution: Yup.number().typeError('Veuillez entrer un caution').required(`La caution est obligatoire`),
      montantRemise: Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      montant: Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      nombreCle: Yup.number().typeError('Veuillez entrer un nombre').max(5, 'Le nombre de clé ne doit pas être supérieur à 5').required(`Le nombre de clé est obligatoire`),
      typeBoite: Yup.string().typeError('Veuillez entrer une chaine de caractère').required(`Le type de boite est obligatoire`),
      modeleBoite: Yup.number().typeError('Veuillez entrer un nombre').required(`Le modèle de boite est obligatoire`),
      fraisCle: Yup.number().typeError('Veuillez entrer un nombre').required('Le frais des clefs est obligatoire'),
      dureeAbonnement: Yup.number().min(1).default(1).typeError('Veuillez entrer des chiffres').required('La durée de l\'abonnement est obligatoire'),
      code: Yup.number().typeError('Veuillez entrer un nombre'),
      quartier: Yup.string().notRequired(),
      arrondissement: Yup.string().notRequired(),
    });

    const options = [
      { value: 'Ordinaire', label: 'Ordinaire' },
      { value: 'Spécifique', label: 'Spécifique' }
    ];
    const etatAgence = ref(true);
    function openNewWindow(data: any) {
      openAndPrint(getUrlApiForFiles("Recu", data));
    }

    const router = useRouter();
    const abonnementForm = ref<Abonnement>();
    const dureeAbonnement = ref(1);
    //const categorieAbonnes = ref(null);
    const abonnesOptions = ref([]);
    const boitesOptions = ref([]);
    const adressePostalesOptions = ref([]);
    const categorieAbonnesOptions = ref([]);
    const demandesOptions = ref([]);
    const bureauxOptions = ref<Array<Option>>([]);
    const communesOptions = ref<Array<Option>>([]);
    const modelesBoitesOptions = ref([]);
    const servicesOptions = ref([]);
    const arrondissementOptions = ref([]);
    const quartierOptions = ref([]);
    const selectedArrondissement = ref([]);
    const selectedQuartier = ref([]);
    const bureauselect = ref();
    const communeSelect = ref();
    const modelselect = ref();
    const boitesselect = ref();
    const loyert = ref(0);
    const loyerAnneeEncours = ref(0);
    const cautionval = ref(0);
    const fraiscleval = ref(0);
    const montantRemise = ref(0);
    const montanttotalval = ref(0);
    const nombreCle = ref(3);
    const selectedAbonne = ref(false);
    const nooptions = "La liste est vide";
    const noResultText = "Aucun enregistrement trouvé";
    const defautOp = ref("Ordinaire")
    const noOptionsTextAdd = ref("Tapez au moins deux caractères");
    const noOptionsTextAbb = ref("Tapez au moins trois caractères");
    const noOptionsTextBoi = ref("Sélectionner d'abord le bureau et le modèle");
    const toggleAbonne = () => {
      selectedAbonne.value = !selectedAbonne.value;
    };
    const adressePostaleC = ref("");
    const categorieCh = ref();
    const idCategorie = ref();
    const etat = ref("");
    const abonneCharger = ref(null);


    const abonnesOps = ref<Array<{ value: any; label: string }>>([]);

    const selectedAbonneId = ref(0);
    const verifyNumber = ref(0);
    const showOtp = ref(true)
    const numVerify = ref(false)
    const codepay = ref();
    const nationalnumlber = ref();
    const numeroNational = ref();
    const telephone = ref();
    const validPhone = ref<boolean>(false);
    const numberPhone = ref();
    const telP = ref();

    const messagePromotion = ref("");
    const tauxPromotion = ref(0);
    const dateFinCampagne = ref(null);
    const categorieAbonneId = ref(0);
    const idAbonnement = ref(0);
    const isShow = ref(false);

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        numberPhone.value = phoneObject.nationalNumber;
        numeroNational.value = phoneObject.nationalNumber;
      } else {
        telephone.value = "";
        codepay.value = "";
        nationalnumlber.value = "";
        numberPhone.value = "";
      }
    }

    onMounted(async () => {
      await fetchModeles()
      await fetchsServices()
      await fetchCommune();

      if (JwtService.getUserCheckIfAllAgence() !== "true") {
        const element: any = await fetchAgences1(JwtService.getUserAgence());
        communeSelect.value = element.communeId;
        bureauselect.value = element.id;
      }
    });

    watch(bureauselect, (newValue, oldValue) => {
      bureauselect.value = newValue;
    });

    watch(communeSelect, (newValue, oldValue) => {
      communeSelect.value = newValue;
    });

    watch(abonneCharger, (newValue, oldValue) => {
      if (newValue != null && newValue != '') {
        console.log("valeur ajouter", newValue);
      } else {
        console.log("valeur non ajouter");
        messagePromotion.value = "";
      }
    });

    const getAbonne = async (values: { id: any; nomComplet: string; telephone: string; categorieAbonne: any }) => {
      try {
        selectedAbonneId.value = values.id;
        await fetchAbonne();
        await getLoyer();
        const data = {
          value: values.id,
          label: values.nomComplet + ' [ ' + values.telephone + ' ]',
        };
        abonnesOps.value = [...abonnesOps.value, data];
        console.log("nous sommes là");
        abonneCharger.value = values.id;
        return abonnesOps.value;
      } catch (error) {
        // Gérer l'erreur ici
      }
    };

    const fetchAbonne = async () => {
      try {
        const response = await axios.get(`/abonnes/${selectedAbonneId.value}`);
        const abonne = response.data.data;
        categorieCh.value = abonne.categorieAbonne.libelle;
        categorieAbonneId.value = abonne.categorieAbonne.id;
        telephone.value = abonne.telephone;
        return abonnesOps.value;
      } catch (error) {
        // Gérer l'erreur ici
      }
    };

    const addAbonnement = async (values, { resetForm }) => {
      if (typeof values.boite === 'string') {
        const boiteParts = values.boite.split('-');
        if (boiteParts.length > 0) {
          values.boite = parseInt(boiteParts[0], 10);
        }
      }
      values.categorieAbonne = idCategorie.value;
      values["numVerify"] = numVerify.value;
      values["etat"] = "Actif";
      ApiService.post("/abonnements/S", values)
        .then(async ({ data }) => {
          if (data.code == 201) {
            const reference = data.data.paiem.refPaiement + ".pdf";
            success(data.message);
            resetForm();
            router.push({ name: "ListeAbonnementPage" });

            const maFonction = async () => {
              const result = await Swal.fire({
                html: `<div>Cliquer sur le bouton Aperçu ci-dessous pour avoir un aperçu de votre document</div>`,
                icon: "success",
                buttonsStyling: true,
                confirmButtonText: "Aperçu",
                showCancelButton: false,
                cancelButtonText: `Fermer`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-warning",
                },
              });

              if (result.isConfirmed) {
                openNewWindow(reference)
              }
            }

            // Utiliser setTimeout pour exécuter la fonction après la durée spécifiée
            setTimeout(maFonction, 3000);
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    watch(dureeAbonnement, (newValue, oldValue) => {
      //montanttotalval.value = (((newValue - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value);
      calculeMontantTotal(newValue);
    });


    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    // async function successHandler(response) {
    //   check.typeError = "SUCCESS";
    //   check.idTransaction = response.transactionId;
    //   // chargement()
    //   return { check, response };
    // }

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        values["idTransaction"] = check.idTransaction;

        // Appel de la fonction addAbonnement et attente de son exécution
        await addAbonnement(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      // Dans le cas d'un échec, vous pouvez ajouter des actions supplémentaires ici
      // Par exemple, réinitialiser le formulaire ou gérer l'affichage d'un message d'erreur

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }



    // async function failHandler(response) {
    //   check.typeError = "ECHOUE";
    //   check.idTransaction = response.transactionId;
    //   return { check, response };
    // }

    const payer = async (values, { resetForm }) => {
      const result = await Swal.fire({
        text: "Choississez un moyen de paiement pour le paiement de la somme de " + values.montant + " F CFA",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "ELECTRONIQUE",
        cancelButtonText: `CASH`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
      if (result.isConfirmed) {
        //
      }
      check = {
        typeError: "RAS",
        idTransaction: 0
      };

      let dismissString: string | undefined;
      dismissString = String(result.dismiss);

      if (result.dismiss !== undefined && dismissString == "cancel" && result.isDismissed) {
        values["mode"] = 1;
        values["check"] = check;
        addAbonnement(values, { resetForm });
      }
      if (result.isConfirmed) {
        values["mode"] = 2;
        KKIAPAY.kkia(values.montant);
        //Attendre que le paiement soit effectué ou échoué
        await new Promise<void>((resolve) => {
          // addKkiapayListener('failed', failHandler);
          // addKkiapayListener('success', successHandler);

          addKkiapayListener('failed', (event) => {
            failHandler(event);  // Passe values et resetForm ici
            resolve();                                  // Résolution de la promesse
          });

          addKkiapayListener('success', (event) => {
            successHandler(event, values, { resetForm }); // Passe values et resetForm ici
            resolve();                                   // Résolution de la promesse
          });

          const checkRef = toRef(check);

          let resolved = false;

          const intervalId = setInterval(() => {
            if (!resolved && checkRef.value.typeError !== "RAS") {
              clearInterval(intervalId);
              resolved = true;
              resolve();
            }
          }, 500);
        });
        // console.log("check0 ==> ", check);
        // values["check"] = check;
        // if (check.typeError === "SUCCESS") {
        //   values["idTransaction"] = check.idTransaction;
        //   await addAbonnement(values, { resetForm });
        // } else {
        //   check.typeError = "RAS";
        //   check.idTransaction = 0;
        // }
        // console.log("check1 ==> ", check);
      }

    }

    const fetchAbonnes = async (key) => {
      try {
        const response = await axios.get('/abonnes/recherche/' + key);
        const abonnesData = response.data.data;
        abonnesOptions.value = abonnesData.map((abonne) => ({
          value: abonne.id,
          label: abonne.nomComplet + ' [ ' + abonne.telephone + ' ]',
        }));
        if (abonnesOptions.value.length == 0) {
          noOptionsTextAbb.value = noResultText;
        } else {
          noOptionsTextAbb.value = 'Tapez au moins trois caractères';
        }
        return abonnesOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchAgences = async (key) => {
      try {
        const response = await axios.get('/bureaus/recherche/' + key);
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchAgences1 = async (key) => {
      try {
        const response = await axios.get(`/bureaus/${parseInt(key)}`);
        const bureau = response.data.data;
        let data = [
          {
            value: bureau.id,
            label: bureau.nom,
          }
        ];
        bureauxOptions.value = data;
        return { id: bureau.id, communeId: bureau.commune.id };
      } catch (error) {
        //
      }
    };

    const fetchBoites = async () => {
      if (bureauselect.value && modelselect.value) {
        try {
          const response = await axios.get(`/boitePostales/recherche/${bureauselect.value}/${modelselect.value}`);
          const boitesData = response.data.data;
          boitesOptions.value = boitesData.map((boite) => ({
            value: boite.id + '-' + boite?.adressePostale?.adresse_postale,
            label: boite.refBoite
          }));
          if (boitesOptions.value.length == 0) {
            noOptionsTextBoi.value = "Il n'y a pas de boîte dans ce bureau pour ce modèle"
          } else {
            noOptionsTextBoi.value = "Sélectionner d'abord le bureau et le modèle";
          }
          return boitesOptions.value;
        } catch (error) {
          //
        }
      }
    };

    const modelChange = (selectedBoite) => {
      if (selectedBoite == null) return;
      const adressePostalePart = selectedBoite.split('-').slice(1).join('-');
      adressePostaleC.value = adressePostalePart;
    };

    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_boites');
        const modelesData = response.data.data;
        modelesBoitesOptions.value = modelesData.map((boite) => ({
          value: boite.id,
          label: boite.code + " [ " + boite.libelle + " ]",
        }));
      } catch (error) {
        //
      }
    };

    const fetchsServices = async () => {
      try {
        const response = await axios.get('/services');
        const servicesData = response.data.data;
        servicesOptions.value = servicesData.map((servicesData) => ({
          value: servicesData.id,
          label: servicesData.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const getLoyerAndBoitBureau = (val) => {
      boitesselect.value = "";
      boitesOptions.value = [];
      bureauselect.value = val;
      fetchBoites();
      getLoyer();
    }

    function communeChange(value) {
      if (value) {
        bureauxOptions.value = [];
        arrondissementOptions.value = [];
        selectedArrondissement.value = [];
        selectedQuartier.value = [];
        bureauselect.value = "";
        ApiService.get('/bureaux/commune/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              bureauxOptions.value = donnee.map((bureau: any) => {
                return {
                  label: bureau.nom,
                  value: bureau.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //
          });
        ApiService.get('/communes/arrondissements/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              arrondissementOptions.value = donnee.map((arrondissement: any) => {
                return {
                  label: arrondissement.libelle,
                  value: arrondissement.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        arrondissementOptions.value = [];
        selectedArrondissement.value = [];
        selectedQuartier.value = [];
        bureauselect.value = "";
      }
    }

    function arrondissementChange(value) {
      if (value) {
        quartierOptions.value = [];
        selectedQuartier.value = [];
        ApiService.get('/arrondissements/quartiers/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              quartierOptions.value = donnee.map((quartier: any) => {
                return {
                  label: quartier.libelle,
                  value: quartier.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        quartierOptions.value = [];
        selectedQuartier.value = [];
      }
    }

    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(async ({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
          if (JwtService.getUserCheckIfAllAgence() !== "true") {
            const element: any = await fetchAgences1(JwtService.getUserAgence());
            communeSelect.value = element.communeId;
            bureauselect.value = element.id;
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    const getLoyerAndBoitModel = (val) => {
      boitesselect.value = "";
      boitesOptions.value = [];
      modelselect.value = val;
      fetchBoites();
      getLoyer();
    }

    const getLoyerAndBoitCate = (val) => {
      selectedAbonneId.value = val;
      fetchBoites();
      getLoyer();
    }

    const saveNum = ref();

    const getLoyer = async () => {
      if (selectedAbonneId.value) {
        try {
          const response = await axios.get(`/abonnes/${selectedAbonneId.value}`);
          const abonne = response.data.data;
          telephone.value = abonne.telephone
          telP.value = abonne.telephone
          saveNum.value = abonne.telephone;
          // console.log('telephone.value', telP.value)
          // console.log('abonne.telephone', abonne.telephone)
          numVerify.value = abonne.verifyNumber;

          watch(telP, (newVal, oldVal) => {
            // console.log('newVal =>', newVal);
            // console.log('oldVal =>', oldVal);
            if (newVal && newVal != oldVal) {
              const newNumber = newVal.replace(/\s/g, '');
              const oldNumber = oldVal.replace(/\s/g, '');
              // console.log('savenum =>', saveNum.value.replace(/\s/g, ''));
              // console.log('newNumber =>', newNumber);
              // console.log(newNumber == saveNum.value.replace(/\s/g, ''))
              if (newNumber != saveNum.value.replace(/\s/g, '') || abonne.verifyNumber === false) {
                // console.log('new', newNumber);
                // console.log('old', oldNumber);
                showOtp.value = true;
              }
              if (newNumber == saveNum.value.replace(/\s/g, '') && abonne.verifyNumber === true) {
                // console.log('=true', oldVal);
                // console.log('=true', oldVal);
                showOtp.value = false;
              }
              if (newNumber == saveNum.value.replace(/\s/g, '') && abonne.verifyNumber === false) {
                // console.log('=false', oldVal);
                // console.log('=false', oldVal);
                showOtp.value = true;
              }
            }

          });

          categorieCh.value = abonne.categorieAbonne.libelle
          if (abonne.verifyNumber === false) {
            showOtp.value = true
          } else {
            showOtp.value = false
          }
        } catch (error) {
          //
        }
      }

      // ici je recupere le numero du mois en cours
      const numberMouth = new Date().getMonth() + 1;

      if (selectedAbonneId.value && modelselect.value) {
        try {
          const response = await axios.get(`/tarifAnnuels/${selectedAbonneId.value}/${modelselect.value}`);
          const tarifAnnuels = response.data.data;
          console.log(tarifAnnuels, "TarifAnnuels tarifAnnuels tarifAnnuels", response.data.data.tarifAnnuel);
          loyerAnneeEncours.value = tarifAnnuels.loyerAnneeEncours;
          loyert.value = tarifAnnuels.cout;
          fraiscleval.value = tarifAnnuels.fraisCle;
          cautionval.value = tarifAnnuels.caution;
          tauxPromotion.value = tarifAnnuels.taux;
          dateFinCampagne.value = tarifAnnuels.dateFinCampagne;
          const categorieId = tarifAnnuels.categorie.id;
          const categorieLibelle = tarifAnnuels.categorie.libelle;
          idCategorie.value = categorieId;
          categorieCh.value = categorieLibelle;

          //const redu = await fetchReductionCampagne(idCategorie.value);
          calculeMontantTotal(dureeAbonnement.value);

        } catch (error) {
          //
        }
      }
    }

    const calculeMontantTotal = async (dure: number) => {
      if (fraiscleval.value >= 0 && cautionval.value >= 0 && loyert.value >= 0 && dure) {
        console.log("nous avons utiliser le taux ici", tauxPromotion.value);
        if (tauxPromotion.value > 0) {
          montantRemise.value = (((dure - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
          montanttotalval.value = (((dure - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value) - (((dure - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
          messagePromotion.value = "Une promotion de " + tauxPromotion.value + " % est accordée pour tout paiement effectué avant le " + format_date((dateFinCampagne.value && dateFinCampagne.value != null) ? dateFinCampagne.value : '');
        } else {
          montanttotalval.value = ((dure - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value;
        }
      } else {
        montanttotalval.value = 0;

        montantRemise.value = 0;
      }
    }


    // watch(telephone, ([newVal, oldVal]) => {
    //   if (newVal !== oldVal) {
    //     console.log('new', newVal)
    //     console.log('old', oldVal)
    //     showOtp.value = true;
    //   } else {
    //     console.log('old', oldVal)
    //     showOtp.value = false;
    //   }
    // });

    const fetchDemandes = async (key) => {
      try {
        const response = await axios.get('/demandes/recherche/' + key);
        const demandesData = response.data.data;
        demandesOptions.value = demandesData.map((demande) => ({
          value: demande.id,
          label: demande.refDem,
        }));
        return demandesOptions.value;
      } catch (error) {
        //
      }
    };
    const sendOtp = async (i) => {
      const dataToSend = {
        telephone: telephone.value,
        estEmail: i
      };
      console.log('fkflefle', dataToSend)
      ApiService.post("/abonnements/verify/abonne/number", dataToSend)
        .then(({ data }) => {
          console.log(data, "data.codedata.code");
          if (data.code == 200) {
            success(data.message);
          }
        }).catch(({ response }) => {
          console.log("Mauvais", response);
          error(response.data.message);
        });
    };



    // reccuperation de la campagne de reduction en cours
    const fetchReductionCampagne = async (idCat) => {
      try {
        const response = await axios.get(`/campagne/reduction/active?id=${idCat}&`);
        const reductionCampagneData = response.data.data;
        messagePromotion.value = "Une promotion de " + reductionCampagneData.taux + " % est accordée pour tout paiement effectué avant le " + format_date(reductionCampagneData.dateFin);
        tauxPromotion.value = reductionCampagneData.taux;
      } catch (error) {
        //
      }
    };

    return {
      getAbonne,
      abonnesOps,
      loyert,
      loyerAnneeEncours,
      dureeAbonnement,
      bureauselect,
      communeSelect,
      //cateselect,
      modelselect,
      boitesselect,
      montanttotalval,
      cautionval,
      fraiscleval,
      montantRemise,
      defautOp,
      nombreCle,
      payer,
      getLoyerAndBoitBureau,
      getLoyerAndBoitModel,
      getLoyerAndBoitCate,
      getLoyer,
      fetchDemandes,
      fetchBoites,
      fetchAgences,
      fetchAgences1,
      //fetchAdressePostale, 
      options,
      abonnementSchema,
      modelesBoitesOptions,
      adressePostalesOptions,
      addAbonnement,
      fetchAbonnes,
      noResultText,
      abonnementForm,
      //categorieAbonnes,
      bureauxOptions,
      boitesOptions,
      abonnesOptions,
      categorieAbonnesOptions,
      demandesOptions,
      servicesOptions,
      communeChange,
      communesOptions,
      selectedAbonne,
      toggleAbonne,
      selectedAbonneId,
      nooptions,
      noOptionsTextAdd,
      noOptionsTextAbb,
      noOptionsTextBoi,
      modelChange,
      adressePostaleC,
      categorieCh,
      verifyNumber,
      showOtp,
      telephone,
      validPhone,
      validate,
      numeroNational,
      sendOtp,
      numVerify,
      etat,
      etatAgence,
      telP,
      saveNum,
      quartierOptions,
      arrondissementOptions,
      arrondissementChange,
      selectedArrondissement,
      selectedQuartier,
      format_date,
      messagePromotion,
      abonneCharger,
    };
  },
});
</script>

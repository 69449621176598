<template>
    <BreadCrumb PageTitle="Etats des Mpost Box" />
    <EtatMpostBox />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatMpostBox from "../../components/Etats/EtatMpostBox.vue";
  
    export default defineComponent({
      name: "EtatMPostBoxPage",
      components: {
        BreadCrumb,
        EtatMpostBox,
      },
    });
  </script>
<template>
  <!-- <MainHeader />
  <MainSidebar />
  <div class="main-content d-flex flex-column transition overflow-hidden">
    <router-view />
    <MainFooter />
  </div> -->
  <RouterView/>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouterView } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    RouterView
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Liste des attestations" />
    <ListeAttestation/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeAttestation from "../../components/Attestation/ListeAttestation.vue";

  
    export default defineComponent({
      name: "ListeAttestationPage",
      components: {
        BreadCrumb,
        ListeAttestation,
      },
    });
  </script>
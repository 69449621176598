<template>
    <BreadCrumb PageTitle="Liste des configurations" />
    <ListeParametre/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeParametre from "../../components/Parametre/ListeParametre.vue";

    export default defineComponent({
      name: "ListeParametrePage",
      components: {
        BreadCrumb,
        ListeParametre,
      },
    });
  </script>
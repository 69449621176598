<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link v-if="checkPermission('AddNotificationCampagne')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajoute-notification-campagne"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Envoyer une notification de campagne
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
       <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une campagne"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                #
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Code
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Libellé
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date de début
              </th>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date de fin
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Taux ( en % )
              </th>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nbre Envoie
              </th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="(ca , index) in campagnes" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ index + 1 }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ ca.code }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ ca.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(ca.dateDebut) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(ca.dateFin) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ (ca.typeDeCampagne) ? ca.typeDeCampagne : '-' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ ca.taux + ' %' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ (ca.nmbreEnvoie) +' / '+ (ca.nmbreDeFois) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>


    <!-- MODAL -->
    <div
    class="modal fade createNewModal"
    id="Image"
    tabindex="-1"
    ref="ImageRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <div>
            <img :src="image_" alt="" />
          </div>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
  <!-- ENDMODAL -->

</template>


<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression, error } from "../../utils/utils";
import { useRouter } from 'vue-router';
import { Campagne } from '@/models/Campagne';
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";


export default defineComponent({
    name: "ListeCampagne",
    components: {
      PaginationComponent
    },
 setup(){
    
    const router = useRouter();
    const campagnes = ref<Array<Campagne>>([]);
    const ImageRef = ref<null | HTMLElement>(null);
    const image_ = ref("");
    const imageBaseUrl = 'http://localhost:3001/uploads';

   // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllCampagne(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllCampagne(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE
  

    

    function getAllCampagne(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/campagnes?page=${page}&limit=${limi}&mot=${searchTerm}&nombreDeFois='yes'&`)
        .then(({ data }) => {
          const donnees = data.data.data;
          campagnes.value = donnees;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
          error(response.data.msg);
        });
    }

    function showImage(id: number) {
      const campagne = campagnes.value.find((c) => c.id === id);
      image_.value = imageBaseUrl+'/'+campagne?.urlImage || "";
      if (campagne) {
        showModal(ImageRef.value);
      }
    }

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
    return privileges.value.includes(name);
    }

    onMounted(() => {
      getAllCampagne();
    });

    return { campagnes,
      checkPermission,
      format_date ,
      suppression,
      showImage,
      ImageRef,
      image_,
      page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
      searchTerm
    };
  },
});
</script>
<template>
  <div class="row">
      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-demandes" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr v-if="demande?.refDemande">
                  <td>Référence</td>
                  <td>{{ demande?.refDemande }}</td>
                </tr>
                <tr v-if="demande?.nomCompletBeneficiaire">
                  <td>Nom du bénéficiaire</td>
                  <td>{{ demande?.nomCompletBeneficiaire }}</td>
                </tr>
                <tr v-if="demande?.typeDemande">
                  <td>Type de demande</td>
                  <td>{{ demande?.typeDemande?.code }} - {{ demande?.typeDemande?.libelle }}</td>
                </tr>
                <tr v-if="demande?.nouvelleAgence">
                  <td>Nouvelle Agence</td>
                  <td>{{ demande?.nouvelleAgence?.code }} - {{ demande?.nouvelleAgence?.nom }}</td>
                </tr>
                <tr v-if="demande?.abonnement">
                  <td>Abonnement</td>
                  <td>{{ demande?.abonnement?.refAbo }} - {{ demande?.abonnement?.adresseVirtuelle }}</td>
                </tr>
                <tr v-if="demande?.telephoneBeneficiaire">
                  <td>Téléphone du bénéficiaire</td>
                  <td>{{ demande?.telephoneBeneficiaire }}</td>
                </tr>
                <tr v-if="demande?.montant">
                  <td>Montant</td>
                  <td>{{ separateur(demande?.montant) }}</td>
                </tr>
                <tr v-if="demande?.categorieBeneficiaire">
                  <td>Catégorie</td>
                  <td>{{ demande?.categorieBeneficiaire?.code }} - {{ demande?.categorieBeneficiaire?.libelle }}</td>
                </tr>
                <tr v-if="demande?.secteurActiviteBeneficiaire">
                  <td>Secteur d'activité</td>
                  <td>{{ demande?.secteurActiviteBeneficiaire?.code }} - {{ demande?.secteurActiviteBeneficiaire?.libelle }}</td>
                </tr>
                <tr v-if="demande?.dateAcceptation">
                  <td>Date d'acceptation</td>
                  <td>{{format_date( demande?.dateAcceptation) }} </td>
                </tr>
                <tr v-if="demande?.dateValidation">
                  <td>Date de validation</td>
                  <td>{{format_date( demande?.dateValidation) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Autres informations</h4>
            <table class="table">
              <tbody>
                <tr v-if="demande?.objet">
                  <td>Objet</td>
                  <td>{{ demande?.objet }}</td>
                </tr>
                <tr v-if="demande?.autreObjet">
                  <td>Autre objet</td>
                  <td>{{ demande?.autreObjet }}</td>
                </tr>
                <tr v-if="demande?.motif">
                  <td>Motif</td>
                  <td>{{ demande?.motif }}</td>
                </tr>
                <tr v-if="demande?.contenu">
                  <td colspan="2">Contenu</td> 
                </tr>
                <tr v-if="demande?.contenu">
                  <td colspan="2">{{ demande?.contenu }} </td> 
                </tr>
                <tr v-if="demande?.status">
                  <td>Statut</td>
                  <td>{{ demande?.status }}</td>
                </tr>
                <tr v-if="demande?.boite">
                  <td>Boite</td>
                  <td>{{ demande?.boite?.refBoite }}</td>
                </tr>
                <tr v-if="demande?.abonne">
                  <td>Abonné</td>
                  <td>{{ demande?.abonne?.nomComplet }}</td>
                </tr>
                <tr v-if="demande?.abonne">
                  <td>Téléphone</td>
                  <td>{{ demande?.abonne?.telephone }}</td>
                </tr>
                
                <tr v-if="demande?.dateDemande">
                  <td>Date de demande</td>
                  <td>{{ format_date(demande? demande.createdAt : null) }}</td>
                </tr>
                <tr v-if="demande?.abonne?.categorieAbonne">
                  <td>Categorie</td>
                  <td>{{ demande?.abonne?.categorieAbonne?.libelle }}</td>
                </tr>
                <tr v-if="demande?.abonne?.activite">
                  <td>Secteur</td>
                  <td>{{ demande?.abonne?.activite?.libelle }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Demande } from "@/models/Demande";
import { error, format_date,separateur } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewDemande",
  setup: () => {
    const route = useRoute();
    const demande = ref<Demande | null>(null);
    const demandes = ref<Array<Demande>>([]);

    function getDemande(id: string) {
      return ApiService.get("/demandes", id)
        .then(({ data }) => {
          demande.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getDemande(id);
    });

    return { demande, getDemande, format_date, demandes,separateur };
  },
});
</script>

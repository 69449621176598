<template>
  <BreadCrumb PageTitle="Modifier une demande" />
  <EditDemande />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import EditDemande from "../../components/Demandes/AddDemande/EditDemande.vue";

  export default defineComponent({
    name: "EditDemandePage",
    components: {
      BreadCrumb,
      EditDemande,
    },
  });
</script>
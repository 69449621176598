<template>
    <BreadCrumb PageTitle="Liste des clés-types de notification" />
    <ListeCleTypeNotification/>
</template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCleTypeNotification from "../../components/CleTypeNotification/ListeCleTypeNotification.vue";

  
    export default defineComponent({
      name: "ListeCleTypeNotificationPage",
      components: {
        BreadCrumb,
        ListeCleTypeNotification,
      },
    });
  </script>
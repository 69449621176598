<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="mb-15 d-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">{{ nom }}</h5>
        <!-- <div class="dropdown">
          <button
            class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);">
                <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                View
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);">
                <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                Edit
              </a>
            </li>
            <li>
              <a  class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);">
                <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                Delete
              </a>
            </li>
          </ul>
        </div> -->
      </div>
      <div id="projectDistributionChart" class="chart">
        <apexchart
          type="donut"
          height="382"
          :options="projectDistributionChart"
          :series="projects"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DonutChart",
  props: {
    nom:{
			type:String,
			required: true,
		},
    project:{
			type: Array,
			required: true,
		},
    labels:{
			type: Array,
			required: true,
		},
    colors:{
			type: Array,
			required: true,
		},
  },

  setup:(props) =>{
      const proj = ref(props.project);
      const projects= proj.value;
      const projectDistributionChart= {
        chart: {
          type: "donut",
          height: 382,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        colors: props.colors,
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#9C9AB6",
          },
          markers: {
            offsetX: -2,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 5,
          },
        },
        labels:props.labels,
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      };
    return {
      projects,
      projectDistributionChart,
    };
  }

});
</script>
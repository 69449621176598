<template>
    <BreadCrumb PageTitle="Liste des rayons" />
    <ListeRayon/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeRayon from "../../components/Rayon/ListeRayon.vue";

    export default defineComponent({
      name: "ListeRayonPage",
      components: {
        BreadCrumb,
        ListeRayon,
      },
    });
  </script>
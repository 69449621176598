<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="demandeForm" @submit="editDemande" :validation-schema="demandeSchema">
      <div class="row">
         <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Type de demande <span class="text-danger">*</span>
              </label>
              <Field name="typeDemande" v-slot="{ field }">
                  <Multiselect 
                    :options="typeDemandeOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
              <ErrorMessage name="typeDemande" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement <span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
        </div>
          <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Référence <span class="text-danger">*</span>
              </label>
              <Field name="refDemande" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="refDemande" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Date <span class="text-danger">*</span>
            </label>
            <Field name="dateDemande" type="date" 
            class="form-control shadow-none rounded-0 text-black"/>
              <ErrorMessage name="dateDemande" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Contenu <span class="text-danger">*</span>
            </label>
            <Field name="contenu" v-slot="{ field }">
            <QuillEditor
              v-model="field.value"
              v-bind="field"
              @text-change="chargeContenu"
              theme="snow"
              placeholder="Ecrivez la demande ici..."
              toolbar="full"/>
            </Field>
            <ErrorMessage name="contenu" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier une demande
            </button>
            <router-link to="/liste-demandes" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import  Swal  from "sweetalert2";
import * as Yup from 'yup';
import { useRouter, useRoute } from "vue-router";
import ApiService from '@/services/ApiService';
import { Demande } from '@/models/Demande';
import Multiselect from '@vueform/multiselect'
import { QuillEditor } from '@vueup/vue-quill'
import router from '@/router';
import { error } from '@/utils/utils';

export default({
  name: "EditDemande",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
  QuillEditor
},
setup: () => {
  const demandeSchema = Yup.object().shape({
    refDemande: Yup.string().required('La référence est obligatoire'),
    dateDemande: Yup.date().required('La date de demande est obligatoire'),
    typeDemande: Yup.string().typeError('Veuillez entrer un nombre').required('Le type de demande est obligatoire'),
    abonnement: Yup.string().typeError('Veuillez entrer un nombre').required('L\'abonnement est obligatoire'),
    // status: Yup.boolean().required('Le status est obligatoire'),
    contenu: Yup.string().required('Le contenu est obligatoire'),
  });

  const demandeForm = ref<null | Demande>(null);
  const abonnementOptions = ref([]);
  const typeDemandeOptions = ref([]);
  const route = useRoute();
  const id = route.params.id;
  const isUPDATE = ref(false);

    const lordEditData = async () => {
      ApiService.get("/demandes/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        for (const key in donnees) {
          demandeForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        isUPDATE.value = true;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const editDemande = async (values: any) => {
        values = values as Demande;
        ApiService.put("/demandes/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) {
                Swal.fire({
                    text: data.message,
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    heightAuto: false,
                    customClass: {
                        confirmButton: "btn fw-semobold btn-primary",
                    },
                });
                router.push({ name: 'ListeBoitePostalePage' });
            }
        })
        .catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réssayer à nouveau!",
                heightAuto: false,
                customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                },
                timer:10
            });
        });
    };

    const fetchAbonnements = async () => {
      ApiService.get("/abonnements")
      .then(({ data }) => {
        const donnees = data.data;
        abonnementOptions.value = donnees.map((abonne) => {
          return {
            value: abonne.id,
            label: abonne.refAbo,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchTypeDemandes = async () => {
      ApiService.get('/typeDemandes')
        .then(({ data }) => {
            const donneee = data.data;
            typeDemandeOptions.value = donneee.map((typeDemande: any) => {
              return {
                id: typeDemande.id,
                label: typeDemande.libelle,
                value: typeDemande.id,
              };
            });
        })
        .catch((response) => {
          error(response.data.message)
        });
    }

    onMounted(() => {
      lordEditData();
      fetchAbonnements();
      fetchTypeDemandes();
    });
  return { demandeSchema, editDemande, demandeForm, abonnementOptions, typeDemandeOptions, lordEditData};
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <BreadCrumb PageTitle="Envoyer une demande" />
  <AddDemande />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddDemande from "../../components/Demandes/AddDemande/AddDemande.vue";

  export default defineComponent({
    name: "AddDemandePage",
    components: {
      BreadCrumb,
      AddDemande,
    },
  });
</script>
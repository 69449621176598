<template>
  <div
    class="modal fade createNewModal"
    id="AddParametreModal"
    tabindex="-1"
    ref="addParametreModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="parametreForm" @submit="addParametre" :validation-schema="parametreSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  CLE
                </label>
                <Field name="cle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer une clé"/>
                <ErrorMessage name="cle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  VALEUR
                </label>
                <Field name="valeur" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la valeur de la clé"/>
                <ErrorMessage name="valeur" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Désignation
                </label>
                <Field name="designation" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la valeur de la clé"/>
                <ErrorMessage name="designation" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Fichier
                </label>
                <Field name="fichier" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                      @change="onFileChange"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez un fichier"/>
              <ErrorMessage name="fichier" class="text-danger"/>

              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
            <!-- <button type="button" @click="resetForm()">Reset</button> -->
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
        
      </div>
    </div>
  </div>
  <div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, toRef, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { hideModal, showModal, success, error } from '@/utils/utils';
import { Parametre } from '@/models/Parametre';
import { title } from 'process';
import axios from "axios";

export default {
  name: "AddParametreModal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    item: {
      type: Number,
      required: true
    },
  },
  setup(props, { emit }){

    const parametreSchema = Yup.object().shape({
      cle: Yup.string().required('La clé est obligatoire'),
      valeur: Yup.string().required('La valeur est obligatoire'),
      designation:Yup.string(),
      fichier: Yup.mixed().notRequired(),
    });
    

    let parametreForm = ref<Parametre | null>(null);
    const router = useRouter();
    const addParametreModalRef = ref<null | HTMLElement>(null);
    const parametrenew = ref<Parametre | null>(null);
    const isUPDATE = ref(false);
    const selectedFile = ref<File | null>();

    watch(() => props.item , (newValue) => {
      if(newValue){
        getParametre(newValue);
        isUPDATE.value = true;
      }
      
      btnTitle();
    });

    
    const title = ref('Ajouter une configuration');
    const btntext = ref('Ajouter')


    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier une configuration";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une configuration";
         btntext.value = "Ajouter";
      }
    }

    const getParametre = async (id: number) => {
      return ApiService.get("/parametres/"+id)
      .then(({ data }) => {
        for (const key in data.data) {
          parametreForm.value?.setFieldValue(key, data.data[key]);
        }
        emit('openmodal', addParametreModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addParametre = async (values: any ,parametreForm) => {
      values = values as Parametre;

      let formData = new FormData();
      for(let i in values){
        if(i != 'fichier' && (values[i] !== undefined ))
          formData.append(i, values[i] );
      }
      formData.append('folderName', 'parametres');

      if (selectedFile.value) {
        formData.append('fichier', selectedFile.value);
      }

      if(isUPDATE.value){
        // ApiService.put("/parametres/"+values.id,values)
        axios.put("/parametres/"+values.id,formData, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              parametreForm.resetForm();
              hideModal(addParametreModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        // let formData = new FormData();
        //   for(let i in values){
        //     if(i != 'fichier' && (values[i] !== undefined ))
        //       formData.append(i, values[i] );
        //   }
        //   formData.append('folderName', 'parametres');

        //   if (selectedFile.value) {
        //     formData.append('fichier', selectedFile.value);
        //   }
        // ApiService.post("/parametres",values)
        console.log('Add', values);
        axios.post("/parametres",formData, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              parametreForm.resetForm();
              hideModal(addParametreModalRef.value);
              isUPDATE.value = false;
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
      
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    const onFileChange = (e) => {
      selectedFile.value = e.target.files[0];
    };

    return { parametreSchema, addParametre, parametreForm, addParametreModalRef,
      btnTitle,
      title,
    btntext,resetValue, onFileChange, selectedFile };
  },
};
</script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="redevanceForm" @submit="addRedevance" :validation-schema="redevanceSchema">
      <div class="row">
        <!-- <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Date
            </label>
            <Field name="dateRed" type="date" 
            class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
              <ErrorMessage name="dateRed" class="text-danger"/>
          </div>
        </div> -->
        
        <!-- <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    @change="selectLastRedevanceYear"
                    placeholder="Sélectionner l'abonnement"
                  />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
        </div> -->
        <div  class="col-md-8">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Abonnement <span class="text-danger">*</span>
            </label>
            <Field name="abonnement" v-slot="{ field }">
                <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonnement"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  @change="selectLastRedevanceYear"
                  :options="async (query) => {
                   return await fetchAbonnements(query)
                  }"
                  noResultText= "Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                  :no-options-text=noOptionsTextAbb
                />
            </Field>
            <ErrorMessage name="abonnement" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Année <span class="text-danger">*</span>
              </label>
              <Field name="annee" v-slot="{ field }">
                  <Multiselect 
                    :options="years" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner une année"
                  />
              </Field>
              <ErrorMessage name="annee" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Faire une redevance
            </button>
            <router-link to="/liste-redevances" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error , success,getUrlApiForFiles,openAndPrint } from '@/utils/utils';
import { Redevance } from '@/models/Redevance';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import axios from 'axios';
import Swal from 'sweetalert2';

export default ({
  name: "AddRedevance",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const redevanceSchema = Yup.object().shape({
    abonnement: Yup.string().required('L\' abonnement est obligatoire'),
    annee: Yup.string().required('L\'année est obligatoire'),
  });

  const redevanceForm = ref<null | Redevance>(null);
  const abonnement = ref(0);
  const abonnementOptions = ref([]);
  const year = ref(new Date().getFullYear());
  const years = ref([{}]);
  const noOptionsTextAbb=ref("Tapez au moins trois caractères");
  const nooptions="Aucun enregistrement trouvé";
  const noResultText="Aucun enregistrement trouvé";
  
  watch(abonnement, (value) => {
    //
  });

  const addRedevance = async (value: any) => {
    
    value = value as Redevance;
    value.arrierer = 0;
    value.status = "En cours";
    value.dateRedevance = new Date().toISOString();
    value.montant = parseInt(value.abonnement.split('-')[1]);
    value.abonnement = value.abonnement.split('-')[0];
    value.refRedevance = 'RE'+Date.now();

    return ApiService.post('/redevances', value)
      .then(async({ data }) => {
       const fullUrl = "Redevance";
        
        if (data.code === 201) {
          const nom = data.data.refRedevance + ".pdf";
         console.log("C'est bon");
         success(data.message);
          router.push({ name: "ListeRedevancePage" });
          const maFonction = async () => {
              const result = await Swal.fire({
                html: `<div>Cliquer sur le bouton Aperçu ci-dessous pour avoir un aperçu de votre document</div>`,
                icon: "success",
                buttonsStyling: true,
                confirmButtonText: "Aperçu",
                showCancelButton: false,
                cancelButtonText: `Fermer`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-warning",
                },
              });

              if (result.isConfirmed) {
                openNewWindow(data.data)
              }
          }

          // Utiliser setTimeout pour exécuter la fonction après la durée spécifiée
          setTimeout(maFonction, 3000);
        }
      })
      .catch((err) => {
        //
      });
  };

  function openNewWindow(data:any){
    console.log("VOIIILA   ===> ",data, "   Redevance ===> ",data.refRedevance + ".pdf")
      openAndPrint(getUrlApiForFiles("Redevance",data.refRedevance + ".pdf"));
    }

  const selectLastRedevanceYear = async (value: any) => {
   
    value = value.split('-')[0];
    return ApiService.get("/last/redevance/year/"+value)
      .then(({ data }) => {

        if(data.code === 200) {
          abonnement.value = 1;
          years.value = [{ value: parseInt(data.data), label: parseInt(data.data), selected: true }];
        }
        
      })
      .catch((response) => {
        error(response.data.message);
      });
  };


  const fetchAbonnements = async (key) => {  
      try {
        const response = await axios.get('/abonnements/recherche/'+key);
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          // value: abonnement.id,
          // label: abonnement.abonne.nomComplet+' [ '+abonnement.boite.refBoite+' ]',
          value: abonnement.id+'-'+abonnement.loyer,
          label: `${abonnement.refAbo} - ${abonnement.abonne.nomComplet} - [${ abonnement.adresseVirtuelle ? abonnement.adresseVirtuelle : abonnement.boite?.adressePostale?.adresse_postale}]`,
        }));
        if (abonnementOptions.value.length==0) {
          noOptionsTextAbb.value=noResultText;
        }else{
          noOptionsTextAbb.value='Tapez au moins trois caractères';
        }
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }


  return {  redevanceSchema, addRedevance, redevanceForm,openAndPrint,
     abonnementOptions, years, selectLastRedevanceYear, fetchAbonnements,
     noOptionsTextAbb,noResultText
     };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-modele_notifications" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Contenu</td>
                  <td>{{ mn?.contenu }}</td>
                </tr>
                <tr>
                  <td>Type de notificaion</td>
                  <td>{{ mn?.typeNotification ? mn.typeNotification.libelle : '' }}</td>
                </tr>
                <tr>
                  <td>Est Actif</td>
                  <td><span v-if="mn?.estActif === true" class="badge text-outline-info">Oui</span>
              <span v-if="mn?.estActif === false" class="badge text-outline-danger">Non</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { ModeleNotification } from "@/models/ModeleNotification";
import { error, format_date } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewAbonne",
  setup: () => {
    const route = useRoute();
    const mn = ref<ModeleNotification | null>(null);

    function getModeleNotification(id: string) {
      return ApiService.get("/modeleNotification", id)
        .then(({ data }) => {
          mn.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getModeleNotification(id);
    });

    return { mn, getModeleNotification, format_date };
  },
});
</script>

<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="mpostBoxForm" @submit="payer" :validation-schema="mpostBoxSchema">
        <div class="row">
          <!-- <div v-if="!etatAgence" class="col-md-6"> -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune <span class="text-danger">*</span>
              </label>
              <Field name="commune" v-model="communeSelect" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :searchable="true"
                  @change="communeChange($event)" placeholder="Selectionner une commune"
                  noOptionsText="Aucun enregistrement trouvé" />
              </Field>
              <ErrorMessage name="commune" class="text-danger" />
            </div>
          </div>
          <!-- <div v-else class="col-md-6">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Commune <span class="text-danger">*</span>
            </label>
            <Field name="commune" v-model="communeSelect" v-slot="{ field }">
              <Multiselect 
                  v-model = "field.value"
                  v-bind = "field"
                  :options="communesOptions"
                  :searchable="true"
                  
                  placeholder="Selectionner une commune"
                  noOptionsText="Aucun enregistrement trouvé"/>
            </Field>
            <ErrorMessage name="commune" class="text-danger"/>
          </div>
        </div> -->
          <!-- <div v-if="!etatAgence" class="col-md-6"> -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau <span class="text-danger">*</span>
              </label>
              <Field v-model="bureauselect" name="bureau" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options=bureauxOptions @change="genererAdresse"
                  placeholder="selectionner un bureau" noOptionsText="selectionner d'abord une commune" />
              </Field>
              <ErrorMessage name="bureau" class="text-danger" />
            </div>
          </div>
          <!-- <div v-else class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau <span class="text-danger">*</span>
              </label>
              <Field v-model="bureauselect" name="bureau" v-slot="{ field }">
                <Multiselect 
                    v-model = "field.value"
                    v-bind = "field"
                    :options=bureauxOptions
                    placeholder="selectionner un bureau"
                    noOptionsText="selectionner d'abord une commune"
                    />
              </Field>
              <ErrorMessage name="bureau" class="text-danger"/>
            </div>
        </div> -->
          <div class="col-md-8">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonné <span class="text-danger">*</span>
                <a style="float: right;" v-if="!selectedAbonne"
                  class="default-btn transition border-0 fw-medium text-white rounded-1 bg-success d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                  data-bs-toggle="modal" data-bs-target="#AddAbonneModal" @click="toggleAbonne">
                  <i class="flaticon-plus position-relative ms-5 fs-12"></i>
                  Ajouter un abonné
                </a>
                <button style="float: right;" v-if="selectedAbonne"
                  class="default-btn transition border-0 fw-medium text-white rounded-1 bg-success d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                  @click="toggleAbonne">
                  <i class="flaticon-plus position-relative ms-5 fs-12"></i>
                  Rechercher un abonné
                </button>
              </label>
              <Field name="abonne" v-slot="{ field }" v-if="!selectedAbonne">
                <Multiselect v-model="field.value" v-bind="field" placeholder="Rechercher un abonné"
                  :filter-results="false" :min-chars="3" :resolve-on-load="false" :delay="0" :searchable="true"
                  :options-limit="300" :options="async (query) => {
                    return await fetchAbonnes(query)
                  }" @select="getLoyerAndBoitCate(field.value)" :no-options-text=noOptionsTextAbb />
              </Field>
              <Field name="abonne" v-model="selectedAbonneId" v-slot="{ field }" v-else>
                <Multiselect v-model="field.value" :options="abonnesOps" @select="getLoyerAndBoitCate(field.value)"
                  placeholder="Sélectionner un abonné" />
              </Field>
              <ErrorMessage name="abonne" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Téléphone <span class="text-danger">*</span>
                <span style="float: right;" @click="sendOtp(false)" class="align-items-center" v-if="showOtp">
                  <i class="flaticon-send lh-1 me-8 position-relative top-1"></i>Vérifier le téléphone
                </span>
              </label>
              <Field name="numero" v-model="telP" v-slot="{ field }">
                <vue-tel-input placeholder="Entrer le numéro de téléphone" v-model="field.value" v-bind="field"
                  defaultCountry="BJ" mode="international" @validate="validate"
                  class="shadow-none fs-md-15 text-black py-1">
                </vue-tel-input>
              </Field>
              <ErrorMessage name="numero" class="text-danger" />
              <div v-if="!validPhone" class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-4" v-if="showOtp">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Code OTP
              </label>
              <Field name="otp" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Renseigner le code OTP" />
              <ErrorMessage name="otp" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse virtuelle <span class="text-danger">*</span>
              </label>
              <Field readonly="true" v-model="adressePostales" name="adresseVirtuelle" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse virtuelle" />
              <ErrorMessage name="adresseVirtuelle" class="text-danger" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Frais d'abonnement
              </label>
              <Field name="loyer" v-model="montant" type="text" class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer les frais d'abonnements" />
              <ErrorMessage name="loyer" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12 mb-md-25">
            <div class="accordion" id="basicAccordion">
              <div class="accordion-item mb-0">
                <button class="accordion-button shadow-none fw-medium" type="button" data-bs-toggle="collapse"
                  data-bs-target="#basicCollapseOne" aria-expanded="true" aria-controls="basicCollapseOne">
                  Adresse complète à utiliser en cas livraison de colis à domicile
                </button>
                <div id="basicCollapseOne" class="accordion-collapse collapse show" data-bs-parent="#basicAccordion">
                  <div class="accordion-body row">
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Arrondissement
                        </label>
                        <Field name="arrondissement" v-slot="{ field }">
                          <Multiselect :options="arrondissementOptions" :searchable="true" track-by="label"
                            label="label" v-model="selectedArrondissement" v-bind="field"
                            noOptionsText="Sélectionner d'abord une commune" @change="arrondissementChange($event)"
                            placeholder="Sélectionner l' arrondissement" />
                        </Field>
                      </div>
                      <ErrorMessage name="arrondissement" class="text-danger" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Quartier
                        </label>
                        <Field name="quartier" v-slot="{ field }">
                          <Multiselect :options="quartierOptions" :searchable="true" track-by="label" label="label"
                            v-model="selectedQuartier" v-bind="field"
                            noOptionsText="Sélectionner d'abord un arrondissement"
                            placeholder="Sélectionner le quartier" />
                        </Field>
                      </div>
                      <ErrorMessage name="quartier" class="text-danger" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          N° Carré / Maison
                        </label>
                        <Field name="adresseMaisonCarre" type="text" aria-disabled="true"
                          class="form-control shadow-none fs-md-15 text-black"
                          placeholder="C/015 Maison Tchatchabloukou" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Directive
                        </label>
                        <Field name="adresseDirective" type="text" aria-disabled="true"
                          class="form-control shadow-none fs-md-15 text-black"
                          placeholder="Non loin de la pharmacie AKONSOMBO" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone">
                Faire un abonnement
              </button>
              <router-link to="/liste-mpostboxs" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
  <AddAbonneModal @getabonne="getAbonne" />

</template>
<script lang="ts">
import Swal from "sweetalert2";
import { defineComponent, reactive, toRef, watch, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success, getUrlApiForFiles, openAndPrint } from '@/utils/utils';
import { VueTelInput } from 'vue3-tel-input'
import AddAbonneModal from "./AddAbonneModal.vue";
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import JwtService from "@/services/JwtService";
import { Option } from "@/models/Option";
import { KKIAPAY } from "@/models/KKIAPAY";

export default defineComponent({
  name: "AddMpostBox",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    AddAbonneModal,
    VueTelInput
  },

  setup: () => {
    const mpostBoxSchema = Yup.object().shape({
      abonne: Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
      demande: Yup.number().typeError('Veuillez selectionner une demande'),
      bureau: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      commune: Yup.number().typeError('Veuillez entrer un nombre').required('La commune est obligatoire'),
      numero: Yup.string().typeError('').required(`Le numéro est obligatoire`),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant est obligatoire`),
      adresseVirtuelle: Yup.string().required('L\'adresse virtuelle est obligatoire'),
      quartier: Yup.string().notRequired(),
      arrondissement: Yup.string().notRequired(),
    });
    const router = useRouter();
    const abonnesOptions = ref([]);
    const bureauxOptions = ref<Array<Option>>([]);
    const communesOptions = ref<Array<Option>>([]);
    const codepay = ref();
    const codeVirtuel = ref();
    const nationalnumlber = ref();
    const telephone = ref();
    const bureauselect = ref();
    const communeSelect = ref();
    const adressePostales = ref();
    const validPhone = ref<boolean>(false);
    const montant = ref(0);
    const selectedAbonne = ref(false);
    const nooptions = "La liste est vide";
    const noResultText = "Aucun enregistrement trouvé";
    const noOptionsTextAdd = ref("Tapez au moins deux caractères");
    const noOptionsTextAbb = ref("Tapez au moins trois caractères");
    const etat = ref("");
    const etatAgence = ref(true)
    const arrondissementOptions = ref([]);
    const quartierOptions = ref([]);

    const selectedArrondissement = ref([]);
    const selectedQuartier = ref([]);


    onMounted(async () => {
      fetchmonatant();

      // if(JwtService.getUserCheckIfAllAgence() == "true"){
      etatAgence.value = false;
      await fetchCommune();
      // }else{
      //   //
      const element = await fetchAgences1(JwtService.getUserAgence());
      if (element) {
        communeSelect.value = element.communeId;
        bureauselect.value = element.id;
      }

      // }
    });

    const fetchAgences1 = async (key) => {
      try {
        const response = await axios.get(`/bureaus/${parseInt(key)}`);
        const bureau = response.data.data;
        let data = [
          {
            value: bureau.id,
            label: bureau.nom,
          }
        ];
        bureauxOptions.value = data;
        return { id: bureau.id, communeId: bureau.commune.id };
      } catch (error) {
        //
      }
      // try {
      //   const response = await axios.get(`/bureaus/${parseInt(key)}`);
      //   const bureau = response.data.data;
      //   let data = [
      //     {
      //       value: bureau.id,
      //       label: bureau.nom,
      //     }
      //   ];
      //   let data2 = [
      //     {
      //       value: bureau.commune.id,
      //       label: `[${bureau.commune.code}] -- ${bureau.commune.libelle}`,
      //     }
      //   ];
      //   bureauxOptions.value = data;
      //   communesOptions.value = data2;
      //   return  bureau;
      // } catch (error) {
      //   //
      // }
    };
    function openNewWindow(data: any) {
      openAndPrint(getUrlApiForFiles("Recu", data));
    }

    const toggleAbonne = () => {
      selectedAbonne.value = !selectedAbonne.value;
    };

    const abonnesOps = ref<Array<{ value: any; label: string }>>([]);

    const selectedAbonneId = ref(0);
    const verifyNumber = ref(0);
    const showOtp = ref(true)
    const numVerify = ref(false)
    const saveNum = ref();


    const getAbonne = async (values: { id: any; nomComplet: string; telephone: string; categorieAbonne: any }) => {
      try {
        selectedAbonneId.value = values.id;
        await fetchAbonne();
        await getLoyer();
        const data = {
          value: values.id,
          label: values.nomComplet + ' [ ' + values.telephone + ' ]',
        };
        abonnesOps.value = [...abonnesOps.value, data];

        return abonnesOps.value;
      } catch (error) {
        // Gérer l'erreur ici
      }
    };

    const fetchAbonne = async () => {
      try {
        const response = await axios.get(`/abonnes/${selectedAbonneId.value}`);
        const abonne = response.data.data;
        telephone.value = abonne.telephone
        return abonnesOps.value;
      } catch (error) {
        // Gérer l'erreur ici
      }
    };

    // const verifyNumberMpos = async (number) => {

    //       ApiService.post("/mpostabonnements",{numero:number})
    //         .then(async({ data }) => {
    //           if(data.code == 200) { 

    //           }
    //         }).catch(erro=>{

    //         })
    //   }

    // const getAbonne = async (values: { id: any; nomComplet: string; telephone: string }) => {
    //   const data = {
    //     value: values.id,
    //     label: values.nomComplet + ' [ ' + values.telephone + ' ]',
    //   };
    //   abonnesOps.value = [...abonnesOps.value, data];
    //   selectedAbonneId.value = values.id;
    //   await clickAbonne(selectedAbonneId.value);
    //   console.log('get aboonn', selectedAbonneId.value)
    //   return abonnesOps.value;
    // };

    const addMpostBox = async (values, { resetForm }) => {
      values["etat"] = "Actif";
      values["numVerify"] = numVerify.value;

      console.log("Tappp", values)
      ApiService.post("/mpostabonnements", values)
        .then(async ({ data }) => {
          console.log('Dans le then')
          if (data.code == 201) {
            console.log('Dans le if')

            success(data.message);
            const reference = data.data.paie.refPaiement + ".pdf";
            resetForm();
            router.push({ name: "ListeMpostBoxPage" });
            const maFonction = async () => {
              const result = await Swal.fire({
                html: `<div>Cliquer sur le bouton Aperçu ci-dessous pour avoir un aperçu de votre document</div>`,
                icon: "success",
                buttonsStyling: true,
                confirmButtonText: "Aperçu",
                showCancelButton: false,
                cancelButtonText: `Fermer`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                heightAuto: false,
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-warning",
                },
              });

              if (result.isConfirmed) {
                openNewWindow(reference)
              }
            }

            // Utiliser setTimeout pour exécuter la fonction après la durée spécifiée
            setTimeout(maFonction, 3000);
          }
        }).catch(({ response }) => {
          //
        });
    };

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addMpostBox(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      const result = await Swal.fire({
        text: "Choississez un moyen de paiement pour le paiement de la somme de " + values.loyer + " F CFA",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "ELECTRONIQUE",
        cancelButtonText: `CASH`,
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
      let dismissString: string | undefined;
      dismissString = String(result.dismiss);

      if (result.dismiss !== undefined && dismissString == "cancel" && result.isDismissed) {
        values["mode"] = 1;
        values["check"] = check;
        addMpostBox(values, { resetForm });
      }
      if (result.isConfirmed) {
        values["mode"] = 2;
        KKIAPAY.kkia(values.loyer);
        //Attendre que le paiement soit effectué ou échoué
        await new Promise<void>((resolve) => {
          addKkiapayListener('failed', (event) => {
            failHandler(event);  
            resolve(); 
          });

          addKkiapayListener('success', (event) => {
            successHandler(event, values, { resetForm });
            resolve();
          });

          const checkRef = toRef(check);
          let resolved = false;

          // Résoudre la promesse après avoir traité le résultat du paiement
          const intervalId = setInterval(() => {
            if (!resolved && checkRef.value.typeError !== "RAS") {
              clearInterval(intervalId);
              resolved = true;
              resolve(); // Ajoutez ici un argument (par exemple, undefined)
            }
          }, 500); // Vérifier toutes les 500 millisecondes
        });
      }

    }

    function onInput(phone, phoneObject, input) {
      //
    }
    const telP = ref();

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        //verifyNumberMpos(phoneObject.number);
        genererAdresse(phoneObject.number);

      } else {
        telephone.value = "";
        codeVirtuel.value = "";
        nationalnumlber.value = "";
        adressePostales.value = "";
      }
    }

    const genererAdresse = async (number: any) => {
      if (bureauselect.value && telephone.value && nationalnumlber.value) {
        ApiService.post('/verify/mpostboxnumber', { bureauId: bureauselect.value, numero: number })
          .then(({ data }) => {
            if (data.code == 200) {
              console.log(data);
              const bureau = data.data
              codeVirtuel.value = bureau.codeVirtuelle
              adressePostales.value = codeVirtuel.value + nationalnumlber.value;
            } else {
              console.log(data, 'eeeeeeeeeeeeeeeeeeeeeee');
              error(data.response.message);
            }
          })
          .catch(({ response }) => {
            console.log(response, 'fgggggggggggggggggggggg', response.data);
            error(response.data.message);
          });
      }
    }

    const fetchAbonnes = async (key) => {
      try {
        const response = await axios.get('/abonnes/recherche/' + key);
        const abonnesData = response.data.data;
        abonnesOptions.value = abonnesData.map((abonne) => ({
          value: abonne.id,
          label: abonne.nomComplet + ' [ ' + abonne.telephone + ' ]',
        }));
        if (abonnesOptions.value.length == 0) {
          noOptionsTextAbb.value = noResultText;
        } else {
          noOptionsTextAbb.value = 'Tapez au moins trois caractères';
        }
        return abonnesOptions.value;
      } catch (error) {
        //
      }
    };

    const getLoyerAndBoitBureau = (val) => {
      getLoyer();
    }

    const getLoyerAndBoitModel = (val) => {
      getLoyer();
    }

    const getLoyerAndBoitCate = (val) => {
      selectedAbonneId.value = val;
      getLoyer();
    }

    const getLoyer = async () => {
      if (selectedAbonneId.value) {
        try {
          const response = await axios.get(`/abonnes/${selectedAbonneId.value}`);
          const abonne = response.data.data;
          telephone.value = abonne.telephone
          telP.value = abonne.telephone
          saveNum.value = abonne.telephone;
          numVerify.value = abonne.verifyNumber
          watch(telP, (newVal, oldVal) => {
            console.log('newVal =>', newVal);
            console.log('oldVal =>', oldVal);
            if (newVal && newVal != oldVal) {
              const newNumber = newVal.replace(/\s/g, '');
              const oldNumber = oldVal.replace(/\s/g, '');
              console.log('savenum =>', saveNum.value.replace(/\s/g, ''));
              console.log('newNumber =>', newNumber);
              console.log(newNumber == saveNum.value.replace(/\s/g, ''))
              if (newNumber != saveNum.value.replace(/\s/g, '') || abonne.verifyNumber === false) {
                console.log('new', newNumber);
                console.log('old', oldNumber);
                showOtp.value = true;
              }
              if (newNumber == saveNum.value.replace(/\s/g, '') && abonne.verifyNumber === true) {
                console.log('=true', oldVal);
                console.log('=true', oldVal);
                showOtp.value = false;
              }
              if (newNumber == saveNum.value.replace(/\s/g, '') && abonne.verifyNumber === false) {
                console.log('=false', oldVal);
                console.log('=false', oldVal);
                showOtp.value = true;
              }
            }

          });
          if (abonne.verifyNumber === false) {
            showOtp.value = true
          } else {
            showOtp.value = false
          }
        } catch (error) {
          //
        }
      }
    }

    // watch(telephone, (newVal, oldVal) => {
    //       if (newVal !== oldVal) {
    //         showOtp.value = true
    //       }
    //       else {
    //         showOtp.value = false
    //       }
    //     });

    // const clickAbonne = async (key) => {
    // console.log('clikc', selectedAbonneId.value)

    //   const response = await axios.get(`/abonnes/${selectedAbonneId.value}`);
    //   const abonnesData = response.data.data;
    //   telephone.value = abonnesData.telephone
    //   console.log('dkfkpef', abonnesData)
    //     if (abonnesData.verifyNumber === false) {
    //       console.log('yessss')
    //           showOtp.value = true
    //         } else {
    //           showOtp.value = false
    //         }
    // }

    const fetchAgences = async (key) => {
      try {
        const response = await axios.get('/bureaus/recherche/' + key);
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    function communeChange(value) {
      if (value) {
        bureauxOptions.value = [];
        arrondissementOptions.value = [];
        selectedArrondissement.value = [];
        selectedQuartier.value = [];
        bureauselect.value = "";
        ApiService.get('/bureaux/commune/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              bureauxOptions.value = donnee.map((bureau: any) => {
                return {
                  label: bureau.nom,
                  value: bureau.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
        ApiService.get('/communes/arrondissements/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              arrondissementOptions.value = donnee.map((arrondissement: any) => {
                return {
                  label: arrondissement.libelle,
                  value: arrondissement.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        arrondissementOptions.value = [];
        selectedArrondissement.value = [];
        selectedQuartier.value = [];
        bureauselect.value = "";
      }
    }

    function arrondissementChange(value) {
      if (value) {
        quartierOptions.value = [];
        selectedQuartier.value = [];
        ApiService.get('/arrondissements/quartiers/' + value)
          .then(({ data }) => {
            const donnee = data.data;
            if (donnee.length > 0) {
              quartierOptions.value = donnee.map((quartier: any) => {
                return {
                  label: quartier.libelle,
                  value: quartier.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        quartierOptions.value = [];
        selectedQuartier.value = [];
      }
    }

    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch((response) => {
          //error(response.data.message);
        });
    }

    const fetchmonatant = async () => {
      try {
        const response = await axios.get('/parametres/key/AMPOSTBOX');
        (response.data.data, 'response', response.data.valeur)
        montant.value = parseFloat(response.data.data.valeur);

        //return demandesOptions.value;
      } catch (error) {
        //
      }
    };


    const numero = ref();
    const sendOtp = async (i) => {
      const dataToSend = {
        telephone: telephone.value,
        estEmail: i
      };
      console.log('fkflefle', dataToSend)
      ApiService.post("/abonnements/verify/abonne/number", dataToSend)
        .then(({ data }) => {
          console.log(data, "data.codedata.code");
          if (data.code == 200) {
            success(data.message);
          }
        }).catch(({ response }) => {
          console.log("Mauvais", response);
          error(response.data.message);
        });
    };

    return {
      bureauselect,
      communeSelect,
      fetchAgences,
      mpostBoxSchema,
      addMpostBox,
      fetchAbonnes,
      communeChange,
      genererAdresse,
      adressePostales,
      telephone,
      validPhone,
      validate,
      onInput,
      montant,
      payer,
      communesOptions,
      bureauxOptions,
      abonnesOptions,
      selectedAbonne,
      toggleAbonne,
      selectedAbonneId,
      nooptions,
      noOptionsTextAdd,
      noOptionsTextAbb,
      abonnesOps,
      getAbonne,
      codeVirtuel,
      etat,
      etatAgence,
      sendOtp,
      numero,
      showOtp,
      numVerify,
      getLoyerAndBoitBureau,
      getLoyerAndBoitModel,
      getLoyerAndBoitCate,
      getLoyer,
      verifyNumber,
      telP,
      saveNum,
      quartierOptions,
      arrondissementOptions,
      arrondissementChange,
      selectedArrondissement,
      selectedQuartier,
    };
  },
});
</script>

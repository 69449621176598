<template>
    <BreadCrumb PageTitle="Etats des abonnements" />
    <EtatAbonnement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatAbonnement from "../../components/Etats/EtatAbonnement.vue";

  
    export default defineComponent({
      name: "EtatAbonnementPage",
      components: {
        BreadCrumb,
        EtatAbonnement,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link v-if="checkPermission('AddAbonne')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-abonne"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un abonné
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un abonne"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <!-- <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                 IMAGES
              </th> -->
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                 <!-- NOM & PRENOMS ou DENOMINATION #}-->
                 NOM & PRENOMS ou DENOMINATION
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                TéLéPHONE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                MAIL
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                SEXE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                CATEGORIE D'ABONNE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                SECTEUR D'ACTIVITE
              </th>
              <th scope="col"  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(abonne, index) in abonnes" :key="index">
              <!-- <td class="shadow-none lh-1 fw-medium text-primary">
                <img :src="getUrlApiForFiles('abonnes',abonne.urlImage)" alt="Image" width="300px" height="300px" v-if="abonne.urlImage">
                <p v-else>Aucune image</p>
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-primary">
                 <p>{{ abonne.nomComplet }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                 <p>{{ abonne.telephone }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                 <p>{{ abonne.email }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                 <p>{{ abonne.sexe }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" v-if="abonne?.categorieAbonne?.code == 'Catégorie 1'" >
                <p class="text-warning" style="color:yellow;">{{ abonne?.categorieAbonne?.code }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" v-if="abonne?.categorieAbonne?.code == 'Catégorie 2'">
                <p class="text-primary">{{ abonne?.categorieAbonne?.code }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" v-if="abonne?.categorieAbonne?.code == 'Catégorie 3'">
                <p class="text-info">{{ abonne?.categorieAbonne?.code }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" v-if="abonne?.categorieAbonne?.code == 'Catégorie 4'">
                <p class="text-danger">{{ abonne?.categorieAbonne?.code }}</p>
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                <p>{{ (abonne?.activite?.libelle.length > 25) ? abonne?.activite?.libelle.substring(0, 25) + '...' : abonne?.activite?.libelle }}</p> 
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(abonne.createdAt)  }}
              </td> -->
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('ViewAbonne')">
                      <router-link :to="{ name: 'ViewAbonnePage', params: { id: abonne.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-eye lh-1 me-8 position-relative top-2"
                        ></i>Détails</router-link>
                    </li>
                    <!--<li v-if="checkPermission('EditAbonne')">
                      <router-link :to="{ name: 'EditAbonnePage', params: { id: abonne.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li>-->
                    <li v-if="abonne.urlImage">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal"
                        @click="showImage(abonne.urlImage)"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Voir l'image
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
       
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>

  <div
      class="modal fade createNewModal"
      id="AddImageModal"
      tabindex="-1"
      ref="addAddImageModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Image actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="image" alt="Image" width="500" height="500">
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
<EditAbonnePassModal :selectedUser="selectedUser"/>

</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/services/ApiService";
import { Abonnes, Abonne } from "@/models/Abonne";
import { format_date,suppression, error, getUrlApiForFiles } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import EditAbonnePassModal from "./EditAbonnePassModal.vue";
import JwtService from "@/services/JwtService";

export default defineComponent({
    name: "ListeAbonne",
    components: {
      PaginationComponent,
      EditAbonnePassModal,
  },
  setup: () => {
    
    onMounted(() => {
      getAllAbonnes();
    });

    const openEditPassModal = (abonne: Abonnes) => {
    selectedUser.value = { ...abonne };
  };
    const selectedUser = ref<Abonnes | undefined>(undefined);

    const abonnes = ref<Array<Abonnes>>([]);
    const abonne = ref<Abonnes>();

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllAbonnes(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllAbonnes(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllAbonnes(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/abonnes?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          abonnes.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }
    const image = ref('');
    const showImage = (imageName) => {
      image.value = getUrlApiForFiles('abonnes', imageName);
    };

    function moddifier(Editabonne:Abonnes) {
      abonne.value = Editabonne;
    }

    const deleteAbonne = (id: number) => {
      ApiService.delete(`/abonnes/${id}`)
      .then(({ data }) => {
        Swal.fire({
          text: data.message,
          toast: true,
          icon: 'success',
          title: 'General Title',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Réssayer à nouveau!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      });

      for(let i = 0; i < abonnes.value.length; i++) {
        if (abonnes.value[i].id === id) {
          abonnes.value.splice(i, 1);
        }
      }
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

  const checkPermission = (name) => {
    return privileges.value.includes(name);
  }
    
    return {
      checkPermission,
      suppression, 
      abonnes,
      format_date,
      getAllAbonnes,
      deleteAbonne,
      moddifier,
      page, 
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher,
      abonne,
      selectedUser,
       openEditPassModal,
       getUrlApiForFiles,
       image,showImage,
    };
  },
});
</script>


<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="resiliationForm" @submit="addResiliation" :validation-schema="resiliationSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Abonnement <span class="text-danger">*</span>
                </label>
                <Field  name="abonnement"  v-slot="{ field }">
                  <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un abonnement"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  :options="async (query) => {
                   return await fetchAbonnements(query)
                  }"
                  noResultText= "Aucun enregistrement trouvé"
                  noOptionsText="Tapez au moins trois caractères"
                  @select="handleObjetInput"
                  />
                </Field>  
                <ErrorMessage name="abonnement" class="text-danger"/>
              </div>
            </div>

              <div class="col-md-6 col-xxl-6 col-sm-6 mb-10" v-if="showDetails">
              <div class="card mb-25 border-0 rounded-0">
                <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
                  <h5 class="text-black fw-bold mb-10">Information sur l'abonnement</h5>
                  <table class="table">
                    <tbody>
                      <tr v-if="abonnement?.refAbo">
                        <td>Référence</td>
                        <td>{{ abonnement?.refAbo }}</td>
                      </tr>
                      <tr v-if="abonnement?.demande?.refDemande">
                        <td>Demande</td>
                        <td>{{ abonnement?.demande.refDemande }}</td>
                      </tr>
                      <tr v-if="abonnement?.adresseVirtuelle">
                        <td>Adresse virtuelle</td>
                        <td>{{ abonnement?.adresseVirtuelle }}</td>
                      </tr>
                      <tr v-if="abonnement?.boite?.adressePostale?.adresse_postale">
                        <td>Adresse postale</td>
                        <td>{{ abonnement?.boite.adressePostale.adresse_postale }}</td>
                      </tr>
                      <tr v-if="abonnement?.adresseVirtuelle">
                        <td>Numéro</td>
                        <td>{{ abonnement?.numero }}</td>
                      </tr>
                      <tr v-if="abonnement?.boite">
                        <td>Boite</td>
                        <td>{{ abonnement?.boite?.refBoite }}</td>
                      </tr>
                      <tr v-if="abonnement?.bureau?.nom">
                        <td>Agence</td>
                        <td>{{ abonnement?.bureau?.nom }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

              <div class="col-md-6 col-xxl-6 col-sm-6 mb-10" v-if="showDetails">
                <div class="card mb-25 border-0 rounded-0">
                  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
                    <h5 class="text-black fw-bold mb-10">Information sur l'abonné</h5>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Nom et prénom</td>
                          <td>{{ abonnement?.abonne?.nomComplet }}</td>
                        </tr>
                        <tr>
                          <td>Nationalité</td>
                          <td>{{ abonnement?.abonne?.nationalite }}</td>
                        </tr>
                        <tr>
                          <td>Téléphone</td>
                          <td>{{ abonnement?.abonne?.telephone }}</td>
                        </tr>
                        <tr>
                          <td>Sexe</td>
                          <td>{{ abonnement?.abonne?.sexe }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Motif <span class="text-danger">*</span>
                </label>
                <Field name="motif" as="textarea"  cols="30"
                  rows="12" placeholder="Entrer les infos supplémentaitres" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                class="form-control shadow-none rounded-0 text-black"
                  v-model="field.value"
                ></textarea>
              </Field>
                <ErrorMessage name="motif" class="text-danger"/>
              </div>
            </div>
            
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Résilier l'abonnement
            </button>
            <router-link to="/liste-resiliations" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../utils/utils';
import { useRouter,useRoute } from "vue-router";
import ApiService from '../../services/ApiService';
import { Abonnements, Abonnement } from '../../models/Abonnement';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from "sweetalert2";
import Multiselect from '@vueform/multiselect'


export default defineComponent({
    name: "AddResiliation",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const resiliationSchema = Yup.object().shape({
      motif: Yup.string().required('Le motif est obligatoire'),
      abonnement: Yup.string().required('L\'abonnement est obligatoire'),
    });

    const resiliationForm =  ref<Abonnement | null>(null);
    const abonnements = ref(null);
    const abonnement = ref<Abonnements | null>(null);
    const abonnementOptions = ref([]);
    const router = useRouter();
    const showDetails = ref(false);
    //const route = useRoute();
    const selectedAbonnementId = ref([]);

    const handleObjetInput = (selectedAbonnement) => {
      showDetails.value = true;
      const abonnementId = selectedAbonnement;
      selectedAbonnementId.value = abonnementId;
      getAbonnement(abonnementId);
    };


    const addResiliation = async (values, {resetForm}) => {
      const resiliationData = {
        id: selectedAbonnementId.value,
        motif: values.motif,
        typeResiliation: "D'office"
      };

      const result = await Swal.fire({
        text: "Vous êtes sur le point de resilier l'abonnement " + abonnement.value?.refAbo + " de "+ abonnement.value?.abonne.nomComplet,
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Resilier",
        cancelButtonText: `Annuler`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });

      if(result.isConfirmed) {
        ApiService.post("/resiliation/abonnement",resiliationData)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeResiliationPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }

      
    };

    // watch(abonnement, (value) => {
    //     //
    // });

    const fetchAbonnements = async (key) => {  
      try {
        const response = await axios.get('/abonnements/recherche/agence/'+key);
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          // value: abonnement.id,
          // label: abonnement.abonne.nomComplet+' [ '+abonnement.boite.refBoite+' ]',
          value: abonnement.id,
          label: `${abonnement.refAbo} - [${abonnement.abonne.nomComplet}]`,
        }));
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }

    function getAbonnement(id: string) {
      return ApiService.get("/abonnements/" + id)
        .then(({ data }) => {
          abonnement.value = data.data;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    onMounted(() => {
      //
    });

    return { resiliationSchema, addResiliation, resiliationForm,
       abonnements, fetchAbonnements,
       abonnementOptions, showDetails,
       handleObjetInput,selectedAbonnementId,abonnement
        };
  },
});
</script>
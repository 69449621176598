<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link  v-if="checkPermission('AddDemande')"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-demande"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Envoyer une demande
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une demande"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                REFERENCE
              </th>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TYPE DE DEMANDE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNEMENT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                CONTENU
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(de , index) in demandes" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ de.refDemande }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ de.typeDemande ? de.typeDemande.libelle : ''  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ de.abonnement ? de.abonnement.refAbo : ''   }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ de.abonnement?.abonne ? de.abonnement.abonne.nomComplet : ''   }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ (de.contenu.length > 25) ? de.contenu.substring(0, 25) + '...' : de.contenu  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(de.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="de.status === 'En attente'" class="badge text-outline-info">{{ de.status }}</span>
                <span v-else class="badge text-outline-success">{{ de.status }}</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('ViewDemande')">
                        <router-link :to="{ name: 'ViewDemandePage', params: { id: de.id } }" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                        </router-link>
                    </li>
                    <li  v-if="de.typeDemande.id == 4 " >
                      <a v-if="de.status =='En attente' && checkPermission('CheckEtatAttestation')"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="accept(de)"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Accepter 
                      </a>
                      <a
                          v-else-if="de.status =='Acceptée' && checkPermission('MakeAttestation')"
                          class="dropdown-item d-flex align-items-center"
                          @click="produireAttestation(de)"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Générer une attestation
                      </a>
                    </li>
                    <li  v-if="de.typeDemande.id == 1">
                      <a v-if="de.status =='En attente'"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="produireAttestation(de)"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Accordée
                      </a>
                      <router-link 
                          v-else-if="de.status =='Accepté' && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'AddAbonnementPage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Produire Abonnement
                      </router-link>
                    </li>
                    <li  v-if="de.typeDemande.id ==7">
                      <router-link 
                          v-if="de.status =='En attente' && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'AccepterChangementClePage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Acceptée
                      </router-link>
                      <a 
                          v-else-if="de.status =='Acceptée' && agenceId == de.abonnement.bureau?.id"
                          class="dropdown-item d-flex align-items-center"
                          href="javascript:void(0);"
                          @click="traiterChangementDeCle(de)"
                        >
                          <i class="flaticon-pen lh-1 me-8 position-relative top-1" ></i>
                          Traitée
                    </a>
                    </li>
                    <li v-else-if="de.typeDemande.id == 5">
                      <router-link 
                          v-if="de.status == 'En attente' && checkPermission('ManageResiliation') && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'TraitementResiliationPage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Traiter
                      </router-link>
                    </li>
                    <li v-else-if="de.typeDemande.id == 8">
                      <router-link 
                          v-if="de.status == 'En attente' && checkPermission('ManageReclamation') && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'TraitementReclamationPage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Traiter
                      </router-link>
                    </li>
                    <li v-else-if="de.typeDemande.id == 2">
                      <a v-if="(de.status =='En attente' && checkPermission('AcceptTransfert') && agenceId == de.abonnement.bureau?.id) || (de.status =='En attente' && role == 'Super Admin')"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="accept(de)"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Acceptée
                      </a>
                      <router-link 
                      :to="{ name: 'AddTransfertMpostBoxPage', params: { demande:de.id } }"
                       v-else-if="(de.status =='Acceptée' && checkPermission('ValidTransfert') && agenceId == de.nouvelleAgence?.id) || (de.status =='Acceptée' && role == 'Super Admin')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        <i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Valider
                      </router-link>
                    </li>
                    <li  v-else-if="de.status !=='Acceptée'">
                      <router-link 
                          v-if="de.status == 'En attente' && checkPermission('CheckCession') && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'AcceptationCessionPage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Vérification
                      </router-link>
                      <router-link 
                          v-else-if="de.status == 'Acceptée' && checkPermission('ValidCession') && agenceId == de.abonnement.bureau?.id"
                          :to="{ name: 'AddCessionPage', params: { id:de.id } }"
                          class="dropdown-item d-flex align-items-center"
                        >
                          <i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Valider
                        </router-link>
                    </li>
                    <li v-if="checkPermission('RejetDemande')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#AddAnnulationMotifModal"
                        @click="annuleId(de.id)"
                      >
                        <i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Rejetée
                      </a>
                    </li>
                    <li>
                      <a @click="openNewWindow_(de)" type="button"
                        class="dropdown-item d-flex align-items-center" >
                        <i class="flaticon-send lh-1 me-8 position-relative top-1" ></i>
                         Reçu de paiement
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
  <AddTransfertModal
    @getAllDemandes="getAllDemandes"
    :id="idTransfert"
    @openmodal="showModalTransferEdite"
  />
  <AddAnnulationMotifModal :item="selectedItem"   @close="recharger" @openmodal="show_Modal" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AddDemande from "./AddDemande.vue";
import { Demande } from "@/models/Demande";
import ApiService from "@/services/ApiService";
import { hideModal, showModal, success,suppression,format_date, error,getDatePlusXDays,decouperEtJoindre,telechargerFichier2,getUrlApiForFiles,openAndPrint } from '@/utils/utils';
import { useRouter } from 'vue-router';
import AddTransfertModal from "./AddTransfertModal.vue";
import { date } from "yup";
import Swal from "sweetalert2";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import AddAnnulationMotifModal from "./AddAnnulationMotifModal.vue"
import JwtService from "@/services/JwtService";


export default defineComponent({
  name: "DemandesList",
  components: {
    AddTransfertModal,
    PaginationComponent,
    AddAnnulationMotifModal
  },
  setup(){
    const router = useRouter();
    const demandes = ref<Array<Demande>>([]);
    const selectedItem = ref(0);
    const idTransfert = ref(0);

    const agenceId = ref<string|null>("");

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllDemandes(page_, limit_);
      } catch (error) {
        //
      }
    };

    const show_Modal = (el) => {
      showModal(el);
      selectedItem.value = 0;
    };

    function rechercher(){
      getAllDemandes(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    const  getAllDemandes = (page = 1, limi = 10, searchTerm = '') => {
      return ApiService.get(`/demandes?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        const donnees = data.data.data;
        demandes.value = donnees;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;

        console.log("DEMANDES ",demandes.value)
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const annuleId = (id: number) => {
      selectedItem.value = id;
    };

    const editDemande = (id: number) => {
      selectedItem.value = id;
      router.push({ name:'EditDemandePage',params: { id: selectedItem.value } });
    };
    function openNewWindow(data:any){
      openAndPrint(getUrlApiForFiles("Attestation",data));
    }

    const produireAttestation = async (demande:any)=>{
      // const result = await Swal.fire({
      //       text: "Vous êtes sur le point de générer une attestation ?",
      //       icon: "warning",
      //       buttonsStyling: true,
      //       showCancelButton: true,
      //       confirmButtonText: "Générer",
      //       cancelButtonText: `Annuler`,
      //       heightAuto: false,
      //       customClass: {
      //         confirmButton: "btn btn-danger",
      //       },
      //     });

          // if (result.isConfirmed) {
            ApiService.post(`/accepte/attestation`, demande)
            .then(async(response) => {
              if (response.status === 201) {
                const attestation = response.data.data.attestation;
                const reference = decouperEtJoindre(attestation.refAttestation,"-",3)+".pdf";
                const document = "Attestation";
                success("Attestation acceptée avec")
                getAllDemandes();
                const result = await Swal.fire({
                  html: `<div>Cliquer sur le bouton Aperçu ci-dessous pour avoir un aperçu de votre document</div>`,
                  icon: "success",
                  buttonsStyling: true,
                  confirmButtonText: "Aperçu",
                  showCancelButton: true,
                  cancelButtonText: `Fermer`,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  heightAuto: false,
                  customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-warning",
                  },
                });

                if (result.isConfirmed) {
                  openNewWindow(reference);
                }
              // if(result.isConfirmed){
              //   telechargerFichier2(document,reference);
              // }else{
              //   //
              // }
               }
            })
            .catch((errorr) => {
                error(errorr.response.data.message);
            });
          router.push({ name: 'ListDemandePage'});
    }

    const accept = async (demande:any)=>{
      const valeur = ref(demande);
      const result = await Swal.fire({
            text: "Etes-vous sur de vouloir accepter cette demande ?",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Accepter",
            cancelButtonText: `Annuler`,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            ApiService.put('/transfert/accepte/'+demande.id, valeur.value)
            .then(async(response) => {
              if (response.status === 200) {
                getAllDemandes();
                success(response.data.message);
              }
            })
            .catch((errorr) => {
                error(errorr.response.data.message);
            });
          }
    }

    function faireTransfert(demande:any) {
        idTransfert.value = demande.id;
    }

    function showModalTransferEdite(model){
      showModal(model);

      idTransfert.value = 0;
    }

    const valider = async (demande)=>{
      if(demande.typeDemande.id == 2){
        //Transfert
      }

      if(demande.typeDemande.id == 1){
        //Abonnement
      }
      if(demande.typeDemande.id == 3){
        //Cession
      }
      if(demande.typeDemande.id == 4){
        //Attesttation
      }

      if(demande.typeDemande.id == 5){
        //Resiliation
      }

      if(demande.typeDemande.id == 6){
        //Partage
      }

      if(demande.typeDemande.id == 7){
        //Changement de clé
      }
      // if(demande.typeDemande.id == 8){
      //   Resiliation
      // }
    }

    function openNewWindow_(data:any){
      openAndPrint(getUrlApiForFiles("Recu",data.paiements[0]?.refPaiement + ".pdf"));
    }

    //Traitement de la demande de changement de clé
    const traiterChangementDeCle =(demande:any)=>{
      router.push({ name: 'AddNotifiationPage',params: { id: demande.id } });
    }

    onMounted(() => {
      getAllDemandes();
      agenceId.value = JwtService.getUserAgence();
      console.log("AGENCE ===> ",agenceId);
    });

    const privileges = ref<Array<string>>(JwtService.getPrivilege());
    const role =  ref(JwtService.getUserRole());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    const recharger = () => {
      getAllDemandes();
    };

    return { 
    checkPermission,
    faireTransfert,
    role,
    accept,
    demandes,
    idTransfert,
    valider,
    showModalTransferEdite,
    format_date,getAllDemandes,
    AddTransfertModal,
    editDemande,
    annuleId,
    recharger,
    selectedItem ,
    suppression,
    page, 
    show_Modal,
    totalPages,
    limit,
    totalElements,
    handlePaginate,
    searchTerm,
    getDatePlusXDays,
    agenceId,
    rechercher,
    produireAttestation,
    traiterChangementDeCle,
    openNewWindow_
    };
  },
});
</script>
<template>
    <BreadCrumb PageTitle="Créer un abonnement" />
    <AddAbonnement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddAbonnement from "../../components/Abonnement/AddAbonnement.vue";
  
    export default defineComponent({
      name: "AddAbonnePage",
      components: {
        BreadCrumb,
        AddAbonnement,
      },
    });
  </script>

<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="notificationForm" @submit="editNotification" :validation-schema="notificationSchema">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Canal <span class="text-danger">*</span>
            </label>
            <Field name="canal" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="canal" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Message 
              </label>
                <QuillEditor
                  theme="snow"
                  placeholder="Message"
                  toolbar="full"
                  name="message"
                />
                <ErrorMessage name="message" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Paiement
            </label>
            <Field name="paiement" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="paiement" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Reabonnement
            </label>
            <Field name="reabonnement" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="reabonnement" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Abonnement <span class="text-danger">*</span>
            </label>
            <Field name="abonnement" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="abonnement" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Campagne <span class="text-danger">*</span>
            </label>
            <Field name="campagne" type="text" 
              class="form-control shadow-none fs-md-15 text-black"/>
              <ErrorMessage name="campagne" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonné <span class="text-danger">*</span>
              </label>
              <Field name="abonne" v-slot="{ field }">
                  <Multiselect 
                    :options="abonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
              <ErrorMessage name="abonne" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modèle <span class="text-danger">*</span>
              </label>
              <Field name="modele" v-slot="{ field }">
                  <Multiselect 
                    :options="modeleOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
              <ErrorMessage name="modele" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier la notification
            </button>
            <router-link to="/liste-notifications" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'

import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
  name: "EditNotification",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const notificationSchema = Yup.object().shape({
    canal: Yup.string().required('Le canal est obligatoire'),
    message: Yup.string().required('Le message est obligatoire'),
    paiement: Yup.number().typeError('Entrer un nombre').required('Le paiement est obligatoire'),
    abonnement: Yup.number().typeError('Entrer un nombre').required('L\' abonnement est obligatoire'),
    reabonnement: Yup.number().typeError('Entrer un nombre').required('Le reabonnement est obligatoire'),
    campagne: Yup.number().typeError('Entrer un nombre').required('La campagne est obligatoire'),
    abonne: Yup.number().typeError('Entrer un nombre').required('L\'abonne est obligatoire'),
    modele: Yup.number().typeError('Entrer un nombre').required('La modèle est obligatoire'),
  });

  const notificationForm = ref(null);
  const abonnes = ref(null);
  const abonneOptions = ref([]);
  const modeles = ref(null);
  const modeleOptions = ref([]);

  const editNotification = async (formData: any) => {
    //
  };

  const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_notification');
        const modelesData = response.data.data;
        modeleOptions.value = modelesData.map((modele) => ({
          value: modele.id,
          label: modele.code,
        }));
      } catch (error) {
        //
      }
  };

  const fetchAbonnes = async () => {
      try {
        const response = await axios.get('/abonnes');
        const abonnesData = response.data.data;
        abonneOptions.value = abonnesData.map((abonne) => ({
          value: abonne.id,
          label: abonne.email,
        }));
      } catch (error) {
        //
      }
  };

  onMounted(() => {
      fetchModeles();
      fetchAbonnes();
    });
  
  return { notificationSchema, editNotification, notificationForm, modeles, abonnes, modeleOptions, abonneOptions };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
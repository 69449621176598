<template>
  <div class="modal fade createNewModal" id="AddTarifAnnuelModal" tabindex="-1" aria-hidden="true"
    ref="addTarifAnnuelModalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="tarifAnnuelForm" @submit="addTarifAnnuel" :validation-schema="tarifAnnuelSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Catégorie d'abonné<span class="text-danger">*</span>
                  </label>
                  <Field name="categorie" v-slot="{ field }">
                    <Multiselect :options="categorieAbonneOptions" :searchable="true" v-model="field.value"
                      v-bind="field" placeholder="Sélectionner une catégorie" />
                  </Field>
                  <ErrorMessage name="categorie" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Modèle de boîte <span class="text-danger">*</span>
                  </label>
                  <Field name="modeleBoite" v-slot="{ field }">
                    <Multiselect :options="modeleOptions" :searchable="true" v-model="field.value" v-bind="field"
                      placeholder="Sélectionner le modèle" />
                  </Field>
                  <ErrorMessage name="modeleBoite" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Type de boîte <span class="text-danger">*</span>
                  </label>
                  <Field name="type" v-slot="{ field }">
                    <Multiselect :options="options" :searchable="true" v-model="field.value" v-bind="field"
                      placeholder="Sélectionner le type de boîte" />
                  </Field>
                  <ErrorMessage name="type" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Caution <span class="text-danger">*</span>
                  </label>
                  <Field name="caution" type="text" class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer la caution" />
                  <ErrorMessage name="caution" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Coût <span class="text-danger">*</span>
                  </label>
                  <Field name="cout" type="text" class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer le coût" />
                  <ErrorMessage name="cout" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Frais de clefs <span class="text-danger">*</span>
                  </label>
                  <Field name="fraisCle" type="text" class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Frais des clefs" />
                  <ErrorMessage name="fraisCle" class="text-danger" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nombre de clefs <span class="text-danger">*</span>
                  </label>
                  <Field name="nombreCle" type="text" class="form-control shadow-none fs-md-15 text-black" />
                  <ErrorMessage name="nombreCle" class="text-danger" />
                </div>
              </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit">
                {{ btntext }}
              </button>
            </div>
          </Form>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
          @click="resetValue()"></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import { TarifAnnuel } from '@/models/TarifAnnuel';
import ApiService from '@/services/ApiService';
import Swal from 'sweetalert2';
import { hideModal, showModal, success, error } from '@/utils/utils';
import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
  name: "AddTarifAnnuelModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      required: true
    },
  },
  setup: (props, { emit }) => {
    const tarifAnnuelSchema = Yup.object().shape({
      categorie: Yup.number().required('La categorie est obligatoire'),
      type: Yup.string().required('Le type est obligatoire'),
      cout: Yup.number().typeError('Veuillez entrer un nombre').required('Le coût est obligatoire'),
      caution: Yup.number().typeError('Veuillez entrer un nombre').required('La caution est obligatoire'),
      modeleBoite: Yup.number().typeError('Veuillez entrer un nombre').required('Le modèle est obligatoire'),
      fraisCle: Yup.number().typeError('Veuillez entrer des chiffres').required('Le frais des clefs est obligatoire'),
      nombreCle: Yup.number().typeError('Veuillez entrer un nombre').required('Le nombre de clé est obligatoire'),
    });

    // const tarifAnnuelForm = ref(null);
    const categorieAbonnes = ref(null);
    const categorieAbonneOptions = ref([]);
    const modeles = ref(null);
    const modeleOptions = ref([]);
    const addTarifAnnuelModalRef = ref<null | HTMLElement>(null);
    const tarifAnnuelForm = ref<TarifAnnuel | null>(null);
    const tarifAnnuelnew = ref<TarifAnnuel | null>(null);
    const isUPDATE = ref(false);
    const title = ref('Ajouter un tarif annuel');
    const btntext = ref('Ajouter');
    const localItem = ref(props.item);
    const fraiscleval = ref(5000);


    watch(() => props.item, (newValue) => {
      getTarifAnnuel(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
        title.value = "Modifier un tarif annuel";
        btntext.value = "Modifier";
      } else {
        title.value = "Ajouter un tarif annuel";
        btntext.value = "Ajouter";
      }
    }

    const options = [
      { value: '', label: 'Selectionnez un type' },
      { value: 'Ordinaire', label: 'Ordinaire' },
      { value: 'Spécifique', label: 'Spécifique' }
    ];

    // const getLoyer = async () => {
    //   if(cateselect.value &&  modelselect.value) {
    //     try {
    //       const response = await axios.get(`/tarifAnnuels/${cateselect.value}/${modelselect.value}`);
    //       const tarifAnnuels = response.data.data;
    //       loyert.value = tarifAnnuels.cout;
    //       cautionval.value = tarifAnnuels.caution;
    //       if (fraiscleval.value &&  cautionval.value && loyert.value) {
    //         montanttotalval.value = fraiscleval.value + cautionval.value + loyert.value;
    //       }
    //     } catch (error) {
    ///
    //     }
    //   }
    // }

    const getTarifAnnuel = async (id: number) => {
      return ApiService.get("/tarifAnnuels/" + id)
        .then(({ data }) => {
          for (const key in data.data) {
            tarifAnnuelForm.value?.setFieldValue(key, data.data[key]);
          }
          tarifAnnuelForm.value?.setFieldValue('categorie', data.data.categorie.id);
          tarifAnnuelForm.value?.setFieldValue('modeleBoite', data.data.modeleBoite.id);
          emit('openmodal', addTarifAnnuelModalRef.value);

        })
        .catch(({ response }) => {
          //error(response.data.message)
        });
    }

    const addTarifAnnuel = async (values: any, tarifAnnuelForm) => {

      values = values as TarifAnnuel;
      if (isUPDATE.value) {
        ApiService.put("/tarifAnnuels/" + values.id, values)
          .then(({ data }) => {
            if (data.code == 200) {
              success(data.message);
              localItem.value = 0;
              tarifAnnuelForm.resetForm();
              hideModal(addTarifAnnuelModalRef.value);
              isUPDATE.value = false;
              btnTitle();
              emit('close');
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        ApiService.post("/tarifAnnuels", values)
          .then(({ data }) => {
            if (data.code == 201) {
              success(data.message);
              tarifAnnuelForm.resetForm();
              hideModal(addTarifAnnuelModalRef.value);
              emit('close');
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      }

    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;

        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_boites');
        const modelesData = response.data.data;
        modeleOptions.value = modelesData.map((modele) => ({
          value: modele.id,
          label: modele.code,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCategorieAbonnes();
      fetchModeles();
    });


    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
      isUPDATE.value = false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return {
      options, tarifAnnuelSchema, addTarifAnnuelModalRef,
      addTarifAnnuel, tarifAnnuelForm, modeles, modeleOptions,
      categorieAbonnes, categorieAbonneOptions, btntext, title,
      resetValue
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Liste des modes de paiements" />
    <ListeModePaiement/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeModePaiement from "../../components/ModePaiement/ListeModePaiement.vue";

    export default defineComponent({
      name: "ListeModePaiementPage",
      components: {
        BreadCrumb,
        ListeModePaiement,
      },
    });
  </script>
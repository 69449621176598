<template>
  <div
    class="modal fade createNewModal"
    id="genererRedevanceModal"
    tabindex="-1"
    aria-hidden="true"
    ref="genererRedevanceModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">Géneration de redevances</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="redevanceForm" @submit="genererRedevances" :validation-schema="redevanceSchema">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                        Catégorie d'abonné
                    </label>
                    <Field name="categorieAbonne" v-slot="{ field }">
                        <Multiselect 
                        :options="categorieAbonneOptions" 
                        :searchable="true" 
                        v-model="field.value"
                        v-bind="field"
                        placeholder="Sélectionner la catégorie de l'abonné"
                        />
                    </Field>
                    <ErrorMessage name="categorieAbonne" class="text-danger"/>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                            Modèle
                        </label>
                        <Field name="modeleBoite" v-slot="{ field }">
                            <Multiselect 
                            :options="modeleOptions" 
                            :searchable="true" 
                            v-model="field.value"
                            v-bind="field"
                            />
                        </Field>
                        <ErrorMessage name="modeleBoite" class="text-danger"/>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                        Année
                    </label>
                    <Field name="annee" v-slot="{ field }">
                        <Multiselect 
                            :options="years" 
                            :searchable="true" 
                            v-model="field.value"
                            v-bind="field"
                            placeholder="Sélectionner une année"
                        />
                    </Field>
                    <ErrorMessage name="annee" class="text-danger"/>
                    </div>
                </div>
                <div class="col-md-12">
                <div class="d-flex align-items-center ">
                    <button
                    class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                    type="submit"
                    >
                        Générer une redevance
                    </button>
                    <button 
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      class="btn btn-danger transition border-0 lh-1 fw-medium">
                      <i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                      <span class="position-relative"></span>Annuler
                    </button>
                </div>
                </div>
            </div>
        </Form>
        </div>
        <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error , hideModal, success, } from '@/utils/utils';
import { EnvoieLotDetail } from '@/models/EnvoieLotDetail';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import { hide } from '@popperjs/core';

export default defineComponent({
  name: "GenererRedevanceModal",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: (_, { emit }) => {
  const redevanceSchema = Yup.object().shape({
    categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonné est obligatoire'),
    modeleBoite: Yup.number().typeError('Veuillez entrer un nombre').required('Le modèle est obligatoire'),
    annee: Yup.string().required('L\'année est obligatoire'),
  });

  const redevanceForm = ref<null | EnvoieLotDetail>(null);
  const abonnementOptions = ref([]);
  const categorieAbonneOptions = ref([]);
  const modeleOptions = ref([]);
  const genererRedevanceModalRef = ref<null | HTMLElement>(null);    
  const year = ref(new Date().getFullYear());
  const years = ref(
    Array.from({ length: 1 }, (_, i) => {
        const year = new Date().getFullYear() + 1;
        return { value: year + i, label: year + i };
    })
  );


    const genererRedevances = async (value: any, redevanceForm) => {
        value = value as EnvoieLotDetail;
        value.estGenerer = 1;
        // ENREGISTREMENT DES REDEVANCES
        ApiService.post('/redevances/generer', value)
        .then(({ data }) => {
          if (data.code === 201) {
            return ApiService.post('/envoie/lot/details', value)
            .then(({ data }) => {
              if (data.code === 201) {
                success(data.message);
                emit('close');
                redevanceForm.resetForm();
                hideModal(genererRedevanceModalRef.value);
              }
            })
            .catch((err) => {
                //error(err.response.data.message);
                redevanceForm.resetForm();
                hideModal(genererRedevanceModalRef.value);
            });

          }else{
              // aucune opérations effectuées
              emit('close');
              redevanceForm.resetForm();
              hideModal(genererRedevanceModalRef.value);
              success(data.message);
          }
        })
        .catch((err) => {
            hideModal(genererRedevanceModalRef.value);
            error(err.response.data.message);
        });
    };

    const fetchCategorieAbonnes = async () => {
        return ApiService.get('/categorieAbonnes')
        .then(({ data }) => {
        if (data.code === 200) {
            // abonnements.value = data.data;
            categorieAbonneOptions.value = data.data.data.map((categorieAbonne: any) => ({
            value: categorieAbonne.id,
            label: categorieAbonne.code+' [ '+categorieAbonne.libelle+' ] ',
            }));
        }
        })
        .catch((response) => {
          error(response.data.message);
        });
    };

    const fetchModeles = async () => {
      ApiService.get("/modele_boites")
      .then(({ data }) => {
        const donnees = data.data;
        modeleOptions.value = donnees.map((modele) => {
          return {
            value: modele.id,
            label: modele.code+" [ "+modele.libelle+" ]",
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }


    onMounted(() => {
    fetchCategorieAbonnes();
    fetchModeles();

  });

  return { redevanceSchema, redevanceForm,
     categorieAbonneOptions, modeleOptions ,
     years, genererRedevanceModalRef,
      genererRedevances,};
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Déplacer un colis" />
    <MoveColis />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import MoveColis from "../../components/Colis/MoveColis.vue";
  
    export default defineComponent({
      name: "MoveColisPage",
      components: {
        BreadCrumb,
        MoveColis,
      },
    });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des notifications de campagne" />
    <ListeNotificationCampagne />
</template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ListeNotificationCampagne from "../../components/Notification/ListeNotificationCampagne.vue";
  
  
  export default defineComponent({
    name: "ListeNotificationCapagnePage",
    components: {
      BreadCrumb,
      ListeNotificationCampagne,
    },
  });
  </script>
<template>
    <BreadCrumb PageTitle="Liste des modèles de notification" />
    <ListeModeleNotification/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeModeleNotification from "../../components/ModeleNotification/ListeModeleNotification.vue";

  
    export default defineComponent({
      name: "ListeModeleNotificationPage",
      components: {
        BreadCrumb,
        ListeModeleNotification,
      },
    });
  </script>
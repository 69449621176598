<template>
    <BreadCrumb PageTitle="Liste des remboursements" />
    <ListeRemboursement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeRemboursement from "../../components/Remboursement/ListeRemboursement.vue";
    export default defineComponent({
      name: "ListeRemboursementPage",
      components: {
        BreadCrumb,
        ListeRemboursement,
      },
    });
  </script>
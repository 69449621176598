<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="campagneForm" @submit="editCampagne" :validation-schema="campagneSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  NOM DE LA CAMPAGNE <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  DATE DE DEBUT <span class="text-danger">*</span>
                </label>
                <Field name="dateDebut" type="date" 
                class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date de début"/>
                <ErrorMessage name="dateDebut" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  DATE DE FIN <span class="text-danger">*</span>
                </label>
                <Field name="dateFin" type="date" 
                class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date de fin"/>
                <ErrorMessage name="dateFin" class="text-danger"/>
              </div>
            </div>
          </div>

            <div class="row">
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de campagne <span class="text-danger">*</span>
                </label>
                <Field name="typeDeCampagne" v-model="typeDeCampagneSelected" v-slot="{ field }">
                  <Multiselect 
                    :options="typeDeCampagneOption" 
                    :searchable="true"
                    v-model="field.value"
                    :allow-empty="false"
                    v-bind="field"
                    placeholder="Sélectionner le type"
                  />
                </Field>
                <ErrorMessage name="typeDeCampagne" class="text-danger"/>
              </div>
            </div>
            <div class="col" v-show="showTauxField">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Taux (en %)
                </label>
                <Field v-model="valeurTaux" name="taux" type="number" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de fois"/>
                  <ErrorMessage name="taux" class="text-danger"/>
              </div>
            </div>
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie d'abonné 
                </label>
                <Field name="categorieAbonne"  v-slot="{ field }">
                  <Multiselect 
                    :options="categorieAbonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    :multiple="true"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>
                <ErrorMessage name="categorieAbonne" class="text-danger"/>
              </div>
            </div>
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de Notification <span class="text-danger">*</span>
                </label>
                <Field name="typeNotification"  v-slot="{ field }">
                  <Multiselect 
                    :options="typeNotificationOptions" 
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type"
                  />
                </Field>
                <ErrorMessage name="typeNotification" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Nombre de fois  <span class="text-danger">*</span>
                </label>
                <Field v-model="nmbreDeFois" name="nmbreDeFois" type="number" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de fois"/>
                  <ErrorMessage name="nmbreDeFois" class="text-danger"/>
              </div>
            </div>
            </div>
            <!--<div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie d'abonné <span class="text-danger">*</span>
                </label>
                <Field name="categorieAbonne" v-slot="{ field }">
                  <Multiselect
                    :options="categorieAbonneOptions"
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner la catégorie de l'abonné"
                  />
                </Field>
                <ErrorMessage name="categorieAbonne" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de Notification <span class="text-danger">*</span>
                </label>
                <Field name="typeNotification" v-slot="{ field }">
                  <Multiselect 
                    :options="typeNotificationOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type de notification"
                  />
                </Field>
                <ErrorMessage name="typeNotification" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Nombre de fois
                </label>
                <Field v-model="nmbreDeFois" name="nmbreDeFois" type="number" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de fois"/>
                  <ErrorMessage name="nmbreDeFois" class="text-danger"/>
              </div>
            </div>-->
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  DESCRIPTION <span class="text-danger">*</span>
                </label>
                <Field name="description" as="textarea" v-slot="{ field }" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                    v-model="field.value"
                    cols="90"
                    rows="3"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="Entrer la description"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Image
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png"
                      @change="onFileChange"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
            
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Modifier la campagne
                </button>
                <router-link to="/liste-campagnes" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
                <!--<button type="button" class=" btn btn-danger transition border-0 lh-1 fw-medium" >
                  <i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                  <span class="position-relative">Annuler</span>
                </button>-->
              </div>
            </div>
          <!--</div>-->
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import ApiService from '@/services/ApiService';
import { Campagne } from '@/models/Campagne';
import * as Yup from 'yup';
import router from '@/router';
import { useRoute } from "vue-router";
import { success, error } from '@/utils/utils';
import axios from 'axios';

export default defineComponent({
    name: "EditCampagne",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const campagneSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      description: Yup.string().notRequired(),
      dateDebut: Yup.date().required('La date de début est obligatoire'),
      dateFin: Yup.date().required('La date de fin est obligatoire'),
      nmbreDeFois: Yup.number().required('Le nombre de fois est obligatoire'),
    });

    const campagneForm = ref<null | Campagne>(null);
    const route = useRoute();
    const id = route.params.id;
    const categorieAbonneOptions = ref([]);
    const typeNotificationOptions = ref([]);
    const nmbreDeFois = ref(0);
    const selectedFile = ref<File | null>();
    const categorieAbonneId = ref(0);
    const typeDeCampagneOption = ref([
      { value: 'Autres', label: 'Autres' },
      { value: 'Reduction', label: 'Reduction' },
    ]);
    const typeDeCampagneSelected = ref("");
    const showTauxField = ref(false);
    const valeurTaux = ref(0);


    const onFileChange = (e) => {
      selectedFile.value = e.target.files[0];
    };

    const lordEditData = async () => {
      ApiService.get("/campagnes/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        categorieAbonneId.value = donnees.categorieAbonne.id;
        
        for (const key in donnees) {
          if (['dateDebut','dateFin'].includes(key)) {
            let date = new Date(donnees[key]);
            let formattedDate = date.toISOString().split('T')[0];
            campagneForm.value?.setFieldValue(key, formattedDate);
          }else {
            campagneForm.value?.setFieldValue(key, 
              (typeof donnees[key] === 'object' && donnees[key] !== null)? 
              donnees[key].id
              : donnees[key] 
            );
          }
        }
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

  

    const editCampagne = async (values: any) => {
      
      const date = new Date(values.dateFin);
      date.setHours(23, 59, 59, 999);
      values.dateFin = date;

      let formData = new FormData();
      for(let i in values){
        if(i != 'urlImage')
          formData.append(i, values[i]);
      }
      
      if (selectedFile.value && selectedFile.value !== null && selectedFile.value !== undefined) {
        formData.append('urlImage', selectedFile.value);
      }else{
        formData.append('urlImage', "");
      }

      axios.put("/campagnes/"+values.id,formData, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              router.push({ name: 'ListeCampagnePage' });
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchTypeOptions = async () => {
      ApiService.get("/typeNotifications")
        .then(({ data }) => {
          // map data
          const donnees = data.data;
          typeNotificationOptions.value = donnees.map((type: any) => {
            return {
              label: type.libelle,
              value: type.id,
            };
          });
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    onMounted(async() => {
      await fetchCategorieAbonnes();
      await fetchTypeOptions();
      await lordEditData();
     if(valeurTaux.value > 0) showTauxField.value = true;
     console.log("AAAAAA ",categorieAbonneId);
    });

    return { 
      campagneSchema,
      fetchTypeOptions,
      nmbreDeFois,
      lordEditData,
      fetchCategorieAbonnes,
      editCampagne,
      campagneForm,
      typeNotificationOptions,
      categorieAbonneOptions,
      onFileChange,
      categorieAbonneId,
      typeDeCampagneOption,
      showTauxField,
      typeDeCampagneSelected,
      valeurTaux,
    };
  },
});
</script>
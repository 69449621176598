<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="boitePostaleForm" @submit="editBoitePostale" :validation-schema="boitePostaleSchema">
        <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Référence de la boîte
                </label>
                <Field name="refBoite" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="refBoite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Couleur
                </label>
                <Field name="couleur" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="couleur" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Dimension
                </label>
                <Field name="dimension" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="dimension" class="text-danger"/>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Bureau
                </label>
                <Field name="bureau" v-slot="{ field }">
                  <Multiselect 
                    :options="bureauOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="bureau" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Modèle
                </label>
                <Field name="modele" v-slot="{ field }">
                  <Multiselect 
                    :options="modeleOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    noResultText= "Aucun enregistrement trouvé"
                    v-bind="field"
                  />
                </Field>
                <ErrorMessage name="modele" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Modifier une boîte postale
              </button>

              <router-link to="/liste-boites" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">

import { onMounted, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import  Swal  from "sweetalert2";
import * as Yup from 'yup';
import { useRoute } from "vue-router";
import ApiService from '@/services/ApiService';
import { hideModal, showModal, error } from '@/utils/utils';
import { BoitePostale } from '@/models/BoitePostale';
import Multiselect from '@vueform/multiselect'
import router from '@/router';

export default ({
  name: "EditBoitePostale",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      required: true,
    },
  },
  setup(_, { emit }) {
    const boitePostaleSchema = Yup.object().shape({
      couleur: Yup.string().notRequired(),
      dimension: Yup.string().notRequired(),
      refBoite: Yup.string().required('La référence de la boite est obligatoire'),
      bureau: Yup.string().required('Le bureau est obligatoire'),
      modele: Yup.string().required('Le modèle est obligatoire'),
      // etat: Yup.boolean().required('L\'état est obligatoire'),
      // estOfficiel: Yup.boolean().required('La boîte est offcielle ?'),
    });

    const boitePostaleForm = ref<null | BoitePostale>(null);
    // const bureaux = ref<Bureau | null>(null);
    const bureauOptions = ref([]);
    // const modeles = ref<Modele | null>(null);
    const modeleOptions = ref([]);
    const addBoitePostaleRef = ref<null | HTMLElement>(null);
    const isUPDATE = ref(false);
    const route = useRoute();
    const id = route.params.id;


    const lordEditData = async () => {
      ApiService.get("/boitePostales/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        for (const key in donnees) {
          boitePostaleForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        isUPDATE.value = true;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const editBoitePostale = async (values: any) => {
        values = values as BoitePostale;
        ApiService.put("/boitePostales/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) {
                Swal.fire({
                    text: data.message,
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    heightAuto: false,
                    customClass: {
                        confirmButton: "btn fw-semobold btn-primary",
                    },
                });
                router.push({ name: 'ListeBoitePostalePage' });
            }
        })
        .catch(({ response }) => {
            Swal.fire({
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réssayer à nouveau!",
                heightAuto: false,
                customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                },
                timer:10
            });
        });
    };

    const fetchBureaux = async () => {
      ApiService.get("/bureaus")
      .then(({ data }) => {
        const donnees = data.data.data;
        bureauOptions.value = donnees.map((bureau) => {
          return {
            value: bureau.id,
            label: bureau.nom,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchModeles = async () => {
      ApiService.get("/modele_boites")
      .then(({ data }) => {
        const donnees = data.data;
        modeleOptions.value = donnees.map((modele) => {
          return {
            value: modele.id,
            label: modele.libelle,
          }
        });
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    onMounted(() => {
      fetchBureaux();
      fetchModeles();
      lordEditData();
    });

    return { boitePostaleSchema, editBoitePostale, lordEditData, boitePostaleForm, modeleOptions, bureauOptions};
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_DonutChart = _resolveComponent("DonutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.etatLibelleCat.length > 0 && _ctx.etatDonneCat.length > 0)
        ? (_openBlock(), _createBlock(_component_PieChart, {
            key: 0,
            colors: _ctx.colors,
            labels: _ctx.etatLibelleCat,
            project: _ctx.etatDonneCat,
            nom: "Nombre d’abonnements par catégorie d'abonnés"
          }, null, 8, ["colors", "labels", "project"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.etatDonne.length > 0)
        ? (_openBlock(), _createBlock(_component_DonutChart, {
            key: 0,
            colors: _ctx.colors,
            labels: _ctx.etatLibelle,
            project: _ctx.etatDonne,
            nom: "Nombre d’abonnements par statut"
          }, null, 8, ["colors", "labels", "project"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
<template>
    <BreadCrumb PageTitle="Modifier la campagne" />
    <EditCampagne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditCampagne from "../../components/Campagne/EditCampagne.vue";
  
    export default defineComponent({
      name: "EditCampagnePage",
      components: {
        BreadCrumb,
        EditCampagne,
      },
    });
  </script>
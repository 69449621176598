<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="campagneForm" @submit="addCampagne" :validation-schema="campagneSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Nom de la campagne <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date Début <span class="text-danger">*</span>
                </label>
                <Field name="dateDebut" type="date" 
                class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date de début"/>
                <ErrorMessage name="dateDebut" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date Fin <span class="text-danger">*</span>
                </label>
                <Field name="dateFin" type="date" 
                class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date de fin"/>
                <ErrorMessage name="dateFin" class="text-danger"/>
              </div>
            </div>
            <div class="row">
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de campagne <span class="text-danger">*</span>
                </label>
                <Field name="typeDeCampagne" v-model="typeDeCampagneSelected" v-slot="{ field }">
                  <Multiselect 
                    :options="typeDeCampagneOption" 
                    :searchable="true"
                    v-model="field.value"
                    :allow-empty="false"
                    v-bind="field"
                    placeholder="Sélectionner le type"
                  />
                </Field>
                <ErrorMessage name="typeDeCampagne" class="text-danger"/>
              </div>
            </div>
            <div class="col" v-show="showTauxField">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Taux (en %)
                </label>
                <Field v-model="valeurTaux" name="taux" type="number" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de fois"/>
                  <ErrorMessage name="taux" class="text-danger"/>
              </div>
            </div>
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie d'abonné 
                </label>
                <Field name="categorieAbonne" v-slot="{ field }">
                  <Multiselect 
                    :options="categorieAbonneOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    :multiple="true"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>
                <ErrorMessage name="categorieAbonne" class="text-danger"/>
              </div>
            </div>
            <div class="col">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de Notification <span class="text-danger">*</span>
                </label>
                <Field name="typeNotification" v-slot="{ field }">
                  <Multiselect 
                    :options="typeNotificationOptions" 
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le type"
                  />
                </Field>
                <ErrorMessage name="typeNotification" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Nombre de fois  <span class="text-danger">*</span>
                </label>
                <Field v-model="nmbreDeFois" name="nmbreDeFois" type="number" aria-disabled="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de fois"/>
                  <ErrorMessage name="nmbreDeFois" class="text-danger"/>
              </div>
            </div>
          </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Description  <span class="text-danger">*</span>
                </label>
                <Field name="description" as="textarea" v-slot="{ field }" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                    v-model="field.value"
                    cols="90"
                    rows="3"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="Entrer la description"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Image
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png"
                      @change="onFileChange"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
            
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Créer une campagne
                </button>
                <router-link to="/liste-campagnes" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
              </div>
            </div>
          </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm, useField } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import { Campagne } from '@/models/Campagne';
import { error, hideModal, success } from '@/utils/utils';
import router from '@/router';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { type } from 'os';

export default defineComponent({
    name: "AddCampagne",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const campagneSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      description: Yup.string().required('La description est obligatoire'),
      dateDebut: Yup.date().required('La date de début est obligatoire'),
      dateFin: Yup.date().required('La date de fin est obligatoire'),
      nmbreDeFois: Yup.number().min(1, 'La valeur doit être d’au moins 1').required('Le nombre de fois est obligatoire'),
      typeNotification: Yup.number().required('Le type de notification est obligatoire'),
      typeDeCampagne: Yup.string().required('Le type de campagne est obligatoire'),
      // urlImage: Yup.string().typeError('Veuillez entrer un nombre')
      //   .when([], (value, schema) => {
      //   return  schema 
      // }),
      // taux: Yup.string(),
      // categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre')
      //   .when([], (value, schema) => {
      //   return  schema 
      // }),
    });

    const campagneForm = ref<null | Campagne>(null);
    const categorieAbonneOptions = ref([]);
    const typeNotificationOptions = ref([]);
    // const myQuillEditor = ref<InstanceType<typeof Quill> | null>(null);
    const nmbreDeFois  = ref(1);
    const valeurTaux = ref(0);
    const isUPDATE     = ref(false);
    const selectedFile = ref<File | null>();
    const typeDeCampagneOption = ref([
      { value: 'Autres', label: 'Autres' },
      { value: 'Reduction', label: 'Reduction' },
    ]);
    const typeDeCampagneSelected = ref("");
    const showTauxField = ref(false);


    const onFileChange = (e) => {
      selectedFile.value = e.target.files[0];
    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    const fetchTypeOptions = async () => {
      ApiService.get("/typeNotifications")
        .then(({ data }) => {
          // map data
          const donnees = data.data;
          typeNotificationOptions.value = donnees.map((type: any) => {
            return {
              label: type.libelle,
              value: type.id,
            };
          });
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const addCampagne = async (values: any ) => {
      // values.categorieAbonne = values.categorieAbonne ?? null;

      const date = new Date(values.dateFin);
      date.setHours(23, 59, 59, 999);
      values.dateFin = date;

      let formData = new FormData();
      for(let i in values){
        if(i != 'urlImage' && (values[i] != 'undefined')){
          formData.append(i, values[i]);
        }
      }
      
      formData.append('folderName', 'campagne');

      if (selectedFile.value) {
        formData.append('urlImage', selectedFile.value);
      }

        
      // Enregistrement de la campagne
      formData.append('code', 'CAMP'+Date.now());
      axios.post("/campagnes",formData, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
      .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            router.push({ name: 'ListeCampagnePage' });
          }
      })
      .catch(({ response }) => {
          error(response);
          error(response.data.message);
      });
      
    };

    watch(typeDeCampagneSelected, (newValue, oldValue) => {
      if (newValue === 'Reduction') {
        showTauxField.value = true;
      }else{
        showTauxField.value = false;
      }
    });

    onMounted(() => {
      fetchCategorieAbonnes();
      fetchTypeOptions();
      campagneForm.value?.setFieldValue('dateDebut', new Date().toISOString().substr(0, 10));
      campagneForm.value?.setFieldValue('dateFin', new Date().toISOString().substr(0, 10));
    });

    return { campagneSchema,
     addCampagne,
     campagneForm,
     categorieAbonneOptions,
     typeNotificationOptions,
     nmbreDeFois,
     valeurTaux,
     selectedFile,
     onFileChange,
     typeDeCampagneOption,
     typeDeCampagneSelected,
     showTauxField
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
    <BreadCrumb PageTitle="Etats des demandes" />
    <EtatDemande />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EtatDemande from "../../components/Etats/EtatDemande.vue";

  
    export default defineComponent({
      name: "EtatDemandePage",
      components: {
        BreadCrumb,
        EtatDemande,
      },
    });
  </script>
<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="redevanceForm" @submit="editRedevance" :validation-schema="redevanceSchema">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Date <span class="text-danger">*</span>
            </label>
            <Field name="dateRed" type="date" 
            class="form-control shadow-none rounded-0 text-black"/>
              <ErrorMessage name="dateRed" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement <span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                  />
                </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
          </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier une redevance
            </button>
            <router-link to="/liste-redevances" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'


import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
  name: "EditRedevance",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const redevanceSchema = Yup.object().shape({
    abonnement: Yup.string().required('L\' abonnement est obligatoire'),
    dateRed: Yup.date().required('La date est obligatoire'),
  });

  const redevanceForm = ref(null);
  const abonnements = ref(null);
  const abonnementOptions = ref([]);

  const editRedevance = async (formData: any) => {
    //
  };

  const fetchAbonnements = async () => {
      try {
        const response = await axios.get('/abonnements');
        const abonnementsData = response.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id,
          label: `${abonnement.refAbo} - [${abonnement.abonne.nomComplet}]`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchAbonnements();
    });

  return { redevanceSchema, editRedevance, redevanceForm, abonnements, abonnementOptions };
},
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
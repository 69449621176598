<template>
    <BreadCrumb PageTitle="Modifier un casier" />
    <EditCasier />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditCasier from "../../components/Casier/EditCasier.vue";
  
    export default defineComponent({
      name: "EditCasierPage",
      components: {
        BreadCrumb,
        EditCasier,
      },
    });
  </script>
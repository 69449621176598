<template>
    <BreadCrumb PageTitle="Réceptionner un colis" />
    <AddColis />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddColis from "../../components/Colis/AddColis.vue";
  
    export default defineComponent({
      name: "AddColisPage",
      components: {
        BreadCrumb,
        AddColis,
      },
    });
  </script>
<template>
  <div
    class="modal fade createNewModal"
    id="AddTypeNotificationModal"
    tabindex="-1"
    ref="addTypeNotificationModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
        <Form ref="typeNotificationForm" @submit="addTypeNotification" :validation-schema="typeNotificationSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libelle <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Canal <span class="text-danger">*</span>
                </label>
                <Field name="canal" v-slot="{ field }">
                  <Multiselect
                    :options="canalOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le canal"
                  />
                </Field>
                <ErrorMessage name="canal" class="text-danger"/>
              </div>
            </div> -->
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { TypeNotification } from '@/models/TypeNotification';
// import Multiselect from '@vueform/multiselect'



export default {
  name: "AddTypeNotificationModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    // Multiselect,
  },
  props:{
    id: {
      type: Number,
      required: true,
      default:0
    },
  },
  // emits: ["getall",'openmodal'],

  setup: (props, { emit }) => {

    const loading = ref<boolean>(false);
    const typeNotificationSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libellé est obligatoire'),
      // canal: Yup.string().required('Le canal est obligatoire'),
    });

    const typeNotificationnew = ref(props.id);
    const typeNotificationForm =  ref<TypeNotification | null>(null);
    const addTypeNotificationModalRef = ref<null | HTMLElement>(null);
    const canalOptions = ref([
      { value: 'SMS', label: 'SMS' },
      { value: 'Email', label: 'Email' },
      { value: 'Push', label: 'Push' },
    ]);
    const title     = ref('Ajouter un type de notification');
    const btntext   = ref('Ajouter');
    const isupdate  = ref(false);

    watch(() => props.id , (newValue) => {   
      if (newValue!=0) {
        getTypeNotification(newValue);
        isupdate.value=true;
      }
      btnTitle();
    });

    const getTypeNotification = async (id: number) => {
      
      return ApiService.get("/typeNotifications/"+id)
      .then(({ data }) => {
        for (const key in data) {
          typeNotificationForm.value?.setFieldValue(key, data[key]);
        }
        emit('openmodal', addTypeNotificationModalRef.value);
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const btnTitle = async () => {
      if (isupdate.value) {
         title.value = "Modifier le type de notification";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un type de notification";
         btntext.value = "Ajouter";
      }
    }

    const addTypeNotification = async (values,{ resetForm }) => {
      values = values as TypeNotification;
      loading.value = false;
      if(isupdate.value) {
        ApiService.put("/typeNotifications/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            hideModal(addTypeNotificationModalRef.value);
            isupdate.value=false;
            btnTitle();
            emit("getall");
          }
        }).catch(({ response }) => {
          error(response.message);
        });
      }else{
        ApiService.post("/typeNotifications",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message)
            resetForm();
            hideModal(addTypeNotificationModalRef.value);
            emit("getall");
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }
    }; 

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isupdate.value=false;
        formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return {addTypeNotification, title, btntext, typeNotificationSchema, canalOptions, typeNotificationForm, addTypeNotificationModalRef, resetValue}
    // return {title,btntext, typeNotificationSchema, addTypeNotification, typeNotificationForm,addTypeNotificationModalRef,typeNotificationnew, canalOptions };
  },
};
</script>